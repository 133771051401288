import { useEffect, useRef, useState } from 'react';

const useResize = () => {
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setWrapperWidth(ref.current.offsetWidth);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { ref, wrapperWidth };
};

export default useResize;
