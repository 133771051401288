interface Props {
  active?: boolean;
  id: string;
}

const IconActuality = ({ active, id }: Props) => (
  <svg
    fill="none"
    height="32"
    id={id}
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="16"
      cy="16"
      fill={active ? '#D2DDED' : '#C4C4C4'}
      rx="16"
      ry="16"
    />
    <path
      clipRule="evenodd"
      d="M9.06136 7.47089C9.5362 6.96439 10.1945 6.66667 10.8958 6.66667H17.6666H17.9791C18.2555 6.66667 18.5196 6.78108 18.7086 6.98273L23.3944 11.9809C23.5632 12.1599 23.6666 12.4012 23.6666 12.6667V22.6667C23.6666 23.3538 23.4113 24.0248 22.9385 24.5291C22.4636 25.0356 21.8053 25.3333 21.1041 25.3333H10.8958C10.1945 25.3333 9.5362 25.0356 9.06136 24.5291C8.58857 24.0248 8.33325 23.3538 8.33325 22.6667V9.33334C8.33325 8.64617 8.58857 7.9752 9.06136 7.47089ZM10.8958 8.66667H16.6666V12.6667C16.6666 13.219 17.1143 13.6667 17.6666 13.6667H21.6666V22.6667C21.6666 22.8636 21.5927 23.0404 21.4794 23.1612C21.3682 23.2799 21.2317 23.3333 21.1041 23.3333H10.8958C10.7682 23.3333 10.6316 23.2799 10.5204 23.1612C10.4072 23.0404 10.3333 22.8636 10.3333 22.6667V9.33334C10.3333 9.13645 10.4072 8.95957 10.5204 8.83877C10.6316 8.72015 10.7682 8.66667 10.8958 8.66667ZM18.6666 11.6667H20.3584L18.6666 9.86212V11.6667ZM12.6666 12.5C12.1143 12.5 11.6666 12.9477 11.6666 13.5C11.6666 14.0523 12.1143 14.5 12.6666 14.5H14.3333C14.8855 14.5 15.3333 14.0523 15.3333 13.5C15.3333 12.9477 14.8855 12.5 14.3333 12.5H12.6666ZM12.6666 15.8333C12.1143 15.8333 11.6666 16.2811 11.6666 16.8333C11.6666 17.3856 12.1143 17.8333 12.6666 17.8333H19.3333C19.8855 17.8333 20.3333 17.3856 20.3333 16.8333C20.3333 16.2811 19.8855 15.8333 19.3333 15.8333H12.6666ZM12.6666 19.1667C12.1143 19.1667 11.6666 19.6144 11.6666 20.1667C11.6666 20.719 12.1143 21.1667 12.6666 21.1667H19.3333C19.8855 21.1667 20.3333 20.719 20.3333 20.1667C20.3333 19.6144 19.8855 19.1667 19.3333 19.1667H12.6666Z"
      fill="#0C2342"
      fillRule="evenodd"
    />
  </svg>
);

export default IconActuality;
