import { breakpoints, color, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  footerWrapper: {
    marginLeft: getRem(340),
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      marginLeft: 0,
    },
    '& footer': {
      background: color('white'),
      padding: '1.3rem 0 !important',
      borderTop: '1px solid #e4e5e5',
      '& > div': {
        maxWidth: '100%',
        margin: 0,
        '& .footer-app__copyright': {
          color: color('grey', 500),
        },
        '& .link': {
          color: color('grey', 500),
        },
      },
      [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
        padding: '1.3rem 0 !important',
      },
      [`@media screen and (max-width: ${breakpoints.m}px)`]: {
        padding: '.5rem 0 !important',
      },
    },
  },
});
