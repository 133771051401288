import StaticMenuLayout from '../../layouts/StaticMenuLayout';
import { ButtonLayout, Illustration } from '@dovera/design-system';
import { cx } from '../../utils/exports';
import useStyles from './OldEpFunc.styles';
import { useQuery } from '../../hooks/useQuery';
import { epSekcie } from '../../constants/epLinks';

const OldEpFunc = () => {
  const classes = useStyles();
  const query = useQuery();
  const url = query.get('sekcia') ? epSekcie?.[query.get('sekcia') || ''] : '/';
  return (
    <StaticMenuLayout>
      <div className={cx('align-items-center text-center', classes.root)}>
        <Illustration
          height={173}
          name="large_waiting_state"
          size="large"
          width={208}
        />
        <h3 className="mb-small">
          Túto sekciu nájdete v elektronickej pobočke
        </h3>
        <div>
          Zjednodušujeme viaceré elektronické služby, ktoré sem budú postupne
          pribúdať.
          <br />
          Zatiaľ ich nájdete v elektronickej pobočke, ako ste boli doteraz
          zvyknutí.
        </div>
        <ButtonLayout preserveWidth>
          <a
            className="btn"
            href={url}
            rel="noopener noreferrer"
            target="_blank"
          >
            Prejsť do elektronickej pobočky
          </a>
        </ButtonLayout>
      </div>
    </StaticMenuLayout>
  );
};

export default OldEpFunc;
