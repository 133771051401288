import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CheckType,
  GetListOfAvailableDoctorsResponse,
  ProposalCheck,
  ProposalDiagnose,
  StepStatus,
} from '../types/proposals.types';
import {
  CheckProposalDataResponse,
  DrugProduct,
  DrugProposalsState,
  GetFormDataResponse,
  ProposalHeader,
  SaveProposalResponse,
} from '../types/drugProposals.types';
import {
  DEFAULT_INDICATOR_LIMITATION_CODE,
  drugProposalsInitialState,
} from '../constants/drugProposals';
import { formatNameStr } from '../utils/strings.utils';
import strings from '../constants/strings';
import { isLoadedTXSProposalControls } from '../utils/spaProposals.utils';
import { validationMsg } from '../utils/form.utils';
import { VALIDATION } from '../types/validation.types';
import {
  checkProposalData,
  getFormData,
  getListOfAvailableDoctors,
  saveProposal,
} from '../api/drugProposals';
import { LoadingState } from '../types';
import { formScroll } from '../utils/drugProposals.utils';
import { Dokument } from '../types/models/Dokument';

const initialState: DrugProposalsState = drugProposalsInitialState;

const drugProposalsSlice = createSlice({
  name: 'drugProposalsSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getListOfAvailableDoctors.pending.toString(), (state) => ({
        ...state,
        new: {
          ...state.new,
          data: {
            ...state.new.data,
            doctors: {
              ...initialState.new.data.doctors,
              isLoading: true,
            },
          },
        },
      }))
      .addCase(
        getListOfAvailableDoctors.fulfilled.toString(),
        (state, action: PayloadAction<GetListOfAvailableDoctorsResponse>) => ({
          ...state,
          new: {
            ...state.new,
            data: {
              ...state.new.data,
              doctors: {
                ...initialState.new.data.doctors,
                data: action.payload?.zoznamDostupnychLekarov,
              },
            },
          },
        }),
      )
      .addCase(getListOfAvailableDoctors.rejected.toString(), (state) => ({
        ...state,
        new: {
          ...state.new,
          data: {
            ...state.new.data,
            doctors: {
              ...initialState.new.data.doctors,
              error: strings.defaultError,
            },
          },
        },
      }))
      .addCase(checkProposalData.pending.toString(), (state, action: any) => {
        const checkTypes: CheckType[] = action?.meta?.arg?.checkTypes;
        state.new.data.controls.isLoadingControl = checkTypes || null;
        state.new.data.controls.error = '';
        if (checkTypes?.includes('PoistnyVztah'))
          state.new.data.controls.data = [];
        setTimeout(() => formScroll(checkTypes), 500);
      })
      .addCase(
        checkProposalData.fulfilled.toString(),
        (state, action: PayloadAction<CheckProposalDataResponse>) => {
          const { payload } = action;
          const obj =
            payload?.data?.filter(
              (d) => d.checkType === 'PoistnyVztah' && d.additionalInfo,
            )?.[0]?.additionalInfo || null;
          let controls = state.new.data.controls.data;
          const removeIndexes: number[] = [];
          payload?.data
            ?.map((u) => u.checkType)
            .forEach((t) => {
              const index = controls.findIndex((o) => o.checkType === t);
              if (index !== -1) removeIndexes.push(index);
            });
          controls = controls
            .filter((c, key) => !removeIndexes.includes(key))
            .map((c) => c);
          state.new.data.controls = {
            ...state.new.data.controls,
            error: '',
            errorInsuree: '',
            isLoadingControl: null,
            data: [
              ...controls,
              ...(payload?.data?.map<ProposalCheck>((u) => ({
                additionalInfo: u.additionalInfo,
                checkType: u.checkType,
                description: u.errorDescription,
                errorCode: u.errorCode,
                errorWeight: u.errorWeightCode,
                result: u.checkResult,
              })) || []),
            ],
          };
          if (
            isLoadedTXSProposalControls(
              state.new.data.controls.data.map((d) => d.checkType),
            )
          ) {
            state.new.data.controls.isLoadedTXSControls = true;
          }
          if (obj) {
            state.new.stepper.step2.poiData = {
              address: obj.value3,
              dateOfBirth: obj.value6,
              firstName: obj.value2,
              lastName: obj.value1,
              mobile: obj.value4,
              sex: obj.value5,
            };
          }
          state.new.data.formData.formId = payload.formId;
        },
      )
      .addCase(checkProposalData.rejected.toString(), (state) => {
        state.new.data.controls.isLoadingControl = null;
        state.new.data.controls.error = strings.defaultError;
        state.new.data.controls.errorInsuree = validationMsg(
          VALIDATION.NotDZPInsuree,
        );
      })
      .addCase(getFormData.pending.toString(), (state) => {
        state.new.data.formData = {
          ...initialState.new.data.formData,
          dataState: LoadingState.fetching,
          formId: state.new.data.formData.formId,
        };
      })
      .addCase(
        getFormData.fulfilled.toString(),
        (state, action: PayloadAction<GetFormDataResponse>) => {
          state.new.data.formData = {
            ...state.new.data.formData,
            data: {
              ...action.payload,
              udajIndikacnehoObmedzenia:
                action.payload.udajIndikacnehoObmedzenia &&
                action.payload.udajIndikacnehoObmedzenia.some(
                  (i) =>
                    i.kodObmedzenia === DEFAULT_INDICATOR_LIMITATION_CODE.value,
                )
                  ? [
                      ...action.payload.udajIndikacnehoObmedzenia.filter(
                        (i) =>
                          i.kodObmedzenia !==
                          DEFAULT_INDICATOR_LIMITATION_CODE.value,
                      ),
                      ...action.payload.udajIndikacnehoObmedzenia.filter(
                        (i) =>
                          i.kodObmedzenia ===
                          DEFAULT_INDICATOR_LIMITATION_CODE.value,
                      ),
                    ]
                  : action.payload.udajIndikacnehoObmedzenia,
            },
            dataState: LoadingState.filled,
            error: null,
          };
        },
      )
      .addCase(getFormData.rejected.toString(), (state) => {
        state.new.data.formData = {
          ...state.new.data.formData,
          data: null,
          dataState: LoadingState.error,
          error: strings.technickaChyba,
        };
      })
      .addCase(saveProposal.pending.toString(), (state) => {
        state.new.save = {
          ...initialState.new.save,
          dataState: LoadingState.fetching,
        };
      })
      .addCase(
        saveProposal.fulfilled.toString(),
        (state, action: PayloadAction<SaveProposalResponse>) => {
          state.new = {
            ...initialState.new,
            save: {
              ...initialState.new.save,
              data: action.payload,
              dataState: LoadingState.filled,
              error: action.payload.chyba,
            },
          };
        },
      )
      .addCase(saveProposal.rejected.toString(), (state) => {
        state.new.save = {
          ...initialState.new.save,
          dataState: LoadingState.error,
          error: strings.technickaChyba,
        };
      });
  },
  reducers: {
    storeStep1(
      state,
      action: PayloadAction<{ ambulanceId: string; doctorId: string }>,
    ) {
      const doctor = state.new.data.doctors.data?.filter(
        (d) => d.idLekarZdravPrac === Number(action.payload.doctorId),
      )?.[0];
      const ambulance = doctor?.ambulancie?.filter(
        (a) => a.idNZ === Number(action.payload.ambulanceId),
      )?.[0];
      const { ambulanceId, doctorId } = action.payload;
      return {
        ...state,
        new: {
          ...state.new,
          isReseted: false,
          stepper: {
            ...(Number(ambulanceId) !== state.new.stepper.step1.ambulance?.id ||
            Number(doctorId) !== state.new.stepper.step1.doctor?.id
              ? initialState.new.stepper
              : state.new.stepper),
            step1: {
              ...state.new.stepper.step1,
              ambulance: {
                id: Number(ambulanceId),
                code: ambulance?.kodNZ || '',
                expertise: ambulance?.odbornostNZ || '',
                name: ambulance?.nazovNZ || '',
              },
              doctor: {
                id: Number(doctorId),
                code: doctor?.kodLekara || '',
                email: doctor?.email || '',
                expertise: doctor?.odbornostLekara || '',
                name: formatNameStr(doctor?.menoLekara || ''),
              },
            },
          },
        },
      };
    },
    storeStep2(
      state,
      action: PayloadAction<{
        insureeId: string;
      }>,
    ) {
      return {
        ...state,
        new: {
          ...state.new,
          stepper: {
            ...state.new.stepper,
            step1: state.new.stepper.step1,
            step2: {
              ...state.new.stepper.step2,
              insureeId: action.payload.insureeId,
            },
            step3: initialState.new.stepper.step3,
          },
        },
      };
    },
    storeStep3(
      state,
      action: PayloadAction<{
        dateFrom?: string;
        diagnose?: ProposalDiagnose | null;
        dosage?: string;
        dosageDescription?: string;
        epicrisis?: string;
        hasContraindication?: string;
        hasIndication?: string;
        hasMV?: string;
        indicatorLimitation?: {
          code: string;
          label: string;
        } | null;
        product?: {
          atc?: string;
          code: string;
          label: string;
        } | null;
        quantity?: number | string;
        reason?: string;
      }>,
    ) {
      const {
        dateFrom,
        diagnose,
        dosage,
        dosageDescription,
        epicrisis,
        hasContraindication,
        hasIndication,
        hasMV,
        indicatorLimitation,
        product,
        quantity,
        reason,
      } = action.payload;

      const hasIndicationValue =
        hasIndication ?? state.new.stepper.step3.hasIndication;
      const hasContraindicationValue =
        hasContraindication ?? state.new.stepper.step3.hasContraindication;
      const productValue = product ?? state.new.stepper.step3.product;
      const diagnoseValue = diagnose ?? state.new.stepper.step3.diagnose;
      const hasMVValue = hasMV ?? state.new.stepper.step3.hasMV;
      const indicatorLimitationValue =
        indicatorLimitation !== undefined
          ? indicatorLimitation
          : state.new.stepper.step3.indicatorLimitation;
      const dateFromValue =
        dateFrom !== undefined ? dateFrom : state.new.stepper.step3.dateFrom;
      const quantityValue = quantity ?? state.new.stepper.step3.quantity;
      const dosageValue = dosage ?? state.new.stepper.step3.dosage;
      const dosageDescriptionValue =
        dosageDescription ?? state.new.stepper.step3.dosageDescription;
      const epicrisisValue = epicrisis ?? state.new.stepper.step3.epicrisis;
      const reasonValue = reason ?? state.new.stepper.step3.reason;

      // setTimeout(() => scrollToBottom(), 100);

      if (product) {
        return {
          ...state,
          new: {
            ...state.new,
            data: {
              ...state.new.data,
              controls: {
                ...state.new.data.controls,
                data: state.new.data.controls.data.filter(
                  (c) => c.checkType !== 'MaMV',
                ),
              },
            },
            stepper: {
              ...state.new.stepper,
              step3: {
                ...initialState.new.stepper.step3,
                dateFrom: dateFromValue,
                diagnose: diagnoseValue,
                dosage: dosageValue,
                dosageDescription: dosageDescriptionValue,
                epicrisis: epicrisisValue,
                hasIndication: hasIndicationValue,
                product: productValue,
                quantity: quantityValue,
                reason: reasonValue,
                hasMV: hasMVValue,
              },
            },
          },
        };
      }

      return {
        ...state,
        new: {
          ...state.new,
          stepper: {
            ...state.new.stepper,
            step3: {
              ...state.new.stepper.step3,
              dateFrom: dateFromValue,
              diagnose: diagnoseValue,
              dosage: dosageValue,
              dosageDescription: dosageDescriptionValue,
              epicrisis: epicrisisValue,
              hasContraindication: hasContraindicationValue,
              hasIndication: hasIndicationValue,
              hasMV: hasMVValue,
              indicatorLimitation: indicatorLimitationValue,
              product: productValue,
              quantity: quantityValue,
              reason: reasonValue,
            },
          },
        },
      };
    },
    changeActiveStep(state, action: PayloadAction<{ step: number }>) {
      return {
        ...state,
        new: {
          ...state.new,
          stepper: {
            ...state.new.stepper,
            activeStep: action.payload.step,
          },
        },
      };
    },
    changeStepStatus(
      state,
      action: PayloadAction<{ status: StepStatus; step: number }>,
    ) {
      const { status, step } = action.payload;
      return {
        ...state,
        new: {
          ...state.new,
          stepper: {
            ...state.new.stepper,
            [`step${step}`]: {
              ...state.new.stepper[`step${step}`],
              stepStatus: status,
            },
          },
        },
      };
    },
    changeIsNotValidModalOpened(
      state,
      action: PayloadAction<{ isOpened: boolean }>,
    ) {
      const { isOpened } = action.payload;

      return {
        ...state,
        new: {
          ...state.new,
          isNotValidModalOpened: isOpened,
        },
      };
    },
    resetNewProposalDrugForm(state) {
      return {
        ...state,
        new: initialState.new,
      };
    },
    resetDrugProposals() {
      return {
        ...initialState,
        new: {
          ...initialState.new,
          isReseted: true,
        },
      };
    },
    resetStep(state, action: PayloadAction<{ step: number }>) {
      return {
        ...state,
        new: {
          ...state.new,
          stepper: {
            ...state.new.stepper,
            [`step${action.payload.step}`]:
              initialState.new.stepper[`step${action.payload.step}`],
          },
        },
      };
    },
    resetControls(
      state,
      action: PayloadAction<{
        controls?: CheckType[];
        resetExcept?: CheckType;
      }>,
    ) {
      const { controls, resetExcept } = action.payload;
      if (!controls && resetExcept)
        return {
          ...state,
          new: {
            ...state.new,
            data: {
              ...state.new.data,
              controls: {
                ...state.new.data.controls,
                data: state.new.data.controls.data.filter(
                  (c) => c.checkType === resetExcept,
                ),
              },
            },
          },
        };
      return {
        ...state,
        new: {
          ...state.new,
          data: {
            ...state.new.data,
            controls: {
              ...state.new.data.controls,
              data: controls
                ? state.new.data.controls.data.filter(
                    (c) => !controls.includes(c.checkType),
                  )
                : [],
            },
          },
        },
      };
    },
    setProducts(state, action: PayloadAction<{ products: DrugProduct[] }>) {
      state.new.data.products = action.payload.products;
    },
    addControl(
      state,
      action: PayloadAction<{
        control: CheckType;
        errorWeight: 'E' | 'I';
        result: 'OK' | 'NOK';
      }>,
    ) {
      const { control, errorWeight, result } = action.payload;
      state.new.data.controls.data.push({
        additionalInfo: null,
        checkType: control,
        description: '',
        errorCode: '',
        errorWeight,
        result,
      });
    },
    resetFormData(state, action: PayloadAction<{ withoutFormId?: boolean }>) {
      state.new.data.formData = {
        ...initialState.new.data.formData,
        formId: action.payload.withoutFormId
          ? state.new.data.formData.formId
          : '',
      };
    },
    setDetailHeaderData(
      state,
      action: PayloadAction<{ header?: ProposalHeader; isLoading: boolean }>,
    ) {
      const { header, isLoading } = action.payload;
      state.detail = {
        ...initialState.detail,
        header: header || initialState.detail.header,
        isLoading,
      };
    },
    setLoadedCNP(state, action: PayloadAction<{ loaded: boolean }>) {
      const { loaded } = action.payload;
      state.list.loadedCNP =
        loaded || state.list.loadedCNP ? loaded : state.list.loadedCNP;
    },
    addCompletion(
      state,
      action: PayloadAction<{
        attachments?: Dokument[];
        dateTime: string;
        proposalNumber: string;
        text: string;
      }>,
    ) {
      state.detail.completions[action.payload.proposalNumber] = {
        attachments: action.payload.attachments,
        dateTime: action.payload.dateTime,
        text: action.payload.text,
      };
    },
  },
});

export const {
  addCompletion,
  addControl,
  changeActiveStep,
  changeIsNotValidModalOpened,
  changeStepStatus,
  resetControls,
  resetDrugProposals,
  resetFormData,
  resetNewProposalDrugForm,
  resetStep,
  setDetailHeaderData,
  setLoadedCNP,
  setProducts,
  storeStep1,
  storeStep2,
  storeStep3,
} = drugProposalsSlice.actions;

export default drugProposalsSlice.reducer;
