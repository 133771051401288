import { useCallback, useEffect } from 'react';
import {
  Button,
  ButtonLayout,
  ButtonSecondary,
  Icon,
  Illustration,
} from '@dovera/design-system';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../../../hooks/useStore';
import useStyles from '../../Proposals.styles';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';
import StaticMenuLayout from '../../../../layouts/StaticMenuLayout';
import { getProposalResult } from '../../../../utils/proposals.utils';
import { cx } from '../../../../utils/exports';
import {
  resetDrugProposals,
  setLoadedCNP,
} from '../../../../slices/drugProposals.slice';

interface Props {
  error?: string | null;
  isCnp?: boolean;
  isTechnicalError?: boolean;
  proposalNumber?: string | null;
  proposalType: 'KN' | 'LN';
}

const SaveProposalResult = ({
  error,
  isCnp,
  isTechnicalError,
  proposalNumber,
  proposalType,
}: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles({ proposalType });
  const navigate = useNavigate();
  const result = getProposalResult({
    type: proposalType,
    proposalNumber,
    error,
    isCnp,
    isTechnicalError,
  });
  useEffect(() => {
    dispatch(setLoadedCNP({ loaded: false }));
  }, [dispatch]);
  const onReset = useCallback(() => {
    if (proposalType === 'LN') dispatch(resetDrugProposals());
  }, [dispatch, proposalType]);
  const renderButtons = (
    <ButtonLayout direction="horizontal">
      {result.secondaryBtn && (
        <ButtonSecondary onClick={onReset}>
          <Icon className="text-space-half-right" name="plus" size="medium" />
          {result.secondaryBtn}
        </ButtonSecondary>
      )}
      <Button
        className={cx(!!result.secondaryBtn && 'text-space-left')}
        onClick={() => {
          if (!result.primaryBtnRoute) {
            onReset();
          } else {
            navigate(result.primaryBtnRoute);
          }
        }}
      >
        {result.primaryBtn}
      </Button>
    </ButtonLayout>
  );
  if (!result.status) return <span />;
  return (
    <StaticMenuLayout mustCheckSystems={{ systems: ['MD', 'TXS'] }}>
      <div className={classes.result}>
        <Illustration
          className="mb-small"
          height={256}
          name={
            result.status === 'success'
              ? 'large_sucess_state'
              : 'large_error_state'
          }
          width={256}
        />
        <SafeHtml
          className={cx(result.status, 'mb-small')}
          html={result.title}
          wrapper="h4"
        />
        <SafeHtml className="mb-large" html={result.description} />
        {renderButtons}
      </div>
    </StaticMenuLayout>
  );
};

export default SaveProposalResult;
