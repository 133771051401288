import React from 'react';

import useStyles from '../styles';
import { cx } from '../../../utils/exports';

type Props = React.JSX.IntrinsicElements['div'];

const FormulaWrapper = ({ className, ...other }: Props) => {
  const classes = useStyles({});

  return <div className={cx(classes.wrapper, className)} {...other} />;
};

export default FormulaWrapper;
