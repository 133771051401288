import { color } from '@dovera/design-system';
import IconEfectivityMenu from '../../CustomIcons/IconEfectivityMenu';
import IconInovationMenu from '../../CustomIcons/IconInovationMenu';
import IconQualityMenu from '../../CustomIcons/IconQualityMenu';
import { IconSuccess } from '../../CustomIcons';
import IconErrorCircle from '../../CustomIcons/IconErrorCircle';
import IconCircleDashed from '../../CustomIcons/IconCircleDashed';

interface Props {
  iconKey: string | null;
}

const Icon = ({ iconKey }: Props) => {
  switch (iconKey) {
    case 'quality':
    case 'quality_black':
      return <IconQualityMenu color={color('black')} id="quality_black" />;
    case 'effectivity':
    case 'effectivity_black':
      return (
        <IconEfectivityMenu color={color('black')} id="effectivity_black" />
      );
    case 'innovations':
    case 'innovations_black':
      return (
        <IconInovationMenu color={color('black')} id="innovations_black" />
      );
    case 'success':
      return (
        <IconSuccess
          color={color('primary', 600)}
          height={24}
          id="icon-success-value"
          width={24}
        />
      );
    case 'warning':
      return (
        <IconErrorCircle
          color={color('secondary')}
          height={24}
          id="icon-warning-value"
          width={24}
        />
      );
    case 'error':
      return (
        <IconErrorCircle
          color={color('error')}
          height={24}
          id="icon-error-value"
          width={24}
        />
      );
    case null:
      return <IconCircleDashed />;
    default:
      return <span />;
  }
};

export default Icon;
