import { Button, ButtonLink, Notification } from '@dovera/design-system';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dajZoznamDokumentovFaktury } from '../../../../../api/zuctovanie';
import SafeHtml from '../../../../../components/SafeHtml/SafeHtml';
import strings from '../../../../../constants/strings';
import { useAppDispatch } from '../../../../../hooks/useStore';
import { RootState } from '../../../../../rootReducer';
import { fetchObsahDokumentu } from '../../../../../slices/dokumenty.slice';
import { ErrorServiceResponse, LoadingState } from '../../../../../types';
import { DajObsahDokumentuPayload } from '../../../../../types/dokumenty.types';
import {
  DajZoznamDokumentovFakturyQueries,
  InputInvoiceDocument,
  OutputInvoiceDocument,
} from '../../../../../types/zuctovanie.types';
import { cx } from '../../../../../utils/exports';
import { translateErrorCode } from '../../../../../utils/validation.utils';
import useStyles from '../../../styles';
import DocumentsSkeleton from './DocumentsSkeleton';

interface Props {
  idHZ: number;
  invoiceId: number;
  invoiceNumber: string;
  invoiceState: string;
}

interface LocalState {
  dataState: LoadingState;
  downloadedDocument: {
    error: string | null;
    guid: string;
  };
  error: string | null;
  inputDocuments: InputInvoiceDocument[];
  outputDocuments: OutputInvoiceDocument[];
}

const initialState: LocalState = {
  dataState: LoadingState.none,
  downloadedDocument: {
    error: null,
    guid: '',
  },
  error: null,
  inputDocuments: [],
  outputDocuments: [],
};

const { attachedDocumentsByDovera, attachedDocumentsByHCP } =
  strings.zuctovanie.invoices.detail.extraDocuments;

const Dokumenty = ({ idHZ, invoiceId, invoiceNumber, invoiceState }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [state, setState] = useState<LocalState>(initialState);
  const [showMore, setShowMore] = useState({
    external: false,
    internal: false,
  });
  const changeShowMore = useCallback(
    (documents: 'internal' | 'external') => {
      setShowMore({ ...showMore, [documents]: !showMore?.[documents] });
    },
    [showMore],
  );
  const { dokumenty } = useSelector((state: RootState) => state.dokumenty);
  const getDocument = useCallback(
    (payload: DajObsahDokumentuPayload) => {
      dispatch(fetchObsahDokumentu(payload));
      setState({
        ...state,
        downloadedDocument: {
          error: null,
          guid: payload.dms?.ciarovyKod || '',
        },
      });
    },
    [dispatch, state],
  );

  useEffect(() => {
    if (dokumenty?.[state.downloadedDocument.guid]?.error)
      setState({
        ...state,
        downloadedDocument: {
          ...state.downloadedDocument,
          error: dokumenty?.[state.downloadedDocument.guid]?.error,
        },
      });
    // eslint-disable-next-line
  }, [dokumenty]);
  useEffect(() => {
    setState({ ...initialState, dataState: LoadingState.fetching });
    const queries: DajZoznamDokumentovFakturyQueries = {
      idFaktura: invoiceId,
    };
    if (invoiceId)
      dajZoznamDokumentovFaktury(queries)
        .then((resp) => {
          setState({
            ...initialState,
            dataState: LoadingState.filled,
            inputDocuments: resp.vstupneDokumenty,
            outputDocuments: resp.vystupneDokumenty,
          });
        })
        .catch((err: ErrorServiceResponse) => {
          setState({
            ...initialState,
            dataState: LoadingState.error,
            error:
              invoiceState !== 'C'
                ? translateErrorCode(err.response?.data?.kod)
                : '',
          });
        });
  }, [idHZ, invoiceId, invoiceNumber, invoiceState]);
  const renderBtnMore = (type: 'internal' | 'external') => (
    <Button
      className="inline-btn"
      onClick={() => changeShowMore(type)}
      type="link-secondary"
    >
      Zobraziť {showMore?.[type] ? 'menej' : 'viac'}
    </Button>
  );
  const renderBtnLink = (
    document: InputInvoiceDocument | OutputInvoiceDocument,
    index: number,
    type: 'internal' | 'external',
  ) => (
    <ButtonLink
      className={cx(
        'inline-btn mb-xsmall',
        !showMore?.[type] && index > 3 && 'hide',
      )}
      isLoading={dokumenty[document.dokumentGuid]?.isLoading}
      onClick={() => getDocument({ Zuctovanie: { id: document.dokumentGuid } })}
    >
      {document.nazov}
    </ButtonLink>
  );
  const renderDocuments = (
    <div className="mb-small">
      {state.downloadedDocument.error && (
        <Notification
          message={
            <SafeHtml
              html={translateErrorCode(state.downloadedDocument.error)}
            />
          }
          variant="error"
        />
      )}
      <div className={classes.documents}>
        <div>
          <h5>{attachedDocumentsByDovera}</h5>
          {state.outputDocuments?.map((d, key) => (
            <div>{renderBtnLink(d, key, 'external')}</div>
          ))}
          {state.outputDocuments?.length > 4 && (
            <div>{renderBtnMore('external')}</div>
          )}
          {!state.outputDocuments?.length && <div>{strings.undefined}</div>}
        </div>
        <div>
          <h5>{attachedDocumentsByHCP}</h5>
          {state.inputDocuments?.map((d, key) => (
            <div>{renderBtnLink(d, key, 'internal')}</div>
          ))}
          {state.inputDocuments?.length > 4 && (
            <div>{renderBtnMore('internal')}</div>
          )}
          {!state.inputDocuments?.length && <div>{strings.undefined}</div>}
        </div>
      </div>
    </div>
  );
  return (
    <>
      {state.dataState === LoadingState.fetching && <DocumentsSkeleton />}
      {state.dataState === LoadingState.error && state.error && (
        <Notification message={state.error} variant="error" />
      )}
      {state.dataState === LoadingState.filled && renderDocuments}
    </>
  );
};

export default Dokumenty;
