import { useCarousel } from 'nuka-carousel';
import useStyles from '../styles';
import { cx } from '../../../../utils/exports';

const Dots = () => {
  const { currentPage, goToPage, totalPages } = useCarousel();
  const classes = useStyles();

  return (
    <div className={classes.dotsWrapper}>
      <div className={classes.dots}>
        {[...Array(totalPages).keys()].map((i, index) => (
          <button
            key={i}
            className={cx(classes.dot, currentPage === index && 'active')}
            onClick={() => goToPage(index)}
            type="button"
          />
        ))}
      </div>
    </div>
  );
};

export default Dots;
