import { Grid, GridCol } from '@dovera/design-system';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../rootReducer';
import strings from '../../../../../../constants/strings';
import SafeHtml from '../../../../../../components/SafeHtml/SafeHtml';
import useStyles from '../../../../Proposals.styles';
import {
  PROPOSAL_SUMMARY_LABEL_BREAKPOINTS,
  PROPOSAL_SUMMARY_VALUE_BREAKPOINTS,
} from '../../../../../../constants/proposals';

const texts = strings.proposals.spa.new;

const Summary = () => {
  const classes = useStyles({ proposalType: 'LN' });
  const { step4 } = useSelector(
    (state: RootState) => state.spaProposals.new.stepper,
  );
  return (
    <div className={classes.summary}>
      <Grid className="mb-small">
        <GridCol size={PROPOSAL_SUMMARY_LABEL_BREAKPOINTS}>
          {texts.summary.medicalHistory}
        </GridCol>
        <GridCol size={PROPOSAL_SUMMARY_VALUE_BREAKPOINTS}>
          <SafeHtml html={step4.description || strings.undefined} wrapper="b" />
        </GridCol>
      </Grid>
      <Grid>
        <GridCol size={PROPOSAL_SUMMARY_LABEL_BREAKPOINTS}>
          {texts.summary.anotherDiagnoses}
        </GridCol>
        <GridCol size={PROPOSAL_SUMMARY_VALUE_BREAKPOINTS}>
          <b>
            {step4.diagnoses.map(
              (d, key) =>
                `${d.kod} - ${d.nazov}${
                  step4.diagnoses.length > key + 1 ? ', ' : ''
                }`,
            )}
            {!step4.diagnoses.length && strings.undefined}
          </b>
        </GridCol>
      </Grid>
    </div>
  );
};

export default Summary;
