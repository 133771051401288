import { color as ddsColor } from '@dovera/design-system';

interface Props {
  backgroundColor?: string;
  color?: string;
  id: string;
}

const IconBlueDivision = ({ backgroundColor, color, id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill={backgroundColor || ddsColor('primary', 600)}
    />
    <path
      d="M4.97998 11.3698H19.02V12.6658H4.97998V11.3698ZM12.036 19.3258C11.604 19.3258 11.232 19.1818 10.92 18.8938C10.632 18.6058 10.488 18.2338 10.488 17.7778C10.488 17.3698 10.632 17.0218 10.92 16.7338C11.232 16.4218 11.604 16.2658 12.036 16.2658C12.444 16.2658 12.792 16.4218 13.08 16.7338C13.392 17.0218 13.548 17.3818 13.548 17.8138C13.548 18.2458 13.404 18.6058 13.116 18.8938C12.828 19.1818 12.468 19.3258 12.036 19.3258ZM12.036 7.73383C11.604 7.73383 11.232 7.58983 10.92 7.30183C10.632 7.01383 10.488 6.64183 10.488 6.18583C10.488 5.77783 10.632 5.42983 10.92 5.14183C11.232 4.82983 11.604 4.67383 12.036 4.67383C12.444 4.67383 12.792 4.82983 13.08 5.14183C13.392 5.42983 13.548 5.78983 13.548 6.22183C13.548 6.65383 13.404 7.01383 13.116 7.30183C12.828 7.58983 12.468 7.73383 12.036 7.73383Z"
      fill={color || 'white'}
    />
  </svg>
);

export default IconBlueDivision;
