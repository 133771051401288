import { useMemo } from 'react';
import { StructureAttributes } from '../../../types/jsonObjects.types';
import JsonObject from '../JsonObject';
import useStyles from '../styles';
import { cx } from '../../../utils/exports';
import useResize from '../../../hooks/useResize';

const Structure = ({ attributes, content }: StructureAttributes) => {
  const classes = useStyles();
  const { ref: structureRef, wrapperWidth } = useResize();

  const sortedContent = useMemo(
    () => content.sort((a, b) => a.order - b.order),
    [content],
  );

  if (attributes.style === 'horizontal') {
    return (
      <div
        ref={structureRef}
        className={cx(wrapperWidth > 240 && classes.horizontalStructure)}
        style={{ alignItems: attributes.alignItems || 'stretch' }}
      >
        {sortedContent.map((item, index) => {
          const style = {
            ...(item.type !== 'icon' && {
              width: attributes.percentages?.[index]
                ? `${attributes.percentages[index]}%`
                : '100%',
            }),
          };
          return (
            <div
              key={`structure--${item.order}`}
              className={cx(
                classes.structure,
                item.type === 'icon' && classes.structureIcon,
              )}
              style={style}
            >
              <JsonObject data={[item]} />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={classes.structure}>
      {sortedContent.map((item) => (
        <JsonObject key={`structure--${item.order}`} data={[item]} />
      ))}
    </div>
  );
};

export default Structure;
