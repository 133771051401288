import useStyles from './InsureeBar.styles';
import { cx } from '../../../../../../utils/exports';
import { hotjarMasking } from '../../../../../../utils/strings.utils';
import strings from '../../../../../../constants/strings';
import { FormVariant } from '../../../../../../types/proposals.types';
import { Grid, GridCol } from '@dovera/design-system';

const texts = strings.proposals.spa.new;

interface Props {
  address: string;
  mobile: string;
  name: string;
  variant: FormVariant;
}

const InsureeBar = ({ address, mobile, name, variant }: Props) => {
  const classes = useStyles(variant);

  return (
    <div className={cx(classes.insureeBar)}>
      <Grid>
        <GridCol className="mb-xxsmall" size={4}>
          {texts.labels.name}
        </GridCol>
        <GridCol className="mb-xxsmall" size={8}>
          {hotjarMasking(name, 'b')}
        </GridCol>
        <GridCol className="mb-xxsmall" size={4}>
          {texts.labels.address}
        </GridCol>
        <GridCol className="mb-xxsmall" size={8}>
          {hotjarMasking(address, 'b')}
        </GridCol>
        <GridCol size={4}>{texts.labels.mobile}</GridCol>
        <GridCol size={8}>{hotjarMasking(mobile, 'b')}</GridCol>
      </Grid>
    </div>
  );
};

export default InsureeBar;
