import { useEffect } from 'react';
import { GetProposalDetailPayload } from '../../../types/spaProposals.types';
import { useAppDispatch } from '../../../hooks/useStore';
import { RootState } from '../../../rootReducer';
import { useSelector } from 'react-redux';
import {
  getProposalDetail,
  getProposalDetailDocuments,
} from '../../../api/spaProposals';
import SkeletonLoaderContent from './SkeletonLoader/SkeletonLoaderContent';
import { NoDataIllustration } from '../../../components/EmptyState';
import ContentKN from './Content/ContentKN';

interface Props {
  proposalId: number;
  proposalNumber: string;
}

const DetailKN = ({ proposalId, proposalNumber }: Props) => {
  const dispatch = useAppDispatch();
  const { data, documents, error, isLoading } = useSelector(
    (state: RootState) => state.spaProposals.detail,
  );
  useEffect(() => {
    if (proposalId && proposalNumber && !isLoading) {
      const payload: GetProposalDetailPayload = {
        cisloNavrhu: proposalNumber,
        idNavrhNaZS: proposalId,
      };
      dispatch(getProposalDetail(payload));
      setTimeout(() => {
        dispatch(getProposalDetailDocuments(payload));
      }, 500);
    }
    // eslint-disable-next-line
  }, [dispatch, proposalId, proposalNumber]);
  const renderError = error && <NoDataIllustration title={error} />;
  if (isLoading) return <SkeletonLoaderContent />;
  return (
    <>
      {renderError}
      {data && (
        <ContentKN data={data} documents={documents} proposalId={proposalId} />
      )}
    </>
  );
};

export default DetailKN;
