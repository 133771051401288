import { Container, PageSection } from '@dovera/design-system';
import { Link } from 'react-router-dom';
import useStylesGlobal from '../../App.styles';
import StaticMenuLayout from '../../layouts/StaticMenuLayout';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

const PageNotFoundPrivate = () => {
  const globalClasses = useStylesGlobal();

  return (
    <StaticMenuLayout
      contentHeader={
        <ContentHeader padBottom>
          <Container>
            <Breadcrumbs items={[{ name: 'Chyba 404' }]} />
            <h2>Stránka sa nenašla.</h2>
          </Container>
        </ContentHeader>
      }
    >
      <PageSection>
        <Container>
          <div className={globalClasses.pageLayout}>
            <p>
              Prosím prejdite na <Link to="/">hlavnú stránku</Link> alebo
              pokračujte pomocou modrej navigácie vľavo.
            </p>
          </div>
        </Container>
      </PageSection>
    </StaticMenuLayout>
  );
};

export default PageNotFoundPrivate;
