import { Card, CardSection, Notification } from '@dovera/design-system';
import { useEffect, useState } from 'react';
import {
  dajSpatneDavkyFaktury,
  dajStavPripravySuborovSpatnychDavok,
} from '../../../../../api/zuctovanie';
import TablePreloaderSkeleton from '../../../../../components/TablePreloaderSkeleton/TablePreloaderSkeleton';
import strings from '../../../../../constants/strings';
import useInterval from '../../../../../hooks/useInterval';
import { ErrorServiceResponse, LoadingState } from '../../../../../types';
import {
  DajSpatneDavkyFakturyQueries,
  DajStavPripravySuborovSpatnychDavokQueries,
  SpatneDavkyState,
} from '../../../../../types/zuctovanie.types';
import { saveBase64Document } from '../../../../../utils/file.utils';
import { translateErrorCode } from '../../../../../utils/validation.utils';
import Table from './Table';

const texts = strings.zuctovanie.invoices.detail;

interface Props {
  invoiceId: number;
}

const initialState: SpatneDavkyState = {
  data: [],
  dataState: LoadingState.none,
  error: null,
};

const SpatneDavky = ({ invoiceId }: Props) => {
  const [loadingIds, setLoadingIds] = useState<string[]>([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [dokumentZipId, setDokumentZipId] = useState(0);
  const [state, setState] = useState<SpatneDavkyState>(initialState);

  useEffect(() => {
    setState({ ...initialState, dataState: LoadingState.fetching });
    const queries: DajSpatneDavkyFakturyQueries = {
      idFaktura: invoiceId,
    };
    if (invoiceId)
      dajSpatneDavkyFaktury(queries)
        .then((resp) => {
          setState({
            ...initialState,
            data: resp.davky,
            dataState: LoadingState.filled,
          });
        })
        .catch((err: ErrorServiceResponse) => {
          setState({
            ...initialState,
            dataState: LoadingState.error,
            error: translateErrorCode(err?.response?.data.kod),
          });
        });
  }, [invoiceId]);

  useInterval(() => {
    if (loadingBtn && dokumentZipId !== 0) {
      const queries: DajStavPripravySuborovSpatnychDavokQueries = {
        idFaktury: invoiceId,
        idSubor: dokumentZipId,
      };
      dajStavPripravySuborovSpatnychDavok(queries).then((resp) => {
        if (resp.stav) {
          saveBase64Document(resp.suborDavky.obsah, resp.suborDavky.nazov);
          setLoadingBtn(false);
        }
      });
    }
  }, 15000);

  const renderContent = () => {
    if (state.dataState === LoadingState.fetching)
      return <TablePreloaderSkeleton columns={4} />;
    if (state.dataState === LoadingState.error && state.error)
      return <Notification message={state.error} variant="error" />;
    return (
      <Table
        invoiceId={invoiceId}
        loadingBtn={loadingBtn}
        loadingIds={loadingIds}
        state={state}
        uploadDokumentZipId={(v: number) => setDokumentZipId(v)}
        uploadLoadingBtn={(v: boolean) => setLoadingBtn(v)}
        uploadLoadingIds={(v: string[]) => setLoadingIds(v)}
      />
    );
  };

  return (
    !!state.data?.length && (
      <Card isBordered>
        <CardSection>
          <div>
            <h5 className="mb-small">{texts.subtitle}</h5>
            {renderContent()}
          </div>
        </CardSection>
      </Card>
    )
  );
};

export default SpatneDavky;
