// @ts-nocheck
import Rating from 'react-rating';
import useStyles from '../Feedback.styles';
import strings from '../../../constants/strings';
import { ratingFullSymbolColors } from '../rating';
import {
  ButtonSecondary,
  Icon,
  breakpoints,
  color,
} from '@dovera/design-system';
import { useWindowSize } from '../../../hooks/useWindowSize';

const texts = strings.feedbacker;

interface Props {
  onChange: (rate: number) => void;
  onClose: (quarantine: boolean) => void;
  rating: number;
  textKey: 'standard' | 'trigger';
}

const AppRating = ({ onChange, onClose, rating, textKey }: Props) => {
  const classes = useStyles();
  const windowSize = useWindowSize();
  const isMobile: boolean = windowSize.width < breakpoints.m;
  return (
    <div className={classes.rating} data-testid="feedback-rating">
      <p className="mb-small">{texts[textKey].firstStep.label}</p>
      <div className="d-flex">
        <Rating
          emptySymbol={
            <div className={classes.iconRaiting}>
              <Icon
                color={color('black')}
                name="24-star"
                size={isMobile ? 'small' : 'medium'}
              />
            </div>
          }
          fullSymbol={ratingFullSymbolColors.map((symbolColor) => (
            <div key={symbolColor} className={classes.iconRaiting}>
              <Icon
                color={symbolColor}
                name="24-star-filled"
                size={isMobile ? 'small' : 'medium'}
              />
            </div>
          ))}
          initialRating={rating}
          onChange={(rate) => onChange(rate)}
          stop={ratingFullSymbolColors.length}
        />
      </div>
      {textKey === 'trigger' && rating === 0 && (
        <div className={classes.triggerCloseBtnWrapper}>
          <ButtonSecondary
            className="no-mrg-bottom"
            onClick={() => onClose(true)}
            size="s"
          >
            {texts[textKey].firstStep.closeBtn}
          </ButtonSecondary>
        </div>
      )}
    </div>
  );
};

export default AppRating;
