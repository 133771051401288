import { Icon, color } from '@dovera/design-system';
import { CampaignListWidgetState } from '../../../types/zmluvy.types';

interface Props {
  state: CampaignListWidgetState;
}

const StateIcon = ({ state }: Props) => {
  if (state === 'W') {
    return <Icon color={color('warning')} name="32-error" size="medium" />;
  }
  if (state === 'I') {
    return <Icon color={color('blue')} name="info" size="medium" />;
  }
  if (state === 'E') {
    return <Icon color={color('error')} name="32-error" size="medium" />;
  }
  return <Icon color={color('primary', 600)} name="32-success" size="medium" />;
};

export default StateIcon;
