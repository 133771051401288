import { color, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  modalNarrower: {
    '& > div': {
      '&:first-child': {
        marginRight: getRem(24),
      },
    },
  },
  modalContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& h4': {
      color: color('black'),
    },
    '& img': {
      width: getRem(264),
      height: 'auto',
      maxHeight: getRem(264),
      marginBottom: getRem(24),
    },
  },
  btnLayout: {
    justifyContent: 'center',
  },
  success: {
    '& h4': {
      color: color('primary', 600),
    },
  },
  warning: {
    '& h4': {
      color: color('black'),
    },
  },
  infoWrapper: {
    width: getRem(230),
    '& > div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
  },
  paragraph: {
    fontSize: getRem(16),
    marginBottom: 0,
    fontWeight: 'bold',
    '&.larger': {
      fontSize: getRem(22),
      marginTop: getRem(-3),
    },
  },
});
