import { isArray } from 'lodash';
import { StringMap } from '../types';
import { iterateObject } from './object.utils';

const appendArrayQueryParam = (
  result: string,
  key: string,
  values: (string | number | boolean)[],
  prefix: string,
): string => {
  values.forEach((v, index) => {
    const newPrefix = prefix === '?' && index > 0 ? '&' : prefix;
    result += `${newPrefix}${key}=${v}`;
  });
  return result;
};

export const iterateAndBuildQueryParams = (
  queryParams: StringMap<
    string | number | string[] | number[] | boolean | undefined | null
  >,
  hasAnotherQueries?: boolean,
  withoutEmpty?: boolean,
): string => {
  let result = '';
  let counter = 0;

  iterateObject(queryParams, (value, key) => {
    let prefix = counter === 0 && !hasAnotherQueries ? '?' : '&';

    if (value && typeof value === 'object') {
      let subResult = '';
      // @ts-ignore
      iterateObject(value, (v, k) => {
        prefix = counter === 0 ? '?' : '&';
        if (!withoutEmpty || (withoutEmpty && v)) {
          subResult += `${prefix}${key}.${k}=${v}`;
          counter++;
        }
      });
      result = `${result}${subResult}`;
    } else if (!(withoutEmpty && !value?.toString()) && !isArray(value)) {
      result = `${result}${prefix}${key}=${value}`;
      counter++;
    }

    if (isArray(value)) {
      if (counter > 0 && result.includes('?')) {
        prefix = '&';
      }
      result = appendArrayQueryParam(
        result,
        key,
        value as (string | number | boolean)[],
        prefix,
      );
    }
  });

  return result;
};

export const createQueryParams = ({
  hasAnotherQueries,
  queryParams = {},
  urlEncoded,
  withoutEmpty,
}: {
  hasAnotherQueries?: boolean;
  queryParams: StringMap<
    string | number | string[] | number[] | boolean | undefined | null
  >;
  urlEncoded?: boolean;
  withoutEmpty?: boolean;
}): string => {
  const result = iterateAndBuildQueryParams(
    queryParams,
    hasAnotherQueries,
    withoutEmpty,
  );
  if (urlEncoded) return encodeURIComponent(result);
  return result;
};

export const createUrl = (
  url: string,
  queryParams?: StringMap<string>,
): string =>
  // @ts-ignore
  `${window.env.config.apiPath}/${url}${createQueryParams({ queryParams })}`;
