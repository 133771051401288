import { createUseStyles } from 'react-jss';

import { borderRadius, color, fontWeight } from '../../constants';
import { getRem } from '@dovera/design-system';

interface StylesProps {
  hasAccordionItem: boolean;
  hasChildren: boolean;
  isFilled?: boolean;
  medzisucet?: boolean;
  vnorenyVzorec?: boolean;
  withoutFill?: boolean;
}

const getBgColor = ({ isFilled, medzisucet }: StylesProps): string => {
  if (isFilled) return color('primary', 600);
  if (medzisucet) return 'transparent';
  return color('white');
};

export default createUseStyles({
  formula: ({
    hasAccordionItem,
    hasChildren,
    isFilled,
    medzisucet,
  }: StylesProps) => ({
    borderRadius: `${borderRadius}px`,
    background: getBgColor({
      hasChildren,
      hasAccordionItem,
      isFilled,
      medzisucet,
    }),
    color: isFilled ? color('white') : 'inherit',
    textAlign: 'center',
    '& > div': {
      textAlign: 'left',
    },
    backgroundColor: color('grey', 100),
    padding: `${getRem(18)} ${getRem(24)}`,
    '&.withoutFill': {
      backgroundColor: '#FFFFFF !important',
      padding: `${getRem(18)} ${getRem(24)}`,
    },
    '& > .bar': {
      marginTop: 0,
    },
    '& .bar__item': {
      marginTop: 0,
    },
    '& > svg': {
      display: 'flex',
      margin: '0 auto',
    },
    '& $formula': {
      margin: `${getRem(18)} 0 `,
    },
    fontWeight: medzisucet ? fontWeight.bold : 'normal',
    '&.clickable': {
      transition: '0.2s ease',
    },
    cursor: hasAccordionItem && 'pointer',
  }),
  accordionFormula: {
    cursor: 'pointer',
    backgroundColor: color('white'),
    '&.active': {
      fontWeight: 'bold',
      borderBottom: '1px solid #E1E2E3',
      paddingBottom: getRem(18),
    },
  },
  formulaAccordionIcon: {
    '& svg': {
      transition: '0.2s ease',
    },
    '&.active': {
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
  },
  formulaAccordionContent: {
    maxHeight: 0,
    overflow: 'hidden',
    visibility: 'hidden',
    '&.active': {
      padding: 0,
      maxHeight: 380,
      transition: '.4s ease-in',
      visibility: 'visible',
    },
    '& > div': {
      backgroundColor: color('white'),
      padding: 0,
    },
  },
  hodnota: {
    fontWeight: fontWeight.bold,
  },
  formulaWithChildren: {
    padding: '1rem !important',
  },
});
