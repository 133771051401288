import { getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

interface StylesProps {
  hideClose: boolean;
}

export default createUseStyles({
  cancelModal: ({ hideClose }: StylesProps) => ({
    width: getRem(907),
    maxWidth: '100%',
    zIndex: 9999,
    '& .modal__header button': {
      display: hideClose ? 'none' : 'flex',
    },
  }),
  '@global #cancel-action--modal .modal__overlay': {
    zIndex: 999,
  },
  btnLayout: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
