import { GraphAttributes } from '../../../types/jsonObjects.types';
import { RadialChart } from '../../RadialChart';
import { getGraphColor } from '../utils';

const Graph = ({ attributes }: GraphAttributes) => {
  if (attributes.graphType === 'circle') {
    const values = attributes.data.map((d) => ({
      color: getGraphColor(d.type),
      value: d.value,
      title: d.type,
    }));

    return (
      <RadialChart
        values={[
          {
            data: values,
            name: attributes.title || '',
            value: attributes.value || '',
          },
        ]}
      />
    );
  }

  return null;
};

export default Graph;
