/* eslint-disable */
import { ChangeEvent, useCallback, useMemo } from 'react';
import { Datepicker, Input } from '@dovera/design-system';
import { formatDateTime } from '../../utils/date.utils';

type Value = Date | null | string;
type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

type DatepickerProps = {
  /**
   * Props from DDS Datepicker
   */
  hasDataAttr?: boolean;
  id: string;
  maxDate?: Date;
  minDate?: Date;
  onChange: (value: Value, e?: ChangeEvent<HTMLInputElement>) => void;
  onClose?: Pikaday.PikadayOptions['onClose'];
  onDraw?: Pikaday.PikadayOptions['onDraw'];
  onInit?: (el: HTMLInputElement, instance?: Pikaday) => void;
  onOpen?: Pikaday.PikadayOptions['onOpen'];
  pikadayOptions?: Pikaday.PikadayOptions;
  placeholder?: string;
  position?: 'top left' | 'top right' | 'bottom left' | 'bottom right';
  reposition?: boolean;
  value?: Value;
} & Omit<Input, 'value'>;

const DDSDatepicker = ({
  error,
  id,
  isDisabled,
  isRequired,
  label,
  maxDate,
  minDate,
  onBlur,
  onChange,
  pikadayOptions,
  placeholder,
  reposition,
  value,
  ...other
}: DatepickerProps) => {
  const minDateMemo = useMemo(() => minDate, [minDate]);
  const maxDateMemo = useMemo(() => maxDate, [maxDate]);
  const valueMemo = useMemo(() => value, [value]);
  const datepickerReposition = useCallback(() => {
    const EL = document.querySelector('.pika-single:not(.is-hidden)');
    const INPUT_EL = document.getElementById(id);
    if (!EL || !INPUT_EL) return;

    const { innerHeight } = window;
    const calendarHeight = EL.clientHeight;
    const inputHeight = INPUT_EL.clientHeight;

    const TOP_POSTFIX = Number(`-${calendarHeight + inputHeight}`);
    const BOTTOM_POSTFIX = 2;

    const elementPosition = Number.parseInt(
      // @ts-ignore
      EL.style.top,
      10,
    );
    // @ts-ignore
    const scrollPosition = document.querySelector('body').scrollTop;

    const isTop = innerHeight - elementPosition < calendarHeight;
    const position = `calc(${scrollPosition}px + ${elementPosition}px + ${
      isTop ? TOP_POSTFIX : BOTTOM_POSTFIX
    }px)`;

    // @ts-ignore Change pika position
    EL.style.top = position;
    // @ts-ignore
    EL.style.display = 'block';
  }, [id]);
  return (
    <Datepicker
      aria-autocomplete="none"
      autoComplete="off"
      error={error}
      id={id}
      isDisabled={isDisabled}
      isRequired={isRequired}
      label={label}
      maxDate={maxDateMemo}
      minDate={minDateMemo}
      onBlur={onBlur}
      onChange={(val) => onChange(val)}
      pikadayOptions={{
        defaultDate: value instanceof Date ? value : undefined,
        onOpen: () => {
          if (reposition) datepickerReposition();
        },
        toString(date, format) {
          return formatDateTime(date, format);
        },
        parse(dateString, format) {
          // dateString is the result of `toString` method
          const parts = dateString.split('.');
          const day = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10) - 1;
          const year = parseInt(parts[2], 10);
          return new Date(year, month, day);
        },
      }}
      placeholder={placeholder}
      // @ts-ignore
      value={valueMemo}
      {...other}
    />
  );
};

export default DDSDatepicker;
