import { useEffect, useState } from 'react';
import { calculateCircumference, calculateOffset, getRadii } from '../utils';
import { color as ddsColor } from '@dovera/design-system';

interface Props {
  classes: any;
  color?: string;
  colorHovered?: string;
  groupsCount: number;
  hoveredChartIndex?: number | null;
  hoveredIndex?: number | null;
  index: number;
  indexChart: number;
  isBackground?: boolean;
  onHovered?: (index: number | null) => void;
  size: number;
  strokeWidth: number;
  value?: number;
}

const CircleEl = ({
  classes,
  color,
  colorHovered,
  groupsCount,
  hoveredChartIndex,
  hoveredIndex,
  index,
  indexChart,
  isBackground,
  onHovered,
  size,
  strokeWidth,
  value,
}: Props) => {
  const radius = getRadii(groupsCount)[index] / (indexChart + 1);
  const circumference = calculateCircumference(radius);
  const [offset, setOffset] = useState(circumference);
  useEffect(() => {
    // Circle animation
    if (value)
      setTimeout(() => {
        setOffset(calculateOffset(value, index, radius));
      }, 200);
  }, [circumference, index, radius, value]);

  if (isBackground)
    return (
      <circle
        key={`bg-${index}`}
        className={classes.circleBackground}
        cx={size / 2}
        cy={size / 2}
        fill="none"
        r={radius}
        stroke={ddsColor('grey', 100)}
        strokeDasharray={`${circumference},${circumference}`}
        strokeWidth={strokeWidth}
      />
    );
  return (
    <circle
      key={`fg-${index}`}
      className={classes.circleForeground}
      cx={size / 2}
      cy={size / 2}
      fill="none"
      onMouseLeave={() => onHovered && onHovered(null)}
      onMouseOver={() => onHovered && onHovered(index)}
      r={radius}
      stroke={
        hoveredIndex === index && hoveredChartIndex === indexChart
          ? colorHovered
          : color
      }
      strokeDasharray={circumference}
      strokeDashoffset={offset}
      strokeLinecap="round"
      strokeWidth={strokeWidth}
    />
  );
};

export default CircleEl;
