import {
  ButtonLayout,
  ButtonSecondary,
  Illustration,
  Modal,
} from '@dovera/design-system';
import { ReactNode } from 'react';

interface PopUpProps {
  data: {
    idPopUp: string;
    nadpis: {
      content: ReactNode;
      id: string | null;
      title: string | null;
    } | null;
    obsah: {
      content: ReactNode;
      id: string | null;
      title: string | null;
    } | null;
  };
  onClickHide: () => void;
  onHide: () => void;
  showPopUp: boolean;
}

const PopUp = ({ data, onClickHide, onHide, showPopUp }: PopUpProps) => {
  const { idPopUp, nadpis, obsah } = data;

  return (
    nadpis &&
    obsah && (
      <Modal
        footer={
          <ButtonLayout className="align-items-center" direction="horizontal">
            <ButtonSecondary onClick={onClickHide}>Zavrieť</ButtonSecondary>
          </ButtonLayout>
        }
        header={nadpis.content || ''}
        isVisible={showPopUp}
        onHide={onHide}
      >
        {idPopUp?.includes('ospravedlnenie') && (
          <div className="text-center">
            <Illustration name="large_warning_state" />
          </div>
        )}
        {obsah.content || ''}
      </Modal>
    )
  );
};

export default PopUp;
