import { useEffect } from 'react';
import { Card, CardSection, Header, TableApp } from '@dovera/design-system';
import useStyles from './AppVersion.styles';
import { ns } from '../../utils/object.utils';
import { useSelector } from 'react-redux';
import { fetchVerzieAplikacie } from '../../slices/versions.slice';
import { RootState } from '../../rootReducer';
import { useAppDispatch } from '../../hooks/useStore';
import { cx } from '../../utils/exports';
import HeaderLogo from '../../components/LogoArea/HeaderLogo/HeaderLogo';

const AppVersion = () => {
  const env = ns(() => window.env.ENV);
  const classes = useStyles({ env });
  const dispatch = useAppDispatch();
  const versionState = useSelector((state: RootState) => state.version);
  useEffect(() => {
    dispatch(fetchVerzieAplikacie());
  }, [dispatch]);
  const renderLogo = (
    <>
      <HeaderLogo href="/" />
      {env !== 'PROD' && (
        <div className={classes.badgeVersion}>
          {`${env}`}
          <span> verzia</span>
        </div>
      )}
    </>
  );
  return (
    <div>
      {/* eslint-disable-next-line */}
      <Header logoArea={() => renderLogo} />
      <Card>
        <CardSection>
          <h2 className="mb-small">Verzie aplikácie DôveraLekárom:</h2>
          <TableApp
            caption=""
            className={cx(classes.table, 'table-caption--hide')}
            columns={[
              {
                accessor: 'col1',
                Header: 'UI',
              },
              {
                accessor: 'col2',
                Header: 'Services',
              },
              {
                accessor: 'col3',
                Header: 'Database',
              },
              {
                accessor: 'col4',
                Header: 'ETL',
              },
              {
                accessor: 'col5',
                Header: 'APM',
              },
              {
                accessor: 'col6',
                Header: 'ENV',
              },
            ]}
            data={[
              {
                col1: ns(() => window.env.version),
                col2: ns(() => versionState.services),
                col3: ns(() => versionState.database),
                col4: ns(() => versionState.etl),
                col5: ns(() => versionState.apm),
                col6: ns(() => window.env.ENV, 'VYV'),
              },
            ]}
          />
        </CardSection>
      </Card>
    </div>
  );
};

export default AppVersion;
