import { createUseStyles } from 'react-jss';
import { ReactNode } from 'react';
import routes from '../../../../routes';
import {
  Bar,
  BarItem,
  Button,
  ButtonLayout,
  ButtonLink,
  Grid,
  GridCol,
  Illustration,
  Notification,
  color,
} from '@dovera/design-system';
import { useNavigate } from 'react-router-dom';
import {
  StavyOdoslaniaEnum,
  VysledokStavuOdoslaniaDavok,
} from '../../../../types/zuctovanie.types';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';
import { saveDocument } from '../../../../utils/file.utils';
import strings from '../../../../constants/strings';

const useStyles = createUseStyles({
  root: {
    textAlign: 'center',
    transform: 'translateY(-30%)',
    margin: '30% auto 0',
    '& h4': {
      marginTop: 24,
    },
    '& .notification': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  successTextColor: { color: '#4CAF50' },
  errorTextColor: { color: '#E60523' },
  errorLinkColor: { color: color('error', 600) },
  warningTextColor: { color: '#F08B37' },
});

const texts = strings.zuctovanie.resultPage;

export enum BatchSaveState {
  isError = 'isError',
  isLoading = 'isLoading',
  isSuccess = 'isSuccess',
  isWarning = 'isWarning',
}

interface Props {
  results?: VysledokStavuOdoslaniaDavok[];
  state?: BatchSaveState;
}

type PageData = {
  description: ReactNode | string;
  illustration: ReactNode;
  title: ReactNode | string;
};

const StavyOdoslania = ({ results, state }: Props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const getPageData = (batchState?: BatchSaveState): PageData => {
    const defaultPageData: PageData = {
      illustration: (
        <Illustration height={256} name="large_waiting_state" width={256} />
      ),
      title: <SafeHtml html={texts.pending.title} wrapper="h4" />,
      description: (
        <SafeHtml
          className="text-center"
          html={texts.pending.description}
          wrapper="div"
        />
      ),
    };

    switch (batchState) {
      case BatchSaveState.isSuccess:
        return {
          illustration: (
            <Illustration height={256} name="large_sucess_state" width={256} />
          ),
          title: (
            <SafeHtml
              className={classes.successTextColor}
              html={texts.success.title}
              wrapper="h4"
            />
          ),
          description: (
            <SafeHtml
              className="text-center"
              html={texts.success.description}
              wrapper="div"
            />
          ),
        };
      case BatchSaveState.isError:
        return {
          illustration: (
            <Illustration height={256} name="large_error_state" width={256} />
          ),
          title: (
            <SafeHtml
              className={classes.errorTextColor}
              html={texts.error.title}
              wrapper="h4"
            />
          ),
          description: (
            <SafeHtml
              className="text-center"
              html={texts.error.description}
              wrapper="div"
            />
          ),
        };
      case BatchSaveState.isWarning:
        return {
          illustration: (
            <Illustration height={256} name="large_warning_state" width={256} />
          ),
          title: (
            <SafeHtml
              className={classes.warningTextColor}
              html={texts.warning.title}
              wrapper="h4"
            />
          ),
          description: (
            <SafeHtml
              className="text-center"
              html={texts.warning.description}
              wrapper="div"
            />
          ),
        };
      default:
        return defaultPageData;
    }
  };

  const renderResults = (list: VysledokStavuOdoslaniaDavok[]) =>
    list?.map((r, index) => {
      let m = <SafeHtml html={r.popis} wrapper={'div'} />;
      const respStateData: {
        btnName: string;
        color: string;
        linkClass: string;
      } =
        Number(r.stav) === StavyOdoslaniaEnum.uspesne
          ? {
              btnName: 'Zobraziť výsledok',
              color: 'success',
              linkClass: 'text-color-primary',
            }
          : {
              btnName: 'Zobraziť chyby',
              color: 'error',
              linkClass: 'text-color-error',
            };
      if (r.protokol) {
        m = (
          <Bar className="no-mrg-bottom">
            <BarItem>
              <SafeHtml html={r.popis} wrapper={'div'} />
            </BarItem>
            <BarItem>
              <ButtonLink
                className={`inline-btn icon-with-text ${respStateData.linkClass}`}
                onClick={() => {
                  // @ts-ignore
                  if (r.protokol) saveDocument(r.protokol, true);
                }}
              >
                {respStateData.btnName}
              </ButtonLink>
            </BarItem>
          </Bar>
        );
      }
      return (
        <Notification
          key={`result-ntf--${index}`}
          className="mb-xsmall"
          message={m}
          variant={
            Number(r.stav) === StavyOdoslaniaEnum.uspesne ? 'success' : 'error'
          }
        />
      );
    });

  return (
    <div className={classes.root}>
      {getPageData(state).illustration}
      <div className="mb">
        {getPageData(state).title}
        {getPageData(state).description}
      </div>

      <Grid>
        <GridCol offset="center" size={9}>
          {state !== BatchSaveState.isLoading && renderResults(results || [])}
        </GridCol>
      </Grid>
      {state === BatchSaveState.isSuccess && (
        <ButtonLayout
          className="align-items-center no-mrg-bottom"
          direction="horizontal"
        >
          <Button onClick={() => navigate(routes.dashboard)}>
            Späť na domov
          </Button>
        </ButtonLayout>
      )}
    </div>
  );
};

export default StavyOdoslania;
