import { createUseStyles } from 'react-jss';

interface Props {
  color: string;
}

export default createUseStyles({
  color: ({ color }: Props) => ({
    color,
  }),
});
