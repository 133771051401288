import { NoDataIllustration } from '../../../../../components/EmptyState';
import strings from '../../../../../constants/strings';
import { history } from '../../../../../helpers/history';
import { useModalContext } from '../../../../../hooks/useModalContext';
import routes from '../../../../../routes';
import { LoadingState, Nullable } from '../../../../../types';
import { ModalType } from '../../../../../types/modal.types';
import { ParameterDetail } from '../../../../../types/parameters.types';
import DetailModalSkeleton from '../../../skeletons/DetailModalSkeleton';
import Content from '../../detail/Content';

interface Props {
  data: Nullable<ParameterDetail[]>;
  dataState: LoadingState;
  modalID: ModalType;
}

const staticTexts = strings.parameters.emptyStates.detail;

const Body = ({ data, dataState, modalID }: Props) => {
  const { closeModal } = useModalContext();

  return (
    <>
      {dataState === LoadingState.error && (
        <NoDataIllustration
          button={staticTexts.button}
          description={staticTexts.description}
          onButtonClick={() => {
            closeModal(modalID);
            history.navigate(routes.parameters);
          }}
          title={staticTexts.title}
        />
      )}
      {dataState === LoadingState.fetching && <DetailModalSkeleton />}
      {dataState === LoadingState.filled && data && (
        <Content
          data={data?.filter(
            (d) => !(d.type === 'html' && d.attributes.text?.includes('<h2')),
          )}
        />
      )}
    </>
  );
};

export default Body;
