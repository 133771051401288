import { ReactNode } from 'react';
import routes from '../../routes';

export const strings = {
  undefined: '-',
  close: 'Zavrieť',
  dlekUnavailabillity:
    '<b>Nedostupnosť služieb DôveraLekárom a Elektronickej pobočky.</b>',
  systemNotification: (from: string, to: string) =>
    `<b>Od ${from} do ${to}</b> budeme pracovať na zlepšovaní aplikácie DôveraLekárom.<br />Niektoré funkcionality tak nebudú dostupné.`,
  filterNoDataDefault: 'Nenašli sme žiadne výsledky',
  defaultError: 'Prepáčte, nastala technická chyba na našej strane.',
  technickaChyba: 'Prepáčte, nastala technická chyba<br />na našej strane.',
  nepripojene:
    'Zdá sa, že nemáte pripojenie na internet. Zmeny, ktoré spravíte, nebudú uložené.',
  loginPublicChyba:
    'Prihláste sa, aby ste si mohli bezpečne<br />pozerať všetky údaje',
  loginPublicChybaPopis:
    'Z technických a bezpečnostných dôvodov Vám odporúčame prihlásiť sa.',
  notificationPublic:
    'Po prihlásení si môžete pozrieť aj detail parametrov a inú odbornosť.',
  adresaTooltip:
    'DÔVERA zdravotná poisťovňa, a.s.<br />' +
    'Cintorínska 5<br />' +
    '949 01 Nitra',
  emptyStates: {
    technicalError:
      'Nastala technická chyba. Skúste, prosím, opakovať akciu neskôr.',
    notAllowed: 'Nemáte oprávnenie na zobrazenie tejto stránky.',
    app404: 'Stránka sa nenašla.',
    dataOdbornost: 'Pre danú sekciu sme nenašli dáta k vybranej odbornosti.',
  },
  automatickeOdhlasenie: {
    nadpis: 'Automatické odhlásenie',
    zostavajuciCas: 'Zostávajúci čas do odhlásenia:',
    pokracovat: 'Zostať prihlásený',
    odhlasitSa: 'Odhlásiť sa',
  },
  vseobecne: {
    upravit: 'Upraviť',
    dozvedietSaViac: 'Dozvedieť sa viac',
    prejstNaFormular: 'Prejsť na formulár',
    tlacit: 'Vytlačiť prehľad parametrov',
    tlacitIETooltip:
      'Pre tlač prehľadu parametrov, použite, prosím iný prehliadač, napríklad Chrome.',
    vitajte: 'Vitajte',
    predchadzajucePlnenie: {
      1: 'Plnenie ',
      2: 'v minulosti',
      tooltip: 'Obdobie je uzavreté a údaje sa už nedajú ovplyvniť.',
    },
    vyhodnotene: {
      1: 'Vyhodnotené plnenie',
      tooltip: 'Obdobie je uzavreté a údaje sa už nedajú ovplyvniť.',
    },
    aktualnePlnenie: {
      1: 'Priebežné ',
      2: 'plnenie',
      tooltip: 'Do konca obdobia zostáva <strong>{{dni}} dní</strong>.',
    },
    buduceObdobie: {
      1: 'Výmena parametra pre ',
      2: 'budúce obdobie',
      tooltip: ' Tooltip pre budúce obdobie... ',
    },
    splnene: 'Splnené',
    nesplnene: 'Nesplnené',
    ciastocneSplnene: 'Čiastočne splnené',
    nepovolenyPristup: {
      nadpis: 'Neoprávnený prístup',
      popisPracujeme:
        'Táto sekcia je momentálne nedostupná. Skúste neskôr, prosím.',
      popis:
        'Pre prístup do tejto sekcie nemáte oprávnenie. Pre viac informácii nás <a href="{{href}}" target="_blank">kontaktujte</a>, prosím.',
    },
    ziadneData:
      'Ospravedlňujeme sa. Pre túto sekciu sa nenačítali žiadne dáta. Skúste to neskôr, prosím.',
  },
  prehladPacientov: {
    diagnozy: {
      ziadneDiagnozy:
        'Diagnózy sú zobrazované len pre konkrétnu odbornosť (vyberte odbornosť v hornej lište)',
      pocet: {
        title: 'Počet pacientov',
        titleDoctor: 'Počet pacientov',
        tooltip: 'Pacienti, ktorých ste diagnostikovali na uvedenú diagnózu',
      },
      pocetSR: {
        title: 'Počet pacientov',
        tooltip:
          'Pacienti, ktorých diagnostikovali kolegovia vo Vašej odbornosti na celom Slovensku. Výberom okresu alebo kraja viete porovnať počet diagnostikovaných pacientov pre akýkoľvek okres alebo kraj.',
      },
      podiel: {
        title: 'Podiel ku všetkým Vašim pacientom',
        titleDoctor: 'Podiel ku všetkým Vašim pacientom',
        tooltip:
          '% pacientov s danou diagnózou voči ostatným, ktoré ste diagnostikovali vo Vašej ambulancii',
      },
      podielSR: {
        title: 'Podiel ku všetkým pacientom v rámci SR',
        tooltip:
          '% pacientov s danou diagnózou voči ostatným, ktoré diagnostikovali Vaši kolegovia v danej odbornosti',
      },
      vyskytDiagnoz: {
        title: 'Pre porovnanie vyberte kraj alebo okres:',
        tooltip:
          'Výberom okresu alebo kraja viete porovnať počet diagnostikovaných pacientov aj voči priemeru pre akýkoľvek okres alebo kraj.',
      },
    },
    pacienti: {
      POC_OSET: {
        title: 'Ošetrení unikátni pacienti',
        tooltip:
          'Pacienti poistení v SR, ktorých ste ošetrili za uvedené obdobie. Ak Vašu ambulanciu pacient navštívil viackrát, je uvedený len raz. Ak máte vo svojej ambulancii evidovaných aj kapitovaných poistencov, títo nie sú započítaní ako unikátni ošetrení pacienti.',
      },
      POC_OSET_EU: {
        title: 'Ošetrení unikátni pacienti (EÚ)',
        tooltip:
          'Pacienti poistení v EÚ. Ak Vašu ambulanciu pacient navštívil viackrát, je uvedený len raz.',
      },
      POC_KONT: {
        title: 'Kontakt s unikátnymi pacientami',
        tooltip:
          'Osobné návštevy, telefonické konzultácie, t.j. všetky interakcie s pacientami kedy bola vykázaná ZS za uvedené obdobie.',
      },
      POC_NOVODIAG: {
        title: 'Novodiagnostikovaní pacienti',
        tooltip:
          'Pacienti, ktorí boli  Vami diagnostikovaní na novú chorobu za obdobie posledného roka a súčasne nemali žiadny kontakt s lekárom, napríklad vyšetrenie alebo telefonickú konzultáciu na danú diagnózu za obdobie posledné 2 roky.',
      },
      POC_NAROC: {
        title: 'Najdrahší pacienti',
        tooltip:
          'Pacienti, ktorých zdravotný stav spôsobuje vysoké náklady. Títo pacienti sa nezohľadňujú ani pri parametri: "Náklady na ZS" ak sa pre danú ambulanciu vypočítava.',
      },
      POC_KAPIT: {
        title: 'Kapitovaní pacienti',
        tooltip:
          'Pacienti, ktorí sú evidovaní vo Vašej ambulancii s podpísanou dohodou.',
      },
      IR: {
        title: 'Index rizika pacienta',
        tooltip:
          'Hodnota na základe, ktorej je možné porovnať rizikovosť pacienta. Pacient s priemerným rizikom má hodnotu 1,0. Ak je hodnota nižšia ako 1,0 máte pacientov s menším rizikom a ak je vyššia ako 1,0 tak s vyšším rizikom.',
      },
      prehlad: {
        ZS: {
          title: 'Čerpanie zdravotnej starostlivosti',
          titleFilter: 'Čerpanie zdravotnej starostlivosti',
          ZS_HOSP: {
            title: 'Hospitalizovaní pacienti',
            description: () =>
              'Tu zobrazujeme Vašich pacientov, ktorí v poslednom roku absolvovali aspoň 1 hospitalizáciu.',
            tooltip:
              'Pacienti, ktorých ste ošetrili a zároveň boli hospitalizovaní za uplynulé obdobie. ',
          },
          POC_NAROC: {
            title: 'Najdrahší pacienti',
            description: () =>
              'Tu si môžete pozrieť Vašich najnákladnejších pacientov. Zobrazujeme 50 Vašich pacientov v poradí od najdrahšieho.',
            tooltip:
              'Pacienti s najvyššími nákladmi, ktorých ošetrujete a ktorým indikujete ďalšiu zdravotnú starostlivosť',
            tooltipCard1:
              'Informatívna suma za celkové náklady na pacienta, ktoré ste vytvorili (Vaše a odporúčané) za lieky, SVLZ a výkony. Táto suma nevstupuje do výpočtu hodnotiacich parametrov.',
            tooltipCard2:
              'Suma po očistení 2% najdrahších pacientov a po odpočítaní vylúčených výkonov, napríklad preventívne prehliadky, očkovanie, ...)',
          },
          ZS_NEOCK: {
            title: 'Pacienti nezaočkovaní proti covidu-19',
            description: (obdobieOd: string, obdobieDo: string) =>
              obdobieOd && obdobieDo
                ? `Informácie o zaočkovaných pacientoch dostávame do poisťovne s mesačným oneskorením a aktualizujeme ich do 10. dňa v mesiaci. Napríklad: Ak bol pacient očkovaný v máji, do prehľadu sa táto informácia dostane po 10. júli. Údaje o rizikových pacientoch sú vyhodnotené za obdobie <b>${obdobieOd}</b> až <b>${obdobieDo}</b>. Definíciu diagnózy, ktorá môže zvýšiť riziko závažného priebehu covidu-19 nájdete vo <a href="https://www.slov-lex.sk/static/pdf/2021/96/ZZ_2021_96_20210605.pdf" target="_blank">vyhláške</a>.`
                : '',
            tooltip:
              'Nezaočkovaní pacienti podľa vekových skupín a diagnózy, ktorá môže zvýšiť riziko závažného priebehu covidu-19.',
          },
          ZS_POS_COV: {
            title: 'Pacienti vhodní na podanie monoklonálnych protilátok',
            description: () =>
              'Na základe vykázaného laboratórneho vyšetrenia (PCR test) môžu títo pacienti spĺňať <b>indikačné kritéria na liečbu monoklonálnymi protilátkami (MAB).</b><br /><br />Zobrazujeme pacientov:<br /><ul class="no-mrg-bottom"><li>nad 65 rokov bez ohľadu na pridružené diagnózy,</li><li>mladších ako 65 rokov, ktorí majú diagnózu spôsobujúcu ťažký priebeh covidu-19.</li></ul><br />Predtým, ako pacientovi vystavíte výmenný lístok a objednáte ho na podanie MAB, <b>skontrolujte, prosím, jeho zdravotný stav</b>, či spĺňa indikačné kritériá na podanie MAB.<br /><br /><b>Dopravnú zdravotnú službu</b> je možné poskytnúť pacientom, ktorým zdravotný stav neumožňuje si zabezpečiť vlastnú dopravu do centra.',
            tooltip:
              'Vaši kapitovaní pacienti, ktorým môže byť indikované podanie liečby monoklonálnymi protilátkami.',
          },
        },
        CHR: {
          title: 'Chronickí pacienti vo Vašom kmeni',
          titleFilter: 'Chronickí pacienti',
          CHR_HYP: {
            title: 'Diagnostikovaní hypertonici',
            description: () =>
              'Nižšie nájdete podiel hypertonikov vo Vašom kmeni a informáciu o rozdelení starostlivosti o nich medzi Vás a lekárov - špecialistov.',
            tooltip:
              'Informácie o pacientoch s hypertenziou vrátane informácie o farmakoterapii a prípadných komorbiditách pacienta',
          },
          CHR_HYP_STAR: {
            title: 'Hypertonici s možnou zmenou v starostlivosti',
            description: () =>
              'Pri týchto pacientoch sme indentifikovali možnosť presunu starostlivosti medzi Vami a lekárom - špecialistom. Zvážte, kedy je vhodná liečba pacienta výhradne vo Vašej starostlivosti a kedy je potrebná spolupráca so špecialistom.',
            tooltip:
              'Informácie o štruktúre pacientov s hypertenziou vo Vašom kmeni podľa komorbidít a o rozložení starostlivosti medzi Vás a lekárov ŠAS.',
          },
          CHR_DIA: {
            title: 'Diagnostikovaní diabetici',
            description: () =>
              'Nižšie nájdete prehľad diabetikov vo Vašej starostlivosti a informácie o priebehu edukácie pacientov.',
            tooltip:
              'Informácie o pacientoch s diabetom vrátane informácie o potrebe edukácie pacienta',
          },
          CHR_DISP: {
            title: 'Dispenzarizovaní pacienti',
            description: () =>
              'Na tejto stránke nájdete prehľad Vašich dispenzarizovaných pacientov. Na ľavo môžete vyhľadať konkrétneho pacienta alebo vybrať zoznam pacientov podľa diagnózy, s ktorou sú dispenzarizovaní.',
            tooltip:
              'Informácie o všetkých dispenzarizovaných pacientoch, ktorých máte vo svojej starostlivosti',
          },
          CHR_GDM: {
            title: 'Gestačné diabetičky',
            description: (text: string) =>
              `Tu môžete vidieť prehľad gestačných diabetičiek, u ktorých od pôrodu neevidujeme kontrolu (oGTT, HbA1c alebo vyšetrenie glykémie) a sú vo vyššom riziku rozvoja diabetu 2.typu, po ktorom treba pátrať celoživotne. Údaje sú aktualizované k ${text}.`,
            tooltip:
              'Tehotné pacientky s rizikom rozvoja diabetu 2. typu a prediabetu.',
            tooltipDiscard: 'Pacientku môžete zo zoznamu vyradiť.',
            archiv: {
              description:
                'Po vyradení už pacientku v zozname neuvidíte a nemôžete ho vrátiť späť.',
              title: 'Naozaj chcete pacientku vyradiť?',
            },
          },
        },
        FARM: {
          title: 'Farmakoterapia Vašich pacientov',
          titleFilter: 'Farmakoterapia',
          FARM_POSUD: {
            title: 'Pacienti s klinickým posudkom',
            description:
              'Pacienti, ktorým bola počas hospitalizácie prehodnotená farmakoterapia. Odporúčania na úpravu medikácie nájdete v prepúšťacej správe pacienta.',
            tooltip:
              'Pacienti, ktorým bola počas hospitalizácie prehodnotená farmakoterapia. Odporúčania na úpravu medikácie nájdete v prepúšťacej správe pacienta.',
            tooltipDiscard: 'Pacienta môžete zo zoznamu vyradiť.',
            archiv: {
              description:
                'Po vyradení už pacienta v zozname pacientov s klinickým posudkom neuvidíte a nemôžete ho vrátiť späť.',
              title: 'Naozaj si želáte pacienta vyradiť?',
            },
          },
          FARM_PROB01: {
            title: 'Pacienti s medikačným problémom',
            description:
              'Pacienti s možným klinickým problémom v preskripcii liekov. Viac o probléme a možnostiach riešenia nájdete v odbornom usmernení Inhibítory protónovej pumpy – nesprávna indikácia.',
            tooltip:
              'Pacienti s možným klinickým problémom v preskripcii liekov.',
            tooltipDiscard: 'Pacienta môžete zo zoznamu vyradiť.',
            archiv: {
              description:
                'Po vyradení už pacienta v zozname pacientov s medikačným problémom neuvidíte a nemôžete ho vrátiť späť.',
              title: 'Naozaj si želáte pacienta vyradiť?',
            },
          },
          FARM_HYP_NESP: {
            title: 'Hypertonici s neštandardnou farmakoterapiou',
            description:
              'V tejto časti nájdete informácie o hypertonikoch bez komorbidít alebo s diabetom, pri ktorých môže nastávať nesúlad farmakoterapie so štandardom.',
            tooltip:
              'Informácie o hypertonikoch bez komorbidít alebo s diabetom, pri ktorých môže nastávať nesúlad farmakoterapie so štandardom.',
          },
          FARM_HYP_NEVH: {
            title: 'Hypertonici s možnou nevhodnou kombináciou liekov',
            description:
              'Pri týchto pacientoch môže dochádzať k nevhodným, resp. kontraindikovaným kombináciám liekov vo farmakoterapii.',
            tooltip:
              'Informácie o hypertonikoch, pri ktorých môže dochádzať k nevhodnej kombinácii liekov vo farmakoterapii.',
          },
          FARM_POLY: {
            title: 'Pacienti s väčším počtom užívaných liekov',
            description:
              'Pacienti, ktorí užívajú dlhodobo viacero druhov liekov. Úpravou preskripcie je možné znížiť riziká ako sú interakcie, kontraindikácie a nežiadúce účinky.<br /><br />U pacientov označených „Pacient s klinickým posudkom” farmakoterapiu prehodnotil klinický farmakológ/farmaceut počas hospitalizácie. Odporúčania k úprave medikácie nájdete v prepúšťacej správe.',
            tooltip:
              'Pacienti, ktorí užívajú vyšší počet liekov a bolo by vhodné prehodnotiť užívané lieky.',
          },
          FARM_NEVYB: {
            title: 'Nevybrané lieky ',
            description:
              'Tu si môžete pozrieť informácie o pacientoch, o ktorých evidujeme, že si v lekárni nevybrali Vami predpísané lieky. Pacienti sú zobrazení od posledného predpisu lieku. V prípade, že v zozname nenájdete pacienta, ktorého hľadáte, môžete použiť vyhľadávanie cez meno alebo rodné číslo pacienta. V zozname liekov sa môžu nachádzať aj lieky, ktoré pacient vybral, avšak lekáreň informáciu o ich výbere neposkytla zdravotnej poisťovni.',
            tooltip:
              'Pacienti, o ktorých evidujeme, že si nevybrali predpísaný liek a tým pádom by mohla byť ovplyvnená ich následná liečba',
          },
          FARM_ZAM: {
            title: 'Zamenený liek v lekárni',
            description:
              'Tu si môžete pozrieť informácie o liekoch, ktoré boli Vašim pacientom v lekárni zamenené za iný liek. Obsahuje zoznam liekov, ktoré sú predpisované lekárom, avšak lekáreň ich, napríklad z dôvodu aktuálneho nedostatku liekov alebo iných okolností, zamenila za iný liek. V tomto zozname však nie sú uvedené lieky, ktoré sa zamieňajú v rámci predpisu na základe účinnej látky (generická substitúcia).',
            tooltip:
              'Pacienti, ktorým aj napriek predpisu konkrétneho lieku bol zamenený v lekárni iný liek.',
          },
        },
      },
    },
  },
  diagnozy: {
    filter: {
      mostExpensive: {
        label: 'Najdrahšie diagnózy',
        tooltip:
          'Voľba „Všetky diagnózy“ zobrazí<br />Vaše náklady za všetky vykázané<br />diagnózy, v rámci vybranej<br />odbornosti. Voľba „Najdrahšie<br />diagnózy“ zobrazí Vaše náklady na<br />top 10 najdrahších diagnóz, v rámci<br />vybranej odbornosti.',
      },
    },
    tooltipy: {
      null: 'Porovnanie Vašich celkových nákladov na pacienta pre túto diagnózu v danej odbornosti.',
      L: 'Porovnanie nákladov (€) <b>na predpísané lieky - ambulantné aj vydané v lekárni</b> pre zvolenú diagnózu v danom období vo Vašej odbornosti.',
      Z: 'Porovnanie nákladov (€) <b>na predpísané zdravotnícke pomôcky - ambulantné aj vydané v lekárni, vrátane optických pomôcok</b>, pre zvolenú diagnózu v danom období vo Vašej odbornosti.',
      V: 'Porovnanie nákladov (€) <b>na vykázané zdravotné výkony - vlastné aj indikované</b>, pre zvolenú diagnózu v danom období vo Vašej odbornosti.',
      S: 'Porovnanie nákladov (€) <b>na indikované SVLZ výkony - laboratórne, zobrazovacie, iné/liečebné)</b>, pre zvolenú diagnózu v danom období vo Vašej odbornosti.',
      SL: 'Porovnanie nákladov (€) <b>na indikované vyšetrenia</b> pre zvolenú diagnózu v danom období vo Vašej odbornosti.',
      SZ: 'Porovnanie nákladov (€) <b>na indikované vyšetrenia</b> pre zvolenú diagnózu v danom období vo Vašej odbornosti.',
      SI: 'Porovnanie nákladov (€) <b>na indikované vyšetrenia</b> pre zvolenú diagnózu v danom období vo Vašej odbornosti.',
    },
    graf: {
      tooltipy: {
        null: 'Náklady na pacienta na lieky, pomôcky, zdravotné výkony a SVLZ pre túto diagnózu (€).',
      },
    },
    segmenty: {
      nadpis: {
        null: 'Náklady na jedného pacienta pre danú diagnózu',
        L: 'Skupiny liekov s najväčším rozdielom v nákladoch v porovnaní s referenciou',
        Z: 'Skupiny zdravotníckych pomôcok s najväčším rozdielom v nákladoch v porovnaní s referenciou',
        V: 'Skupiny výkonov s najväčším rozdielom v nákladoch v porovnaní s referenciou',
        S: 'Skupiny SVLZ s najväčším rozdielom v nákladoch v porovnaní s referenciou',
        SL: 'Laboratórne vyšetrenia s najväčším rozdielom v nákladoch v porovnaní s referenciou',
        SZ: 'Zobrazovacie vyšetrenia s najväčším rozdielom v nákladoch v porovnaní s referenciou',
        SI: 'Liečebné vyšetrenia s najväčším rozdielom v nákladoch v porovnaní s referenciou',
      },
      tooltipy: {
        L: 'Predpísané lieky - ambulantné, vydané v lekárni.',
        Z: 'Predpísané zdravotnícke pomôcky - ambulantné aj vydané v lekárni, vrátane optických pomôcok.',
        V: 'Vykázané zdravotné výkony - vlastné aj indikované. ',
        S: 'Indikované SVLZ výkony - laboratórne, zobrazovacie, iné/liečebné. ',
      },
      nadpisTooltipy: {
        L: '<b>Pacienti</b> - podiel zo všetkých Vašich pacientov, ktorým bol pri tejto diagnóze predpísaný uvedený liek.<br /><b>Náklady</b> - priemerný náklad  na pacienta, ktorému bol predpísaný daný liek (€).',
        Z: '<b>Pacienti</b> - podiel zo všetkých Vašich pacientov, ktorým boli pri tejto diagnóze predpísané uvedené pomôcky.<br /><b>Náklady</b> - priemerný náklad na pacienta, ktorému bola predpísaná daná pomôcka (€).',
        V: '<b>Pacienti</b> - podiel zo všetkých Vašich pacientov, ktorým boli pri tejto diagnóze vykázané uvedené výkony.',
        S: '<b>Pacienti</b> - podiel zo všetkých Vašich pacientov, ktorým boli pri tejto diagnóze indikované uvedené SVLZ výkony.<br /><b>Náklady</b> - priemerný náklad na pacienta, ktorému boli  indikované dané SVLZ výkony (€).',
        SL: '<b>Pacienti</b> - podiel zo všetkých Vašich pacientov, ktorým boli pri tejto diagnóze indikované uvedené vyšetrenia.<br /><b>Náklad</b> - priemerný náklad na pacienta, ktorému bolo indikované dané vyšetrenie (€).',
        SZ: '<b>Pacienti</b> - podiel zo všetkých Vašich pacientov, ktorým boli pri tejto diagnóze indikované uvedené vyšetrenia.<br /><b>Náklad</b> - priemerný náklad na pacienta, ktorému bolo indikované dané vyšetrenie (€).',
        SI: '<b>Pacienti</b> - podiel zo všetkých Vašich pacientov, ktorým boli pri tejto diagnóze indikované uvedené vyšetrenia.<br /><b>Náklad</b> - priemerný náklad na pacienta, ktorému bolo indikované dané vyšetrenie (€).',
      },
    },
    detail: {
      popis: {
        L: 'V tabuľke zobrazujeme pacientov, ktorým ste predpísali účinnú látku.<br />Pacientov zoraďujeme podľa výšky nákladov na liečbu.',
        Z: 'V tabuľke zobrazujeme pacientov, ktorým ste predpísali vybrané zdravotnícke pomôcky.<br />Pacientov zoraďujeme podľa výšky nákladov na liečbu.',
        V: 'V tabuľke zobrazujeme pacientov, ktorým ste vykázali vybrané zdravotné výkony.<br />Pacientov zoraďujeme podľa výšky nákladov na liečbu.',
        S: 'V tabuľke zobrazujeme pacientov, ktorým ste vykázali vybrané SVLZ výkony.<br />Pacientov zoraďujeme podľa výšky nákladov na liečbu.',
      },
      vyluceniPacienti:
        'V zozname sa nenachádzajú {2 % pacientov s najvyššími nákladmi} na liečbu. Títo pacienti boli výlučení aj z hodnotiacich parametrov a nevstupujú ani do výpočtu tejto referencie.',
    },
  },
  cookies: {
    title: 'Cookies',
    description:
      'Súbor cookie je malý blok údajov (textový súbor), ktoré sa do vášho prehliadača ukladajú na pokyn internetovej lokality, keď ju používateľ navštívi, s cieľom zapamätať si informácie o vás, ako napríklad preferencie jazyka alebo prihlasovacie údaje. Tieto súbory cookie nastavujeme my a nazývajú sa súbory cookie prvej strany. Používame aj súbory cookie tretích strán. Ide o súbory cookie z inej domény, ako je doména internetovej lokality, ktorú práve navštevujete, a slúžia na účely našich reklamných a marketingových činností. Konkrétnejšie používame súbory cookie a iné sledovacie technológie na tieto účely:',
    necessaryCookies: {
      title: 'Nevyhnutne potrebné súbory cookie',
      description:
        'Tieto súbory cookie sú potrebné na zabezpečenie funkčnosti internetovej stránky a nemožno ich v našich systémoch vypnúť. Zvyčajne sa nastavujú len ako reakcia na vami vykonané činnosti, ktoré predstavujú žiadosť súvisiacu so službami, ako je napríklad nastavenie preferencií ochrany osobných údajov, prihlasovanie alebo vypĺňanie formulárov. Svoj prehliadač môžete nastaviť tak, aby blokoval alebo vás upozorňoval na takéto súbory cookie, v takom prípade však nemusia niektoré časti stránky fungovať.',
      tableTitle: 'Súbory cookies prvých strán',
    },
    functionCookies: {
      title: 'Súbory cookie súvisiace s funkčnosťou',
      description:
        'Tieto súbory cookie zabezpečujú lepšiu funkčnosť a prispôsobenie obsahu, ako sú videá a živý chat. Môžeme ich nastaviť my alebo externí poskytovatelia, ktorých služby sme pridali na naše stránky. Ak tieto súbory cookie nepovolíte, niektoré alebo všetky funkcie nemusia fungovať správne.',
      tableTitleFirstParty: 'Súbory cookies prvých strán',
      tableTitleThirdParty: 'Súbory cookies tretích strán',
    },
    powerCookies: {
      title: 'Súbory cookie súvisiace s výkonom',
      description:
        'Tieto súbory cookie nám umožňujú určiť počet návštev a zdroje návštevnosti, aby sme mohli merať a vylepšovať výkon našej stránky. Pomáhajú nám zistiť, ktoré stránky sú najviac a najmenej populárne, a vidieť, koľko návštevníkov sa na stránke pohybuje. Všetky informácie, ktoré tieto súbory cookie zbierajú, sú súhrnné, a teda anonymné. Ak tieto súbory cookie nepovolíte, nebudeme vedieť, kedy ste našu stránku navštívili.',
      tableTitle: 'Súbory cookies prvých strán',
    },
    targetCookies: {
      title: 'Súbory cookie pre cieľové zameranie',
      description:
        'Tieto súbory cookie nastavujú prostredníctvom našej stránky naši reklamní partneri. Tieto spoločnosti ich môžu používať na zostavenie profilu vašich záujmov a zobrazenie relevantných reklám na iných stránkach. Fungujú tak, že jedinečným spôsobom identifikujú váš prehliadač a zariadenie. Ak tieto súbory cookie nepovolíte, nebudete na iných internetových stránkach dostávať náš cieľový reklamný obsah.',
      tableTitleFirstParty: 'Súbory cookies prvých strán',
      tableTitleThirdParty: 'Súbory cookies tretích strán',
    },
    validityCookies: {
      title: '*Životnosť',
      description:
        'Dočasné cookies (automaticky sú zmazané po zatvorení webového prehliadača).<br />Trvalé cookies (ostanú na disku uložené aj po zatvorení webového prehliadača).',
    },
    settingsButton: 'Nastavenia cookies',
  },
  profile: {
    title: 'Profil',
    labels: {
      name: 'Názov firmy:',
      ico: 'IČO:',
      kodPZS: 'Kód poskytovateľa:',
      as: 'Adresa sídla:',
      ka: 'Korešpondenčná adresa:',
      asoc: 'Členstvo v asociácii:',
      doveraPomaha: 'Program DôveraPomáha:',
      vztah: 'Zmluvný vzťah:',
      odbornosti: 'Odbornosti:',
      addAddress: 'Pridať adresu',
      editAddress: 'Upraviť adresu',
      countExpertises: (count: number) =>
        `${count} odbornost${count > 4 ? 'í' : 'i'}`,
      position: 'Pozícia:',
      email: 'E-mail:',
      doctorCode: 'Kód lekára:',
    },
    tooltips: {
      as: 'Sídlo spoločnosti ako je zapísané v obchodnom registri.',
      ka: 'Zmenu korešpondenčnej adresy nám nahláste cez Schránku správ v Elektronickej pobočke.',
    },
    links: {
      kontaktneOsoby: 'Kontaktné osoby',
      bankoveSpojenie: 'Bankové spojenie',
      zmluvnePristroje: 'Zmluvné prístroje',
      overeniaPrehlady: 'Overenia a prehľady',
      prihlasovacieUdaje: 'Zmena prihlasovacích údajov',
    },
    modals: {
      expertises: {
        title: 'Odbornosti',
        close: 'Zavrieť',
        labels: {
          code: 'Kód',
          expertise: 'Odbornosť',
        },
      },
      ka: {
        title: (addressExist: boolean) =>
          `${addressExist ? 'Upraviť' : 'Pridať'} korešpondenčnú adresu`,
        labels: {
          country: 'Krajina',
          town: 'Obec',
          zip: 'PSČ',
          street: 'Ulica',
          streetNumber: 'Popisné číslo',
          cancel: 'Zrušiť',
          submit: 'Uložiť zmeny',
        },
      },
    },
    contactPersons: {
      title: 'Kontaktné osoby',
      description:
        'Zoznam kontaktných osôb, ako ich evidujeme v poisťovni.<br />Ak chcete pridať alebo upraviť kontaktné osoby, oznámte nám to cez [Schránka správ].',
      labels: {
        name: 'Meno a rola',
        phone: 'Telefón',
        email: 'Email',
        mandate: 'Splnomocnenie',
      },
      noData: 'Neevidujeme žiadnu kontaktnú osobu',
    },
    bankAccounts: {
      title: 'Bankové spojenie',
      description:
        'Tieto bankové účty evidujeme v poisťovni.<br />Ak chcete pridať alebo upraviť účty, oznámte nám to cez [Schránka správ].',
      labels: {
        iban: 'IBAN',
        validity: 'Platnosť od',
        note: 'Poznámka',
      },
      noData: 'Neevidujeme žiadne bankové spojenie',
    },
    devices: {
      title: 'Zmluvné prístroje',
      description:
        'Vaše prístroje, ktoré evidujeme v poisťovni ako zmluvné.<br />Ak chcete pridať alebo upraviť prístroje, oznámte nám to cez [Schránka správ].',
      labels: {
        type: 'Typ',
        name: 'Názov',
        sn: 'Číslo prístroja',
      },
      noData: 'Neevidujeme žiadne zmluvné prístroje',
    },
    changeLoginDetails: {
      title: 'Zmena prihlasovacích údajov',
    },
    overeniaPrehlady: {
      title: 'Overenia a prehľady',
      description:
        'Overenia <a href="https://www.dovera.sk/overenia/overit-poistenca" target="_blank">poistenca</a>, <a href="https://www.dovera.sk/overenia/overit-uvazok-lekara" target="_blank">lekára a jeho úväzku</a> alebo <a href="https://www.dovera.sk/overenia/overit-prevadzku" target="_blank">ambulancie</a> sú dostupné na webe <a href="https://www.dovera.sk" target="_blank">dovera.sk</a>.',
      subtitle: 'Prehľady lekárov a ambulancií',
      baseFilter: [
        {
          text: 'Lekári',
          value: 'L',
        },
        {
          text: 'Ambulancie',
          value: 'A',
        },
      ],
      filterNoData: 'Nenašli sme žiadne výsledky',
      export: 'Exportovať',
      exportTitle: (isAmbulances: boolean) =>
        `Overenia a prehľady - ${isAmbulances ? 'AMBULANCIE' : 'LEKÁRI'}`,
      filter: {
        ambulances: {
          labels: {
            name: 'Kód/názov ambulancie',
            expertise: 'Kód/názov odbornosti',
            contractual: 'Zmluvná',
            date: 'K dátumu',
          },
          columns: {
            name: 'Kód/názov ambulancie',
            expertise: 'Kód/názov odbornosti',
            valid: 'Platná od - do',
            contractual: 'Zmluvná',
            action: 'Akcia',
          },
        },
        doctors: {
          labels: {
            name: 'Kód/meno lekára',
            expertise: 'Kód/názov odbornosti',
            workingTime: 'Typ úväzku',
          },
          columns: {
            name: 'Kód/meno lekára',
            ambulance: 'Kód/názov ambulancie',
            expertise: 'Kód/názov odbornosti',
            workingTime: 'Typ úväzku',
            valid: 'Platný',
          },
        },
      },
      ambulanceDetail: {
        title: 'Detail ambulancie',
        emptyState: 'Nenašli sme žiadne výsledky',
        card: {
          titleAddress: 'Adresa prevádzky',
          titleOfficeHours: 'Ordinačné hodiny',
          btnChangeOfficeHours: 'Zmeniť ordinačné hodiny',
          btnSetAbsence: 'Nahlásiť neprítomnosť',
          tootlip:
            'Zmenu ordinačných hodín nám nahláste cez Schránku správ alebo<br />na poskytovatel@dovera.sk.<br /><b>PR</b> = Prestávka<br /><b>LO</b> = Len na objednávku<br /><b>SV</b> = Špeciálne vyšetrenia a odbery<br /><b>AZ</b> = Ambulancia zatvorená - návštevy, administratíva<br /><b>OD</b> = Objednávky pacientov Dôvery',
          tooltipAbsence:
            'Vyplňte formulár a pošlite nám ho<br />cez Schránku správ alebo na<br />poskytovatel@dovera.sk.',
        },
        table: {
          title: 'Zoznam lekárov v ambulancii',
          labels: {
            name: 'Kód/meno lekára',
            expertise: 'Kód/názov odbornosti',
            workingTime: 'Typ úväzku',
            validity: 'Platný',
          },
        },
      },
    },
    loginDetails: {
      warning:
        'Nové prihlasovacie údaje si nezabudnite aktualizovať aj vo svojom ambulantnom softvéri.',
      subtitleLogin: 'Prihlasovacie meno',
      subtitlePassword: 'Prihlasovacie heslo',
      labels: {
        currentLogin: 'Aktuálne prihlasovacie meno',
        newLogin: 'Nové prihlasovacie meno',
        currentPassword: 'Aktuálne prihlasovacie heslo',
        newPassword: 'Nové prihlasovacie heslo',
        repeatNewPassword: 'Zopakujte prihlasovacie heslo',
        button: 'Potvrdiť zmenu',
      },
      tooltips: {
        newPassword:
          'Heslo musí obsahovať:<ul class="no-mrg"><li>min. 6, max. 128 znakov,</li><li>veľké aj malé písmená,</li><li>aspoň jednu číslicu.</li></ul>Dlhšie heslá sú bezpečnejšie.',
      },
    },
  },
  validation: {
    required: {
      default: 'Povnné pole',
    },
    incorrectCharacter: 'Nepovolený znak',
  },
  passwordIndicator: {
    weekLabel: 'Slabé heslo',
    strongLabel: 'Silné heslo',
  },
  capsLockWarning: 'Máte zapnutý Caps Lock - povoľuje iba veľké písmená.',
  form: {
    successDefault: 'Vaše údaje boli úspešne zmenené.',
    successFormDefault:
      'Údaje boli úspešne zmenené. Do piatich minút sa nimi môžete prihlásiť.',
  },
  feedbacker: {
    standard: {
      feedbackButton: 'Váš názor',
      firstStep: {
        label: 'Nakoľko Vám pomohla táto stránka?',
        rating: 'Hodnotenie',
      },
      secondStep: {
        textareaDefault: 'Napíšte Váš názor',
        badRating: {
          label: 'Na aký problém ste narazili?',
          checkboxes: {
            narocnaOrientacia: 'Náročná orientácia na stránke',
            parametre: 'Nesprávne vyhodnotené parametre',
            zlozitost: 'Zložitý text',
            ine: 'Iné',
          },
        },
        goodRating: {
          label: 'Čo sa vám páčilo?',
          checkboxes: [
            'Prehľadná stránka',
            'Prínosné informácie',
            'Jednoduché elektronické služby',
            'Iné',
          ],
        },
        submit: 'Odoslať',
      },
      lastStep: {
        success: 'Ďakujeme za spätnú väzbu!',
        error: 'Prepáčte, nepodarilo sa odoslať spätnú väzbu. (Skúsiť znovu)',
      },
    },
    trigger: {
      feedbackButton: 'Váš názor',
      firstStep: {
        label: 'Aká bola vaša skúsenosť s používaním aplikácie?',
        rating: 'Hodnotenie',
        closeBtn: 'V budúcnosti sa ma na názor nepýtajte',
      },
      secondStep: {
        badRating: {
          label: 'Ktoré funkcionality sa vám nepáčili?',
        },
        goodRating: {
          label: 'Ktoré funkcionality sa vám páčili?',
        },
        textarea: 'Napíšte nám návrh na zlepšenie',
        submit: 'Odoslať',
      },
      lastStep: {
        success: 'Ďakujeme za spätnú väzbu!',
        error: 'Prepáčte, nepodarilo sa odoslať spätnú väzbu. (Skúsiť znovu)',
      },
    },
    errors: {
      validation: 'Vyberte aspoň jednu z možností.',
      validationText: 'Napíšte Váš názor',
    },
  },
  poistenci: {
    kapitovani: {
      title: 'Poistenci',
      subtitle: 'Prehľad kapitovaných a sporných poistencov',
      baseFilter: [
        {
          text: 'Kapitovaní',
          value: routes.kapitovaniPoistenci,
          id: 'kap-filter',
        },
        {
          text: 'Sporní',
          value: routes.sporniPoistenci,
          id: 'spor-filter',
        },
      ],
      filterDohoda: [
        {
          text: 'Všetky',
          value: 'all',
        },
        {
          text: 'Platná',
          value: 'P',
        },
        {
          text: 'Neplatná',
          value: 'N',
        },
      ],
      labels: {
        poi: 'Poistenec',
        kod: 'Kód/meno lekára',
        export: 'Excel',
        exportCSV: 'CSV',
        konflikt: 'Konflikt s lekármi',
        vznikKonfliktu: 'Vznik konfliktu',
        vyradit: 'Vyradiť',
        ponechat: 'Ponechať',
        dohoda: 'Dohoda o poskytovaní ZS',
        predchadzajuciLekar: 'Predchádzajúci lekár',
        kapitovanyOd: 'Kapitovaný od',
        kapitovanyDo: 'Kapitovaný do',
        platnaDohoda: 'Platná dohoda',
      },
      columnsCap: [
        'Poistenec',
        'Kód/meno lekára',
        'Predchádzajúci lekár',
        'Kapitovaný od',
        'Kapitovaný do',
        'Platná dohoda',
      ],
      columnsConflict: [
        'Poistenec',
        'Kód/meno lekára',
        'Konflikt s lekármi',
        'Vznik konfliktu',
        '',
      ],
      tips: {
        title: 'Ako vyriešiť kapitačný konflikt?',
        description: '',
      },
      tooltips: {
        keepLongTime:
          'Poistenec je sporný už dlhší čas.<br />Ak si ho chcete ponechať, nahrajte dohodu.',
      },
      modals: {
        labels: {
          poi: 'Poistenec:',
          doctors: 'Lekári v konflikte:',
        },
        discardPatient: {
          title: 'Vyradiť pacienta a ukončiť dohodu?',
          labels: {
            datepicker: 'Ukončiť dohodu k dátumu',
            button: 'Ukončiť dohodu',
          },
          description:
            'Na tohto poistenca si poslal prírastkovú dávku iný lekár, ale neevidujeme úbytkovú dávku od Vás. Alebo ste si prírastkovú dávku poslali omylom.',
          toast:
            'Konflikt bol vyriešený. Do poisťovne už nemusíte posielať úbytkovú dávku.',
        },
        keepPatient: {
          title: 'Priložte, prosím, aktuálnu dohodu',
          labels: {
            attachment: 'Priložiť dohodu',
            attachmentDescription: 'Presuňte dohodu sem alebo kliknite na',
            button: 'Odoslať',
          },
          description:
            'Ak si chcete tohto poistenca ponechať ako kapitovaného, pošlite nám aktuálnu Dohodu o poskytovaní zdravotnej starostlivosti. Overíme jej platnosť a odpovieme Vám čo najskôr.',
          error: 'Pre odoslanie je potrebné priložiť prílohu.',
          toast:
            'O vyriešení konfliktu Vás budeme informovať mailom/cez schránku správ.',
        },
      },
    },
  },
  auth: {
    banner: {
      title: 'Upravte si účet podľa seba',
      description:
        '<ul><li class="mb-small">Zdieľate tento účet s inými kolegami? Môžete si vytvoriť svoj vlastný.</li><li class="mb-small">Dostanete údaje o pacientoch a diagnózach prepočítané na konkrétneho lekára.</li><li>Ak tento účet používate iba vy, skontrolujte a aktualizujte si Vaše osobné údaje.</li></ul>',
      description2:
        '<ul><li class="mb-small icon-security">Pre zvýšenie bezpečnosti a zobrazovanie dát na mieru potrebujeme, aby mal každý svoj vlastný účet s grid kartu.</li><li class="mb-small icon-user">Ak používate grid kartu svojho kolegu, založíme Vám vlastný účet.</li><li class="mb-small icon-edit">Ak tento účet používate iba Vy, stačí keď doplníte Vašu pozíciu a aktualizujete osobné údaje.</li><li class="icon-hearth">Upravením Vášho účtu získate navyše niekoľko výhod, a aplikáciu personalizujeme iba pre Vás.</li></ul>',
      description2Hidden:
        '<ul><li class="mb-small icon-sign">Ak ste štatutár, dostanete relevantné informácie k zmluvnej dokumentácii a môžete elektronicky podpisovať zmluvné dokumenty.</li><li class="mb-small icon-statistics">Každý lekár na svojom účte získa údaje o pacientoch, ktorých ošetril a môže sa porovnať s inými lekármi vo svojej odbornosti.</li><li class="icon-key">Mnoho ďalších výhod ako je vyššia bezpečnosť údajov o pacientoch, prihlásenie emailom, na ktorý budete dostávať informácie, dôležité pre Vašu pozíciu.</li><li>Pre účtovníkov a iné administratívne pozície garantujeme naďalej prístup k potrebným údajom, avšak iba relevantným pre ich pozíciu.</li></ul>',
      buttonShowBenefits: 'Zobraziť výhody',
      buttonHideBenefits: 'Skryť výhody',
      button: 'Pokračovať',
    },
    modal: {
      title: 'Vytvorte si vlastný účet<br />v DôveraLekárom',
      description:
        '<ul><li class="mb-xsmall">Uvidíte iba svojich pacientov a údaje za svoju ambulanciu.</li><li class="mb-xsmall">Ak tento prístup používate viacerí, vytvoríte si vlastné prihlasovacie údaje.</li><li>Dostanete pravidelne aktualizované dáta o diagnózach pacientov.</li></ul>',
      button: 'Pokračovať',
      buttonShowBenefits: 'Zobraziť výhody',
      buttonHideBenefits: 'Skryť výhody',
    },
    bannerPatients: {
      title: 'Chcete vidieť iba svojich pacientov?',
      description:
        'Vytvorte si svoj vlastný účet alebo si personalizujte existujúci.',
      button: 'Vytvoriť účet',
    },
    bannerCard: {
      title: 'Vytvorte si vlastný účet v&nbsp;3&nbsp;krokoch',
      description:
        'Dostanete aktuálne dáta iba pre Vás alebo si personalizujte existujúci.',
      button: 'Vytvoriť účet',
    },
    page: {
      step1: {
        title: 'Prihlasujete sa jednou GRID kartou viacerí?',
        stepTitle: 'Ako sa prihlasujete',
        y: 'Áno',
        n: 'Nie',
        notification:
          'Ak sa GRID kartou prihlasujete viacerí, v ďalších krokoch si vytvoríte vlastný účet.',
      },
      step2: {
        title: 'Aká je Vaša pozícia?',
        stepTitle: 'Výber pozície',
        description: 'Vyberte maximálne dve pozície.',
        infoFuture:
          'V budúcnosti budú dostupné aj pozície ako zdravotná sestra, administratívna pozícia a iné.',
        L: {
          description: 'Máte kód lekára',
        },
        S: {
          description: 'Podpisujete zmluvné dokumenty',
        },
        Z: {
          description: 'Staráte sa o pacientov',
        },
        A: {
          description: 'Napr. manažér, účtovník, IT správca,...',
        },
        I: {
          description: 'Napíšte svoju pozíciu',
        },
        errors: {
          default: 'Vyberte aspoň jednu pozíciu.',
          differentPosition: 'Musíte vyplniť svoju pozíciu.',
        },
        warnings: {
          doctorSister:
            'Kombinácia lekára a sestry nie je možná. Zvoľte, prosím, inú pozíciu.',
          max2Positions:
            'Kombinácia 3 a viac pozícií nie je možná. Zvoľte, prosím, maximálne 2 pozície.',
        },
      },
      step3: {
        title: 'Osobné údaje',
        stepTitle: 'Osobné údaje',
        labels: {
          doctorCode: 'Kód lekára',
          titleBefore: 'Titul pred menom',
          titleAfter: 'Titul za menom',
          firstName: 'Meno',
          lastName: 'Priezvisko',
          email: 'E-mail',
          repeatEmail: 'Zopakujte e-mail',
          phone: 'Pevná linka',
          mobile: 'Mobil',
          chooseStatutory: 'Vyberte štatutára',
          statutoryRole: 'Funkcia štatutára',
        },
        helpers: {
          doctorCode: 'Zadajte kód v tvare Axxx45 (bez kódu odbornosti).',
        },
        buttons: {
          home: 'Domov',
          back: 'Späť',
          continue: 'Pokračovať',
          submit: 'Uložiť údaje',
        },
        dontSeeExpertises: 'Nevidíte všetky Vaše odbornosti?',
        dontSeeExpertisesModal: {
          title:
            'Požiadajte vlastníka elektronickej pobočky o nahlásenie úväzku',
          description: (email: string) =>
            `E-mail na vlastníka Vašej elektronickej pobočky je <b>${email}</b>.`,
          close: 'Zavrieť',
        },
        tootlips: {
          expertises:
            'Odbornosti zobrazujeme podľa<br />úväzkov u daného poskytovateľa.',
          rfoTitles:
            'Tituly zobrazujeme tak, ako sú<br />evidované v Registri fyzických osôb.',
        },
      },
      stepResult: {
        success: {
          title: 'Ďakujeme Vám za doplnenie údajov',
          titleApproval: 'Prístup bol schválený',
          description: 'Môžete pokračovať do služby DôveraLekárom.',
          descriptionApproval: (name: string) =>
            `<b>${name}</b> teraz môže používať svoj vlastný účet v DôveraLekárom.<br />Na mail sme mu poslali vlastnú GRID kartu na prihlásenie.`,
        },
        warning: {
          title: 'Účet musí schváliť vlastník prístupu',
          titleDisapproval: 'Prístup nebol schválený',
          description: (email: string) =>
            `Na <b>${email}</b> sme poslali žiadosť o schválenie účtu.<br />Pokiaľ Vám nepríde e-mail o schválení, prihlasujte sa tak, ako doteraz.`,
          descriptionDisapproval: (name: string) =>
            `<b>${name}</b> nebude používať vlastný účet v DôveraLekárom. Bude sa prihlasovať ako doteraz.`,
        },
        warningHK: {
          title: 'Prijali sme Vašu žiadosť o vytvorenie nového účtu',
          description:
            'Kým Vám nepríde e-mail s novou GRID kartou, prihlasujte sa tak, ako doteraz.',
        },
        error: {
          title: 'Prepáčte, účet sa nepodarilo vytvoriť',
          titleApproval:
            'Prepáčte, nastala technická chyba pri schvaľovaní prístupu',
          description:
            'Skúste znova načítať stránku.<br />Ak chyba pretrváva, napíšte nám na <a href="mailto:poskytovatel@dovera.sk">poskytovatel@dovera.sk</a>.',
        },
        errorPersonalized: {
          title: 'Prepáčte, účet sa nepodarilo personalizovať',
          titleApproval:
            'Prepáčte, nastala technická chyba pri schvaľovaní prístupu',
          description:
            'Skúste to ešte raz a ak chyba pretrváva,<br />napíšte nám na napíšte nám na <a href="mailto:poskytovatel@dovera.sk">poskytovatel@dovera.sk</a>.',
        },
        buttons: {
          close: 'Zavrieť',
          home: 'Domov',
          tryAgain: 'Skúsiť znova',
        },
      },
      step4: {
        title: 'Vytvorte si prihlasovacie údaje',
        stepTitle: 'Prihlasovacie údaje',
        labels: {
          login: 'Prihlasovacie meno',
          loginPass: 'Prihlasovacie heslo',
          repeatPassword: 'Zopakujte prihlasovacie heslo',
        },
        warning:
          '<b>Heslo si dobre poznačte</b>, budete ho potrebovať na stiahnutie GRID karty.',
        buttons: {
          back: 'Späť',
          submit: 'Vytvoriť účet',
        },
      },
      approvalAccess: {
        labels: {
          status: 'Stav prístupu:',
          type: 'Typ prístupu:',
          doctorCode: 'Kód lekára:',
          email: 'E-mail',
          phone: 'Pevná linka',
          mobile: 'Mobil',
        },
        subtitles: {
          position: 'Pozícia',
          sectionsEP: 'Sekcie v elektronickej pobočke',
          sectionsDLek: 'Sekcie v DôveraLekárom',
        },
        buttons: {
          approve: 'Schváliť prístup',
          disapprove: 'Neschváliť',
          cancel: 'Zrušiť',
        },
        modals: {
          accessApprove: {
            buttons: {
              primary: 'Schváliť prístup',
              secondary: 'Upraviť',
              destructive: 'Neschváliť',
            },
            labels: {
              role: 'Rola:',
              accessType: 'Typ prístupu:',
              email: 'E-mail:',
              phone: 'Pevná linka:',
              mobile: 'Mobil:',
            },
            subtitles: {
              ep: 'Elektronická pobočka',
              dLek: 'Dôvera Lekárom',
            },
          },
          accessDisapprove: {
            label: 'Dôvod neschválenia',
            help: 'Prepošleme ho žiadateľovi o prístup.',
            error: 'Zadajte dôvod neschválenia.',
            title: 'Prístup nebude schválený',
          },
        },
        notifications: {
          info: 'Pre sekciu Bezpečné lieky a sekciu Návrhy na ZS sa Vám na nový účet prenesú rovnaké nastavenia ako ste mali na tomto účte. Upraviť nastavenia si môžete následne v Správe prístupov vo Vašej elektronickej pobočke. K tomu je potrebná sekcia Správa prístupov.',
        },
      },
    },
  },
  fileUploadForm: {
    errors: {
      allowedFormats: (formats: ReactNode) => (
        <>
          <b>Súbor je v nesprávnom formáte.</b> Prosím, nahrajte ho ako{' '}
          {formats}.
        </>
      ),
      maxFileSize: (max: number) =>
        `<b>Súbor má viac ako ${max} MB</b>. Prosím, nahrajte ho v menšej veľkosti alebo ako .zip.`,
      maxUploadSize: (max: number, multiple?: boolean) =>
        multiple
          ? `Prílohy nesmú prekročiť <b>${max} MB</b>. Skúste ich poslať ako .zip.`
          : `Súbor presahuje limit <b>${max} MB</b>.`,
    },
  },
  schrankaSprav: {
    title: 'Správy',
    attachments: {
      error:
        'Počas stiahnutia prílohy nastala technická chyba. Skúste neskôr, prosím.',
    },
    buttons: {
      newMessage: 'Nová správa',
      cancel: 'Zrušiť správu',
      cancelModal: 'Zrušiť',
      submit: 'Odoslať',
    },
    deleteMessage: (count: number) =>
      count === 1 ? 'Správa bola vymazaná' : 'Správy boli vymazané',
    restoreMessage: 'Správa bola úspešne obnovená',
    sendMessage: {
      error: 'Nepodarilo sa odoslať správu. Skúste to, prosím, znovu.',
      success: 'Správa bola úspešne odoslaná',
    },
    modals: {
      confirmRemoveModal: {
        description: (count: number) =>
          `Správ${
            count === 1 ? 'u' : 'y'
          } presunieme do priečinka <b>Vymazané</b>.`,
        title: (count: number) =>
          `Naozaj chcete zmazať ${
            count === 1 ? 'toto vlákno správ' : 'tieto vlákna správ'
          }?`,
        primaryBtn: 'Vymazať',
        secondaryBtn: 'Ponechať',
      },
      newMessageModal: {
        helpers: {
          email: 'Na e-mail pošleme len notifikácie k Vašej správe.',
        },
        labels: {
          email: 'Váš e-mail',
          theme: 'Téma',
          subtheme: 'Podtéma',
          message: 'Správa',
        },
        tooltips: {
          email:
            'Na uvedený e-mail Vám odošleme notifikáciu o spracovaní Vašej správy.',
        },
        themeLoadingError:
          'Nastala technická chyba pri načítavaní tém správy. Skúste, prosím, vytvoriť novú správu neskôr.',
      },
      cancelNewMessage: {
        title: 'Naozaj chcete odísť?',
        description: 'Správa sa po zrušení neuloží.',
        buttons: {
          continue: 'Pokračovať v správe',
          cancel: 'Odísť',
        },
      },
    },
    notifications: {
      archiv: 'Tu nájdete aj archívne správy.',
    },
    thread: {
      detail: {
        noReply:
          'Na tento automatický mail neodpovedajte, prosím. V prípade otázok sme tu pre Vás telefonicky na 0800 150 155 alebo mailom na <a href="mailto:poskytovatel@dovera.sk">poskytovatel@dovera.sk</a>.',
        tooLongThread:
          'Toto vlákno je už príliš dlhé. Ak máte otázky, napíšte novú správu.',
        buttons: {
          newMessage: 'Napísať novú správu',
          reply: 'Odpovedať',
          refresh: 'Obnoviť',
        },
      },
    },
    emptyStates: {
      loadError: {
        title: (single: boolean) =>
          `Nepodarilo sa načítať správ${single ? 'u' : 'y'}`,
        description: (single: boolean) =>
          `Prepáčte, nastala technická chyba pri otváraní správ${
            single ? 'y' : ''
          }.`,
        buttons: {
          home: 'Domov',
          loadAgain: 'Načítať znovu',
        },
      },
      noMessages: {
        title: 'Nemáte žiadne správy.',
        buttons: {
          home: 'Domov',
          loadAgain: 'Nová správa',
        },
      },
    },
  },
};
