import { useQuery } from '@tanstack/react-query';
import {
  GetMenuItemsQueries,
  GetReportDataQueries,
  GetReportStructureQueries,
  GetReportWidgetsQueries,
} from '../../types/reports.types';
import {
  getMenuItems,
  getReportData,
  getReportStructure,
  getReportWidgets,
} from '../reports';

export const useFetchMenuItems = (
  queries: GetMenuItemsQueries,
  options = {},
) => {
  const { expCode, healthCareCode, themes } = queries;
  return useQuery({
    queryKey: ['fetchMenuItems', queries],
    queryFn: () => getMenuItems(queries),
    enabled: !!expCode && !!healthCareCode && !!themes.length,
    ...options,
  });
};

export const useFetchReportStructure = (
  queries: GetReportStructureQueries,
  options = {},
) => {
  const { reportId } = queries;
  return useQuery({
    queryKey: ['fetchReportStructure', queries],
    queryFn: () => getReportStructure(queries),
    enabled: !!reportId,
    ...options,
  });
};

export const useFetchReportData = (
  queries: GetReportDataQueries,
  options = {},
) => {
  const { reportId, tabId } = queries;
  return useQuery({
    queryKey: ['fetchReportData', queries],
    queryFn: () => getReportData(queries),
    enabled: !!reportId && !!tabId,
    ...options,
  });
};

export const useFetchReportWidgets = (
  queries: GetReportWidgetsQueries,
  options = {},
) => {
  const { expertiseCode, healthCareCode } = queries;
  return useQuery({
    queryKey: ['fetchReportWidgets', queries],
    queryFn: () => getReportWidgets(queries),
    enabled: !!expertiseCode && !!healthCareCode,
    ...options,
  });
};
