import { ReactNode, useEffect, useMemo, useState } from 'react';
import { TabsFilter } from '../../../components/Filter';
import { Icon, TabPanel } from '@dovera/design-system';
import { Tab } from '../../../types/reports.types';

interface Props {
  activeTabId: number;
  onTabChange: (tabId: number) => void;
  tabs: Tab[];
}

const Tabs = ({ activeTabId, onTabChange, tabs }: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setIsVisible(true), 0);
    return () => clearTimeout(timer);
  }, []);

  const activeTabIndex = useMemo(() => {
    if (!activeTabId) return 0;
    const index = tabs.findIndex((tab) => tab.id === activeTabId);
    return index > 2 ? 2 : index;
  }, [activeTabId, tabs]);

  const getTabTitle = (isLock: boolean, name: string): ReactNode => (
    <>
      <Icon name={`${!isLock ? 'un' : ''}locked`} size="medium" />
      <span>{name}</span>
    </>
  );

  if (!tabs.length || !isVisible) return null;

  return (
    <TabsFilter
      activeTabIndex={activeTabIndex}
      id="vb-periodTabs"
      onChange={(v) => onTabChange(Number(v?.panel?.id?.split('period')?.[1]))}
    >
      {tabs.map((t) => (
        <TabPanel
          key={`tab-panel--${t.id}`}
          id={`period${t.id}`}
          isActive={activeTabId === t.id}
          tab={getTabTitle(!!t.filters?.length, t.name)}
        />
      ))}
    </TabsFilter>
  );
};

export default Tabs;
