import { PropsWithChildren } from 'react';
import useStyles from './ColoredLabel.styles';
import { cx } from '../../utils/exports';

interface Props {
  className?: string;
  color: string;
}

const ColoredLabel = ({
  children,
  className,
  color,
}: PropsWithChildren<Props>) => {
  const classes = useStyles({
    color,
  });

  return <span className={cx(classes.color, className)}>{children}</span>;
};

export default ColoredLabel;
