import Thread from '../Thread/Thread';
import InfiniteScroll from 'react-infinite-scroller';
import useStyles from '../Spravy.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { useCallback } from 'react';
import { KategoriaSprav } from '../../../types/spravy.types';
import { LoadingState } from '../../../types';
import { Loader, Skeleton } from '@dovera/design-system';
import { hideTooltips, scrollToTop } from '../../../utils/app.utils';
import { cx } from '../../../utils/exports';
import { callThreadDetail } from '../../../utils/spravy.utils';
import { getPlainText } from '../../../utils/strings.utils';

interface Props {
  isRemoveBarVisible: boolean;
  loadMore: () => void;
}

const MessageThreads = ({ isRemoveBarVisible, loadMore }: Props) => {
  const {
    detail,
    kategoria,
    vlakna: { data, dataState, offset, pocty, zobrazNtf },
  } = useSelector((state: RootState) => state.spravy);
  const classes = useStyles({
    showThreadsNtf: zobrazNtf && kategoria === KategoriaSprav.Vymazane,
  });
  const hasMoreThreads: boolean =
    pocty[kategoria] > data.length && dataState !== LoadingState.fetching;
  const getThreadDetail = useCallback((threadId: number) => {
    scrollToTop();
    callThreadDetail({ id: threadId });
  }, []);
  const renderSkeleton = (
    <>
      <div className="mb-small">
        <Skeleton width="40%" />
        <Skeleton width="100%" />
      </div>
      <div className="mb-small">
        <Skeleton width="40%" />
        <Skeleton width="100%" />
      </div>
      <div className="mb-small">
        <Skeleton width="40%" />
        <Skeleton width="100%" />
      </div>
    </>
  );
  const renderThreads = (
    <>
      {data.map((v) => (
        <Thread
          key={`message-thread--${v.id}`}
          badge={v.typBadge}
          date={v.datumPoslednejSpravy}
          description={getPlainText(v.obsahPoslednejSpravy)}
          id={v.id}
          isActive={detail.id === v.id}
          isDeleted={
            !!(
              kategoria === KategoriaSprav.Vymazane ||
              kategoria === KategoriaSprav.Archiv
            )
          }
          // eslint-disable-next-line
          isChecked={!!v.oznacenePreVymazanie}
          onClick={() => getThreadDetail(v.id)}
          sentByPZS={v.jeAutorPzs}
          showRemoveCheckox={data.some((v) => v.oznacenePreVymazanie)}
          title={v.nadpis}
          unread={v.neprecitane}
        />
      ))}
      {dataState === LoadingState.fetching && !!offset && (
        <div className="text-center">
          <Loader size="large" />
        </div>
      )}
    </>
  );
  return (
    <div
      className={cx(
        classes.threads,
        isRemoveBarVisible && classes.threadsRemoveBar,
      )}
      id="pzs-message-threads-list"
      onScroll={hideTooltips}
    >
      <InfiniteScroll
        getScrollParent={() =>
          document.querySelector('#pzs-message-threads-list')
        }
        hasMore={hasMoreThreads}
        initialLoad={false}
        loadMore={loadMore}
        useWindow={false}
      >
        {[LoadingState.fetching, LoadingState.none].includes(dataState) &&
        !offset
          ? renderSkeleton
          : renderThreads}
      </InfiniteScroll>
    </div>
  );
};

export default MessageThreads;
