import { Button, ButtonLayout } from '@dovera/design-system';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import strings from '../../../../../constants/strings';

const texts = strings.proposals.spa.new;

interface ButtonsProps {
  errorInsuree: string;
  handleSubmit: () => void;
  insureeLoading: boolean | undefined;
  isInactiveInsuree: boolean;
  onCancel: () => void;
  values: {
    insureeId: string;
  };
}

const Buttons = ({
  errorInsuree,
  handleSubmit,
  insureeLoading,
  isInactiveInsuree,
  onCancel,
  values,
}: ButtonsProps) => {
  const { insureeId } = useSelector(
    (state: RootState) => state.drugProposals.new.stepper.step2,
  );

  return (
    <ButtonLayout className="no-mrg" direction="horizontal">
      {isInactiveInsuree ? (
        <Button className="no-mrg" onClick={() => onCancel()}>
          {texts.buttons.cancel}
        </Button>
      ) : (
        <Button
          className="no-mrg"
          isDisabled={
            insureeLoading ||
            (values.insureeId.length >= 9 && !insureeId && !errorInsuree)
          }
          onClick={() => handleSubmit()}
        >
          {texts.buttons.continue}
        </Button>
      )}
    </ButtonLayout>
  );
};

export default Buttons;
