import { createUseStyles } from 'react-jss';

export default createUseStyles({
  label: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  helper: {
    display: 'flex',
    justifyContent: 'space-between',
    '& .align-with-label': {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
});
