import { ButtonLink } from '@dovera/design-system';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import SortableTable from '../../../../components/SortableTable/SortableTable';
import strings from '../../../../constants/strings';
import { useAppDispatch } from '../../../../hooks/useStore';
import routes from '../../../../routes';
import { setSelectedInvoice } from '../../../../slices/zuctovanie.slice';
import { Faktura, InvoicesState } from '../../../../types/zuctovanie.types';
import { formatDate } from '../../../../utils/date.utils';
import { getPriceFormat } from '../../../../utils/number.utils';
import { getInvoiceState } from '../../../../utils/zuctovanie.utils';

const { buttons, columns } = strings.zuctovanie.invoices.table;

interface Props {
  data: InvoicesState;
}

const Table = ({ data }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const detailClick = useCallback(
    (invoice: Faktura) => {
      dispatch(setSelectedInvoice({ invoice }));
      navigate(`${routes.faktury}/${invoice.cisloFaktury}`);
    },
    [dispatch, navigate],
  );

  return (
    <SortableTable
      cols={[
        {
          index: 1,
          name: columns.vs,
          isSortable: true,
          sortType: 'alphabetical',
        },
        {
          index: 2,
          name: columns.internalNumber,
          isSortable: true,
          sortType: 'alphabetical',
        },
        {
          index: 3,
          name: columns.period,
        },
        {
          index: 4,
          name: columns.state,
          isSortable: true,
          sortType: 'alphabetical',
        },
        {
          index: 5,
          name: columns.sum,
          isSortable: true,
          sortType: 'sum',
        },
        {
          index: 6,
          name: columns.delivery,
          isSortable: true,
          sortType: 'date',
        },
        {
          index: 7,
          name: '',
        },
      ]}
      noRerender
      rows={data.filteredData.map((f) => ({
        col1: {
          sortableValue: f.vs,
          value: f.vs || strings.undefined,
        },
        col2: {
          sortableValue: f.cisloFaktury,
          value: f.cisloFaktury,
        },
        col3: { value: f.obdobieFaktury.toString() },
        col4: {
          sortableValue: f.stavFaktury,
          value: getInvoiceState(f.stavFaktury, true),
        },
        col5: {
          sortableValue: f.fakturovanaSuma.toString(),
          value: getPriceFormat(f.fakturovanaSuma, true),
        },
        col6: {
          sortableValue: f.datumDorucenia,
          value: formatDate(f.datumDorucenia),
        },
        col7: {
          value: (
            <ButtonLink
              className="inline-btn d-flex"
              onClick={() => detailClick(f)}
            >
              {buttons.detail}
            </ButtonLink>
          ),
        },
      }))}
      storeInRedux
    />
  );
};

export default Table;
