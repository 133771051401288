import {
  ApprovedProposals,
  CheckboxType,
  ProposalDetailQueries,
  ProposalIndicatorGroup,
  Step3,
  Stepper,
  StoreStep3Payload,
} from '../types/spaProposals.types';
import {
  CheckType,
  EventType,
  LocalControls,
  PROPOSAL_TYPES,
  ProposalCheck,
  ProposalDiagnose,
} from '../types/proposals.types';
import { ReactNode } from 'react';
import { Icon, Notification, color } from '@dovera/design-system';
import strings from '../constants/strings';
import SafeHtml from '../components/SafeHtml/SafeHtml';
import store from '../store';
import { reset } from '../slices/spaProposals.slice';
import { CustomTooltip } from '../components/CustomTooltip';
import { cx } from './exports';
import { formatNameStr } from './strings.utils';
import { Link } from 'react-router-dom';
import { navrhyRoutes } from '../routes';
import { createViewUrl } from './app.utils';
import { formatDate } from './date.utils';
import { LOCAL_CONTROLS_ERROR_STATES } from '../constants/proposals';

export const getRadioResult = (
  result: CheckboxType | null,
  contraindications?: boolean,
): 'Áno' | 'Nie' | 'Má' | 'Nemá' | '' => {
  if (!result) return '';
  if (contraindications) {
    return result === 'Y' ? 'Má' : 'Nemá';
  }
  return result === 'Y' ? 'Áno' : 'Nie';
};

export const getEventTypeLabel = (
  eventType: EventType | null,
  isSummary?: boolean,
): string | null => {
  if (eventType === 'Chronik' || eventType === 'SustavnaLiecba') return null;
  switch (eventType) {
    case 'Diagnostika':
      return isSummary
        ? 'Diagnostikované ochorenie'
        : 'Kedy bolo diagnostikované ochorenie?';
    case 'Hospitalizacia':
      return isSummary
        ? 'Pacient bol hospitalizovaný'
        : 'Kedy bola ukončená hospitalizácia?';
    case 'Operacia':
      return isSummary
        ? 'Pacient bol operovaný'
        : 'Kedy bol pacient operovaný?';
    case 'UkoncenieLiecby':
      return isSummary
        ? 'Dátum ukončenia liečby'
        : 'Kedy bola ukončená liečba?';
    default:
      return null;
  }
};

const getStateProperties = (
  state: 'N' | 'S' | string,
): {
  color: string;
  name: 'reject' | 'check' | '16-edit' | 'hourglass';
  text: string;
} => {
  switch (state) {
    case 'N':
      return {
        color: color('error', 600),
        name: 'reject',
        text: 'Neschválený',
      };
    case 'S':
      return {
        color: color('success', 600),
        name: 'check',
        text: 'Schválený',
      };
    case 'D':
      return {
        color: color('black'),
        name: '16-edit',
        text: 'Na doplnenie',
      };
    default:
      return {
        color: color('black'),
        name: 'hourglass',
        text: 'Spracováva sa',
      };
  }
};

export const getProposalState = (
  state: 'N' | 'S' | string,
  classes?: any,
  fromDetail?: boolean,
): ReactNode => {
  const properties = getStateProperties(state);

  return (
    <div
      className={
        fromDetail ? classes?.proposalHeaderRow : classes?.proposalState
      }
    >
      <Icon color={properties.color} name={properties.name} size="medium" />
      <span
        className={cx(fromDetail && 'text-bold')}
        style={{ color: properties.color }}
      >
        {properties.text}
      </span>
    </div>
  );
};

export const spaTxsCheckTypes: CheckType[] = [
  'CerpanieKNsJednoraz',
  'CerpanieKNsPeriod',
  'ExistSchvaleneRozhodnutie',
  'NedostPocetNavstChronik',
  'NedostPocetNavstSusLiecbu',
  'NenajdenaHospitalizacia',
  'NenajdenaOperacia',
  'EsteJePriskoro',
  'UzJeNeskoro',
  'IsVekPoi',
  'Dlznik',
  'DlznikVociCSParNeodklZS',
];

export const spaBaseCheckTypes: CheckType[] = ['Dlznik', 'PoistnyVztah'];

export const isLoadedTXSProposalControls = (controls: CheckType[]): boolean =>
  controls.some((a) => spaTxsCheckTypes.includes(a));

export const isDebtor = (data: ProposalCheck[]): boolean =>
  data.some((d) => d.checkType === 'Dlznik' && d.result === 'NOK') &&
  data.some(
    (d) => d.checkType === 'DlznikVociCSParNeodklZS' && d.result === 'NOK',
  );

export const getFilteredControls = (data: ProposalCheck[]): CheckType[] => {
  const cannotCheck: CheckType[] = ['DlznikVociCSParNeodklZS'];
  return spaTxsCheckTypes.filter((c) => {
    if (c === 'Dlznik' && !isDebtor(data)) return false;
    return data.some(
      (d) =>
        d.checkType === c && !cannotCheck.includes(c) && d.result === 'NOK',
    );
  });
};

export const getTxsControlNtfs = (
  data: ProposalCheck[],
  period: number,
): ReactNode | null => {
  const filteredControls = getFilteredControls(data);
  if (!filteredControls.length) return null;
  return (
    <>
      {filteredControls.map((c, key) => {
        let msg: string = '';
        switch (true) {
          case c === 'CerpanieKNsJednoraz':
          case c === 'CerpanieKNsPeriod':
            msg =
              strings.proposals.spa.new.notifications.errors.txsControls.CerpanieKNsPeriod(
                period,
              );
            break;
          case c === 'Dlznik' && isDebtor(data):
          case c !== 'Dlznik' && c !== 'DlznikVociCSParNeodklZS':
            msg =
              strings.proposals.spa.new.notifications.errors.txsControls[
                `${c}`
              ];
            break;
          default:
            msg = '';
            break;
        }
        if (!msg) return <span />;
        return (
          <div key={`txs-control--${key}`} className="mb-small">
            <Notification message={<SafeHtml html={msg} />} variant="error" />
          </div>
        );
      })}
    </>
  );
};

export const resetProposals = () => {
  store.dispatch(reset());
};

export const hasLocalProposalControlErrors = (data: {
  spaConfirmation: CheckboxType | null;
  spaContraindications: CheckboxType | null;
  spaExaminations: CheckboxType | null;
  spaSVLZ: CheckboxType | null;
}): boolean =>
  data.spaConfirmation === 'N' ||
  data.spaContraindications === 'Y' ||
  data.spaExaminations === 'N' ||
  data.spaSVLZ === 'N';

export const hasNOKControls = (
  controlsToCheck: CheckType[],
  controls: ProposalCheck[],
) =>
  controls.some(
    (control) =>
      control.result === 'NOK' &&
      controlsToCheck.some((c) => c === control.checkType),
  );

export const hasLocalNOKMessages = (
  localControls: LocalControls[],
  step3: Step3,
) => localControls.some((lc) => LOCAL_CONTROLS_ERROR_STATES[lc] === step3[lc]);

export const getSortableCols = [
  {
    index: 1,
    name: 'Návrh',
    isSortable: true,
  },
  {
    index: 2,
    name: 'Pacient',
    isSortable: true,
  },
  {
    index: 3,
    name: 'Kód/meno lekára',
    isSortable: true,
  },
  {
    index: 4,
    name: 'Platnosť návrhu',
    isSortable: true,
  },
  {
    index: 5,
    name: 'Rezervácia kúpeľov',
  },
  {
    index: 6,
    name: '',
  },
];

// Get table col value for Approved and Submitted proposals
export const getColValues = (
  colIndex: number,
  obj: ApprovedProposals,
): { sortableValue?: string; value: ReactNode } => {
  const queries: ProposalDetailQueries = {
    cisloNavrhu: obj.cisloNavrhu,
    id: obj.id,
    typ: PROPOSAL_TYPES.SPA,
  };
  // Navrh
  if (colIndex === 1)
    return {
      sortableValue: obj.cisloNavrhu,
      value: (
        <>
          {obj.poistenec.stav === 'A' ? (
            <Link
              className="text-normal no-mrg no-pad"
              to={createViewUrl(navrhyRoutes.zoznamSchvalenychNavrhov, queries)}
            >
              {obj.cisloNavrhu}
            </Link>
          ) : (
            obj.cisloNavrhu
          )}
          <br />
          <span>
            Typ: <b>{obj.typ || '-'}</b>
          </span>
        </>
      ),
    };
  if (colIndex === 2)
    return {
      sortableValue: obj.poistenec.meno,
      value: (
        <span data-hj-masked>
          {obj.poistenec.rodneCislo}
          <b
            className={cx(
              'd-block',
              obj.poistenec.stav !== 'A' && 'text-color-error-base',
            )}
          >
            <span className="d-flex text-justify">
              {`${formatNameStr(obj.poistenec.meno)}`}
              {obj.poistenec.stav !== 'A' && (
                <span className="text-space-half-left">
                  <CustomTooltip
                    dialog=""
                    id={`tooltip-help--${obj.cisloNavrhu}`}
                  >
                    <Icon color={color('error')} name="16-help" />
                  </CustomTooltip>
                </span>
              )}
            </span>
          </b>
        </span>
      ),
    };
  if (colIndex === 3)
    return {
      sortableValue: obj.lekar.meno,
      value: (
        <span data-hj-masked>
          {obj.lekar.kod}
          <b className="d-block">{formatNameStr(obj.lekar.meno)}</b>
        </span>
      ),
    };
  if (colIndex === 4)
    return {
      sortableValue: obj.platnostNavrhuOd,
      value: (
        <span>
          {formatDate(obj.platnostNavrhuOd)}
          <br />
          {formatDate(obj.platnostNavrhuDo)}
        </span>
      ),
    };
  return {
    value: '',
  };
};

export const getStep3Values = (
  payload: StoreStep3Payload,
  initialStepper: Stepper,
  stepper: Stepper,
) => {
  const {
    diagnose,
    indicatorGroup,
    showCancelBtnAfter,
    spaConfirmation,
    spaContraindications,
    spaExaminations,
    spaSVLZ,
  } = payload;

  const actualState = getActualState(
    diagnose,
    indicatorGroup,
    initialStepper,
    stepper,
  );
  const cancelBtnValue = getCancelBtnVal(
    showCancelBtnAfter,
    spaConfirmation,
    spaContraindications,
    spaExaminations,
    spaSVLZ,
  );
  const resetAfterFilled = getResetAfterFilled(diagnose, indicatorGroup);

  const {
    currentDiagnose,
    currentIndicatorGroup,
    currentSpaConfirmation,
    currentSpaContraindications,
    currentSpaExaminations,
    currentSpaSVLZ,
  } = getCurrentValues(payload, stepper, actualState);

  return {
    actualState,
    cancelBtnValue,
    currentDiagnose,
    currentIndicatorGroup,
    currentSpaConfirmation,
    currentSpaContraindications,
    currentSpaExaminations,
    currentSpaSVLZ,
    resetAfterFilled,
  };
};

const getCurrentValues = (
  payload: StoreStep3Payload,
  stepper: Stepper,
  actualState: Step3,
) => {
  const {
    diagnose,
    indicatorGroup,
    spaConfirmation,
    spaContraindications,
    spaExaminations,
    spaSVLZ,
  } = payload;

  const currentDiagnose =
    diagnose === null || diagnose ? diagnose : stepper.step3.diagnose;

  const currentIndicatorGroup =
    indicatorGroup === null || indicatorGroup
      ? indicatorGroup
      : stepper.step3.indicatorGroup;

  const currentSpaConfirmation =
    spaConfirmation !== undefined
      ? spaConfirmation
      : actualState.spaConfirmation;

  const currentSpaContraindications =
    spaContraindications !== undefined
      ? spaContraindications
      : actualState.spaContraindications;

  const currentSpaExaminations =
    spaExaminations !== undefined
      ? spaExaminations
      : actualState.spaExaminations;

  const currentSpaSVLZ = spaSVLZ !== undefined ? spaSVLZ : actualState.spaSVLZ;

  return {
    currentDiagnose,
    currentIndicatorGroup,
    currentSpaConfirmation,
    currentSpaContraindications,
    currentSpaExaminations,
    currentSpaSVLZ,
  };
};

const getResetAfterFilled = (
  diagnose: ProposalDiagnose | null | undefined,
  indicatorGroup: ProposalIndicatorGroup | null | undefined,
): 'IS' | 'DG' | undefined => {
  if (diagnose) {
    return 'DG';
  }
  if (indicatorGroup) {
    return 'IS';
  }
  return undefined;
};

const getActualState = (
  diagnose: ProposalDiagnose | null | undefined,
  indicatorGroup: ProposalIndicatorGroup | null | undefined,
  initialStepper: Stepper,
  stepper: Stepper,
): Step3 =>
  diagnose === null || indicatorGroup === null
    ? initialStepper.step3
    : stepper.step3;

function getCancelBtnVal(
  showCancelBtnAfter: string | null | undefined,
  spaConfirmation: string | undefined,
  spaContraindications: string | undefined,
  spaExaminations: string | undefined,
  spaSVLZ: string | undefined,
): string | null {
  if (showCancelBtnAfter !== undefined) {
    return showCancelBtnAfter;
  }
  if (spaConfirmation === 'N') {
    return 'spaConfirmation';
  }
  if (spaContraindications === 'Y') {
    return 'spaContraindications';
  }
  if (spaExaminations === 'N') {
    return 'spaExaminations';
  }
  if (spaSVLZ === 'N') {
    return 'spaSVLZ';
  }
  return null;
}
