import { ButtonLink, Icon } from '@dovera/design-system';
import { useState } from 'react';
import { FieldRenderProps } from 'react-final-form-hooks';
import strings from '../../../../constants/strings';
import useStyles from '../../Proposals.styles';
import {
  PROPOSAL_STATES,
  PROPOSAL_STATE_VALUES,
  PROPOSAL_TYPES,
} from '../../../../types/proposals.types';
import { getDefaultSelectOption } from '../../../../utils/dds.utils';
import { DATE_INTERNATIONAL, formatDate } from '../../../../utils/date.utils';
import {
  DatepickerFilter,
  InputFilter,
  SelectFilter,
} from '../../../../components/Filter';

const labels = strings.proposals.drugs.list.filter;
const labelsSPA = strings.proposals.spa.list.submittedProposals.filter;

interface Props {
  search: FieldRenderProps<any, string>;
  state: FieldRenderProps<any, string>;
  type: PROPOSAL_TYPES | string;
  validityFrom: FieldRenderProps<any, string>;
  validityTo: FieldRenderProps<any, string>;
}

const Filter = ({ search, state, type, validityFrom, validityTo }: Props) => {
  const classes = useStyles({ proposalType: PROPOSAL_TYPES.Drugs });
  const [isFilterCollapsed, setIsFilterCollapsed] = useState(false);
  return (
    <form className={classes.listFilter}>
      <InputFilter
        addonsInside
        crossOrigin={undefined}
        id="filter--search"
        label={type === PROPOSAL_TYPES.SPA ? labelsSPA.search : labels.search}
        rightAddons={<Icon name="search" size="medium" />}
        {...search.input}
      />
      <SelectFilter
        id="filter--state"
        label={labels.state}
        options={[
          ...[getDefaultSelectOption('Všetky')],
          ...Object.keys(PROPOSAL_STATES).map((s) => ({
            label: PROPOSAL_STATES[s],
            value: PROPOSAL_STATE_VALUES[s],
          })),
        ]}
        {...state.input}
      />
      {isFilterCollapsed && (
        <>
          <DatepickerFilter
            className={classes.filterDatepicker}
            id="filter--validity-from"
            label={labels.validityFrom}
            {...validityFrom.input}
            onChange={(value) =>
              validityFrom.input.onChange(formatDate(value, DATE_INTERNATIONAL))
            }
          />
          <DatepickerFilter
            className={classes.filterDatepicker}
            id="filter--validity-to"
            label={labels.validityTo}
            {...validityTo.input}
            onChange={(value) =>
              validityTo.input.onChange(formatDate(value, DATE_INTERNATIONAL))
            }
          />
        </>
      )}
      <ButtonLink
        className="no-mrg no-pad"
        onClick={() => setIsFilterCollapsed(!isFilterCollapsed)}
      >
        <Icon
          className="text-space-right"
          name={isFilterCollapsed ? '16-minus' : '16-plus'}
          size="medium"
        />
        {isFilterCollapsed ? labels.lessFilters : labels.moreFilters}
      </ButtonLink>
    </form>
  );
};

export default Filter;
