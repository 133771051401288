import { ButtonLink } from '@dovera/design-system';
import { DajProfilResponse } from '../../../../../types/api/profil';
import { cx } from '../../../../../utils/exports';
import { useState } from 'react';
import strings from '../../../../../constants/strings';
import OdbornostiModal from '../../OdbornostiModal/OdbornostiModal';

const texts = strings.profile.labels;

interface Props {
  data: DajProfilResponse | null;
}

const Expertises = ({ data }: Props) => {
  const [isVisibleExpertisesModal, setIsVisibleExpertisesModal] =
    useState(false);
  if (data && data?.odbornosti?.filter((o) => o.kodOdbNz)?.length > 1)
    return (
      <>
        <ButtonLink
          className={cx('inline-btn')}
          onClick={() => setIsVisibleExpertisesModal(true)}
        >
          {texts.countExpertises(
            data?.odbornosti?.filter((o) => o.kodOdbNz)?.length,
          )}
        </ButtonLink>
        <OdbornostiModal
          expertises={data?.odbornosti}
          isVisible={isVisibleExpertisesModal}
          onHide={() => setIsVisibleExpertisesModal(false)}
        />
      </>
    );
  return `${data?.odbornosti?.filter((o) => o.kodOdbNz)[0]?.kodOdbNz || ''} - ${
    data?.odbornosti?.filter((o) => o.kodOdbNz)[0]?.nazovOdbNz || ''
  }`.trim();
};

export default Expertises;
