import { inlineStrCondition } from '../../utils/app.utils';

export default {
  header: {
    title: 'Zmluvy a dodatky',
    howToSignBtn: 'Ako podpísať dokument elektronicky',
  },
  hromadnyPodpis: {
    description:
      'Aby ste mohli podpísať zmluvné dokumenty, musíte si ich najskôr stiahnuť a oboznámiť sa s ich obsahom. Po stiahnutí budete môcť dokumenty podpísať.',
    stepper: {
      step1: {
        title: 'Stiahnuť dokumenty',
        description: (count: number) =>
          `<b>${count}</b> dokument${
            count !== 1
              ? inlineStrCondition(count > 1 && count < 5, 'y', 'ov')
              : ''
          } v .zip súbore je pripravených sa stiahnutie.`,
        button: 'Stiahnuť',
      },
      step2: {
        title: 'Podpísať dokumenty',
        descriptionAsoc: (name?: string, func?: string) =>
          `Zmluvné dokumenty podpisujete ako <b data-hj-masked="true">${name}</b>, <span data-hj-masked="true">${func}</span>.`,
        button: 'Podpísať',
        error: 'Podmienkou pre podpísanie je vyjadrenie súhlasu s podmienkami.',
      },
    },
  },
  individualnyPodpis: {
    description:
      'Dôvera a Zdravita sa dohodli na predĺžení zmluvných podmienok do 30.6.2021. Dodatkom sa navyšuje základná kapitácia pre prvých ...',
    detailPdf: 'Zobraziť detail (PDF)',
    detailPdfPzs: 'Pozrieť detail zmeny',
    labels: {
      state: 'Stav',
      dateAdded: 'Pridané',
      name: 'Názov poskytovateľa',
      ico: 'IČO',
      date: 'Dátum účinnosti',
      signTo: 'Podpísať do',
    },
    subtitle: (
      cisloDodatku: string | null,
      cisloZmluvy: string | null,
      typDokumentu: string | null,
    ) =>
      typDokumentu !== 'Z'
        ? `Dodatok č. ${cisloDodatku || ''} k zmluve č. ${cisloZmluvy || ''}`
        : `Zmluva č. ${cisloZmluvy || ''}`,
    downloadBtn: 'Stiahnuť',
    signingAs: (name: string, role: string) =>
      `Zmluvný dokument podpisujete ako <b data-hj-masked="true">${name}</b>, <span data-hj-masked="true">${role}</span>.`,
    warning:
      'Pre podpísanie si stiahnite dokument alebo si ho pozrite celý v náhľade.',
    errorInactiveCheckbox:
      'Pred podpísaním si pozrite celý dokument - v náhľade alebo kliknutím na [Zobraziť]',
    error: 'Podmienkou pre podpísanie je vyjadrenie súhlasu s podmienkami.',
    signBtn: 'Podpísať',
    remarkBtn: 'Pripomienkovať',
    remarkTooltip:
      'Možnosť pripomienkovať končí 2 pracovné dni pred dátumom podpisu.',
    chybaKonatel:
      'Nemáte oprávnenie na podpis zmluvnej dokumentácie. Dokumentáciu môže <b>podpisovať iba štatutár/konateľ</b>, ktorému je potrebné <b>vytvoriť prístup</b> v Elektronickej pobočke.',
    chybaStatutar:
      'Nemáte oprávnenie na podpis zmluvnej dokumentácie. Dokumentáciu môže <b>podpisovať iba štatutár/konateľ</b>.',
  },
  spolocne: {
    approval: (dodatok: boolean) =>
      `Zakliknutím tohto poľa potvrdzujete, že ste sa oboznámili s obsahom návrhu ${
        dodatok ? 'dodatku' : 'zmluvy'
      } a porozumeli mu. Kliknutím na “Podpísať” súhlasíte s obsahom návrhu ${
        dodatok ? 'dodatku' : 'zmluvy'
      }, návrh prijímate a vyjadrujete vôľu poskytovateľa zdravotnej starostlivosti byť ustanoveniami ${
        dodatok ? 'dodatku' : 'zmluvy'
      } viazaný.`,
  },
  vylsedokPodpisu: {
    multiple: {
      success: {
        title: 'Dokumenty boli úspešne podpísané',
        description:
          'Na {tomto odkaze} si môžete stiahnuť zoznam podpísaných dokumentov.',
      },
      loading: 'Prebieha podpisovanie zmlúv.<br/ >Počkajte, prosím...',
    },
    single: {
      success: {
        title: 'Dokument bol úspešne podpísaný',
        description: '',
      },
      loading: 'Prebieha podpisovanie zmluvy.<br/ >Počkajte, prosím...',
    },
    error: {
      title: 'Nepodarilo sa podpísať',
      description:
        'Prepáčte, nastala technická chyba na našej strane.<br />Skúste, prosím, podpísať neskôr.',
    },
    errorAlreadySigned: {
      title: 'Nepodarilo sa podpísať',
      description: 'Dokument už bol podpísaný inou osobou.',
    },
    button: 'Ísť na prehľad zmlúv a dodatkov',
  },
  zoznam: {
    tooltipy: {
      vyhladavanie: 'Zadajte IČO alebo názov poskytovateľa',
    },
    ciastocnePodpisana: (dodatok: number | null) =>
      `${dodatok ? 'Dodatok' : 'Zmluva'} je čiastočne ${
        dodatok ? 'podpísaný' : 'podpísaná'
      }.`,
  },
  detail: {
    zobrazitLink: 'Zobraziť zmluvu',
    zobrazitDetail: 'Zobraziť detail (PDF)',
    stiahnutBtn: 'Stiahnuť',
    stiahnutBtnPZS: 'Stiahnuť zmluvu',
    stiahnutPdf: 'Stiahnuť PDF',
    nahladZmluvy: 'Náhľad zmluvy',
    nahladDodatku: 'Náhľad dodatku',
    labels: {
      dodatokPodpisal: 'Dodatok podpísal/a',
      zmluvuPodpisal: 'Podpísal/a',
      podpisane: 'Podpísané',
      datumPodpisu: 'Dátum podpisu',
      datumUcinnosti: 'Dátum účinnosti',
      ucinnostDo: 'Účinné do',
      stav: 'Stav',
      pridane: 'Pridané',
      podpisatDo: 'Podpísať do',
      stornovane: 'Stornované',
    },
    uplynulCas: 'Uplnynul čas na podpis zmluvného dokumentu.',
    stornovane: (dovod: string) => `<b>Stornované</b> z dôvodu: ${dovod}`,
    nazovDokumentu: (
      cisloDodatku: string,
      cisloZmluvy: string,
      idDodatok: number | null,
      typZmluvy: string,
    ) =>
      typZmluvy === 'Z' || (!cisloDodatku && !idDodatok)
        ? `Zmluva č. ${cisloZmluvy}`
        : `Dodatok č. ${cisloDodatku} k zmluve č. ${cisloZmluvy}`,
  },
  emptyStates: {
    zmluvy: 'Nenašli sme žiadne dokumenty v tomto stave',
    zmluvyNaPodpis: 'Aktuálne nemáte žiadne zmluvy alebo dodatky na podpis.',
    dodatky: 'Neevidujeme dodatky k tejto zmluve',
    vyhladavanie: 'Pre hľadaný výraz sme nenašli žiadne výsledky',
    default: 'Nenašli sme žiadne dokumenty',
    ziadneDodadtky: 'Nenašli sme požadovaný dodatok',
    defaultBtn: 'Vrátiť sa na zoznam',
    backToDetailBtn: 'Vrátiť sa na detail zmluvy',
  },
  signed: {
    title: 'Podpísané zmluvy',
    columns: [
      'Číslo zmluvy',
      'Účinná od',
      'Účinná do',
      'Podpísaná',
      'Podpísal/-a',
      'Akcia',
    ],
    detail: 'Detail',
  },
  forSinging: {
    title: 'Na podpísanie',
  },
  modal: {
    discard: {
      title: 'Nezobrazovať návrh zmluvy/dodatku?',
      description:
        'Tento návrh je neplatný, môžete si ho schovať.<br />V prípade potreby Vám vytvoríme nový návrh na podpis.',
      doNotDisplayBtn: 'Už nezobrazovať',
      keepBtn: 'Ponechať',
    },
  },
  card: {
    signTo: 'Podpísať do:',
    added: 'Pridané',
  },
  mostRecentlySigned: {
    title: 'Najnovšie podpísané zmluvné dokumenty',
    columns: ['Názov dokumentu', 'Účinný od', ''],
    detail: 'Detail',
  },
  asoc: {
    header: {
      title: 'Zmluvy a dodatky',
    },
  },
};
