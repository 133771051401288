import { color, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  orangeEffect: {
    top: '-29%',
    right: getRem(110),
    width: '36%',
    bottom: 0,
    filter: `blur(${getRem(80)})`,
    height: '50%',
    zIndex: 0,
    position: 'absolute',
    background: color('secondary', 500),
    borderRadius: getRem(416),
  },
  mintEffect: {
    top: '21%',
    right: getRem(-5),
    width: '18%',
    bottom: 0,
    filter: `blur(${getRem(80)})`,
    height: '25%',
    zIndex: 0,
    position: 'absolute',
    background: color('primary', 400),
    borderRadius: getRem(416),
  },
});
