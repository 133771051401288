import { Breadcrumb } from '@dovera/design-system';
import useStyles from './Breadcrumbs.styles';
import { BreadcrumbItem } from './types';
import { Link } from 'react-router-dom';
import routes from '../../routes';

interface Props {
  items: BreadcrumbItem[];
}

const Breadcrumbs = ({ items }: Props) => {
  const classes = useStyles();

  const homeItem = [{ href: <Link to={routes.dashboard} />, title: 'home' }];

  const breadcrumbItems = items.map((i) => ({
    href: i?.route ? (
      <Link key={`breadcrumb-${i.name}--${i?.route}`} to={i.route} />
    ) : undefined,
    title: <span key={`breadcrumb-${i.name}`}>{i.name}</span>,
  }));

  return (
    <div className={classes.breadcrumbs}>
      {/* @ts-ignore */}
      <Breadcrumb items={[...homeItem, ...breadcrumbItems]} />
    </div>
  );
};

export default Breadcrumbs;
