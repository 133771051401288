import { Path, Svg } from '@react-pdf/renderer';
import { color } from '@dovera/design-system';

const EditIcon = () => (
  // @ts-ignore
  <Svg style={{ width: 24, height: 24, marginTop: 1 }}>
    <Path
      d="M18 11L21 8L16 3L13 6M18 11L8 21H3V16L13 6M18 11L13 6"
      stroke={color('black')}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </Svg>
);

export default EditIcon;
