import strings from '../../../../../constants/strings';
import {
  addressLowerCases,
  hotjarMasking,
} from '../../../../../utils/strings.utils';

interface Props {
  city?: string;
  country?: string;
  street?: string | null;
  streetNumber?: string;
  zip?: string;
}

const Address = ({ city, country, street, streetNumber, zip }: Props) => (
  <>
    {street &&
      streetNumber &&
      hotjarMasking(
        `${addressLowerCases(street, false)} ${streetNumber}`,
        'b',
        'd-block',
      )}
    {zip &&
      city &&
      hotjarMasking(`${zip} ${addressLowerCases(city, true)}`, 'b', 'd-block')}
    <b className="d-block">{country && addressLowerCases(country, false)}</b>
    {!street && !city && !country && <b>{strings.undefined}</b>}
  </>
);

export default Address;
