import { Button } from '@dovera/design-system';
import strings from '../../../../../constants/strings';
import { useCallback } from 'react';
import { useModalContext } from '../../../../../hooks/useModalContext';
import { ModalType } from '../../../../../types/modal.types';
import { PROPOSAL_TYPES } from '../../../../../types/proposals.types';
import {
  stornoCNPProposal,
  stornoDrugProposal,
} from '../../../../../api/drugProposals';
import { useNavigate } from 'react-router';
import { navrhyRoutes } from '../../../../../routes';
import {
  ResultType,
  ToastActionType,
} from '../../../../../types/drugProposals.types';
import { useAppDispatch } from '../../../../../hooks/useStore';
import { setLoadedCNP } from '../../../../../slices/drugProposals.slice';

const texts = strings.proposals.drugs.detail;

interface Props {
  proposalId: number;
  toastAction: ({ isVisible, onHide, result, text }: ToastActionType) => void;
  type: PROPOSAL_TYPES;
}

const ProposalStorno = ({ proposalId, toastAction, type }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const modalContext = useModalContext();
  const toastCallback = useCallback(
    (isVisible?: boolean, result?: ResultType) => {
      toastAction({
        isVisible: !!isVisible,
        result: result || 'success',
        text:
          result === 'error'
            ? texts.toasts.errorStorno
            : texts.toasts.successStorno,
        onHide: () => {
          if (result === 'success')
            navigate(
              `${navrhyRoutes.zoznamPodanychNavrhov}?typ=${type}${`${type !== PROPOSAL_TYPES.CNP ? '&reload=1' : ''}`}`,
            );
        },
      });
    },
    [navigate, toastAction, type],
  );
  const stornoCallback = useCallback(() => {
    toastCallback(false);
    let result: ResultType = null;
    if (type === PROPOSAL_TYPES.CNP)
      stornoCNPProposal(proposalId)
        .then(() => {
          dispatch(setLoadedCNP({ loaded: false }));
          result = 'success';
        })
        .catch(() => {
          result = 'error';
        })
        .finally(() => {
          modalContext.closeModal(ModalType.CANCEL_ACTION);
          toastCallback(true, result);
        });
    if (type === PROPOSAL_TYPES.Drugs)
      stornoDrugProposal(proposalId)
        .then(() => {
          result = 'success';
        })
        .catch(() => {
          result = 'error';
        })
        .finally(() => {
          modalContext.closeModal(ModalType.CANCEL_ACTION);
          toastCallback(true, result);
        });
  }, [dispatch, modalContext, proposalId, type, toastCallback]);
  const proposalStorno = useCallback(() => {
    modalContext.showModal(ModalType.CANCEL_ACTION, {
      isFormModal: true,
      onCancel: () => {
        modalContext.closeModal(ModalType.CANCEL_ACTION);
      },
      onContinue: () => stornoCallback(),
      texts: {
        btnCancel: texts.modals.storno.btnCancel,
        btnContinue: texts.modals.storno.btnContinue,
        description: texts.modals.storno.description,
        title: texts.modals.storno.title,
      },
      withLoading: true,
    });
  }, [modalContext, stornoCallback]);

  return (
    <Button onClick={proposalStorno} type="destructive">
      {texts.buttons.storno}
    </Button>
  );
};

export default ProposalStorno;
