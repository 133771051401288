import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { fetchBankoveSpojenia } from '../../../slices/profil.slice';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import { Container } from '@dovera/design-system';
import routes from '../../../routes';
import { NoDataIllustration } from '../../../components/EmptyState';
import { useAppDispatch } from '../../../hooks/useStore';
import { getPZS } from '../../../slices/auth.slice';
import Table from './Table';
import { textWithActions } from '../../../utils/strings.utils';
import strings from '../../../constants/strings';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import _ from 'lodash';
import { TABLE_PAGINATION } from '../../../constants/misc';
import TablePreloaderSkeleton from '../../../components/TablePreloaderSkeleton/TablePreloaderSkeleton';
import CardBubble from '../../../components/CardBubble/CardBubble';
import ContentHeader from '../../../components/ContentHeader/ContentHeader';

const texts = strings.profile;

const BankoveSpojenie = () => {
  const dispatch = useAppDispatch();
  const pzs = useSelector((state: RootState) => getPZS(state.auth));
  const idHz = useSelector((state: RootState) => state.poskytovatel.idHz);
  const { data, error, isLoading } = useSelector(
    (state: RootState) => state.profil.bankoveSpojenie,
  );
  useEffect(() => {
    if (idHz && !isLoading)
      dispatch(fetchBankoveSpojenia(pzs.userId || 0, idHz, pzs.vzId || 0));
    // eslint-disable-next-line
  }, [dispatch, idHz]);
  return (
    <StaticMenuLayout
      contentHeader={
        <ContentHeader flex>
          <Container>
            <Breadcrumbs
              items={[
                { name: texts.title, route: routes.profil },
                { name: texts.bankAccounts.title },
              ]}
            />
            <h2 className="mb-small">{texts.bankAccounts.title}</h2>
            {textWithActions({
              actions: [{ key: '[Schránka správ]', link: routes.spravy }],
              className: 'mb-xlarge',
              text: texts.bankAccounts.description,
            })}
          </Container>
        </ContentHeader>
      }
      contentWrapperId="dlek-profile"
      hideEPNotifications
    >
      <CardBubble>
        {isLoading && <TablePreloaderSkeleton columns={3} rows={3} />}
        {error && <NoDataIllustration illustration="technical" title={error} />}
        {data && !data.length && !isLoading && (
          <NoDataIllustration title={texts.bankAccounts.noData} />
        )}
        {data && data.length > 0 && (
          <Table accounts={_.chunk(data, TABLE_PAGINATION)} />
        )}
      </CardBubble>
    </StaticMenuLayout>
  );
};

export default BankoveSpojenie;
