import { useMemo } from 'react';
import { Notification } from '@dovera/design-system';
import { BannerAttributes } from '../../../types/jsonObjects.types';
import JsonObject from '../JsonObject';
import useStyles from '../styles';
import { getNotificationType } from '../utils';

const Banner = ({ attributes, content }: BannerAttributes) => {
  const classes = useStyles();

  const notificationType = useMemo(
    () => getNotificationType(attributes.type),
    [attributes.type],
  );

  return (
    <Notification
      className={classes.banner}
      message={<JsonObject data={content} />}
      variant={notificationType}
    />
  );
};

export default Banner;
