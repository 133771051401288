import { createUseStyles } from 'react-jss';
import { color } from '../../constants';

interface StylesProps {
  whiteBackground?: boolean;
}

export default createUseStyles({
  root: ({ whiteBackground }: StylesProps) => ({
    position: 'relative',
    '& input': {
      paddingRight: 30,
      backgroundColor: whiteBackground ? '#FFFFFF' : 'inherit',
      borderRadius: '5px !important',
      '&.focus-visible': {
        outline: 'none',
      },
    },
    '& .form-control': {
      marginBottom: 0,
    },
    '& .input-addons__item': {
      position: 'absolute',
      right: 0,
      top: 0,
      background: 'transparent',
      border: 'none',
      cursor: 'pointer',
      '& > span': {
        display: 'flex',
      },
    },
  }),
  choices: {
    display: 'none',
    maxHeight: '300px',
    overflowY: 'auto',
    backgroundColor: '#FFFFFF',
    zIndex: 999,
    padding: '1rem',
    transition: 'visibility .1s ease-out .1s,opacity .1s ease-out 0s',
    position: 'absolute',
    maxWidth: 'none',
    minWidth: 'none',
    boxShadow: 'none',
    borderRadius: 8,
    border: '1px solid #e1e2e3',
    cursor: 'default',
    '& button': {
      display: 'block',
      width: '100%',
      padding: '10px 15px',
      textAlign: 'left',
      borderRadius: 8,
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover': {
        color: color('primary'),
        backgroundColor: '#dbefdc',
      },
    },
    '&.active': {
      display: 'block',
      minWidth: '100%',
      maxWidth: '-webkit-max-content',
      boxShadow: '0 10px 15px 0 rgba(0,0,0,.04)',
    },
  },
});
