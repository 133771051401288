import {
  Button,
  ButtonLayout,
  ButtonSecondary,
  Grid,
  GridCol,
  Illustration,
  Input,
  Loader,
  Modal,
} from '@dovera/design-system';
import useStyles from './styles';
import strings from '../../../../constants/strings';
import ModalContent from './ModalContent';
import { useField, useForm } from 'react-final-form-hooks';
import { validationMsg } from '../../../../utils/form.utils';
import { VALIDATION } from '../../../../types/validation.types';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';
import { DATE_TIME_MESSAGE_FORMAT } from '../../../../utils/date.utils';
import { getPriceFormat } from '../../../../utils/number.utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
  DajNepovoleneVSQueries,
  InvoiceActionType,
  OdosliLekarenskuFakturuRequest,
  PotvrdFakturuRequest,
} from '../../../../types/zuctovanie.types';
import UploadForm from '../../../../components/UploadForm/UploadForm';
import IconUpload from '../../../../components/CustomIcons/IconUpload';
import {
  dajNepovoleneVS,
  odosliLekarenskuFakturu,
  potvrdFakturu,
} from '../../../../api/zuctovanie';
import {
  translateErrorCode,
  validateVS,
} from '../../../../utils/validation.utils';
import { LoadingState } from '../../../../types';
import { filesToBase64 } from '../../../../utils/file.utils';
import { getZucUploadProps } from '../../../../utils/zuctovanie.utils';
import { cx } from '../../../../utils/exports';
// @ts-check

interface Props {
  invoiceSum?: number;
  isVisible: boolean;
  onCancel: () => void;
  onConfirm?: () => void;
  refreshDocuments: () => void;
  type: InvoiceActionType;
}

type AcceptedState = {
  error: string;
  isLoading: LoadingState;
  result: 'success' | 'error' | null;
};

const validateForm = (values: { [x: string]: any }, forbiddenVS: string[]) => {
  const errors: any = {};
  if (!values.name) errors.name = validationMsg(VALIDATION.RequiredName);
  // @ts-ignore
  return { ...errors, ...validateVS(values?.vs, forbiddenVS) };
};

const initialState: AcceptedState = {
  error: '',
  isLoading: LoadingState.none,
  result: null,
};

const texts = strings.zuctovanie.modals.acceptedInvoice;

const InvoiceActionModal = ({
  invoiceSum,
  isVisible,
  onCancel,
  onConfirm,
  refreshDocuments,
  type,
}: Props) => {
  const classes = useStyles();
  const [invoices, setInvoices] = useState<File[] | null>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const [invoiceErr, setInvoiceErr] = useState<string | null>(null);
  const [vsLoadingState, setVsLoadingState] = useState<LoadingState>(
    LoadingState.none,
  );
  const [forbiddenVS, setForbiddenVS] = useState<string[]>([]);
  const [acceptedState, setAcceptedState] =
    useState<AcceptedState>(initialState);
  const { detail } = useSelector(
    (state: RootState) => state.zuctovanie.invoices,
  );
  const { menoZobrazLekar } = useSelector(
    (state: RootState) => state.poskytovatel,
  );
  const [dateTime, setDateTime] = useState(
    moment().format(DATE_TIME_MESSAGE_FORMAT),
  );
  setInterval(() => {
    setDateTime(moment().format(DATE_TIME_MESSAGE_FORMAT));
  }, 1000);

  useEffect(() => {
    const queries: DajNepovoleneVSQueries = {
      obdobie: detail?.obdobieFaktury || 0,
    };
    setAcceptedState(initialState);
    setVsLoadingState(LoadingState.fetching);
    if (detail?.obdobieFaktury && isVisible)
      dajNepovoleneVS(queries)
        .then((resp) => {
          const { nepovoleneVS } = resp.nepovoleneVariabilneSymboly;
          setVsLoadingState(LoadingState.filled);
          setForbiddenVS(nepovoleneVS);
        })
        .catch(() => setVsLoadingState(LoadingState.error));
  }, [detail?.obdobieFaktury, isVisible]);

  const sum = detail?.fakturovanaSuma || 0;
  const { form, handleSubmit } = useForm({
    onSubmit: (values: any) => {
      setAcceptedState({ ...initialState, isLoading: LoadingState.fetching });
      const payload: PotvrdFakturuRequest = {
        idFaktury: Number(detail?.idFaktury),
        potvrdilMeno: values.name,
        variabilnySymbol: values.vs,
      };
      if (type === InvoiceActionType.Potvrdenie)
        potvrdFakturu(payload)
          .then(() =>
            setAcceptedState({
              ...initialState,
              isLoading: LoadingState.filled,
              result: 'success',
            }),
          )
          .catch((err) =>
            setAcceptedState({
              ...initialState,
              error: translateErrorCode(err?.response?.data.kod),
              isLoading: LoadingState.error,
              result: 'error',
            }),
          );
      if (type === InvoiceActionType.Dolozenie) {
        if (!invoices && invoiceSum) {
          setAcceptedState({ ...initialState });
          setInvoiceErr(texts.form.errors.uploadInvoice);
        } else {
          filesToBase64(
            invoices || [],
            (invoiceFiles) => {
              filesToBase64(
                files || [],
                (respFiles) => {
                  const payloadDolozenie: OdosliLekarenskuFakturuRequest = {
                    fakturaDokument: invoiceFiles[0],
                    idFaktury: Number(detail?.idFaktury),
                    ostatneDokumenty: respFiles,
                    suma: Number(detail?.fakturovanaSuma),
                    variabilnySymbol: values.vs,
                    vystavil: values.name,
                  };
                  odosliLekarenskuFakturu(payloadDolozenie)
                    .then(() =>
                      setAcceptedState({
                        ...initialState,
                        isLoading: LoadingState.filled,
                        result: 'success',
                      }),
                    )
                    .catch((err) =>
                      setAcceptedState({
                        ...initialState,
                        error: translateErrorCode(err?.response?.data.kod),
                        isLoading: LoadingState.error,
                        result: 'error',
                      }),
                    )
                    .finally(() => resetForm());
                },
                'INE',
              );
            },
            'FKT',
          );
        }
      }
    },
    initialValues: {
      name: menoZobrazLekar || '',
      vs: '',
    },
    validate: (valuesToValid) => validateForm(valuesToValid, forbiddenVS),
  });
  const nameForm = useField('name', form);
  const vsForm = useField('vs', form);

  const resetForm = useCallback(() => {
    form.reset();
    form.resetFieldState('name');
    form.resetFieldState('vs');
    setFiles([]);
    setInvoices(null);
    setInvoiceErr(null);
  }, [form]);

  const renderNameInput = (
    <Input
      {...nameForm.input}
      crossOrigin
      error={
        nameForm.meta.touched &&
        nameForm.meta.error && <SafeHtml html={nameForm.meta.error} />
      }
      label={texts.form.labels.name}
    />
  );

  const renderVsInput = (
    <Input
      {...vsForm.input}
      addonsInside={vsLoadingState === LoadingState.fetching}
      crossOrigin
      error={
        vsForm.meta.touched &&
        vsForm.meta.error && <SafeHtml html={vsForm.meta.error} />
      }
      isDisabled={vsLoadingState === LoadingState.fetching}
      label={texts.form.labels.vs}
      rightAddons={
        vsLoadingState === LoadingState.fetching && <Loader size="large" />
      }
    />
  );

  const renderButtons = (
    <ButtonLayout className={classes.btnLayout} direction="horizontal">
      <ButtonSecondary
        onClick={() => {
          resetForm();
          onCancel();
        }}
      >
        {texts.form.buttons.cancel}
      </ButtonSecondary>
      <Button
        isLoading={acceptedState.isLoading === LoadingState.fetching}
        onClick={() => {
          if (!invoices && invoiceSum)
            setInvoiceErr(texts.form.errors.uploadInvoice);
        }}
        submit
      >
        {texts.form.buttons.submit}
      </Button>
    </ButtonLayout>
  );
  const renderInvoiceUpload = type === InvoiceActionType.Dolozenie && (
    <>
      <SafeHtml
        className="mb-small"
        html={texts.form.uploadInvoice.title(!!invoiceSum)}
        wrapper="h4"
      />
      <UploadForm
        {...getZucUploadProps()}
        className="mb-large"
        files={
          invoices
            ? [
                {
                  name: invoices[0].name,
                  size: invoices[0].size,
                  type: invoices[0].type,
                },
              ]
            : null
        }
        forbiddenExtensions={new Set()}
        formError={invoiceErr}
        isUploading={false}
        multiple={false}
        onResetForm={() => setInvoices(null)}
        onUpload={(f) => {
          setInvoiceErr(null);
          setInvoices(f);
        }}
        uploadFormLabelText={
          <div className="d-flex align-center">
            <IconUpload id="icon--davky-upload" />
            <SafeHtml
              className="text-space-half-left"
              html={texts.form.uploadInvoice.label}
              wrapper="span"
            />
          </div>
        }
      />
    </>
  );

  const renderDocumentsUpload = type === InvoiceActionType.Dolozenie && (
    <>
      <SafeHtml
        className="mb-small"
        html={texts.form.uploadExtraDocuments.title}
        wrapper="h4"
      />
      <UploadForm
        {...getZucUploadProps()}
        className="mb-large"
        files={
          files?.map((f) => ({
            name: f.name,
            size: f.size,
            type: f.type,
          })) || null
        }
        forbiddenExtensions={new Set()}
        isUploading={false}
        multiple
        onResetForm={(file, index) =>
          setFiles(files?.filter((f, key) => key !== index) || [])
        }
        onUpload={(f) => {
          setFiles([...(files || []), ...f]);
        }}
        uploadFormLabelText={
          <div className="d-flex align-center">
            <IconUpload id="icon--davky-upload" />
            <SafeHtml
              className="text-space-half-left"
              html={texts.form.uploadExtraDocuments.label}
              wrapper="span"
            />
          </div>
        }
      />
    </>
  );

  const renderFormContent = () => (
    <ModalContent className="d-block">
      <div>
        <Grid>
          <GridCol size={4}>{texts.form.labels.sum}:</GridCol>
          <GridCol size={7}>
            <p className={cx(classes.paragraph, 'larger')}>
              {getPriceFormat(sum, true)}
            </p>
          </GridCol>
          <GridCol size={4}>{texts.form.labels.date}:</GridCol>
          <GridCol size={7}>
            <p className={cx(classes.paragraph, 'mb')}>{dateTime}</p>
          </GridCol>
        </Grid>
        <form onSubmit={handleSubmit}>
          <div className={cx(classes.modalNarrower, 'd-flex')}>
            {renderNameInput}
            {renderVsInput}
          </div>
          {renderInvoiceUpload}
          {renderDocumentsUpload}
          {renderButtons}
        </form>
      </div>
    </ModalContent>
  );
  const renderAcceptedContent =
    acceptedState.result === 'success' ? (
      <ModalContent
        btnCancel={texts.buttons.cancel}
        btnSubmit={texts.buttons.submit}
        className={classes.success}
        description={texts.description}
        illustration={
          <Illustration
            className="mb-xsmall"
            height={256}
            name="large_sucess_state"
            width={256}
          />
        }
        onCancelClick={() => {
          onCancel();
          refreshDocuments();
        }}
        onSubmitClick={onConfirm}
        title={texts.title}
      />
    ) : (
      <ModalContent
        btnCancel={texts.buttons.cancel}
        btnSubmit={texts.buttons.submit}
        className={classes.warning}
        description={acceptedState.error}
        illustration={
          <Illustration
            className="mb-xsmall"
            height={256}
            name="large_error_state"
            width={256}
          />
        }
        onCancelClick={onCancel}
        onSubmitClick={onConfirm}
        title={texts.titleErr}
      />
    );
  return (
    <Modal
      header={
        type === InvoiceActionType.Dolozenie
          ? texts.form.titleAdd
          : texts.form.title
      }
      id="modal--accepted-invoice"
      isVisible={isVisible}
      onHide={() => {
        resetForm();
        onCancel();
      }}
    >
      {acceptedState.result ? renderAcceptedContent : renderFormContent()}
    </Modal>
  );
};

export default InvoiceActionModal;
