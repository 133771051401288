import { Skeleton } from '@dovera/design-system';

const BannerSkeleton = () => (
  <>
    <h3>
      <Skeleton width="80%" />
    </h3>
    <Skeleton className="d-block mb" width="60%" />
    <Skeleton className="d-block mb" height={38} width={150} />
  </>
);

export default BannerSkeleton;
