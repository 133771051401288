import { useCallback, useState } from 'react';
import useStyles from '../Profil.styles';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import { Container, Grid, GridCol, Notification } from '@dovera/design-system';
import routes from '../../../routes';
import { cx } from '../../../utils/exports';
import strings from '../../../constants/strings';
import FormLogin from './Forms/FormLogin';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { jwtDecode } from 'jwt-decode';
import FormPassword from './Forms/FormPassword';
import { changeLogin, changePassword } from '../../../api/profil';
import { VALIDATION } from '../../../types/validation.types';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import CardBubble from '../../../components/CardBubble/CardBubble';
import ContentHeader from '../../../components/ContentHeader/ContentHeader';

const texts = strings.profile;

type SubmitState = {
  error: string | null;
  input2Error: string | null;
  inputError: string | null;
  isLoading: boolean;
  success: boolean | null;
};

const PrihlasovacieUdaje = () => {
  const classes = useStyles();
  const { pouzivatelId, token } = useSelector((state: RootState) => state.auth);
  const [loginState, setLoginState] = useState<SubmitState>({
    error: null,
    inputError: null,
    input2Error: null,
    isLoading: false,
    success: null,
  });
  const [passwordState, setPasswordState] = useState<SubmitState>({
    error: null,
    inputError: null,
    input2Error: null,
    isLoading: false,
    success: null,
  });
  const submitCallback = useCallback(
    async (values: any, type: 'login' | 'password') => {
      if (pouzivatelId) {
        let apiResponse;
        switch (type) {
          case 'login':
            setLoginState({
              ...loginState,
              isLoading: true,
            });
            apiResponse = await changeLogin({
              idPouzivatel: pouzivatelId,
              login: values?.newLogin,
            });
            break;
          case 'password':
            setPasswordState({
              ...passwordState,
              isLoading: true,
            });
            apiResponse = await changePassword({
              idPouzivatel: pouzivatelId,
              noveHeslo: values?.newPassword,
              stareHeslo: values?.currentPassword,
            });
            break;
          default:
            break;
        }
        const resp = {
          ...loginState,
          isLoading: false,
          error:
            apiResponse.vysledok === null ||
            apiResponse.chyba === VALIDATION.InProgress ||
            apiResponse.chyba === VALIDATION.MaxAttempts
              ? apiResponse.chyba
              : '',
          inputError:
            apiResponse.vysledok !== null &&
            apiResponse.chyba !== VALIDATION.InProgress &&
            apiResponse.chyba !== VALIDATION.MaxAttempts &&
            apiResponse.chyba !== VALIDATION.PasswordNewSameAsOld
              ? apiResponse.chyba
              : '',
          input2Error:
            apiResponse.vysledok !== null &&
            apiResponse.chyba !== VALIDATION.InProgress &&
            apiResponse.chyba !== VALIDATION.MaxAttempts &&
            apiResponse.chyba === VALIDATION.PasswordNewSameAsOld
              ? apiResponse.chyba
              : '',
          success: apiResponse.vysledok,
        };
        switch (type) {
          case 'login':
            setLoginState(resp);
            break;
          case 'password':
            setPasswordState(resp);
            break;
          default:
            break;
        }
      }
    },
    [loginState, passwordState, pouzivatelId],
  );
  const renderTitle = (
    <h2 className={cx('mb')}>{texts.changeLoginDetails.title}</h2>
  );
  const renderWarning = (
    <div className={cx(classes.notification, 'mb')}>
      <Notification message={texts.loginDetails.warning} variant="warning" />
    </div>
  );
  return (
    <StaticMenuLayout
      contentHeader={
        <ContentHeader flex>
          <Container>
            <Breadcrumbs
              items={[
                { name: texts.title, route: routes.profil },
                { name: texts.changeLoginDetails.title },
              ]}
            />
            {renderTitle}
            {renderWarning}
          </Container>
        </ContentHeader>
      }
      contentWrapperId="dlek-profile"
      hideEPNotifications
    >
      <CardBubble>
        <Grid>
          <GridCol size={{ l: 6, m: 12 }}>
            <FormLogin
              error={
                loginState.error === VALIDATION.MaxAttempts
                  ? VALIDATION.MaxAttemptsLogin
                  : loginState.error
              }
              isLoading={loginState.isLoading}
              newLoginError={loginState.inputError}
              onSubmit={(values) => submitCallback(values, 'login')}
              success={loginState.success}
              // @ts-ignore
              username={token ? jwtDecode(token)?.name : ''}
            />
          </GridCol>
          <GridCol size={{ l: 6, m: 12 }}>
            <FormPassword
              currentPasswordError={passwordState.inputError}
              error={
                passwordState.error === VALIDATION.MaxAttempts
                  ? VALIDATION.MaxAttemptsPassword
                  : passwordState.error
              }
              isLoading={passwordState.isLoading}
              newPasswordError={passwordState.input2Error}
              onSubmit={(values) => submitCallback(values, 'password')}
              success={passwordState.success}
            />
          </GridCol>
        </Grid>
      </CardBubble>
    </StaticMenuLayout>
  );
};

export default PrihlasovacieUdaje;
