import { useContext, useEffect, useRef } from 'react';
import { useQuery } from '../../../hooks/useQuery';
import { FilterContext } from '../../../providers/FilterProvider';
import { FILTER_PREFIX } from '../constants';
import { AutocompleteApp } from '@dovera/design-system';
import { AutocompleteType } from '../types';
import { Choice } from 'choices.js';

const AutocompleteFilter = ({ ...props }: AutocompleteType) => {
  const ref = useRef(null);
  const {
    id,
    isPrivate = true,
    label,
    onChange,
    options,
    ...restProps
  } = props;

  const { searchParams, setSearchParams } = useContext(FilterContext);
  const query = useQuery();

  useEffect(() => {
    if (!isPrivate) {
      const queryValue = query.get(`${FILTER_PREFIX}_${id}`);
      if (queryValue) {
        handleChange(queryValue);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (value) => {
    if (value && !searchParams.get(`${FILTER_PREFIX}_${id}`)) {
      searchParams.set(`${FILTER_PREFIX}_${id}`, isPrivate ? true : value);
      setSearchParams(searchParams);
    } else if (!value && searchParams.get(`${FILTER_PREFIX}_${id}`)) {
      searchParams.delete(`${FILTER_PREFIX}_${id}`);
      setSearchParams(searchParams);
    }
    if (onChange) onChange(value);
  };

  return (
    <AutocompleteApp
      ref={ref}
      addonsInside
      disableSuccessFrame
      id={id || ''}
      // @ts-ignore
      inputType="input"
      label={label}
      onChange={(value) => handleChange(value)}
      source={async (): Promise<Choice[]> =>
        options?.map((item) => ({ label: item.label, value: item.value })) || []
      }
      {...restProps}
    />
  );
};

export default AutocompleteFilter;
