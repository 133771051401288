import { ButtonLink, Icon, color } from '@dovera/design-system';
import { useCallback } from 'react';
import { dajSuborSpatnejDavky } from '../../../../../api/zuctovanie';
import SortableTable from '../../../../../components/SortableTable/SortableTable';
import strings from '../../../../../constants/strings';
import {
  DajSuborSpatnejDavkyQueries,
  SpatneDavkyState,
} from '../../../../../types/zuctovanie.types';
import { formatDate } from '../../../../../utils/date.utils';
import { cx } from '../../../../../utils/exports';
import { saveBase64Document } from '../../../../../utils/file.utils';

const texts = strings.zuctovanie.invoices.detail;
const { tableCols } = strings.zuctovanie.invoices.detail.spatneDavky;

interface Props {
  invoiceId: number;
  loadingBtn: boolean;
  loadingIds: string[];
  state: SpatneDavkyState;
  uploadDokumentZipId: (id: number) => void;
  uploadLoadingBtn: (loading: boolean) => void;
  uploadLoadingIds: (ids: string[]) => void;
}

const Table = ({
  invoiceId,
  loadingBtn,
  loadingIds,
  state,
  uploadDokumentZipId,
  uploadLoadingBtn,
  uploadLoadingIds,
}: Props) => {
  const downloadFile = useCallback(
    ({
      batchId,
      invoiceCode,
      zip,
    }: {
      batchId?: string;
      invoiceCode?: string;
      zip?: boolean;
    }) => {
      if (batchId) uploadLoadingIds([...loadingIds, batchId]);
      if (zip) uploadLoadingBtn(true);
      const queries: DajSuborSpatnejDavkyQueries = {
        guidDavky: zip ? undefined : batchId,
        idFaktury: invoiceId,
        kodTypDavky: invoiceCode || '',
        vsetkyAkoZIP: !!zip,
      };
      if (batchId) {
        dajSuborSpatnejDavky(queries)
          .then((resp) => resp.suborDavky)
          .then((data) => saveBase64Document(data.obsah, data.nazov))
          .finally(() => {
            if (batchId)
              uploadLoadingIds(loadingIds.filter((i) => i !== batchId));
            if (zip) uploadLoadingBtn(false);
          });
      }
      if (zip) {
        dajSuborSpatnejDavky(queries)
          .then((resp) => resp.suborDavky)
          .then((data) => {
            if (data.id) uploadDokumentZipId(data.id);
          });
      }
    },
    [
      invoiceId,
      loadingIds,
      uploadDokumentZipId,
      uploadLoadingBtn,
      uploadLoadingIds,
    ],
  );

  return (
    <>
      <ButtonLink
        className="inline-btn mb-small"
        isLoading={loadingBtn}
        onClick={() => downloadFile({ zip: true })}
      >
        <Icon
          className="text-space-half-right"
          color={color('primary', 600)}
          name="download"
          size="medium"
        />
        {texts.buttons.download}
      </ButtonLink>
      {state.data?.length && (
        <SortableTable
          cols={[
            {
              index: 1,
              isSortable: true,
              name: tableCols.name,
              sortType: 'alphabetical',
            },
            {
              index: 2,
              isSortable: true,
              name: tableCols.type,
              sortType: 'alphabetical',
            },
            {
              index: 3,
              isSortable: true,
              name: tableCols.period,
              sortType: 'alphabetical',
            },
            { index: 4, name: tableCols.date },
          ]}
          noRerender
          rows={state.data.map((d) => ({
            col1: {
              sortableValue: d.nazov,
              value: (
                <ButtonLink
                  className={cx(
                    `inline-btn text-thin`,
                    !loadingIds.includes(d.guidDavky) && 'text-color-black',
                  )}
                  isLoading={loadingIds.includes(d.guidDavky)}
                  onClick={() =>
                    downloadFile({
                      batchId: d.guidDavky,
                      invoiceCode: d.kodTypDavky,
                    })
                  }
                >
                  {d.nazov}
                </ButtonLink>
              ),
            },
            col2: {
              sortableValue: d.kodTypDavky,
              value: d.kodTypDavky,
            },
            col3: {
              sortableValue: d.obdobie.toString(),
              value: d.obdobie.toString(),
            },
            col4: {
              value: formatDate(d.datumCasSpracovaniaGenerovania),
            },
          }))}
        />
      )}
    </>
  );
};

export default Table;
