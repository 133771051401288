import { Checkbox, Icon, Tag, color } from '@dovera/design-system';
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { breakpoints } from '../../../hooks/screen-width/useScreenWidth';
import useOutsideMouseover from '../../../hooks/useOutsideMouseover';
import { useAppDispatch } from '../../../hooks/useStore';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { oznacitPreVymazanie } from '../../../slices/spravy.slice';
import { KategoriaSprav } from '../../../types/spravy.types';
import { DATE_FORMAT_SLOVAK, formatDate } from '../../../utils/date.utils';
import { cx } from '../../../utils/exports';
import {
  getListFromString,
  splitText,
  stripHtml,
} from '../../../utils/strings.utils';
import useStyles from '../Spravy.styles';

interface Props {
  badge: 'A' | 'N' | string | null;
  date: string;
  description: string;
  id: number;
  isActive: boolean;
  isChecked: boolean;
  isDeleted: boolean;
  onClick: () => void;
  sentByPZS: boolean;
  showRemoveCheckox: boolean;
  title: string;
  unread: boolean;
}

const Thread = ({
  badge,
  date,
  description,
  id,
  isActive,
  isChecked,
  isDeleted,
  onClick,
  sentByPZS,
  showRemoveCheckox,
  title,
  unread,
}: Props) => {
  const classes = useStyles({});
  const ref = useRef(null);
  const windowSize = useWindowSize();
  const dispatch = useAppDispatch();
  const [isVisibleCheckbox, setIsVisibleCheckbox] = useState(false);
  const calculateChars = (type: 'title' | 'description'): number => {
    if (type === 'title') {
      switch (true) {
        case windowSize.width >= breakpoints.l && windowSize.width < 1300:
          return 20;
        case windowSize.width < breakpoints.l &&
          windowSize.width >= breakpoints.s:
          return 25;
        case windowSize.width < breakpoints.s:
          return 15;
        case windowSize.width > 1700:
          return 50;
        case windowSize.width > 1500:
          return 40;
        default:
          return 30;
      }
    }
    switch (true) {
      case windowSize.width >= breakpoints.l && windowSize.width < 1300:
        return 27;
      case windowSize.width < breakpoints.l &&
        windowSize.width >= breakpoints.s:
        return 40;
      case windowSize.width < breakpoints.s:
        return 25;
      case windowSize.width > 1700:
        return 70;
      case windowSize.width > 1500:
        return 60;
      default:
        return 45;
    }
  };
  const clickCallback = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      // @ts-ignore
      const { nodeName } = e.target;
      if (!['INPUT', 'LABEL'].includes(nodeName)) onClick();
    },
    [onClick],
  );
  useEffect(() => {
    setIsVisibleCheckbox(
      windowSize.width <= breakpoints.xl || showRemoveCheckox,
    );
  }, [showRemoveCheckox, windowSize.width]);
  useEffect(() => {
    if (isDeleted) setIsVisibleCheckbox(false);
  }, [isDeleted]);
  const checkedCallback = useCallback(
    (isChecked) => {
      dispatch(oznacitPreVymazanie({ id, oznacene: isChecked }));
    },
    [dispatch, id],
  );
  const hideCheckbox = useCallback(() => {
    if (
      isVisibleCheckbox &&
      !showRemoveCheckox &&
      windowSize.width > breakpoints.xl
    )
      setIsVisibleCheckbox(false);
  }, [isVisibleCheckbox, showRemoveCheckox, windowSize.width]);
  useOutsideMouseover(ref, () => {
    hideCheckbox();
  });
  const renderCheckbox = isVisibleCheckbox && (
    <Checkbox
      className="no-mrg"
      data-checked={isChecked}
      // eslint-disable-next-line
      checked={isChecked}
      id={`${id}--checkbox`}
      isChecked={isChecked}
      onChange={checkedCallback}
    />
  );
  const renderDotNewThread = unread && (
    <span className={cx(classes.threadNewDot, isDeleted && 'is-archived')} />
  );
  return (
    <div
      ref={ref}
      className={cx(classes.thread, unread && 'unread', isActive && 'active')}
      onBlur={() => !isDeleted && setIsVisibleCheckbox(true)}
      onClick={clickCallback}
      onFocus={() => !isDeleted && setIsVisibleCheckbox(true)}
      onKeyPress={onClick}
      onMouseLeave={hideCheckbox}
      onMouseOver={() => !isDeleted && setIsVisibleCheckbox(true)}
      role="button"
      tabIndex={0}
    >
      {(!isDeleted || unread) && (
        <div className={classes.threadCheckbox}>
          {(!isDeleted && renderCheckbox) || renderDotNewThread}
        </div>
      )}
      <b style={{ marginLeft: isDeleted ? 0 : '2rem' }}>
        {splitText(title, calculateChars('title'))}
      </b>
      <span className={classes.threadDate}>
        {formatDate(date, DATE_FORMAT_SLOVAK)}
      </span>
      <div className="d-flex" style={{ marginLeft: isDeleted ? 0 : '2rem' }}>
        {sentByPZS && (
          <span className="icon--left">
            <Icon
              color={color('black')}
              id={`icon-reply-thread--${id}`}
              name="reply"
              size="medium"
            />
          </span>
        )}
        {splitText(
          `${sentByPZS ? 'Vy:' : ''} ${stripHtml(
            getListFromString(description)
              .map((d) => d)
              .join(' '),
          )}`,
          calculateChars('description') - (badge ? 10 : 0),
        )}
        {badge && (
          <Tag variant={badge !== KategoriaSprav.Archiv ? 'orchid' : undefined}>
            {badge === KategoriaSprav.Archiv ? 'Archív' : 'Notifikácia'}
          </Tag>
        )}
      </div>
    </div>
  );
};

export default Thread;
