import { createUseStyles } from 'react-jss';

export default createUseStyles({
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    width: '100%',
  },
});
