import { Icon, color } from '@dovera/design-system';
import { FILTER_PREFIX } from '../components/Filter/constants';

export const ERROR_COLOR = color('error', 500);
export const BLACK_COLOR = color('black');

export const ERROR_ICON = (
  <Icon color={ERROR_COLOR} name="error" size="medium" />
);

export const DOCTOR_ICON = (
  <Icon color={BLACK_COLOR} name="doctor" size="medium" />
);

export const PILL_ICON = <Icon color={BLACK_COLOR} name="pill" size="medium" />;

export const USER_ICON = (
  <Icon color={BLACK_COLOR} name="16-user" size="medium" />
);

export const CALENDAR_ICON = (
  <Icon color={BLACK_COLOR} name="calendar" size="medium" />
);

export const INFO_ICON = <Icon color={BLACK_COLOR} name="info" size="small" />;

export const TOAST_HIDE_MS = 5000;

export const PATIENT_TYPES_FILTER_QUERY = `${FILTER_PREFIX}_typy-pacientov`;

export const INITIAL_PATIENT_FILTER = 'A';
