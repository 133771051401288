import { useCallback, useEffect } from 'react';
import strings from '../../../../../constants/strings';
import {
  checkProposalData,
  getFormData,
} from '../../../../../api/spaProposals';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import {
  changeActiveStep,
  changeStepStatus,
  resetControls,
  storeStep3,
} from '../../../../../slices/spaProposals.slice';
import { DATE_INTERNATIONAL, getMoment } from '../../../../../utils/date.utils';
import {
  getEventTypeLabel,
  getFilteredControls,
  hasLocalProposalControlErrors,
  spaTxsCheckTypes,
} from '../../../../../utils/spaProposals.utils';
import { useField, useForm } from 'react-final-form-hooks';
import { VALIDATION } from '../../../../../types/validation.types';
import { validationMsg } from '../../../../../utils/form.utils';
import { Summary } from './summary';
import { IsDg, StepFields } from './components';
import { useAppDispatch } from '../../../../../hooks/useStore';
import {
  MIN_LENGTH_EPICRISIS,
  PROPOSAL_SPA_STEP_COUNT,
} from '../../../../../constants/proposals';
import {
  getFirstMatch,
  isSqlInjection,
  replaceNewLineChars,
} from '../../../../../utils/strings.utils';
import regex from '../../../../../constants/regex';
import { StepWrapper } from '../../common';

const texts = strings.proposals.spa.new;

interface Props {
  onCancel: () => void;
  onContinue: () => void;
}

const validate = (
  values: { [x: string]: any },
  stored: { dateVisible: boolean; diagnose: string; indicatorGroup: string },
) => {
  const errors: any = {};

  if (!values.diagnose || !stored.diagnose)
    errors.diagnose = texts.inputErrors.diagnose;
  if (!values.indicatorGroup || !stored.indicatorGroup)
    errors.indicatorGroup = texts.inputErrors.indicatorGroup;
  if (!values.eventDate && stored.dateVisible)
    errors.eventDate = 'Vyberte dátum z kalendára alebo ho vpíšte.';
  if (values.eventDate) {
    const choosedDate = getMoment(values.eventDate).format(DATE_INTERNATIONAL);
    if (!getMoment(choosedDate).isValid())
      errors.eventDate = validationMsg(VALIDATION.DateIncorrect);
  }
  if (!values.epicrisis) errors.epicrisis = texts.inputErrors.epicrisis;
  if (
    values.epicrisis &&
    (!replaceNewLineChars(values.epicrisis) ||
      !values.epicrisis.replace(/\s+/gi, '').replace(/\n+/gi, ''))
  )
    errors.epicrisis = texts.inputErrors.incorrectValue;
  if (values.epicrisis && values.epicrisis.length < MIN_LENGTH_EPICRISIS)
    errors.epicrisis = texts.inputErrors.epicrisisLength;
  if (values.epicrisis && isSqlInjection(values.epicrisis))
    errors.epicrisis = validationMsg(
      VALIDATION.SqlInjection,
      getFirstMatch(values.epicrisis, regex.SQL_INJECTION),
    );

  return {
    ...errors,
  };
};

const Step3 = ({ onCancel, onContinue }: Props) => {
  const dispatch = useAppDispatch();
  const {
    data: {
      controls,
      formData: { data },
    },
    resetAfterFilled,
    stepper: { activeStep, step1, step2, step3 },
  } = useSelector((state: RootState) => state.spaProposals.new);
  const { form, handleSubmit, values } = useForm({
    initialValues: {
      diagnose: '',
      indicatorGroup: '',
      eventDate: '',
      confirmation: undefined,
      contraindications: undefined,
      examinations: undefined,
      svlz: undefined,
      epicrisis: '',
    },
    onSubmit: () => {
      onContinueCallback();
    },
    validate: (values) =>
      validate(values, {
        diagnose: step3?.diagnose?.kod || '',
        indicatorGroup: step3?.indicatorGroup?.kod || '',
        dateVisible: !!getEventTypeLabel(
          data?.udajParUdalIndSkup?.udalost || null,
        ),
      }),
  });
  const diagnoseField = useField('diagnose', form);
  const indicatorGroupField = useField('indicatorGroup', form);
  const eventDateField = useField('eventDate', form);
  const confirmationField = useField('confirmation', form);
  const contraindicationsField = useField('contraindications', form);
  const examinationsField = useField('examinations', form);
  const svlzField = useField('svlz', form);
  const epicrisisField = useField('epicrisis', form);
  const expertiseAmbulance = step1.ambulance.expertise;
  const expertiseDoctor = step1.doctor.expertise;
  const onContinueCallback = useCallback(() => {
    const { spaConfirmation, spaContraindications, spaExaminations, spaSVLZ } =
      step3;
    dispatch(
      storeStep3({
        date: values.eventDate
          ? getMoment(values.eventDate).format(DATE_INTERNATIONAL)
          : '',
        reasonDescription: values.epicrisis,
      }),
    );
    dispatch(
      changeStepStatus({
        step: 3,
        status:
          !!getFilteredControls(
            controls.data.filter((d) => d.checkType !== 'PoistnyVztah'),
          ).length ||
          hasLocalProposalControlErrors({
            spaConfirmation,
            spaSVLZ,
            spaExaminations,
            spaContraindications,
          })
            ? 'error'
            : 'completed',
      }),
    );
    setTimeout(() => {
      if (values.epicrisis) onContinue();
    }, 100);
  }, [controls.data, dispatch, onContinue, step3, values]);
  useEffect(() => {
    /**
     * if user choosed IS & diagnose
     */
    if (step3.diagnose && step3.indicatorGroup) {
      dispatch(
        getFormData({
          kodDiagnoza: step3.diagnose.kod,
          kodIndikacnaSkupina: step3.indicatorGroup.kod,
        }),
      );
    }
  }, [dispatch, step3.diagnose, step3.indicatorGroup]);
  useEffect(() => {
    if (resetAfterFilled === 'RC' && step2.stepStatus) form.restart();
    // eslint-disable-next-line
  }, [resetAfterFilled, step2.stepStatus]);

  useEffect(() => {
    if (
      data &&
      (!getEventTypeLabel(data?.udajParUdalIndSkup?.udalost) ||
        (values.eventDate && getMoment(values.eventDate).isValid()))
    )
      dispatch(
        checkProposalData({
          kontrolaTypy: spaTxsCheckTypes,
          isDg: {
            datumUdalosti: values.eventDate
              ? getMoment(values.eventDate).format(DATE_INTERNATIONAL)
              : undefined,
          },
          rodneCislo: step2.insureeId,
        }),
      );
  }, [data, dispatch, step2.insureeId, values.eventDate]);
  useEffect(() => {
    if (
      step3.diagnose === null ||
      step3.indicatorGroup === null ||
      resetAfterFilled
    ) {
      form.change('confirmation', undefined);
      form.change('contraindications', undefined);
      form.change('epicrisis', undefined);
      form.change('eventDate', undefined);
      form.change('examinations', undefined);
      form.change('svlz', undefined);
      form.resetFieldState('epicrisis');
      dispatch(resetControls());
    }
  }, [dispatch, form, resetAfterFilled, step3.diagnose, step3.indicatorGroup]);
  useEffect(() => {
    if (values.eventDate) {
      form.change('contraindications', undefined);
      form.change('epicrisis', undefined);
      form.change('examinations', undefined);
      form.change('svlz', undefined);
      form.resetFieldState('epicrisis');
    }
  }, [form, values.eventDate]);
  const renderContent = (
    <form onSubmit={handleSubmit}>
      <IsDg
        diagnose={step3.diagnose}
        diagnoseField={diagnoseField}
        expertiseAmbulance={expertiseAmbulance}
        expertiseDoctor={expertiseDoctor}
        indicatorGroup={step3.indicatorGroup}
        indicatorGroupField={indicatorGroupField}
      />
      {step3.diagnose && step3.indicatorGroup && (
        <StepFields
          confirmationField={confirmationField}
          contraindications={
            data?.udajDetIndSkup?.kontraIndikacie?.split('\n') || []
          }
          contraindicationsField={contraindicationsField}
          epicrisisField={epicrisisField}
          eventDateField={eventDateField}
          examinations={
            data?.udajDetIndSkup?.objektivnyNalez?.split('\n') || []
          }
          examinationsField={examinationsField}
          onCancel={onCancel}
          onContinue={handleSubmit}
          svlzField={svlzField}
        />
      )}
    </form>
  );
  return (
    <StepWrapper
      activeStep={activeStep}
      changeActiveStep={changeActiveStep}
      // eslint-disable-next-line
      content={renderContent}
      step={3}
      stepCount={PROPOSAL_SPA_STEP_COUNT}
      stepStatus={step3.stepStatus}
      summary={<Summary />}
      title={texts.stepTitles.step3}
    />
  );
};

export default Step3;
