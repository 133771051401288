import {
  Button,
  ButtonLink,
  ButtonSecondary,
  Icon,
  Pagination,
  TableApp,
  Tooltip,
  color,
} from '@dovera/design-system';
import { navrhyRoutes } from '../../../../routes';
import {
  formatNameStr,
  hotjarMasking,
  stringWithSpaces,
} from '../../../../utils/strings.utils';
import { formatDate } from '../../../../utils/date.utils';
import strings from '../../../../constants/strings';
import { ProposalDetailQueries } from '../../../../types/spaProposals.types';
import { useNavigate } from 'react-router';
import { createViewUrl } from '../../../../utils/app.utils';
import { PROPOSAL_TYPES } from '../../../../types/proposals.types';
import useStyles from '../../Proposals.styles';
import { useState } from 'react';
import { TABLE_PAGINATION } from '../../../../constants/misc';
import _ from 'lodash';
import { TruncateText } from '../../../../components/TruncateText';

interface Props {
  data: {
    doctor: {
      code: string;
      firstName: string;
      lastName: string;
      titleAfter: string | null;
      titleBefore: string | null;
    };
    id: number;
    insuree: {
      firstName: string;
      insureeId: string;
      lastName: string;
      /**
       * A = Aktivny
       * U = Umrtie
       * K = Ukonceny
       */
      state: 'A' | 'U' | 'K' | string;
    };
    proposalNumber: string;
    proposalType: 'A' | 'B' | string | null;
    reservation: {
      from: string;
      to: string;
    } | null;
    validity: {
      from: string;
      to: string;
    };
  }[];
  // eslint-disable-next-line
  onChooseDateClick: (proposalNumber: number) => void;
}

const texts = strings.proposals.spa.list.approvedProposals;

const TableList = ({ data, onChooseDateClick }: Props) => {
  const classes = useStyles({ proposalType: PROPOSAL_TYPES.SPA });
  const [actualPage, setActualPage] = useState(0);
  const navigate = useNavigate();
  return (
    <TableApp
      caption=""
      className={classes.listTable}
      columns={[
        {
          accessor: 'col1',
          Header: texts.labels.proposal,
        },
        {
          accessor: 'col2',
          Header: texts.labels.insuree,
        },
        {
          accessor: 'col3',
          Header: texts.labels.doctorCode,
        },
        {
          accessor: 'col4',
          Header: texts.labels.validity,
        },
        {
          accessor: 'col5',
          Header: texts.labels.reservation,
        },
        {
          accessor: 'col6',
          Header: '',
        },
      ]}
      data={
        (data &&
          _.chunk(data, TABLE_PAGINATION)?.[actualPage]?.map((d) => {
            const queries: ProposalDetailQueries = {
              cisloNavrhu: d.proposalNumber,
              id: d.id,
              typ: PROPOSAL_TYPES.SPA,
            };
            return {
              col1: (
                <>
                  {d.insuree.state === 'A' ? (
                    <Button
                      className="text-normal no-mrg no-pad"
                      onClick={() =>
                        navigate(
                          createViewUrl(
                            navrhyRoutes.zoznamSchvalenychNavrhov,
                            queries,
                          ),
                        )
                      }
                      type="link-secondary"
                    >
                      {d.proposalNumber}
                    </Button>
                  ) : (
                    d.proposalNumber
                  )}
                  <br />
                  <span>
                    Typ: <b>{d.proposalType || '-'}</b>
                  </span>
                </>
              ),
              col2: (
                <>
                  <span className="d-flex text-justify">
                    {hotjarMasking(
                      d.insuree.insureeId,
                      'b',
                      `d-block ${d.insuree.state !== 'A' ? 'text-color-error-base' : ''}`,
                    )}
                    {d.insuree.state !== 'A' && (
                      <span className="text-space-half-left">
                        <Tooltip dialog={texts.tooltips.inactive}>
                          <Icon color={color('error')} name="16-help" />
                        </Tooltip>
                      </span>
                    )}
                  </span>
                  <TruncateText
                    classes="d-block fs-14 text-color-grey-light"
                    hjMask
                    text={formatNameStr(
                      stringWithSpaces([
                        `${d.insuree.firstName} ${d.insuree.lastName}`,
                      ]),
                    )}
                  />
                </>
              ),
              col3: (
                <>
                  {hotjarMasking(d.doctor.code, 'b', 'd-block')}
                  <TruncateText
                    classes="d-block fs-14 text-color-grey-light"
                    hjMask
                    text={formatNameStr(
                      stringWithSpaces([
                        d.doctor.titleBefore || '',
                        d.doctor.firstName,
                        `${d.doctor.lastName}${d.doctor.titleAfter ? ',' : ''}`,
                        d.doctor.titleAfter || '',
                      ]),
                    )}
                  />
                </>
              ),
              col4: (
                <span>
                  {formatDate(d.validity.from)}
                  <br />
                  {formatDate(d.validity.to)}
                </span>
              ),
              col5: d.reservation ? (
                <span>
                  {formatDate(d.reservation.from)}
                  <br />
                  {formatDate(d.reservation.to)}
                </span>
              ) : d.insuree.state !== 'A' ? (
                '-'
              ) : (
                <ButtonSecondary
                  className="no-mrg"
                  onClick={() => onChooseDateClick(d.id)}
                  size="s"
                >
                  {texts.labels.date}
                </ButtonSecondary>
              ),
              col6:
                d.insuree.state === 'A' ? (
                  <ButtonLink
                    className="no-mrg no-pad"
                    onClick={() =>
                      navigate(
                        createViewUrl(
                          navrhyRoutes.zoznamSchvalenychNavrhov,
                          queries,
                        ),
                      )
                    }
                  >
                    Detail
                  </ButtonLink>
                ) : (
                  '-'
                ),
            };
          })) ||
        []
      }
      // @ts-ignore
      pagination={
        <Pagination
          currentPage={actualPage + 1}
          onChange={(pageNumber) => setActualPage(pageNumber - 1)}
          totalPages={Math.ceil(Number(data?.length) / TABLE_PAGINATION)}
        />
      }
    />
  );
};

export default TableList;
