import CardBubble from '../../../components/CardBubble/CardBubble';
import JsonObject from '../../../components/JsonObject/JsonObject';
import { JsonObjectType } from '../../../types/jsonObjects.types';
import { cx } from '../../../utils/exports';
import useStyles from './styles';

interface Props {
  content: JsonObjectType | null;
  id: number;
}

const WidgetJsonObject = ({ content, id }: Props) => {
  const classes = useStyles();
  if (!content) return null;
  return (
    <CardBubble
      className={cx('mb', content.type !== 'carousel' && classes.root)}
      id={`widget-${id}`}
    >
      <JsonObject data={[content]} />
    </CardBubble>
  );
};

export default WidgetJsonObject;
