import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  root: {
    maxWidth: 600,
    textAlign: 'center',
    marginTop: '20%',
    transform: 'translateY(-20%)',
  },
  browsers: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    '& a': {
      textAlign: 'center',
    },
  },
  button: {
    display: 'flex',
    margin: '0 auto',
    justifyContent: 'center',
  },
});
