import { Input, Notification, Skeleton, Textarea } from '@dovera/design-system';
import RemainingChars from '../../../components/RemainingChars/RemainingChars';
import strings from '../../../constants/strings';
import { LoadingState } from '../../../types';
import { TemaSpravy } from '../../../types/spravy.types';
import { getDefaultSelectOption } from '../../../utils/dds.utils';
import RequiredAttachment from './RequiredAttachment';
import { SelectFilter } from '../../../components/Filter';

interface Props {
  attachmentUrl?: string;
  emailField: any;
  hasPzsEmail?: boolean;
  isReply?: boolean;
  messageField: any;
  subthemeField: any;
  subthemeLoading: boolean;
  themeField: any;
  themes: {
    data: TemaSpravy[];
    dataState: LoadingState;
  };
}

const newMessageTexts = strings.schrankaSprav.modals.newMessageModal;

const FormFields = ({
  attachmentUrl,
  emailField,
  hasPzsEmail,
  isReply,
  messageField,
  subthemeField,
  subthemeLoading,
  themeField,
  themes,
}: Props) => {
  const renderEmail = !hasPzsEmail && (
    <Input
      {...emailField.input}
      crossOrigin
      error={emailField.meta.touched && emailField.meta.error}
      help={newMessageTexts.helpers.email}
      id="message--formFields--confirmEmail"
      isRequired
      label={newMessageTexts.labels.email}
    />
  );
  const renderSelectSkeleton = !isReply && (
    <div className="mb-small">
      <div className="mb-xxsmall">
        <Skeleton width="40%" />
      </div>
      <Skeleton height={50} width="100%" />
    </div>
  );

  const renderTheme = !isReply && (
    <SelectFilter
      {...themeField.input}
      data-testid="message--theme"
      error={themeField.meta.touched && themeField.meta.error}
      id="tema-spravy"
      initWithDelay
      isRequired
      label={newMessageTexts.labels.theme}
      options={[
        getDefaultSelectOption(' '),
        ...themes.data.map((d) => ({
          value: d.id.toString(),
          label: d.nazov,
        })),
      ]}
      role="combobox"
    />
  );
  const renderSubtheme =
    subthemeLoading && themeField.input.value
      ? renderSelectSkeleton
      : themes.data.some(
          (d) => d.id === Number(themeField.input.value) && d.podtypy?.length,
        ) && (
          <SelectFilter
            {...subthemeField.input}
            data-testid="message--subtheme"
            error={subthemeField.meta.touched && subthemeField.meta.error}
            id="podtema-spravy"
            initWithDelay
            isRequired
            label={newMessageTexts.labels.subtheme}
            options={[
              getDefaultSelectOption(' '),
              ...(themes.data
                .filter((d) => d.id === Number(themeField.input.value))?.[0]
                ?.podtypy?.map(
                  /**
                   * TODO: change value as unique slug (for friendly query url params)
                   * Predispozition: need BE change
                   * ITA: iVIK
                   */
                  (p) => ({
                    value: p.id.toString(),
                    label: p.nazov,
                  }),
                ) || []),
            ]}
          />
        );
  const renderMessage = (
    <Textarea
      {...messageField.input}
      data-testid="message--text"
      error={messageField.meta.touched && messageField.meta.error}
      id="messageField"
      label={
        <RemainingChars
          formLabel={!isReply ? newMessageTexts.labels.message : ''}
          maxLength={1500}
          valueLength={messageField.input.value?.length}
        />
      }
      maxLength={1500}
      role="textbox"
    />
  );
  const renderThemeErrorLoading = themes.dataState === LoadingState.error && (
    <Notification message={newMessageTexts.themeLoadingError} variant="error" />
  );
  return (
    <div data-testid="message-formFields">
      {renderEmail}
      {renderThemeErrorLoading ||
        (themes.dataState === LoadingState.fetching || !themes.data.length
          ? renderSelectSkeleton
          : renderTheme)}
      {renderSubtheme}
      {attachmentUrl && <RequiredAttachment url={attachmentUrl} />}
      {renderMessage}
    </div>
  );
};

export default FormFields;
