import { breakpoints, color, fontWeight, getRem } from '@dovera/design-system';
import { jss } from 'react-jss';

import { HEADER_HEIGHT } from './constants';
import { ns } from './utils/object.utils';
import { isIE } from './utils/app.utils';

const baseUri = ns(() => window.env.config.baseURI, '');

jss
  .createStyleSheet({
    '@global header.header .container': {
      maxWidth: '100%',
    },
    '@global #root': {
      minHeight: '100vh',
    },
    '@global #content': {
      paddingTop: `${HEADER_HEIGHT}px`,
      minHeight: `calc(100vh - 67px)`,
      background: color('grey', 50),
    },
    '@global .relative': {
      position: 'relative',
    },
    '@global svg.icon--pos--category': {
      verticalAlign: 'middle',
      marginTop: -2,
    },
    '@global p svg.icon': {
      verticalAlign: 'middle',
      marginTop: -3,
    },
    '@global .withIcon svg.icon': {
      verticalAlign: 'middle',
      marginTop: -4,
      marginLeft: 5,
    },
    '@global *:focus': {
      outline: 'none',
    },
    '@global #parameter-formula-superdetail .modal__overlay': {
      backgroundColor: color('white'),
    },
    '@global .link': {
      wordWrap: 'inherit',
      wordBreak: 'inherit',
    },
    '@global .bar__item': {
      wordWrap: 'normal',
      wordBreak: 'normal',
      maxWidth: '100%',
    },
    '@global div > span': {
      wordBreak: 'break-word',
    },
    '@global .dlek--break-content': {
      wordBreak: 'break-word',
    },
    '@global span.inlineLastP p:last-child': {
      display: 'inline',
    },
    '@global *[role=button]:focus': {
      outline: 'none',
    },
    '@global .page-section': {
      width: isIE ? '100%' : 'inherit',
    },
    '@global .unselectable': {
      '-webkit-touch-callout': 'none' /* iOS Safari */,
      '-webkit-user-select': 'none' /* Safari */,
      '-khtml-user-select': 'none' /* Konqueror HTML */,
      '-moz-user-select': 'none' /* Old versions of Firefox */,
      '-ms-user-select': 'none' /* Internet Explorer/Edge */,
      userSelect: 'none' /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */,
    },
    '@global .content-center-vert svg': {
      verticalAlign: 'middle',
      marginTop: -4,
      marginRight: 8,
    },
    '@global .centered-icon svg': {
      verticalAlign: 'middle',
      marginTop: -4,
    },
    '@global .my-masonry-grid': {
      display: 'flex',
      width: 'auto',
    },
    '@global .my-masonry-grid_column': {
      backgroundClip: 'padding-box',
    },
    '@global .table td': {
      borderLeft: isIE ? 'none' : 'inherit',
      borderBottom: isIE ? 'none' : 'inherit',
      border: isIE ? '1px solid #e1e2e3' : '',
    },
    '@global .table td:last-child': {
      borderRight: isIE ? 'none' : 'inherit',
    },
    '@global .table td:not(:last-child):after': {
      borderRight: isIE ? 'none' : '1px solid #e1e2e3',
    },
    '@global .d-block': {
      display: 'block !important',
    },
    '@global .d-inline-flex': {
      display: 'inline-flex !important',
    },
    '@global .d-inline': {
      display: 'inline !important',
    },
    '@global .d-inline > div': {
      display: 'inline',
    },
    '@global .d-inline > p': {
      marginBottom: 8,
    },
    '@global .inline-btn': {
      minHeight: 0,
      margin: `-1px 0 0 0`,
      padding: 0,
      alignItems: 'center',
      textAlign: 'left',
    },
    '@global .inline-btn svg': {
      marginLeft: getRem(8),
    },
    '@global .inline-btn div:not(.loader)': {
      marginLeft: getRem(8),
      marginRight: `0 !important`,
    },
    '@global .inline-btn div.loader': {
      marginRight: getRem(12),
    },
    '@global': {
      '*::-webkit-scrollbar': {
        width: 6,
      },
      '*::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '*::-webkit-scrollbar-thumb': {
        background: '#DADADA',
        borderRadius: 8,
      },
      '*::-webkit-scrollbar:horizontal': {
        height: 5,
      },
      '*::-webkit-scrollbar-thumb:horizontal': {
        borderRadius: 10,
      },
      small: {
        color: color('grey', 500),
        fontSize: getRem(14),
      },
      '.modal__header .modal__title': {
        marginBottom: 0,
      },
      '.footer-app .container': {
        padding: '0 2rem',
      },
      '.table-caption--hide caption': {
        margin: 0,
      },
      '.input-addons': {
        borderRadius: 8,
      },
      '.input-addons__item': {
        border: 'none',
        borderLeft: 'none',
        minHeight: 48,
        [`@media screen and (max-width: ${breakpoints.m}px)`]: {
          minHeight: 'auto',
        },
      },
      '.content-center-vert': {
        display: 'flex',
        alignItems: 'center',
        [`@media screen and (max-width: ${breakpoints.s}px)`]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      },
      '.content-center-horiz': {
        display: 'flex',
        justifyContent: 'center',
      },
      '.content-center-icons': {
        display: 'flex',
        alignItems: 'center',
        '& > *:not(:first-child)': {
          marginLeft: getRem(16),
        },
      },
      '#select-expertise .input-addons__item': {
        minHeight: 'auto !important',
      },
      '.choices__list--dropdown': {
        maxHeight: 200,
        maxWidth: 350,
        overflow: 'hidden',
      },
      '.choices__list--dropdown .choices__list': {
        maxHeight: '100%',
      },
      '.tooltip[aria-hidden=true]': {
        display: 'none',
      },
      '.text-color-error-base': {
        color: `${color('error')} !important`,
      },
      '.text-color-grey-light': {
        color: `${color('grey', 500)} !important`,
      },
      '.card .link': {
        textDecoration: 'underline',
      },
      '.tooltip-container': {
        maxWidth: '55ch !important',
        padding: '1rem 1rem !important',
        backgroundColor: '#133463 !important',
        borderRadius: '8px !important',
        fontSize: '14px !important',
        lineHeight: '1.714 !important',
        color: '#fff',
        display: 'block',
        border: '0 !important',
        transform: 'translate3d(0,.5rem,0)',
        marginBottom: '12px !important',
      },
      '.text-space-quarter-left': {
        marginLeft: '0.25rem !important',
      },
      '.text-space-quarter-right': {
        marginRight: '0.25rem !important',
      },
      '.text-space-half-left': {
        marginLeft: '0.5rem !important',
      },
      '.text-space-half-right': {
        marginRight: '0.5rem !important',
      },
      '.text-space-two-thirds': {
        marginRight: '0.7rem !important',
      },
      'button.tab': {
        display: 'flex',
        alignItems: 'center',
      },
      'button.tab svg': {
        marginTop: -3,
        marginRight: 8,
      },
      '.form-control.is-error .input-addons__item': {
        display: 'none',
      },
      '.form-control.is-error .input-addons--inside input': {
        borderRadius: 8,
      },
      // '.is-error .input-addons.input-addons--inside': {
      //   border: `2px solid ${color('error')}`,
      // },
      '.fs-12': {
        fontSize: '12px !important',
      },
      '.fs-14': {
        fontSize: '14px !important',
      },
      '.fs-16': {
        fontSize: '16px !important',
      },
      '.fs-18': {
        fontSize: '18px !important',
      },
      '.pad-left': {
        paddingLeft: '1rem !important',
      },
      '.pad-bottom': {
        paddingBottom: getRem(24),
      },
      '.pad-bottom-small': {
        paddingBottom: getRem(8),
      },
      '.align-center': {
        display: 'flex',
        alignItems: 'center !important',
      },
      '.tooltip__content a': {
        color: 'white !important',
        textDecoration: 'underline',
      },
      '.form-control--textarea label': {
        width: '100%',
      },
      '.tooltip__content': {
        maxWidth: '60ch',
      },
      '.table-app__cell': {
        '&:first-child': {
          borderTopLeftRadius: getRem(5),
          borderBottomLeftRadius: getRem(5),
        },
        '&:last-child': {
          borderTopRightRadius: getRem(5),
          borderBottomRightRadius: getRem(5),
        },
      },
      '.icon-with-text': {
        display: 'flex',
        alignItems: 'center',
      },
      '.icon-with-text svg': {
        marginRight: getRem(8),
      },
      '.underline': {
        textDecoration: 'underline',
      },
      '.fit-content': {
        width: 'fit-content',
      },
      '.text-thin': {
        fontWeight: 'normal',
      },
      '.text-semibold': {
        fontWeight: fontWeight.semiBold,
      },
      '.notification-container .notification': {
        marginBottom: 0,
      },
      '.hide-xxl-s': {
        [`@media not all and (min-width: ${breakpoints.xl}px) and (max-width: 1430px)`]:
          {
            display: 'none',
          },
      },
      '.table-responsive-sh': {
        fontSize: 16,
        '& p': {
          fontSize: 16,
        },
        '& td': {
          padding: '.5rem 1rem',
        },
        '& table table': {
          border: 'none',
          marginBottom: 0,
          '& a': {
            fontSize: 16,
            fontWeight: 'bold',
          },
          '& table td': {
            padding: 0,
            border: 'none',
            '&:after': {
              display: 'none',
            },
          },
        },
        '& .table-responsive-sh': {
          marginBottom: 0,
        },
      },
      '.tab--link.is-active': {
        color: color('success', 600),
      },
      '.select--inline .choices__inner': {
        color: color('success', 600),
      },
      '.w-100': {
        width: '100%',
      },
      '.fw-500': {
        fontWeight: '500 !important',
      },
      '.header-mobile__hamburger': {
        display: 'none',
      },
      ul: {
        fontSize: getRem(16),
        paddingLeft: getRem(16),
        '&.no-marker': {
          padding: 0,
          '& li': {
            listStyle: 'none',
          },
        },
      },
      div: {
        fontSize: getRem(16),
        lineHeight: getRem(28),
      },
      p: {
        fontSize: getRem(16),
        lineHeight: getRem(28),
        maxWidth: '100%',
      },
      'h2, h3, h4, h5': {
        position: 'relative',
      },
      '.euro-symbol': {
        position: 'absolute',
        fontSize: getRem(18),
        top: 0,
        marginTop: getRem(4),
        marginLeft: getRem(4),
      },
      'h4 .euro-symbol, h5 .euro-symbol': {
        fontSize: getRem(18),
        marginTop: getRem(2),
      },
      '.tips__content ul': {
        paddingLeft: getRem(18),
        marginBottom: 0,
      },
      '.my-masonry-grid': {
        '& > div': {
          '&:nth-child(odd)': {
            paddingRight: getRem(32),
            [`@media screen and (max-width: ${breakpoints.m}px)`]: {
              paddingRight: 0,
            },
          },
          '&:nth-child(even)': {
            paddingLeft: getRem(32),
            [`@media screen and (max-width: ${breakpoints.m}px)`]: {
              paddingLeft: 0,
            },
          },
        },
      },
    },
  })
  .attach();

jss
  .createStyleSheet({
    // @ts-ignore
    '@font-face': [
      {
        fontFamily: 'DoveraSansNew',
        src: `url("${baseUri}/fonts/dovera-sans-new/DoveraSansNew-Light.woff2") format("woff2"),
        url("${baseUri}/fonts/dovera-sans-new/DoveraSansNew-Light.otf") format('opentype');`,
        fontWeight: 100,
      },
      {
        fontFamily: 'DoveraSansNew',
        src: `url("${baseUri}/fonts/dovera-sans-new/DoveraSansNew-Regular.woff2") format("woff2"),
        url("${baseUri}/fonts/dovera-sans-new/DoveraSansNew-Regular.otf") format('opentype');`,
        fontWeight: fontWeight.normal,
      },
      {
        fontFamily: 'DoveraSansNew',
        src: `url("${baseUri}/fonts/dovera-sans-new/DoveraSansNew-Medium.woff2") format("woff2"),
        url("${baseUri}/fonts/dovera-sans-new/DoveraSansNew-Medium.otf") format('opentype');`,
        fontWeight: fontWeight.semiBold,
      },
      {
        fontFamily: 'DoveraSansNew',
        src: `url("${baseUri}/fonts/dovera-sans-new/DoveraSansNew-Bold.woff2") format("woff2"),
        url("${baseUri}/fonts/dovera-sans-new/DoveraSansNew-Bold.otf") format('opentype');`,
        fontWeight: fontWeight.bold,
      },
      {
        fontFamily: 'DoveraSansNew',
        src: `url("${baseUri}/fonts/dovera-sans-new/DoveraSansNew-LightItalic.woff2") format("woff2"),
        url("${baseUri}/fonts/dovera-sans-new/DoveraSansNew-LightItalic.otf") format('opentype');`,
        fontWeight: 100,
        fontStyle: 'italic',
      },
      {
        fontFamily: 'DoveraSansNew',
        src: `url("${baseUri}/fonts/dovera-sans-new/DoveraSansNew-RegularItalic.woff2") format("woff2"),
        url("${baseUri}/fonts/dovera-sans-new/DoveraSansNew-RegularItalic.otf") format('opentype');`,
        fontWeight: fontWeight.normal,
        fontStyle: 'italic',
      },
      {
        fontFamily: 'DoveraSansNew',
        src: `url("${baseUri}/fonts/dovera-sans-new/DoveraSansNew-MediumItalic.woff2") format("woff2"),
        url("${baseUri}/fonts/dovera-sans-new/DoveraSansNew-MediumItalic.otf") format('opentype');`,
        fontWeight: fontWeight.semiBold,
        fontStyle: 'italic',
      },
      {
        fontFamily: 'DoveraSansNew',
        src: `url("${baseUri}/fonts/dovera-sans-new/DoveraSansNew-BoldItalic.woff2") format("woff2"),
        url("${baseUri}/fonts/dovera-sans-new/DoveraSansNew-BoldItalic.otf") format('opentype');`,
        fontWeight: fontWeight.bold,
        fontStyle: 'italic',
      },
    ],
  })
  .attach();
