import { useCallback, useState } from 'react';
import { AvailableDoctorType } from '../../../../../types/proposals.types';
import { Select, Skeleton } from '@dovera/design-system';
import strings from '../../../../../constants/strings';
import {
  formatNameStr,
  hotjarMasking,
} from '../../../../../utils/strings.utils';
import { LocalSearch } from '../../../../../components/LocalSearch';

interface Props {
  ambulanceField: any;
  doctorData: AvailableDoctorType[] | null;
  doctorField: any;
  form: any;
  isLoading: boolean;
  values: any;
}

const texts = strings.proposals.spa.new;

const Form = ({
  ambulanceField,
  doctorData,
  doctorField,
  form,
  isLoading,
  values,
}: Props) => {
  const [isLoadingAmbulance, setIsLoadingAmbulance] = useState(false);
  const doctorChange = useCallback(
    (value) => {
      setIsLoadingAmbulance(true);
      doctorField.input.onChange(value);
      const choosedDoctor = doctorData?.filter(
        (d) => d.idLekarZdravPrac === Number(value),
      )[0];
      ambulanceField.input.onChange(undefined);
      form.resetFieldState('ambulance');
      if (choosedDoctor && choosedDoctor.ambulancie.length === 1) {
        ambulanceField.input.onChange(
          choosedDoctor.ambulancie[0].idNZ.toString(),
        );
      }
      setTimeout(() => {
        setIsLoadingAmbulance(false);
      }, 500);
    },
    [ambulanceField.input, doctorData, doctorField.input, form],
  );
  const renderSelectSkeleton = (
    <div className="mb-small">
      <div className="mb-xxsmall">
        <Skeleton width={180} />
      </div>
      <Skeleton height={50} width={516} />
    </div>
  );
  const renderDoctor =
    doctorData && doctorData.length > 1 ? (
      <LocalSearch
        {...doctorField.input}
        addonsInside
        className={doctorField.input.value && 'mb'}
        id="step1-proposal-chooseDoctor"
        isRequired
        label={texts.labels.chooseDoctor}
        onChoose={doctorChange}
        options={doctorData.map((d) => ({
          value: d.idLekarZdravPrac.toString(),
          text: `${formatNameStr(d.menoLekara)}(${d.kodLekara})`,
        }))}
        placeholder="Zadajte meno, priezvisko alebo kód lekára"
        resetChoice={() => {
          if (doctorField.input.value) doctorField.input.onChange('');
        }}
      />
    ) : (
      doctorData?.length === 1 && (
        <div className="mb-small">
          <b>{`Lekár: `}</b>
          {hotjarMasking(
            formatNameStr(
              `${doctorData[0].menoLekara} (${doctorData[0].kodLekara})`,
            ),
            'b',
          )}
        </div>
      )
    );
  const renderAmbulance =
    values.doctor &&
    doctorData &&
    doctorData.some(
      (d) =>
        d.idLekarZdravPrac === Number(values.doctor) && d.ambulancie.length > 1,
    ) &&
    (isLoadingAmbulance ? (
      renderSelectSkeleton
    ) : (
      <Select
        {...ambulanceField.input}
        error={ambulanceField.meta.touched && ambulanceField.meta.error}
        id="step1-proposal-chooseAmbulance"
        isRequired
        label={texts.labels.chooseAmbulance}
        options={[
          {
            label: '',
            value: '',
          },
          ...doctorData
            .filter((d) => d.idLekarZdravPrac === Number(values.doctor))[0]
            .ambulancie.map((a) => ({
              id: `ambulance-choose--${a.idNZ}`,
              label: `${a.kodNZ} (${a.nazovNZ})`,
              value: a.idNZ.toString(),
            })),
        ]}
      />
    ));
  const renderEmail = values.doctor && doctorData && (
    <>
      <b>{`${texts.labels.email}: `}</b>
      {hotjarMasking(
        doctorData.filter(
          (d) => d.idLekarZdravPrac === Number(values.doctor),
        )[0].email || strings.undefined,
      )}
    </>
  );

  if (isLoading || !doctorData) return renderSelectSkeleton;
  return (
    <>
      {renderDoctor}
      {renderAmbulance}
      {renderEmail}
    </>
  );
};

export default Form;
