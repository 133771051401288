import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { reportsInitialState } from '../constants/reports';
import { ReportsSliceState, TabFilter } from '../types/reports.types';

const initialState: ReportsSliceState = reportsInitialState;

const reportsSlice = createSlice({
  name: 'reportsSlice',
  initialState,
  reducers: {
    setActiveTab(
      state,
      action: PayloadAction<{ filter?: TabFilter; tabId: number }>,
    ) {
      const { filter, tabId } = action.payload;

      state.virtualBudget = {
        activeTabId: tabId,
        filter: filter
          ? {
              [filter.filterId.toString()]: filter.values[0].valueId.toString(),
            }
          : undefined,
      };
    },
    setFilter(state, action: PayloadAction<{ [key: string]: string }>) {
      state.virtualBudget.filter = action.payload;
    },
  },
});

export const { setActiveTab, setFilter } = reportsSlice.actions;

export default reportsSlice.reducer;
