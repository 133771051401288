import React, { PropsWithChildren } from 'react';
import { StepperStep as StepperStepDDS } from '@dovera/design-system';
import { StepState } from '../../../types';
import useStyles from './StepperStep.styles';
import { cx } from '../../../utils/exports';

interface Props {
  activeStep: number;
  step: number;
  stepCount: number;
  stepStatus: StepState;
}

const StepperStep = ({
  activeStep,
  children,
  step,
  stepCount,
  stepStatus,
}: PropsWithChildren<Props>) => {
  const isActive = activeStep === step - 1;
  const isCompleted = stepStatus === 'completed';
  const isError = stepStatus === 'error';
  const shouldRemovePadding = activeStep === step - 2;

  const classes = useStyles({
    isError: !isActive && isError,
  });

  return (
    <StepperStepDDS
      active={isActive}
      className={cx(shouldRemovePadding ? 'no-pad' : '', classes.header)}
      completed={!isActive && (isCompleted || isError)}
      disabled={!isActive && !isCompleted && !isError}
      error={!isActive && isError}
      lastStep={step === stepCount}
      waiting={!isActive && stepStatus === 'waiting'}
      warning={!isActive && stepStatus === 'warning'}
    >
      {children}
    </StepperStepDDS>
  );
};

export default StepperStep;
