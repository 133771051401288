import { createUseStyles } from 'react-jss';
import { breakpoints, getRem } from '@dovera/design-system';

export default createUseStyles({
  staticMenuLayoutWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: `calc(100vh - ${getRem(123)})`,
  },
  staticMenuLayout: {
    minHeight: 'calc(100vh - 123px)',
    width: '100%',
    '&.without-padding': {
      '& .container': {
        padding: 0,
      },
      '& > div': {
        padding: 0,
      },
    },
    '&.white': {
      backgroundColor: '#FFFFFF',
    },
    [`@media screen and (max-width: ${breakpoints.s}px) and (max-height: 700px)`]:
      {
        minHeight: '100vh',
      },
  },
  pageSection: {
    '&.with-menu': {
      marginLeft: 340,
    },
    padding: `${getRem(32)} ${getRem(48)}`,
    paddingBottom: 0,
    height: '100%',
    '&.is-dashboard': {
      padding: `${getRem(48)}`,
    },
  },
  responsiveSection: {
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      width: '100%',
      marginLeft: '0 !important',
    },
  },
  notifications: {
    height: 'auto !important',
    width: 'auto !important',
  },
  headerDescription: {
    maxWidth: getRem(700),
    '& button': {
      marginTop: getRem(8),
      '& svg': {
        color: '#FF0000',
        marginRight: `${getRem(8)} !important`,
      },
    },
  },
});
