import {
  ButtonSecondary,
  Grid,
  GridCol,
  Icon,
  color,
} from '@dovera/design-system';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { dajZoznamTemPZS } from '../../api/schrankaSprav';
import SearchBar from '../../components/SearchBar/SearchBar';
import strings from '../../constants/strings';
import { systemConstants } from '../../constants/systemConstants';
import { breakpoints } from '../../hooks/screen-width/useScreenWidth';
import { useAppDispatch } from '../../hooks/useStore';
import { useWindowSize } from '../../hooks/useWindowSize';
import StaticMenuLayout from '../../layouts/StaticMenuLayout';
import { RootState } from '../../rootReducer';
import {
  resetSchranky,
  zmenaOffsetuVlakien,
  zobrazDetail,
  zobrazitToast,
} from '../../slices/spravy.slice';
import { LoadingState } from '../../types';
import { cx } from '../../utils/exports';
import { callThreadDetail, callThreads } from '../../utils/spravy.utils';
import LoadError from './EmptyStates/LoadError';
import NoMessages from './EmptyStates/NoMessages';
import { NewMessageModal } from './MessageForm';
import MessageTabs from './MessageTabs/MessageTabs';
import MessageThreads from './MessageThreads/MessageThreads';
import { RemoveThreadModal, RemoveThreads } from './RemoveThreads';
import useStyles from './Spravy.styles';
import ThreadDetail from './ThreadDetail/ThreadDetail';
import { useQuery } from '../../hooks/useQuery';
import { FILTER_PREFIX } from '../../components/Filter/constants';
import CardBubble from '../../components/CardBubble/CardBubble';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { Toast } from '../../components';
import { ContentWrapper } from '../../components/ContentWrapper';

const texts = strings.schrankaSprav;

const Spravy = () => {
  const dispatch = useAppDispatch();
  const windowSize = useWindowSize();
  const query = useQuery();
  const filterTheme = query.get(`${FILTER_PREFIX}_tema-spravy`);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAnotherModalVisible, setIsAnotherModalVisible] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [searchStr, setSearchStr] = useState('');
  const [isNewMessageModalVisible, setIsNewMessageModalVisible] =
    useState(false);
  const { detail, kategoria, refresh, temy, toast, vlakna } = useSelector(
    (state: RootState) => state.spravy,
  );
  const classes = useStyles({
    noMessagesInThread:
      detail.dataState === LoadingState.error ||
      (detail.dataState === LoadingState.filled && !detail?.nadpis),
  });
  const haveMessages =
    [LoadingState.none, LoadingState.fetching, LoadingState.reset].includes(
      vlakna.dataState,
    ) || vlakna.data.length;
  const hideToast = useCallback(() => {
    dispatch(zobrazitToast({ typ: '' }));
    dispatch(resetSchranky());
  }, [dispatch]);
  useEffect(() => {
    if (![LoadingState.fetching].includes(vlakna.dataState) || refresh)
      callThreads(
        refresh
          ? {}
          : {
              category: kategoria,
              offset:
                searchStr && searchStr !== vlakna.vyhladavanie
                  ? 0
                  : vlakna.offset,
              search: searchStr,
            },
      );
    // eslint-disable-next-line
  }, [kategoria, refresh, vlakna.offset, searchStr]);
  useEffect(() => {
    if (!vlakna.offset && vlakna.firstId) {
      setTimeout(() => {
        callThreadDetail({});
      }, 500);
    }
    // eslint-disable-next-line
  }, [vlakna.offset, vlakna.firstId]);
  useEffect(() => {
    dispatch(zmenaOffsetuVlakien({ offset: 0 }));
  }, [dispatch, searchStr]);
  useEffect(() => {
    if (
      isNewMessageModalVisible &&
      temy.dataState === LoadingState.none &&
      !temy.data.length
    )
      dispatch(dajZoznamTemPZS());
  }, [dispatch, isNewMessageModalVisible, temy]);
  useEffect(() => {
    const isExistsThemeFilter = !!filterTheme;
    if (
      isExistsThemeFilter &&
      !isNewMessageModalVisible &&
      vlakna.dataState === LoadingState.filled &&
      !isAnotherModalVisible
    )
      setTimeout(() => setIsNewMessageModalVisible(true), 500);
    // eslint-disable-next-line
  }, [isAnotherModalVisible, vlakna.dataState, filterTheme]);
  useEffect(() => {
    if (toast.odoslanie || toast.vymazanie || toast.obnovenie) {
      setToastMessage(
        toast.odoslanie
          ? texts.sendMessage.success
          : toast.obnovenie
            ? texts.restoreMessage
            : toast.vymazanie
              ? texts.deleteMessage(
                  vlakna.data.filter((v) => v.oznacenePreVymazanie).length || 1,
                )
              : '',
      );
      setTimeout(() => {
        dispatch(zobrazitToast({ typ: '' }));
      }, 5000);
    } else {
      setToastMessage('');
    }
    // eslint-disable-next-line
  }, [dispatch, toast]);
  const renderNewMessageBtn = (
    <ButtonSecondary
      className={classes.button}
      onClick={() => setIsNewMessageModalVisible(true)}
      size="s"
    >
      <Icon className="icon--left" name="plus" size="medium" />
      {texts.buttons.newMessage}
    </ButtonSecondary>
  );
  const renderTitle = (
    <h2 className={classes.title}>Správy{renderNewMessageBtn}</h2>
  );
  const renderNewMessageModal = (
    <NewMessageModal
      isVisible={isNewMessageModalVisible}
      onContinueForm={() => setIsNewMessageModalVisible(true)}
      onHide={(isCancelModal?: boolean) => {
        setIsAnotherModalVisible(!!isCancelModal);
        setIsNewMessageModalVisible(false);
      }}
    />
  );
  const renderThreadDetail = haveMessages ? (
    detail.dataState === LoadingState.error ? (
      <LoadError onClickLoadAgain={() => window.location.reload()} single />
    ) : (
      <CardBubble className={classes.messageCard}>
        <ThreadDetail
          onClickNewMessage={() => setIsNewMessageModalVisible(true)}
          onRemove={() => setIsModalVisible(true)}
        />
      </CardBubble>
    )
  ) : (
    <NoMessages />
  );

  const isRemoveBarVisible = useMemo(
    () =>
      vlakna.data.some((v) => v.oznacenePreVymazanie) &&
      vlakna.dataState === LoadingState.filled,
    [vlakna.data, vlakna.dataState],
  );

  const renderContent = (
    <Grid>
      <GridCol className="relative" size={{ s: 12, m: 12, xl: 6 }}>
        <div className={classes.topWrapper}>
          {renderTitle}
          <SearchBar
            debounce
            onSearch={(str) => setSearchStr(str)}
            placeholder="Vyhľadať v správach"
            refresh={refresh}
            withoutLabel
            withoutTooltip
          />
          <MessageTabs />
        </div>
        <MessageThreads
          isRemoveBarVisible={isRemoveBarVisible}
          loadMore={() => {
            if (
              ![LoadingState.fetching, LoadingState.reset].includes(
                vlakna.dataState,
              )
            ) {
              dispatch(
                zmenaOffsetuVlakien({
                  offset:
                    vlakna.offset + systemConstants.MAX_MESSAGES_IN_THREAD,
                }),
              );
            }
          }}
        />
        <RemoveThreads
          onRemove={() => setIsModalVisible(true)}
          shouldHide={!isRemoveBarVisible}
        />
        {!haveMessages && (
          <div className="hide-xl">
            <NoMessages />
          </div>
        )}
      </GridCol>
      <GridCol size={{ s: 12, m: 12, xl: 6 }}>
        <div
          className={cx(
            classes.detailWrapper,
            windowSize.width >= breakpoints.xl &&
              (!haveMessages || detail.dataState === LoadingState.error) &&
              classes.detailWithBorder,
            windowSize.width < breakpoints.xl && classes.mobileDetailWrapper,
            detail.zobrazit && 'active',
          )}
        >
          {windowSize.width < breakpoints.xl && (
            <button
              className={classes.navigateToList}
              onClick={() => dispatch(zobrazDetail({ otvorit: false }))}
              type="button"
            >
              <Icon color={color('black')} name="chevron-right" size="medium" />
              <span>Správy</span>
            </button>
          )}
          {renderThreadDetail}
        </div>
      </GridCol>
    </Grid>
  );
  const renderToast = (
    <Toast
      iconType="success"
      onHide={hideToast}
      show={toast.odoslanie || toast.vymazanie || toast.obnovenie}
      text={toastMessage}
    />
  );
  return (
    <StaticMenuLayout hideEPNotifications withoutWarmBackgroundEffect>
      <ContentWrapper className="no-mrg" id="dlek-messages">
        <div className={classes.breadcrumbs}>
          <Breadcrumbs items={[{ name: texts.title }]} />
        </div>
        {renderToast}
        {vlakna.dataState === LoadingState.error ? (
          <LoadError onClickLoadAgain={() => window.location.reload()} />
        ) : (
          renderContent
        )}
        <RemoveThreadModal
          isVisible={isModalVisible}
          onHide={() => setIsModalVisible(false)}
          onRemoved={() => {
            setIsModalVisible(false);
          }}
        />
        {renderNewMessageModal}
      </ContentWrapper>
    </StaticMenuLayout>
  );
};

export default Spravy;
