import { ReactNode, useEffect, useState } from 'react';
import useStyles from './RemainingChars.styles';
import { systemConstants } from '../../constants/systemConstants';
import { cx } from '../../utils/exports';

interface Props {
  alignWithLabel?: boolean;
  formHelper?: string | ReactNode;
  formLabel?: string | ReactNode;
  maxLength?: number;
  valueLength: number;
}

/**
 * Komponent pre zobrazenie zostavajuceho poctu znakov v textovom poli.
 * @date 7. 3. 2023 - 10:35:35
 *
 * @param {Props} {
  formHelper, // pouzivat, ak textove pole obsahuje help property
  formLabel, // pouzivat, iba ak textove pole neobsahuje help property
  maxLength,
  valueLength,
}
 * @returns {*}
 */

const RemainingChars = ({
  alignWithLabel,
  formHelper,
  formLabel,
  maxLength,
  valueLength,
}: Props) => {
  const classes = useStyles();
  const max = maxLength || systemConstants.MAX_TEXTAREA_LETTERS;
  const [remainingStr, setRemainingStr] = useState<string>(
    `${valueLength}/${max}`,
  );
  const isMaxReached = valueLength === max;

  useEffect(() => {
    setRemainingStr(`${valueLength}/${max}`);
  }, [max, valueLength]);

  if (formHelper)
    return (
      <div className={classes.helper}>
        {formHelper}
        <div className={cx('text-right', alignWithLabel && 'align-with-label')}>
          <small
            className={`no-mrg-bottom text-color-${
              isMaxReached ? 'error' : 'black'
            }`}
          >
            {remainingStr}
          </small>
        </div>
      </div>
    );
  return (
    <div className={cx(classes.helper, alignWithLabel && 'align-with-label')}>
      <span>{formLabel || ''} </span>
      <small className={`no-mrg-bottom ${isMaxReached && 'text-color-error'}`}>
        {remainingStr}
      </small>
    </div>
  );
};

export default RemainingChars;
