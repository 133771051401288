import { Pagination, TableApp } from '@dovera/design-system';
import { useState } from 'react';
import { BankovyUcet } from '../../../types/profil.types';
import { formatIBAN, hotjarMasking } from '../../../utils/strings.utils';
import { formatDate } from '../../../utils/date.utils';
import strings from '../../../constants/strings';

const { labels } = strings.profile.bankAccounts;

interface Props {
  accounts: BankovyUcet[][];
}

const Table = ({ accounts }: Props) => {
  const [page, setPage] = useState(0);
  return (
    <TableApp
      caption=""
      className="table-caption--hide"
      columns={[
        {
          accessor: 'col1',
          Header: labels.iban,
        },
        {
          accessor: 'col2',
          Header: labels.validity,
        },
        {
          accessor: 'col3',
          Header: labels.note,
        },
      ]}
      data={accounts[page].map((d) => ({
        col1: <div>{hotjarMasking(formatIBAN(d.IBAN || '-'))}</div>,
        col2: <div>{formatDate(d.datumOd)}</div>,
        col3: <div>{d.poznamka ? d.poznamka : '-'}</div>,
      }))}
      // @ts-ignore
      pagination={
        <Pagination
          currentPage={page + 1}
          onChange={(page) => setPage(page - 1)}
          totalPages={accounts.length}
        />
      }
    />
  );
};

export default Table;
