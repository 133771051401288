import React, { useMemo, useState } from 'react';
import { ChartData } from './types';
import useStyles from './styles';
import { getSize } from './utils';
import { CircleEl, LabelEl } from './partial';

interface Props {
  availableSideChartHover?: boolean;
  chartSize?: number;
  strokeWidth?: number;
  values: ChartData[] | null;
}

const MAX_CHART_SIZE = 180;

const RadialChart = ({
  availableSideChartHover,
  chartSize,
  strokeWidth = 8,
  values,
}: Props) => {
  const classes = useStyles({ isDefinedSize: !!chartSize });

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [hoveredChart, setHoveredChart] = useState<number | null>(null);

  const getChart = useMemo(
    () =>
      values?.map((v, chartIndex) => {
        let hoveredValues = {
          color: '',
          hoveredColor: '',
          value: 0,
          title: '',
        };
        const size = getSize(chartIndex, chartSize || MAX_CHART_SIZE);
        if (hoveredChart === chartIndex && hoveredIndex !== null)
          hoveredValues = {
            color: values[hoveredChart].data[hoveredIndex].color,
            hoveredColor:
              values[hoveredChart].data[hoveredIndex].hoveredColor || '',
            value: values[hoveredChart].data[hoveredIndex].value,
            title: values[hoveredChart].data[hoveredIndex].title || '',
          };
        return (
          <svg
            key={`svg--${v.name}-${chartIndex}`}
            className={classes.chart}
            height={chartSize ? size : '100%'}
            onMouseLeave={() => setHoveredChart(null)}
            onMouseOver={() => setHoveredChart(chartIndex)}
            viewBox={`0 0 ${size} ${size}`}
            width={chartSize ? size : '100%'}
          >
            {v.data.map((d, index) => (
              <React.Fragment key={`${d.color}-${d.value}`}>
                <CircleEl
                  classes={classes}
                  groupsCount={v.data.length}
                  index={index}
                  indexChart={chartIndex}
                  isBackground
                  size={size}
                  strokeWidth={strokeWidth}
                />
                <CircleEl
                  classes={classes}
                  color={d.color}
                  colorHovered={d.hoveredColor}
                  groupsCount={v.data.length}
                  hoveredChartIndex={hoveredChart}
                  hoveredIndex={hoveredIndex}
                  index={index}
                  indexChart={chartIndex}
                  onHovered={(index) => {
                    if (chartIndex === 0 || availableSideChartHover)
                      setHoveredIndex(index);
                  }}
                  size={size}
                  strokeWidth={strokeWidth}
                  value={d.value}
                />
              </React.Fragment>
            ))}
            <LabelEl
              color={hoveredValues.color}
              defaultValue={v.value}
              hoveredChartIndex={hoveredChart}
              hoveredIndex={hoveredIndex}
              indexChart={chartIndex}
              isMain
              value={hoveredValues.value}
            />
            <LabelEl
              defaultValue={v.name}
              hoveredChartIndex={hoveredChart}
              hoveredIndex={hoveredIndex}
              indexChart={chartIndex}
              value={hoveredValues.title}
            />
          </svg>
        );
      }),
    [
      availableSideChartHover,
      chartSize,
      classes,
      hoveredChart,
      hoveredIndex,
      strokeWidth,
      values,
    ],
  );

  return (
    <div
      className={
        values?.length === 1 ? classes.chartWrapperSingle : classes.chartWrapper
      }
    >
      {getChart}
    </div>
  );
};

export default RadialChart;
