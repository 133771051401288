import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import routes from '../routes';
import {
  fetchKapitacneDohody,
  fetchKapitacneKonflikty,
  saveDocumentOZS,
  saveKapitacnyUbytok,
} from '../api/kapitacie';
import _ from 'lodash';
import {
  DajKapitacneDohodyResponse,
  DajKapitacneKonfliktyResponse,
  KapitacieState,
  KapitacnaDohoda,
  KapitacnyKonflikt,
  UlozDokumentDohodyOZSResponse,
  UlozKapitacnyUbytokResponse,
} from '../types/kapitacie.types';
import { TABLE_PAGINATION } from '../constants/misc';
import strings from '../constants/strings';
import { stringWithSpaces } from '../utils/strings.utils';
import { KAP_LIMIT } from '../constants/kapitacie';
import { mergeMultidimensionalArray, uniqueArray } from '../utils/array.utils';

export const initialState: KapitacieState = {
  error: null,
  isLoaded: false,
  isLoading: false,
  kapitacneDohody: {
    allCount: 0,
    countPages: 0,
    doctors: [],
    filter: {
      agreement: null,
      doctor: '',
      insuree: '',
    },
    offset: 0,
    pages: null,
    remainingLazyLoadCalls: 0,
  },
  kapitacneKonflikty: {
    data: null,
    filter: {
      doctor: '',
      insuree: '',
    },
    pages: null,
  },
  pageType: 'SPOR',
  resetKAP: false,
  submitForm: {
    action: 'V',
    error: null,
    isLoading: false,
    success: false,
    visibleToast: false,
  },
  tablePage: 0,
};

const kapitacieSlice = createSlice({
  name: 'kapitacie',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchKapitacneDohody.pending.toString(), (state) => ({
        ...initialState,
        isLoading: true,
        pageType: 'KAP',
        kapitacneDohody: state.kapitacneDohody,
      }))
      .addCase(
        fetchKapitacneDohody.fulfilled.toString(),
        (state, action: PayloadAction<DajKapitacneDohodyResponse>) => {
          const currentOffset = state.kapitacneDohody.offset;
          const data =
            state.kapitacneDohody.pages && currentOffset
              ? [
                  ...mergeMultidimensionalArray(state.kapitacneDohody.pages),
                  ...action.payload.kapitacneDohody,
                ]
              : action.payload.kapitacneDohody || [];
          const pages: KapitacnaDohoda[][] | null =
            _.chunk(data, TABLE_PAGINATION) || null;

          const doctors = state.kapitacneDohody.doctors?.length
            ? state.kapitacneDohody.doctors
            : uniqueArray(
                action.payload.lekari?.map((d) => ({
                  celeMeno: d.celeMeno,
                  kod: d.kod,
                })),
              ).map((a) => ({
                ...a,
                lekarId:
                  action.payload.lekari?.find(
                    (l) => l.kod === a.kod && l.celeMeno === a.celeMeno,
                  )?.lekarId || 0,
              }));
          return {
            ...state,
            isLoaded: true,
            isLoading: false,
            kapitacneDohody: {
              ...state.kapitacneDohody,
              allCount:
                state.kapitacneDohody.allCount ||
                action.payload.celkovyPocetKapitacii,
              countPages: Math.ceil(
                action.payload.celkovyPocetKapitacii / TABLE_PAGINATION,
              ),
              doctors,
              offset: state.kapitacneDohody.remainingLazyLoadCalls
                ? currentOffset + KAP_LIMIT
                : currentOffset,
              pages,
              remainingLazyLoadCalls:
                state.kapitacneDohody.remainingLazyLoadCalls > 0
                  ? state.kapitacneDohody.remainingLazyLoadCalls - 1
                  : 0,
            },
          };
        },
      )
      .addCase(fetchKapitacneDohody.rejected.toString(), (state) => ({
        ...state,
        error: strings.vseobecne.ziadneData,
        isLoaded: true,
        isLoading: false,
        kapitacneDohody: initialState.kapitacneDohody,
      }))
      .addCase(fetchKapitacneKonflikty.pending.toString(), () => ({
        ...initialState,
        isLoading: true,
        pageType: 'SPOR',
      }))
      .addCase(
        fetchKapitacneKonflikty.fulfilled.toString(),
        (state, action: PayloadAction<DajKapitacneKonfliktyResponse>) => {
          const data = action.payload.kapitacneKonflikty || [];
          const pages: KapitacnyKonflikt[][] | null = _.chunk(
            data,
            TABLE_PAGINATION,
          );
          return {
            ...state,
            isLoaded: true,
            isLoading: false,
            kapitacneKonflikty: {
              ...state.kapitacneKonflikty,
              data,
              pages,
            },
          };
        },
      )
      .addCase(fetchKapitacneKonflikty.rejected.toString(), (state) => ({
        ...state,
        error: strings.vseobecne.ziadneData,
        isLoaded: true,
        isLoading: false,
        kapitacneKonflikty: {
          ...initialState.kapitacneKonflikty,
        },
      }))
      .addCase(saveKapitacnyUbytok.pending.toString(), (state) => ({
        ...state,
        submitForm: {
          ...initialState.submitForm,
          action: 'V',
          error: null,
          isLoading: true,
        },
      }))
      .addCase(
        saveKapitacnyUbytok.fulfilled.toString(),
        (state, action: PayloadAction<UlozKapitacnyUbytokResponse>) => ({
          ...state,
          submitForm: {
            action: 'V',
            error: action.payload.chyba,
            isLoading: false,
            success: true,
            visibleToast: true,
          },
        }),
      )
      .addCase(saveKapitacnyUbytok.rejected.toString(), (state) => ({
        ...state,
        submitForm: {
          ...initialState.submitForm,
          error: strings.defaultError,
          isLoading: false,
        },
      }))
      .addCase(saveDocumentOZS.pending.toString(), (state) => ({
        ...state,
        submitForm: {
          ...initialState.submitForm,
          action: 'P',
          error: null,
          isLoading: true,
        },
      }))
      .addCase(
        saveDocumentOZS.fulfilled.toString(),
        (state, action: PayloadAction<UlozDokumentDohodyOZSResponse>) => ({
          ...state,
          submitForm: {
            action: 'P',
            error: action.payload.chyba,
            isLoading: false,
            success: true,
            visibleToast: true,
          },
        }),
      )
      .addCase(saveDocumentOZS.rejected.toString(), (state) => ({
        ...state,
        submitForm: {
          ...initialState.submitForm,
          error: strings.defaultError,
          isLoading: false,
        },
      }));
  },
  reducers: {
    changeCapType(state, action: PayloadAction<{ url: string }>) {
      return {
        ...state,
        pageType:
          action.payload.url === routes.kapitovaniPoistenci ? 'KAP' : 'SPOR',
      };
    },
    filterKap(
      state,
      action: PayloadAction<{
        agreement?: 'P' | 'N' | string | null;
        doctor?: string | null;
        insuree?: string;
      }>,
    ) {
      const { agreement, doctor, insuree } = action.payload;
      const stateFilter = state.kapitacneDohody.filter;
      const agreementFilter =
        agreement !== undefined ? agreement : stateFilter.agreement;
      const doctorFilter =
        doctor !== undefined ? action.payload.doctor : stateFilter.doctor;
      const insureeFilter =
        insuree !== undefined ? insuree : stateFilter.insuree;
      const filterObj = {
        ...state.kapitacneDohody.filter,
        agreement: agreementFilter,
        doctor: doctorFilter,
        insuree: insureeFilter,
      };
      if (
        JSON.stringify(state.kapitacneDohody.filter) ===
        JSON.stringify(filterObj)
      )
        return state;
      return {
        ...state,
        kapitacneDohody: {
          ...state.kapitacneDohody,
          filter: filterObj,
          offset: 0,
          pages: initialState.kapitacneDohody.pages,
          remainingLazyLoadCalls: 0,
        },
      };
    },
    filterSpor(
      state,
      action: PayloadAction<{
        data: {
          agreement?: 'P' | 'N' | string | null;
          doctor?: string;
          insuree?: string;
        };
      }>,
    ) {
      const { data } = action.payload;
      const sporFilter: { doctor?: string; insuree?: string } = {
        doctor:
          data.doctor !== undefined
            ? action.payload.data.doctor
            : state.kapitacneKonflikty?.filter.doctor,
        insuree:
          data.insuree !== undefined
            ? action.payload.data.insuree
            : state.kapitacneKonflikty?.filter.insuree,
      };
      const filterState: {
        agreement?: 'P' | 'N' | string | null;
        doctor?: string;
        insuree?: string;
      } = sporFilter;
      return {
        ...state,
        kapitacneKonflikty: {
          ...state.kapitacneKonflikty,
          filter: filterState,
          pages:
            _.chunk(
              state.kapitacneKonflikty.data?.filter(
                (d) =>
                  (!filterState.doctor ||
                    filterState.doctor === 'all' ||
                    `${d.ckodLekarVlastny} - ${stringWithSpaces([
                      d.titulPred,
                      d.lekarMenoVlastny,
                      `${d.lekarPriezviskoVlastny}${d.titulZa ? ',' : ''}`,
                      d.titulZa,
                    ])}`
                      .toLowerCase()
                      .includes(filterState.doctor.toLowerCase())) &&
                  (!filterState.insuree ||
                    (filterState.insuree &&
                      (d.poistenecMeno
                        .toLowerCase()
                        .includes(filterState.insuree.toLowerCase()) ||
                        d.poistenecPriezvisko
                          .toLowerCase()
                          .includes(filterState.insuree.toLowerCase()) ||
                        filterState.insuree
                          .toLowerCase()
                          .includes(
                            `${d.poistenecMeno} ${d.poistenecPriezvisko}`.toLowerCase(),
                          ) ||
                        d.poistenecRc
                          .toLowerCase()
                          .includes(filterState.insuree.toLowerCase())))),
              ),
              TABLE_PAGINATION,
            ) || null,
        },
        tablePage: 0,
      };
    },
    changeTablePage(state, action: PayloadAction<{ pageNumber: number }>) {
      return {
        ...state,
        tablePage: action.payload.pageNumber,
      };
    },
    resetSubmitState(state) {
      return {
        ...state,
        submitForm: {
          ...state.submitForm,
          error: null,
          success: false,
        },
      };
    },
    resetKAPAction(state) {
      return {
        ...initialState,
        kapitacneDohody: {
          ...initialState.kapitacneDohody,
          filter: state.kapitacneDohody.filter,
        },
        resetKAP: true,
      };
    },
    setRemainingLazyLoadCalls(
      state,
      action: PayloadAction<{ remaining: number }>,
    ) {
      const { remaining } = action.payload;
      return {
        ...state,
        kapitacneDohody: {
          ...state.kapitacneDohody,
          offset: KAP_LIMIT,
          remainingLazyLoadCalls: remaining >= 0 ? remaining : 0,
        },
      };
    },
  },
});

export const {
  changeCapType,
  changeTablePage,
  filterKap,
  filterSpor,
  resetKAPAction,
  resetSubmitState,
  setRemainingLazyLoadCalls,
} = kapitacieSlice.actions;
export default kapitacieSlice.reducer;
