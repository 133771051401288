import axios, { AxiosResponse } from 'axios';
import {
  GetMenuItemsResponseSK,
  GetReportDataResponseSK,
  GetReportStructureResponseSK,
  GetReportWidgetsResponseSK,
} from '../translations/apiTypes/reports.types';
import {
  GetMenuItemsQueries,
  GetMenuItemsResponse,
  GetReportDataQueries,
  GetReportDataResponse,
  GetReportStructureQueries,
  GetReportStructureResponse,
  GetReportWidgetsQueries,
  GetReportWidgetsResponse,
} from '../types/reports.types';
import { createQueryParams } from '../utils/api.utils';
import {
  convertGetMenuItems,
  convertGetReportData,
  convertGetReportStructure,
  convertGetReportWidgets,
} from '../translations/apiResponses/reports';
import { convertPayloadToSK } from '../translations/utils';
import store from '../store';
import { setActiveTab } from '../slices/reports.slice';

const API_CONTROLLER = `Reporty`;
const API_URL = `${window.env?.config?.apiPath}/${API_CONTROLLER}/`;

export const getMenuItems = async (
  queries: GetMenuItemsQueries,
): Promise<GetMenuItemsResponse> => {
  const { data }: AxiosResponse<GetMenuItemsResponseSK> = await axios.get(
    `${API_URL}DajPolozkyMenu${createQueryParams({ withoutEmpty: false, queryParams: { ...convertPayloadToSK(queries) } })}`,
  );
  return convertGetMenuItems(data);
};

export const getReportStructure = async (
  queries: GetReportStructureQueries,
): Promise<GetReportStructureResponse> => {
  const { data }: AxiosResponse<GetReportStructureResponseSK> = await axios.get(
    `${API_URL}DajStrukturuReportu${createQueryParams({ withoutEmpty: false, queryParams: { ...convertPayloadToSK(queries) } })}`,
  );
  const response = convertGetReportStructure(data);
  store.dispatch(setActiveTab({ tabId: response.report.tabs[0].id }));
  return response;
};

export const getReportData = async (
  queries: GetReportDataQueries,
): Promise<GetReportDataResponse> => {
  const { data }: AxiosResponse<GetReportDataResponseSK> = await axios.get(
    `${API_URL}DajDataReportu${createQueryParams({ withoutEmpty: true, queryParams: { ...convertPayloadToSK(queries) } })}`,
  );
  return convertGetReportData(data);
};

export const getReportWidgets = async (
  queries: GetReportWidgetsQueries,
): Promise<GetReportWidgetsResponse> => {
  const { data }: AxiosResponse<GetReportWidgetsResponseSK> = await axios.get(
    `${API_URL}DajWidgetyReportov${createQueryParams({ withoutEmpty: true, queryParams: { ...convertPayloadToSK(queries) } })}`,
  );
  return convertGetReportWidgets(data);
};
