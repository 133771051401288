export type CheckType =
  | 'Dlznik'
  | 'DlznikVociCSParNeodklZS'
  | 'PoistnyVztah'
  | 'IsVekPoi'
  | 'UzJeNeskoro'
  | 'EsteJePriskoro'
  | 'Sprievodca'
  | 'CerpanieKNsPeriod'
  | 'CerpanieKNsJednoraz'
  | 'ExistSchvaleneRozhodnutie'
  | 'NenajdenaHospitalizacia'
  | 'NenajdenaOperacia'
  | 'NedostPocetNavstChronik'
  | 'NedostPocetNavstSusLiecbu'
  | 'ExistujeProdukt'
  | 'JeKategorizovany'
  | 'DatumPlatnostiOd'
  | 'Diagnoza'
  | 'MaOdbornost'
  | 'MaSekciuPreProdukt'
  | 'ProduktVekPoi'
  | 'PohlaviePoi'
  | 'ExistDuplicita'
  | 'JePlatny'
  | 'JeCnp'
  | 'JeNavrh'
  | 'MaMV'
  | 'MaKontraindikaciu'
  | 'JeNutnyNavrh'
  | 'TypLiecby'
  | 'Mnozstvo';

export type EventType =
  | 'Diagnostika'
  | 'Hospitalizacia'
  | 'Operacia'
  | 'UkoncenieLiecby'
  | 'Chronik'
  | 'SustavnaLiecba';

export type LocalControls =
  | 'spaConfirmation'
  | 'spaContraindications'
  | 'spaExaminations'
  | 'spaSVLZ';

export enum PROPOSAL_TYPES {
  CNP = 'CNP',
  Drugs = 'LN',
  SPA = 'KN',
}

export enum PROPOSAL_STATES {
  Approved = 'Schválený',
  InProgress = 'Spracováva sa',
  Notapproved = 'Neschválený',
  ToSupplement = 'Na doplnenie',
}

export enum PROPOSAL_STATE_VALUES {
  Approved = 'S',
  InProgress = 'E',
  Notapproved = 'N',
  ToSupplement = 'D',
}

export type StepStatus = 'completed' | 'error' | 'warning' | null;

export type ProposalCheck = {
  additionalInfo?: {
    value1: string;
    value2: string;
    value3: string;
    value4: string;
    value5: string;
    value6: string;
  } | null;
  checkType: CheckType;
  description: string;
  errorCode: string;
  errorWeight: 'E' | 'I' | string;
  result: 'OK' | 'NOK';
};

export enum Result {
  NOK = 'NOK',
  OK = 'OK',
}

export type ProposalPOIData = {
  address: string;
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  mobile: string;
  sex: 'M' | 'Z' | string;
};

export type AmbulanceType = {
  idNZ: number;
  kodNZ: string;
  nazovNZ: string;
  odbornostNZ: string;
  typUvazku: string;
};

export type EscortType = {
  kod: string;
  popis: string;
};

export type AvailableDoctorType = {
  ambulancie: AmbulanceType[];
  email: string;
  idLekarZdravPrac: number;
  kodLekara: string;
  menoLekara: string;
  odbornostLekara: string;
};

export type ProposalDiagnose = {
  kod: string;
  kodSkupinaDiagnoz?: number;
  nazov: string;
};

export type NewProposalStep1 = {
  ambulance: {
    code: string;
    expertise: string;
    id: number;
    name: string;
  };
  doctor: {
    code: string;
    email: string;
    expertise: string;
    id: number;
    name: string;
  };
  stepStatus: StepStatus;
};

export interface CheckProposalDataPayload {
  idLekarZdravPrac?: number;
  isDg?: {
    datumUdalosti?: string;
    kodDiagnoza?: string | null;
    kodIndikacnaSkupina?: string | null;
    kodOdbNavrhujucaNZ?: string;
    kodOdbNavrhujuciLek?: string;
    kodVedlajsiaDiagnoza?: string | null;
  } | null;
  kontrolaTypy: CheckType[];
  mnozstvo?: number;
  odbornostLek?: string;
  odbornostNZ?: string;
  rodneCislo?: string;
  sprievodca?: {
    rozhodnutie: boolean;
    sprievodcaDovod: string;
  } | null;
}

export interface GetFormDataResponse {
  dovodySprievodu: EscortType[];
  udajDetIndSkup: {
    diagnozy: string;
    epikriza: string;
    id: number;
    kodIndikacnaSkupina: string;
    kodMieraUhradyZS: string;
    kodProduktPZS: string;
    kontraIndikacie: string;
    nazov: string;
    objektivnyNalez: string;
    pocetDniKupel: number;
    trvanieKupel: number;
    vekDo: number;
    vekOd: number;
  };
  udajParUdalIndSkup: {
    drgVykony: string;
    id: number;
    kodIndikacnaSkupina: string;
    kodTypCasjednotky: number;
    perioda: number;
    udalost: EventType;
    udalostDo: number | string;
    udalostOd: number | string;
  };
}

export interface GetListOfAvailableDoctorsPayload {}

export interface GetListOfAvailableDoctorsResponse {
  zoznamDostupnychLekarov: AvailableDoctorType[];
}

export interface GetDiagnosesListPayload {
  hladanaDiagnoza: string;
  kodIndikacnaSkupina?: string | null;
  odbornostLek?: string;
  odbornostNZ?: string;
}

export interface GetDiagnosesListReponse {
  diagnozy: ProposalDiagnose[];
}

export enum FormVariant {
  Error = 'error',
  Success = 'success',
}

export type ProposalRadioType = 'Áno/Nie' | 'Nemá/Má' | 'Má/Nemá';

export type ProposalsFilter = {
  dateFrom?: string;
  dateTo?: string;
  proposalState?: string;
  search?: string;
};
