import { Grid, GridCol } from '@dovera/design-system';
import { ReactNode } from 'react';

interface Props {
  label: ReactNode | string;
  value: ReactNode | string;
}

const CardRow = ({ label, value }: Props) => (
  <Grid className="mb-small">
    <GridCol size={{ s: 6, xs: 12 }}>{label}</GridCol>
    <GridCol size={{ s: 6, xs: 12 }}>{value}</GridCol>
  </Grid>
);

export default CardRow;
