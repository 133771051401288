import { Loader, Notification } from '@dovera/design-system';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { CheckType, Result } from '../../../../../types/proposals.types';
import SafeHtml from '../../../../../components/SafeHtml/SafeHtml';
import {
  getMessageAssignedToCheckType,
  isCheckWithSameMessage,
} from '../../../../../utils/drugProposals.utils';
import {
  DEFAULT_PRODUCT_CODE,
  checkWithNoMessage,
} from '../../../../../constants/drugProposals';
import useStyles from '../../../Proposals.styles';

interface CheckNotificationsProps {
  checkTypes: CheckType[];
  notLoadingTypes?: CheckType[];
}

const CheckNotifications = ({
  checkTypes,
  notLoadingTypes,
}: CheckNotificationsProps) => {
  const classes = useStyles({ proposalType: 'LN' });
  const {
    data: { controls },
    stepper: {
      step3: { product },
    },
  } = useSelector((state: RootState) => state.drugProposals.new);

  const shouldDisplay = (checkType: CheckType): boolean => {
    const debtorArr: CheckType[] = ['Dlznik', 'DlznikVociCSParNeodklZS'];
    if (
      (checkType === 'Dlznik' || checkType === 'DlznikVociCSParNeodklZS') &&
      !debtorArr.every((el) =>
        controls?.data
          ?.filter((c) => c.result === 'NOK')
          ?.map((c) => c.checkType)
          ?.includes(el),
      )
    )
      return false;
    if (
      isCheckWithSameMessage(controls?.data) &&
      checkType === 'DlznikVociCSParNeodklZS'
    )
      return false;
    if (!checkTypes.includes(checkType)) return false;
    if (checkWithNoMessage.includes(checkType)) return false;

    return true;
  };

  if (
    controls.isLoadingControl?.some(
      (c) => checkTypes.includes(c) && !notLoadingTypes?.includes(c),
    )
  )
    return (
      <>
        <div className="mb" /> <Loader className="mb-small" size="large" />
      </>
    );

  return controls?.data
    .filter(
      (i) =>
        i.result === Result.NOK &&
        shouldDisplay(i.checkType) &&
        !(
          product?.code === DEFAULT_PRODUCT_CODE &&
          i.checkType === 'JeNutnyNavrh'
        ),
    )
    .map(
      (item) =>
        getMessageAssignedToCheckType(item.checkType) && (
          <Notification
            className={classes.ntf}
            message={
              <SafeHtml html={getMessageAssignedToCheckType(item.checkType)} />
            }
            variant={item.checkType === 'JeNutnyNavrh' ? 'blue' : 'error'}
          />
        ),
    );
};

export default CheckNotifications;
