import { color } from '@dovera/design-system';

interface Props {
  active?: boolean;
  id: string;
}

const IconTarget = ({ active, id }: Props) => (
  <svg
    fill="none"
    height="32"
    id={id}
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="16"
      cy="16"
      fill={active ? color('success', 100) : '#C4C4C4'}
      rx="16"
      ry="16"
    />
    <g clipPath="url(#clip0_304_10483)">
      <path
        d="M16 24.3333C20.6024 24.3333 24.3333 20.6024 24.3333 16C24.3333 11.3976 20.6024 7.66667 16 7.66667C12.117 7.66667 8.8543 10.3224 7.92921 13.9167C7.75782 14.5825 7.66667 15.2806 7.66667 16C7.66667 20.6024 11.3976 24.3333 16 24.3333Z"
        stroke="#0F996E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M11.8317 18.7648C12.7275 20.1121 14.2593 21 15.9984 21C18.7598 21 20.9984 18.7614 20.9984 16C20.9984 13.2386 18.7598 11 15.9984 11C14.1477 11 12.5318 12.0055 11.6673 13.5"
        stroke="#0F996E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M16 17.6667C16.9205 17.6667 17.6667 16.9205 17.6667 16C17.6667 15.0795 16.9205 14.3333 16 14.3333"
        stroke="#0F996E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_304_10483">
        <rect fill="white" height="20" transform="translate(6 6)" width="20" />
      </clipPath>
    </defs>
  </svg>
);

export default IconTarget;
