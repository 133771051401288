import { getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  phoneNum: {
    minWidth: getRem(150),
  },
  email: {
    minWidth: getRem(250),
  },
  nameRole: {
    minWidth: getRem(200),
  },
});
