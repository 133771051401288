import { useCallback } from 'react';
import { useAppDispatch } from '../../../../../hooks/useStore';
import { fetchObsahDokumentu } from '../../../../../slices/dokumenty.slice';
import { ButtonLink, Icon, Notification } from '@dovera/design-system';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { useFetchProposalDocuments } from '../../../../../api/drugProposals';
import strings from '../../../../../constants/strings';

const texts = strings.proposals.drugs.detail;

interface Props {
  proposalId: number;
  proposalNumber: string;
}

const DisapprovedProposal = ({ proposalId, proposalNumber }: Props) => {
  const dispatch = useAppDispatch();
  const fetchedDocuments = useSelector(
    (state: RootState) => state.dokumenty.dokumenty,
  );
  const documents = useFetchProposalDocuments({ proposalId, proposalNumber });
  const getDocument = useCallback(
    (document: string, barCode: boolean) => {
      if (document)
        dispatch(
          fetchObsahDokumentu(
            barCode
              ? { dms: { ciarovyKod: document } }
              : { navrh: { idNavrhNaZS: proposalId, overovaciKod: document } },
            true,
            false,
          ),
        );
    },
    [dispatch, proposalId],
  );
  return (
    <div className="fit-content pad-bottom-small">
      <Notification
        message={
          <div className="content-center-vert">
            <span>{texts.notificaions.disapproved.description}</span>
            {documents.data?.documents
              .filter((d) => d.name.includes('NESCHV'))
              .map((d) => {
                const documentCode: string = d.barCode || d.validatedCode || '';
                return (
                  <ButtonLink
                    className="inline-btn text-color-error"
                    isLoading={fetchedDocuments[documentCode]?.isLoading}
                    onClick={() => getDocument(documentCode, !!d.barCode)}
                  >
                    <Icon
                      className="text-space-half-left text-space-half-right"
                      name="download"
                      size="medium"
                    />
                    {texts.notificaions.disapproved.button}
                  </ButtonLink>
                );
              })}
          </div>
        }
        variant="error"
      />
    </div>
  );
};

export default DisapprovedProposal;
