import React, { ReactNode } from 'react';
import { Icon } from '@dovera/design-system';
import { cx } from '../../utils/exports';

import useStyles from './AlignIconWithComponent.styles';
import { CustomTooltip } from '../CustomTooltip';

type AlignIconWithComponentType = {
  component: ReactNode;
  displayCloseIcon?: boolean;
  icon?: ReactNode | null;
  iconTooltip?: ReactNode | string;
  id: string;
  noPadding?: boolean;
  onCloseClick?: () => void;
  smallPadding?: boolean;
} & React.JSX.IntrinsicElements['div'];

const AlignIconWithComponent = ({
  component,
  displayCloseIcon,
  icon,
  iconTooltip,
  id,
  noPadding,
  onCloseClick,
  smallPadding,
}: AlignIconWithComponentType) => {
  const classes = useStyles();

  const renderIcon = (): ReactNode => {
    if (!icon) return <span />;
    if (iconTooltip)
      return (
        <CustomTooltip dialog={iconTooltip} id={`${id}--tooltip`}>
          <span className={classes.icon}>{icon}</span>
        </CustomTooltip>
      );
    return <span className={classes.icon}>{icon}</span>;
  };

  return (
    <div className={classes.root}>
      {renderIcon()}
      <span
        className={cx(
          classes.component,
          noPadding && classes.noPadding,
          smallPadding && classes.smallPadding,
        )}
      >
        {component}
        {displayCloseIcon && (
          <button
            className={classes.closeIcon}
            onClick={onCloseClick}
            type="button"
          >
            <Icon name="close" />
          </button>
        )}
      </span>
    </div>
  );
};

export default AlignIconWithComponent;
