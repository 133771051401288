import strings from '../constants/strings';
import { StavyKampani } from '../constants/zmluvy';
import { formatDate, getMoment } from './date.utils';

export const getTextSignToDate = (date: string): string => {
  if (!date) return strings.undefined;
  const countDays: number = getMoment(
    getMoment(date).format('YYYY-MM-DD'),
  ).diff(getMoment(getMoment().format('YYYY-MM-DD')), 'days');
  if (countDays < 0) return formatDate(date);
  if (countDays === 0) return 'Dnes';
  if (!countDays) return '';
  if (countDays === 1) return 'Zostáva 1 deň';
  if (countDays > 1 && countDays < 5) return `Zostávajú ${countDays} dni`;
  if (countDays === 5) return 'Zostáva 5 dní';
  return formatDate(date);
};

export const composeDetailRoutePZS = (
  contractId: string | null,
  supplementId: string | null,
  state: string | null,
  isSTAT: boolean,
  isActiveContractSection: boolean,
): string => {
  if (!isActiveContractSection) return '/';
  if (contractId && !supplementId && state === 'N' && isSTAT)
    return `/zmluvy-dodatky/podpis/${contractId}`;
  if (contractId && supplementId && state === 'N' && isSTAT)
    return `/zmluvy-dodatky/podpis/${contractId}/dodatok/${supplementId}`;
  if (contractId && supplementId)
    return `/zmluvy-dodatky/detail/${contractId}/dodatok/${supplementId}`;
  if (contractId) return `/zmluvy-dodatky/detail/${contractId}`;
  return '/zmluvy-dodatky';
};

export const getSignWarningMessage = (daysLeft: number) => {
  if (daysLeft <= 0) return `Dnes`;
  if (daysLeft === 1) return `Na podpis zostáva ${daysLeft} deň.`;
  if (daysLeft === 5) return `Na podpis zostáva ${daysLeft} dní.`;
  return `Na podpis zostávajú ${daysLeft} dni.`;
};

export const getOutdatedWarning = (state: string) => {
  const message = StavyKampani.filter((s) => s.value === state)[0]?.text || '';
  return `${message}<br/>V prípade potreby Vám vytvoríme nový návrh na podpis.`;
};
