import { getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  localSearchWrapper: {
    position: 'relative',
    '& .form-control': {
      marginBottom: 0,
      '& input': {
        cursor: 'pointer',
      },
    },
  },
  options: {
    minWidth: 'max-content',
    minHeight: getRem(36),
    pointerEvents: 'all',
    backgroundColor: '#FFFFFF',
    borderRadius: getRem(8),
    boxShadow: '0 10px 15px 0 rgba(0, 0, 0, 0.04)',
    zIndex: 100,
    position: 'absolute',
    maxHeight: getRem(200),
    maxWidth: getRem(516),
    width: '100%',
    willChange: 'scroll-position',
    overflow: 'auto',
    padding: `0`,
    top: getRem(82),
    border: `1px solid #e4e5e5`,
    '& li': {
      fontSize: getRem(16),
      width: '100%',
      transition: 'color 0.2s ease-out 0s, background 0.2s ease-out 0s',
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      '-webkit-appearance': 'none',
      minHeight: '2rem',
      listStyle: 'none',
      padding: `${getRem(10)} ${getRem(16)}`,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f0ffeb',
        outline: 'none',
        color: '#50a028',
      },
    },
  },
  emptyResult: {
    padding: `${getRem(10)} ${getRem(16)}`,
    fontSize: getRem(16),
    display: 'block',
  },
});
