import { ReactNode, useEffect } from 'react';
import useStyles from './DashboardBanner.styles';
import { Card, Modal } from '@dovera/design-system';
import { sendDataLayer } from '../../utils/gtm.utils';
import { cx } from '../../utils/exports';

interface Props {
  children?: ReactNode;
  dataLayerEvent?: string;
  isModal?: boolean;
  onClose?: () => void;
  show: boolean;
}

const DashboardBanner = ({
  children,
  dataLayerEvent,
  isModal,
  onClose,
  show,
}: Props) => {
  const classes = useStyles();
  useEffect(() => {
    if (show && dataLayerEvent) sendDataLayer(dataLayerEvent);
  }, [show, dataLayerEvent]);
  if (isModal)
    return (
      <Modal
        className={classes.modal}
        closeOnOverlayClick={false}
        data-modal-initial-focus
        header={<span />}
        id="dashboard-banner-modal"
        isVisible={show}
        onHide={onClose}
      >
        <div
          className={cx(classes.root, classes.dashboardBanner)}
          data-testid="dashboard-banner"
        >
          {children}
        </div>
      </Modal>
    );

  return (
    <Card
      className={cx(classes.root, classes.dashboardBanner, 'mb')}
      data-testid="dashboard-banner"
      isBordered
    >
      {children}
    </Card>
  );
};

export default DashboardBanner;
