import { CardSection, Card as DDSCard } from '@dovera/design-system';
import { CardAttributes } from '../../../types/jsonObjects.types';
import JsonObject from '../JsonObject';
import useStyles from '../styles';

const Card = ({ attributes, content }: CardAttributes) => {
  const classes = useStyles();
  return (
    <DDSCard className={classes.card} isBordered={attributes.isBordered}>
      <CardSection bg={attributes.background} isCondensed>
        <JsonObject data={content} />
      </CardSection>
    </DDSCard>
  );
};

export default Card;
