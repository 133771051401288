import React, { useCallback, useEffect } from 'react';
import { useField, useForm } from 'react-final-form-hooks';
import useStyles from '../../../Proposals.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { validationMsg } from '../../../../../utils/form.utils';
import { VALIDATION } from '../../../../../types/validation.types';
import { useAppDispatch } from '../../../../../hooks/useStore';
import {
  changeStepStatus,
  resetFormData,
  resetStep,
  storeStep2,
} from '../../../../../slices/drugProposals.slice';
import { checkProposalData } from '../../../../../api/drugProposals';
import Buttons from './Buttons';
import regex from '../../../../../constants/regex';
import { Form } from '../../common/PatientStep';
import { CheckType } from '../../../../../types/proposals.types';
import SafeHtml from '../../../../../components/SafeHtml/SafeHtml';
import { Notification } from '@dovera/design-system';
import strings from '../../../../../constants/strings';

const { notInsuree } = strings.proposals.drugs.new.notifications.error;
import { cx } from '../../../../../utils/exports';

interface PatientFormProps {
  onCancel: () => void;
  onContinue: () => void;
}

const validateForm = (values: { [x: string]: any }) => {
  const errors: any = {};
  if (!values.insureeId)
    errors.insureeId = validationMsg(VALIDATION.RequiredEmpty);
  if (values.insureeId.length < 9 || values.insureeId.length > 10)
    errors.insureeId = validationMsg(VALIDATION.InsureeIdWrongLength);
  if (
    !errors.insureeId &&
    (!regex.RODNE_CISLO.test(values.insureeId) ||
      !regex.NUMBERS_ONLY.test(values.insureeId))
  )
    errors.insureeId = validationMsg(VALIDATION.InsureeIdWrongFormat);

  return errors;
};

const checkNotifications: CheckType[] = ['PoistnyVztah'];

const PatientForm = ({ onCancel, onContinue }: PatientFormProps) => {
  const dispatch = useAppDispatch();
  const classes = useStyles({ proposalType: 'LN' });
  const { ambulance, doctor } = useSelector(
    (state: RootState) => state.drugProposals.new.stepper.step1,
  );
  const { insureeId, poiData } = useSelector(
    (state: RootState) => state.drugProposals.new.stepper.step2,
  );
  const currentControls = useSelector(
    (state: RootState) => state.drugProposals.new.data.controls.data,
  );
  const { errorInsuree, isLoadingControl } = useSelector(
    (state: RootState) => state.drugProposals.new.data.controls,
  );
  const { isReseted } = useSelector(
    (state: RootState) => state.drugProposals.new,
  );
  const insureeLoading = isLoadingControl?.some((c) => c === 'PoistnyVztah');
  const isInactiveInsuree: boolean = currentControls.some(
    (c) => c.checkType === 'PoistnyVztah' && c.result === 'NOK',
  );
  const { form, handleSubmit, values } = useForm({
    onSubmit: () => {
      if (!errorInsuree) onContinueCallback();
    },
    initialValues: {
      insureeId: '',
    },
    validate: (valuesToValid) => validateForm(valuesToValid),
  });
  const insureeIdField = useField('insureeId', form);

  useEffect(() => {
    if (ambulance.id && doctor.id) {
      form.reset();
      form.resetFieldState('insureeId');
    }
    // eslint-disable-next-line
  }, [ambulance.id, doctor.id]);
  useEffect(() => {
    if (isReseted) form.restart();
  }, [form, isReseted]);

  const onContinueCallback = useCallback(() => {
    if (insureeId) {
      dispatch(
        changeStepStatus({
          status: 'completed',
          step: 2,
        }),
      );
      onContinue();
    }
  }, [dispatch, insureeId, onContinue]);

  const resetStepCallback = useCallback(() => {
    dispatch(resetStep({ step: 2 }));
    dispatch(resetFormData({}));
  }, [dispatch]);

  const checkDataCallback = useCallback(
    (value: string) => {
      if (poiData) resetStepCallback();
      dispatch(storeStep2({ insureeId: value }));
      dispatch(
        checkProposalData({
          checkTypes: checkNotifications,
          insureeId: value,
          proposingDoctor: {
            code: doctor.code,
            expertise: doctor.expertise,
            id: doctor.id,
          },
          proposingExpertise: {
            code: ambulance.code,
            expertise: ambulance.expertise,
            id: ambulance.id,
          },
        }),
      );
    },
    [ambulance, dispatch, doctor, poiData, resetStepCallback],
  );

  return (
    <form
      className={cx(classes.drugsWrapper, 'no-mrg')}
      id="proposal--insureeId"
      onSubmit={handleSubmit}
    >
      <Form
        errorElement={
          <Notification
            className={classes.alignBodyNtf}
            message={<SafeHtml html={notInsuree} />}
            variant="error"
          />
        }
        errorInsuree={errorInsuree}
        insureeIdField={insureeIdField}
        insureeLoading={insureeLoading}
        isInactiveInsuree={isInactiveInsuree}
        onChange={checkDataCallback}
        onReset={resetStepCallback}
        poiData={poiData}
      />
      <Buttons
        errorInsuree={errorInsuree}
        handleSubmit={handleSubmit}
        insureeLoading={insureeLoading}
        isInactiveInsuree={isInactiveInsuree}
        onCancel={onCancel}
        values={values}
      />
    </form>
  );
};

export default PatientForm;
