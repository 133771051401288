import { AxiosError } from 'axios';
import {
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactNode,
  ReactPortal,
} from 'react';

export type StringMap<T> = { [key: string]: T };
export type DefaultType =
  | ReactElement<any, string | JSXElementConstructor<any>>
  | ReactFragment
  | ReactPortal
  | ReactNode
  | string
  | null
  | number;
export interface Hodnotenie {
  className?: string;
  hodnota: string;
  popis: ReactNode;
  tooltip: ReactNode;
}

export type RadioButtonType = {
  checked?: boolean;
  count?: number;
  text: string | ReactNode;
  value: string;
};

export type SelectType = {
  count?: number;
  selected?: boolean;
  text: string;
  value: string;
};

/* eslint-disable */
export enum Roly {
  distributor = 'distributor',
  externaFirma = 'external',
  platitel = 'payer',
  poistenec = 'insuree',
  poskytovatel = 'care-provider',
}

export enum EP_SEKCIE {
  overeneDavky = 'overene-davky',
  overenieDavok = 'overenie-davok',
  odoslanieDavok = 'odoslanie-davok',
  prehladDavok = 'prehlad-davok',
  chyboveDavky = 'chybove-davky',
  faktury = 'faktury',
  zdravotnaKarta = 'zdravotna-karta',
  kapitovaniPoistenci = 'kapitovani-poistenci',
  odideniKapitovaniPoistenci = 'odideni-kapitovani-poistenci',
  overeniePoistnehoVztahu = 'overenie-poistneho-vztahu',
  prehladHromadnychOvereni = 'prehlad-hromadnych-overeni',
  dispenzarizovaniPoistenci = 'dispenzarizovani-poistenci',
  dispZaz = 'dispenzarizovane-zaznamy',
  dlznici = 'dlznici',
  novyNavrh = 'novy-navrh',
  zoznamPodanychNavrhov = 'zoznam-podanych-navrhov',
  prehladPripadov = 'prehlad-pripadov',
  novyPripad = 'novy-pripad',
  produktyNaSklade = 'produkty-na-sklade',
  ziadostOPresun = 'ziadost-o-presun',
  prehladObjednavok = 'prehlad-objednavok',
  overenieLekara = 'overenie-lekara',
  overenieAmbulancie = 'overenie-ambulancie',
  overenieUvazku = 'overenie-uvazku',
  prehladLekarov = 'prehlad-lekarov',
  prehladAmbulancii = 'prehlad-ambulancii',
  prehladUvazkov = 'prehlad-uvazkov',
  podpisaneLiekyPomocky = 'podpisane-lieky-a-pomocky',
  navratoveChyby = 'navratove-chyby',
  spravaPristupov = 'sprava-pristupov',
  profil = 'profil',
  spravy = 'spravy',
}
/* eslint-enable */

export type Nullable<T> = T | null | undefined;

export interface Loadable {
  error?: any;
  isError?: boolean;
  isLoaded?: boolean;
  isLoading?: boolean;
  isSending?: boolean;
  isSent?: boolean;
  loadingError?: any;
}

export interface StatusLoadable extends Loadable {
  meta?: any;
  requestId?: Nullable<string>;
}

/* eslint-disable */
export enum LoadingState {
  error = 'error',
  fetching = 'fetching',
  filled = 'filled',
  none = 'none',
  reset = 'reset',
  sending = 'sending',
  sent = 'sent',
}
/* eslint-enable */

export type StepState = 'completed' | 'error' | 'warning' | 'waiting' | null;

export type EPSection =
  | 'PZSP'
  | 'PZSR'
  | 'NAVZS'
  | 'NKUP'
  | 'PROF'
  | 'SUPR'
  | 'HOSP'
  | 'KAP'
  | 'IAPZS'
  | 'EXTF'
  | 'ZUC'
  | 'MAST'
  | 'FKT'
  | 'VYKZS'
  | 'CNP'
  | 'PZSHPKEI'
  | 'PZSNAKL'
  | 'STAT'
  | 'ZMLUVY';

export interface ErrorResponse {
  detail: string;
  kod: string;
  text: string;
}

export type ErrorServiceResponse = AxiosError<ErrorResponse>;
