import { ReactNode } from 'react';
import { useAppDispatch } from '../../../../../hooks/useStore';
import { StepStatus } from '../../../../../types/proposals.types';
import {
  StepperContent,
  StepperHeader,
  StepperSummary,
} from '@dovera/design-system';
import StepperStep from '../../../../../components/StepperNew/StepperStep/StepperStep';
import useStyles from './StepWrapper.styles';

interface Props {
  activeStep: number;
  changeActiveStep: (payload: { step: number }) => void;
  content: ReactNode;
  step: number;
  stepCount: number;
  stepStatus: StepStatus;
  summary: ReactNode;
  title: string;
}

const StepWrapper = ({
  activeStep,
  changeActiveStep,
  content,
  step,
  stepCount,
  stepStatus,
  summary,
  title,
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  return (
    <StepperStep
      activeStep={activeStep}
      step={step}
      stepCount={stepCount}
      stepStatus={stepStatus}
    >
      <StepperHeader icon={`\u00A0${step}.`}>{title}</StepperHeader>
      <StepperSummary
        onClick={() => {
          if (stepStatus && ['completed', 'error'].includes(stepStatus))
            dispatch(changeActiveStep({ step: step - 1 }));
        }}
        showEditIcon
      >
        {summary}
      </StepperSummary>
      <StepperContent className={classes.stepperContent}>
        {content}
      </StepperContent>
    </StepperStep>
  );
};

export default StepWrapper;
