import { Path, Svg } from '@react-pdf/renderer';
import { color } from '@dovera/design-system';

interface Props {
  height?: number;
  width?: number;
}

const SuccessIcon = ({ height, width }: Props) => (
  // @ts-ignore
  <Svg style={{ width: width || 24, height: height || 24, marginTop: 1 }}>
    <Path
      d="M4 12L9 17L20 6"
      stroke={color('primary', 700)}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </Svg>
);

export default SuccessIcon;
