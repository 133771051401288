import useStyles from '../Spravy.styles';
import strings from '../../../constants/strings';
import { Illustration } from '@dovera/design-system';

const texts = strings.schrankaSprav.emptyStates.noMessages;

const NoMessages = () => {
  const classes = useStyles({});
  return (
    <div className={classes.emptyState}>
      <Illustration name="large_empty_state" />
      <div className={classes.description}>{texts.title}</div>
    </div>
  );
};

export default NoMessages;
