import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import {
  AktualizaciaZmluvy,
  DajAktualizacieZmluvyPayload,
  DajAktualneDokumentyResponse,
  DajDetailDodatkuPayload,
  DajDetailZmluvyPayload,
  DajDovodyPripomienkovaniaPayload,
  DajZoznamZmluvPayload,
  DetailDodatku,
  DetailZmluvy,
  DetailZmluvyPageType,
  Dodatok,
  DovodPripomienky,
  Kampan,
  KampaneWidget,
  Sumarizacia,
  UlozAkciuZmluvyPayload,
  Zmluva,
  ZmluvaMetadata,
  ZmluvyPayload,
  ZmluvyState,
} from '../types/zmluvy.types';
import {
  dajAktualizacieZmluvyApi,
  dajDetailDodatkuApi,
  dajDetailZmluvyApi,
  dajDovodyPripomienkovaniaApi,
  dajZoznamKampaniApi,
  dajZoznamKampaniPreWidgetApi,
  dajZoznamZmluvApi,
  fetchActualDocuments,
  ulozAkciuZmluvyApi,
} from '../api/prehladZmluv';
import _ from 'lodash';
import { TABLE_PAGINATION } from '../constants/misc';
import { STAV_DETAILU_ZMLUVY } from '../constants/zmluvy';
import { LoadingState } from '../types';
import strings from '../constants/strings';

export const initialState: ZmluvyState = {
  detailZmluvy: {
    aktualizacieZmluvy: {
      data: null,
      error: null,
      isLoading: false,
    },
    error: null,
    idKampane: 0,
    idZmluvy: 0,
    detail: null,
    isLoading: false,
    pageType: 'ZOZNAM_DODATKOV',
    pagesDodatky: null,
    pageNumberDodatky: 0,
    dovodyPripomienkovania: {
      isLoading: false,
      dovody: null,
      error: null,
    },
    pripomienka: {
      isLoading: false,
      success: null,
    },
  },
  detailDodatku: {
    detail: null,
    error: null,
    isLoading: false,
  },
  zoznamKampani: {
    error: null,
    isLoaded: false,
    isLoading: false,
    kampane: [],
    pages: null,
    pageNumber: 0,
    sumarizacie: [],
  },
  zoznamKampaniWidget: {
    linkTlacidla: null,
    nadpis: null,
    popis: null,
    textTlacidla: null,
    stav: null,
    typTlacidla: null,
    error: null,
    isLoaded: false,
    isLoading: false,
  },
  zoznamZmluv: {
    akciaZmluvyLoading: false,
    akciaZmluvyError: null,
    aktualnePodpisaneDokumenty: {
      data: [],
      dataState: LoadingState.none,
      error: null,
    },
    canBeLoaded: false,
    error: null,
    idKampane: 0,
    idZmluvy: 0,
    idDodatok: null,
    isLoading: false,
    metadata: null,
    pages: null,
    pageNumber: 0,
    zmluvy: [],
  },
  podpisanieZmluv: {
    chyba: null,
    hromadnePodpisanie: false,
    isLoading: false,
    uzPodpisane: false,
    uspech: false,
    typAkcie: 'O',
    vystup: null,
  },
};

const zmluvySlice = createSlice({
  name: 'zmluvy',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchActualDocuments.pending.toString(), (state) => {
        state.zoznamZmluv.aktualnePodpisaneDokumenty = {
          ...initialState.zoznamZmluv.aktualnePodpisaneDokumenty,
          dataState: LoadingState.fetching,
        };
      })
      .addCase(
        fetchActualDocuments.fulfilled.toString(),
        (state, action: PayloadAction<DajAktualneDokumentyResponse>) => {
          state.zoznamZmluv.aktualnePodpisaneDokumenty = {
            data: action.payload.aktualneDokumenty,
            dataState: LoadingState.filled,
            error: action.payload.error?.text || '',
          };
        },
      )
      .addCase(fetchActualDocuments.rejected.toString(), (state) => {
        state.zoznamZmluv.aktualnePodpisaneDokumenty = {
          ...initialState.zoznamZmluv.aktualnePodpisaneDokumenty,
          error: strings.defaultError,
        };
      });
  },
  reducers: {
    dajDetailZmluvyStart(
      state,
      action: PayloadAction<{ idKampane: number; idZmluvy: number }>,
    ) {
      return {
        ...state,
        detailZmluvy: {
          ...initialState.detailZmluvy,
          isLoading: true,
          idKampane: action.payload.idKampane,
          idZmluvy: action.payload.idZmluvy,
        },
      };
    },
    dajDetailZmluvy(state, action: PayloadAction<DetailZmluvy>) {
      const result: Pick<Dodatok, keyof Dodatok>[][] =
        action.payload && action.payload.zoznamDodatkov
          ? _.chunk(action.payload.zoznamDodatkov, TABLE_PAGINATION)
          : [];
      return {
        ...state,
        detailZmluvy: {
          ...state.detailZmluvy,
          detail: action.payload,
          isLoading: false,
          pageNumberDodatky: 0,
          pagesDodatky: result,
        },
      };
    },
    dajDetailZmluvyChyba(state) {
      return {
        ...state,
        detailZmluvy: {
          ...initialState.detailZmluvy,
          error: strings.defaultError,
          isLoading: false,
        },
      };
    },
    dajDetailDodatkuStart(state) {
      return {
        ...state,
        detailDodatku: {
          ...initialState.detailDodatku,
          error: null,
          isLoading: true,
        },
      };
    },
    dajDetailDodatku(state, action: PayloadAction<DetailDodatku>) {
      return {
        ...state,
        detailDodatku: {
          ...state.detailDodatku,
          detail: action.payload,
          isLoading: false,
        },
      };
    },
    dajDetailDodatkuChyba(state, action: PayloadAction<any>) {
      return {
        ...state,
        detailDodatku: {
          ...initialState.detailDodatku,
          error: action.payload,
          isLoading: false,
        },
      };
    },
    dajZoznamKampaniPreWidgetStart(state) {
      return {
        ...state,
        zoznamKampaniWidget: {
          ...state.zoznamKampaniWidget,
          isLoading: true,
        },
      };
    },
    dajZoznamKampaniPreWidget(state, action: PayloadAction<KampaneWidget>) {
      return {
        ...state,
        zoznamKampaniWidget: {
          linkTlacidla: action.payload.linkTlacidla,
          nadpis: action.payload.nadpis,
          popis: action.payload.popis,
          textTlacidla: action.payload.textTlacidla,
          stav: action.payload.stav,
          typTlacidla: action.payload.typTlacidla,
          error: null,
          isLoaded: true,
          isLoading: false,
        },
      };
    },
    dajZoznamKampaniPreWidgetChyba(state, action: PayloadAction<any>) {
      return {
        ...state,
        zoznamKampaniWidget: {
          ...state.zoznamKampaniWidget,
          error: action.payload,
          isLoading: false,
        },
      };
    },
    dajZoznamKampaniStart(state) {
      return {
        ...state,
        zoznamKampani: {
          ...state.zoznamKampani,
          isLoading: true,
        },
        podpisanieZmluv: {
          ...initialState.podpisanieZmluv,
        },
      };
    },
    dajZoznamKampani(
      state,
      action: PayloadAction<{
        kampane: Kampan[];
        sumarizacie: Sumarizacia[] | null;
      }>,
    ) {
      const kampane = action.payload.kampane.map((k) => ({
        ...k,
        originalStav: k.stav,
        stav: k.stav === 'C' ? 'N' : k.stav,
      }));
      const result: { [x: string]: Pick<Kampan, keyof Kampan>[][] } = {};
      const grouped = _.groupBy(
        _.orderBy(
          kampane
            ? kampane.map((z) => ({
                ...z,
                stav: z.stav === 'R' ? 'N' : z.stav,
              }))
            : [],
          'originalStav',
        ),
        'stav',
      );

      Object.keys(grouped).forEach((k) => {
        result[k] = _.chunk(grouped[k], TABLE_PAGINATION);
      });
      return {
        ...state,
        zoznamKampani: {
          error: null,
          isLoaded: true,
          isLoading: false,
          kampane,
          pages: result,
          pageNumber: 0,
          sumarizacie: action.payload.sumarizacie,
        },
      };
    },
    dajZoznamKampaniChyba(state, action: PayloadAction<any>) {
      return {
        ...state,
        zoznamKampani: {
          ...state.zoznamKampani,
          error: action.payload,
          isLoading: false,
        },
      };
    },
    dajZoznamZmluvStart(state, action: PayloadAction<{ idKampane: number }>) {
      return {
        ...state,
        zoznamZmluv: {
          ...initialState.zoznamZmluv,
          aktualnePodpisaneDokumenty:
            state.zoznamZmluv.aktualnePodpisaneDokumenty,
          isLoading: true,
          idKampane: action.payload?.idKampane,
        },
        podpisanieZmluv: {
          ...initialState.podpisanieZmluv,
        },
      };
    },
    dajZoznamZmluv(
      state,
      action: PayloadAction<{
        metadata: ZmluvaMetadata | null;
        zmluvy: Zmluva[];
      }>,
    ) {
      const result: { [x: string]: Pick<Zmluva, keyof Zmluva>[][] } = {};
      const grouped = _.groupBy(
        _.orderBy(
          action.payload.zmluvy
            ? action.payload.zmluvy.map((z) => ({
                ...z,
                stav: z.stav === 'R' ? 'N' : z.stav === 'E' ? 'P' : z.stav,
                stavZmluvy: z.stav,
              }))
            : [],
          'poradoveCislo',
        ),
        'stav',
      );
      Object.keys(grouped).forEach((k) => {
        result[k] = _.chunk(grouped[k], TABLE_PAGINATION);
      });

      return {
        ...state,
        zoznamKampaniWidget: {
          ...state.zoznamKampaniWidget,
          isLoaded: false,
        },
        zoznamZmluv: {
          ...state.zoznamZmluv,
          canBeLoaded: true,
          error: null,
          isLoading: false,
          metadata: action.payload.metadata,
          zmluvy: action.payload.zmluvy,
          pageNumber: 0,
          pages: result,
        },
        detailZmluvy:
          action.payload.zmluvy &&
          action.payload.zmluvy.length === 1 &&
          state.zoznamKampani.kampane.filter(
            (k) => k.idKampane === action.payload.zmluvy[0].idKampan,
          )[0]?.stav !== 'N'
            ? {
                aktualizacieZmluvy:
                  initialState.detailZmluvy.aktualizacieZmluvy,
                detail: null,
                dovodyPripomienkovania: {
                  dovody: null,
                  error: null,
                  isLoading: false,
                },
                error: null,
                pripomienka: {
                  isLoading: false,
                  success: null,
                },
                idKampane: action.payload.zmluvy[0].idKampan,
                idZmluvy: action.payload.zmluvy[0].idZmluvy,
                isLoading: false,
                pageType:
                  STAV_DETAILU_ZMLUVY[
                    state.zoznamKampani.kampane.filter(
                      (k) => k.idKampane === action.payload.zmluvy[0].idKampan,
                    )[0]?.stav
                  ],
                pageNumberDodatky: 0,
                pagesDodatky: null,
              }
            : {
                ...state.detailZmluvy,
                pageType: 'ZOZNAM_DODATKOV',
              },
      };
    },
    dajZoznamZmluvChyba(state, action: PayloadAction<any>) {
      return {
        ...state,
        zoznamZmluv: {
          ...state.zoznamZmluv,
          error: action.payload,
          isLoading: false,
        },
      };
    },
    ulozAkciuZmluvyStart(
      state,
      action: PayloadAction<{
        idDodatok: number | null;
        idZmluvy: number;
        typAkcie: 'O' | 'S' | 'P' | 'N' | string;
      }>,
    ) {
      return {
        ...state,
        podpisanieZmluv: {
          ...initialState.podpisanieZmluv,
          isLoading:
            action.payload.typAkcie === 'P' || action.payload.typAkcie === 'S',
          typAkcie: action.payload.typAkcie,
          chyba: null,
          uspech: false,
          vystup: null,
        },
        zoznamZmluv: {
          ...state.zoznamZmluv,
          akciaZmluvyLoading: true,
          akciaZmluvyError: null,
          idZmluvy: action.payload.idZmluvy,
          idDodatok: action.payload.idDodatok,
        },
        detailZmluvy: {
          ...state.detailZmluvy,
          pripomienka: {
            isLoading: action.payload.typAkcie === 'N', // namietka
            success: null,
          },
        },
        detailDodatku:
          action.payload.typAkcie === 'O' && !action.payload.idDodatok
            ? initialState.detailDodatku
            : state.detailDodatku,
      };
    },
    ulozAkciuZmluvy(
      state,
      action: PayloadAction<{ uspesne: boolean; vystup: string | null }>,
    ) {
      return {
        ...state,
        podpisanieZmluv: {
          ...state.podpisanieZmluv,
          isLoading: false,
          chyba: !action.payload.uspesne ? 'Chyba' : null,
          uspech: !state.detailZmluvy.pripomienka.isLoading
            ? action.payload.uspesne
            : false,
          vystup: !state.detailZmluvy.pripomienka.isLoading
            ? action.payload.vystup
            : null,
        },
        zoznamZmluv: {
          ...state.zoznamZmluv,
          akciaZmluvyLoading: false,
          akciaZmluvyError: !action.payload.uspesne
            ? action.payload.vystup
            : null,
        },
        detailZmluvy: {
          ...state.detailZmluvy,
          pripomienka: {
            success: state.detailZmluvy.pripomienka.isLoading
              ? action.payload.uspesne
              : null,
            isLoading: false,
          },
        },
      };
    },
    ulozAkciuZmluvyChyba(state, action: PayloadAction<any>) {
      return {
        ...state,
        podpisanieZmluv: {
          ...state.podpisanieZmluv,
          isLoading: false,
          chyba: action.payload,
        },
        zoznamZmluv: {
          ...state.zoznamZmluv,
          akciaZmluvyLoading: false,
          akciaZmluvyError:
            'Nastala technická chyba. Zopakujte akciu neskôr, prosím.',
        },
        detailZmluvy: {
          ...state.detailZmluvy,
          pripomienka: {
            success: state.detailZmluvy.pripomienka.isLoading ? false : null,
            isLoading: false,
          },
        },
      };
    },
    zmenTypDetailuZmluvy(
      state,
      action: PayloadAction<{
        idKampane?: number;
        idZmluvy?: number;
        typ?: DetailZmluvyPageType;
      }>,
    ) {
      return {
        ...state,
        detailZmluvy: {
          ...state.detailZmluvy,
          idKampane: action.payload.idKampane || state.detailZmluvy.idKampane,
          idZmluvy: action.payload.idZmluvy || state.detailZmluvy.idZmluvy,
          pageType: action.payload.typ || state.detailZmluvy.pageType,
        },
        zoznamZmluv: !action.payload.idZmluvy
          ? {
              ...initialState.zoznamZmluv,
              pageNumber: 0,
            }
          : {
              ...state.zoznamZmluv,
              pageNumber: 0,
            },
      };
    },
    resetZmluv() {
      return {
        ...initialState,
      };
    },
    resetPodpisanie(state) {
      return {
        ...state,
        podpisanieZmluv: {
          ...state.podpisanieZmluv,
          isLoading: false,
          chyba: null,
          uspech: false,
          typAkcie: 'O',
        },
      };
    },
    zmenCisloStrany(
      state,
      action: PayloadAction<{
        page: number;
        type: 'kampan' | 'zmluvy' | 'dodatky';
      }>,
    ) {
      if (action.payload.type === 'kampan')
        return {
          ...state,
          zoznamKampani: {
            ...state.zoznamKampani,
            pageNumber: action.payload.page,
          },
          zoznamZmluv: {
            ...initialState.zoznamZmluv,
          },
        };
      if (action.payload.type === 'dodatky')
        return {
          ...state,
          detailZmluvy: {
            ...state.detailZmluvy,
            pageNumberDodatky: action.payload.page,
          },
        };
      return {
        ...state,
        zoznamZmluv: {
          ...state.zoznamZmluv,
          pageNumber: action.payload.page,
        },
      };
    },
    // Typ podpisu H - Hromadne, I - Individualne
    zmenTypPodpisu(state, action: PayloadAction<'H' | 'I'>) {
      return {
        ...state,
        podpisanieZmluv: {
          ...state.podpisanieZmluv,
          hromadnePodpisanie: action.payload === 'H',
        },
      };
    },
    filtruj(state, action: PayloadAction<string | null>) {
      const filtered = action.payload
        ? state.zoznamZmluv.zmluvy.filter(
            (z) =>
              action.payload &&
              (z.ico?.includes(action.payload) ||
                z.nazov?.toLowerCase().includes(action.payload.toLowerCase()) ||
                z.cisloZmluvy?.includes(action.payload)),
          )
        : state.zoznamZmluv.zmluvy;
      const result: { [x: string]: Pick<Zmluva, keyof Zmluva>[][] } = {};
      const grouped = _.groupBy(
        _.orderBy(
          filtered?.map((z) => ({
            ...z,
            stav: z.stav === 'R' ? 'N' : z.stav === 'E' ? 'P' : z.stav,
            stavZmluvy: z.stav,
          })),
          'poradoveCislo',
        ),
        'stav',
      );
      Object.keys(grouped).forEach((k) => {
        result[k] = _.chunk(grouped[k], TABLE_PAGINATION);
      });
      return {
        ...state,
        zoznamZmluv: {
          ...state.zoznamZmluv,
          pages: result,
        },
      };
    },
    odstranUlozAkciaChyba(state) {
      return {
        ...state,
        zoznamZmluv: {
          ...state.zoznamZmluv,
          akciaZmluvyError: null,
        },
      };
    },
    dajDovodyPripomienkovaniaStart(state) {
      return {
        ...state,
        detailZmluvy: {
          ...state.detailZmluvy,
          dovodyPripomienkovania: {
            isLoading: true,
            dovody: null,
            error: null,
          },
          pripomienka: {
            isLoading: false,
            success: null,
          },
        },
      };
    },
    dajDovodyPripomienkovania(
      state,
      action: PayloadAction<{ dovodyPripomienkovania: DovodPripomienky[] }>,
    ) {
      return {
        ...state,
        detailZmluvy: {
          ...state.detailZmluvy,
          dovodyPripomienkovania: {
            isLoading: false,
            dovody: action.payload.dovodyPripomienkovania,
            error: null,
          },
        },
      };
    },
    dajDovodyPripomienkovaniaChyba(state, action: PayloadAction<any>) {
      return {
        ...state,
        detailZmluvy: {
          ...state.detailZmluvy,
          dovodyPripomienkovania: {
            isLoading: true,
            dovody: null,
            error: action.payload,
          },
        },
      };
    },
    dajAktualizacieZmluvyStart(state) {
      return {
        ...state,
        detailZmluvy: {
          ...state.detailZmluvy,
          aktualizacieZmluvy: {
            ...state.detailZmluvy.aktualizacieZmluvy,
            data: null,
            error: null,
            isLoading: true,
          },
        },
      };
    },
    dajAktualizacieZmluvy(
      state,
      action: PayloadAction<{ aktualizacieZmluvy: AktualizaciaZmluvy[] }>,
    ) {
      return {
        ...state,
        detailZmluvy: {
          ...state.detailZmluvy,
          aktualizacieZmluvy: {
            ...state.detailZmluvy.aktualizacieZmluvy,
            data: action.payload.aktualizacieZmluvy,
            isLoading: false,
          },
        },
      };
    },
    dajAktualizacieZmluvyChyba(state, action: PayloadAction<any>) {
      return {
        ...state,
        detailZmluvy: {
          ...state.detailZmluvy,
          aktualizacieZmluvy: {
            ...state.detailZmluvy.aktualizacieZmluvy,
            error: action.payload,
            isLoading: false,
          },
        },
      };
    },
    resetStavPripomienky(state) {
      return {
        ...state,
        detailZmluvy: {
          ...state.detailZmluvy,
          pripomienka: initialState.detailZmluvy.pripomienka,
        },
      };
    },
  },
});

export const {
  filtruj,
  odstranUlozAkciaChyba,
  resetPodpisanie,
  resetStavPripomienky,
  resetZmluv,
  zmenCisloStrany,
  zmenTypDetailuZmluvy,
  zmenTypPodpisu,
} = zmluvySlice.actions;

export const {
  dajAktualizacieZmluvy,
  dajAktualizacieZmluvyChyba,
  dajAktualizacieZmluvyStart,
  dajDetailDodatku,
  dajDetailDodatkuChyba,
  dajDetailDodatkuStart,
  dajDetailZmluvy,
  dajDetailZmluvyChyba,
  dajDetailZmluvyStart,
  dajDovodyPripomienkovania,
  dajDovodyPripomienkovaniaChyba,
  dajDovodyPripomienkovaniaStart,
  dajZoznamKampani,
  dajZoznamKampaniChyba,
  dajZoznamKampaniPreWidget,
  dajZoznamKampaniPreWidgetChyba,
  dajZoznamKampaniPreWidgetStart,
  dajZoznamKampaniStart,
  dajZoznamZmluv,
  dajZoznamZmluvChyba,
  dajZoznamZmluvStart,
  ulozAkciuZmluvy,
  ulozAkciuZmluvyChyba,
  ulozAkciuZmluvyStart,
} = zmluvySlice.actions;

export default zmluvySlice.reducer;

export const fetchZoznamKampaniPreWidget =
  (payload: ZmluvyPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajZoznamKampaniPreWidgetStart());
      const response = await dajZoznamKampaniPreWidgetApi(payload);
      dispatch(dajZoznamKampaniPreWidget(response));
    } catch (err) {
      dispatch(dajZoznamKampaniPreWidgetChyba(err));
    }
  };

export const fetchZoznamKampani =
  (payload: ZmluvyPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajZoznamKampaniStart());
      const response = await dajZoznamKampaniApi(payload);
      dispatch(dajZoznamKampani(response));
    } catch (err) {
      dispatch(dajZoznamKampaniChyba(err));
    }
  };

export const fetchZoznamZmluv =
  (payload: DajZoznamZmluvPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajZoznamZmluvStart({ idKampane: payload.idKampane || 0 }));
      const response = await dajZoznamZmluvApi(payload);
      dispatch(dajZoznamZmluv(response));
    } catch (err) {
      dispatch(dajZoznamZmluvChyba(err));
    }
  };

export const fetchUlozAkciuZmluvy =
  (payload: UlozAkciuZmluvyPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(
        ulozAkciuZmluvyStart({
          typAkcie: payload.typAkcie,
          idDodatok: payload.idDodatok ? payload.idDodatok : null,
          idZmluvy: payload.idZmluvy || 0,
        }),
      );
      const response = await ulozAkciuZmluvyApi(payload);
      dispatch(ulozAkciuZmluvy(response));
      if (payload.typAkcie === 'Z') {
        dispatch(fetchZoznamZmluv({ idHz: payload.idHz || 0 }));
      }
    } catch (err) {
      dispatch(ulozAkciuZmluvyChyba(err));
    }
  };

export const fetchDetailZmluvy =
  (payload: DajDetailZmluvyPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(
        dajDetailZmluvyStart({
          idKampane: payload.idKampan,
          idZmluvy: payload.idZmluvy,
        }),
      );
      const response = await dajDetailZmluvyApi(payload);
      dispatch(dajDetailZmluvy(response));
    } catch (err) {
      dispatch(dajDetailZmluvyChyba());
    }
  };

export const fetchDetailDodatku =
  (payload: DajDetailDodatkuPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajDetailDodatkuStart());
      const response = await dajDetailDodatkuApi(payload);
      dispatch(dajDetailDodatku(response));
    } catch (err) {
      dispatch(dajDetailDodatkuChyba(err));
    }
  };

export const fetchDovodyPripomienkovania =
  (payload: DajDovodyPripomienkovaniaPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajDovodyPripomienkovaniaStart());
      const response = await dajDovodyPripomienkovaniaApi(payload);
      dispatch(dajDovodyPripomienkovania(response));
    } catch (err) {
      dispatch(dajDovodyPripomienkovaniaChyba(err));
    }
  };

export const fetchAktualizacieZmluvy =
  (payload: DajAktualizacieZmluvyPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(dajAktualizacieZmluvyStart());
      const response = await dajAktualizacieZmluvyApi(payload);
      dispatch(dajAktualizacieZmluvy(response));
    } catch (err) {
      dispatch(dajAktualizacieZmluvyChyba(err));
    }
  };
