import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import ContentHeader from '../../../components/ContentHeader/ContentHeader';
import { Container } from '@dovera/design-system';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { navrhyRoutes } from '../../../routes';
import HeaderKN from './ProposalHeader/HeaderKN';
import DetailKN from './DetailKN';
import DetailLN from './DetailLN';
import HeaderLN from './ProposalHeader/HeaderLN';
import DetailCNP from './DetailCNP';
import HeaderCNP from './ProposalHeader/HeaderCNP';
import CardBubble from '../../../components/CardBubble/CardBubble';

interface Props {
  id: number;
  pin?: string | null;
  proposalNumber: string;
  type: 'CNP' | 'LN' | 'KN' | string;
}

const ProposalDetail = ({ id, pin, proposalNumber, type }: Props) => {
  const isApprovedProposal: boolean =
    navrhyRoutes.zoznamSchvalenychNavrhov.includes(location.pathname);

  const renderHeader = (
    <ContentHeader padBottom={false}>
      <Container>
        <Breadcrumbs
          items={[
            {
              name: 'Návrhy',
              route: '#',
            },
            {
              name: isApprovedProposal
                ? 'Zoznam schválených návrhov'
                : 'Zoznam podaných návrhov',
              route: isApprovedProposal
                ? navrhyRoutes.zoznamSchvalenychNavrhov
                : `${navrhyRoutes.zoznamPodanychNavrhov}?typ=${type}`,
            },
            {
              name: 'Detail',
            },
          ]}
        />
      </Container>
      {type === 'KN' && (
        <HeaderKN pin={pin} proposalId={id} proposalNumber={proposalNumber} />
      )}
      {type === 'LN' && (
        <HeaderLN proposalId={id} proposalNumber={proposalNumber} />
      )}
      {type === 'CNP' && (
        <HeaderCNP proposalId={id} proposalNumber={proposalNumber} />
      )}
    </ContentHeader>
  );

  return (
    <StaticMenuLayout contentHeader={renderHeader}>
      <CardBubble>
        {type === 'KN' && (
          <DetailKN proposalId={id} proposalNumber={proposalNumber} />
        )}
        {type === 'LN' && (
          <DetailLN proposalId={id} proposalNumber={proposalNumber} />
        )}
        {type === 'CNP' && (
          <DetailCNP proposalId={id} proposalNumber={proposalNumber} />
        )}
      </CardBubble>
    </StaticMenuLayout>
  );
};

export default ProposalDetail;
