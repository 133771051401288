import { ReactNode, createContext, useState } from 'react';
import { ModalType } from '../types/modal.types';
import RecommendedModal from '../modals/RecommendedModal';
import { CancelActionModal } from '../modals/CancelActionModal';
import NotValidConditionsModal from '../pages/proposals/Modals/DrugProposal/NotValidConditionsModal';
import ParameterDetailModal from '../pages/parameters/common/modals/ParameterDetailModal';
import { ModalObjectType } from '../types/jsonObjects.types';
import Modal from '../components/JsonObject/common/Modal';

interface ModalContextType {
  closeModal: (modalName: ModalType | string, props?: {}) => void;
  isOpenModal: (modalName: ModalType | string) => boolean;
  modalType: ModalType | string | null;
  props: any;
  showModal: (modalName: ModalType | string, props?: {}) => void;
}

interface ModalProviderProps {
  children: ReactNode;
  dynamicContent?: ModalObjectType[];
}

const defaultModalContext: ModalContextType = {
  isOpenModal: () => false,
  modalType: null,
  showModal: () => {},
  closeModal: () => {},
  props: {},
};

export const ModalContext = createContext(defaultModalContext);

export const ModalProvider = ({
  children,
  dynamicContent,
}: ModalProviderProps) => {
  const showModal = (modalType: ModalType | string, props = {}) => {
    showCurrentModal((previousState) => ({
      ...previousState,
      modalType,
      props: { ...props },
      isOpenModal: (modalTypeToCheck: ModalType | string) =>
        modalType === modalTypeToCheck,
    }));
  };

  const closeModal = (modalType: ModalType | string, props = {}) => {
    showCurrentModal((previousState) =>
      modalType === previousState.modalType
        ? {
            ...previousState,
            modalType: null,
            ...props,
            isOpenModal: () => false,
          }
        : previousState,
    );
  };

  const [currentModal, showCurrentModal] = useState<ModalContextType>({
    isOpenModal: () => false,
    modalType: null,
    props: {},
    showModal,
    closeModal,
  });

  if (dynamicContent)
    return (
      <ModalContext.Provider value={currentModal}>
        {dynamicContent.map((content) => (
          <Modal key={content.id} modalID={content.id} object={content} />
        ))}
        {children}
      </ModalContext.Provider>
    );

  return (
    <ModalContext.Provider value={currentModal}>
      <RecommendedModal
        {...currentModal.props}
        modalID={ModalType.DP_RECOMMENDED}
      />
      <CancelActionModal
        {...currentModal.props}
        modalID={ModalType.CANCEL_ACTION}
      />
      <NotValidConditionsModal
        {...currentModal.props}
        modalID={ModalType.DP_NOT_VALID_CONDITIONS}
      />
      <ParameterDetailModal
        {...currentModal.props}
        modalID={ModalType.PARAMETERS_DETAIL}
      />
      {children}
    </ModalContext.Provider>
  );
};

export const ModalConsumer = ModalContext.Consumer;
