import {
  Icon,
  Notification,
  Select,
  Skeleton,
  TabPanel,
  breakpoints,
} from '@dovera/design-system';
import useStyles from '../Spravy.styles';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../hooks/useStore';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { RootState } from '../../../rootReducer';
import { vyberKategorieVlakien, zatvorNtf } from '../../../slices/spravy.slice';
import { LoadingState } from '../../../types';
import { KategoriaSprav, MessageTabType } from '../../../types/spravy.types';
import strings from '../../../constants/strings';
import { safeNode } from '../../../utils/app.utils';
import { TabsFilter } from '../../../components/Filter';

const texts = strings.schrankaSprav;

const MessageTabs = () => {
  const classes = useStyles({});
  const dispatch = useAppDispatch();
  const windowSize = useWindowSize();
  const [loading, setLoading] = useState(false);
  const {
    kategoria,
    neprecitaneVlakna: { neprecitaneVymazane },
    refresh,
    vlakna: { dataState, pocty, zobrazNtf },
  } = useSelector((state: RootState) => state.spravy);
  const refreshCallback = useCallback(() => {
    setLoading(true);
    window.location.hash = '';
    // @ts-ignore
    if (document.querySelector('ul.tab-list__list'))
      // @ts-ignore
      document.querySelector('ul.tab-list__list').scrollLeft = 0;
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  useEffect(() => {
    if (dataState === LoadingState.none || refresh) refreshCallback();
  }, [dataState, refresh, refreshCallback]);
  const calculateTabs = (): MessageTabType[] => [
    {
      count: pocty[KategoriaSprav.Vsetky],
      label: 'Všetky',
      value: KategoriaSprav.Vsetky,
    },
    {
      count: pocty[KategoriaSprav.Spravy],
      label: 'Správy',
      value: KategoriaSprav.Spravy,
    },
    {
      count: pocty[KategoriaSprav.Notifikacie],
      label: 'Notifikácie',
      value: KategoriaSprav.Notifikacie,
    },
    {
      count: pocty[KategoriaSprav.Vymazane],
      label: 'Vymazané',
      value: KategoriaSprav.Vymazane,
    },
  ];
  const renderArchivNtf = safeNode(
    <div className={classes.archivNtfWrapper}>
      <Notification
        closeButton
        closeButtonOnClick={() => dispatch(zatvorNtf())}
        iconArea={<Icon className="text-color-black" name="info" />}
        message={texts.notifications.archiv}
        variant="blue"
      />
    </div>,
    kategoria === KategoriaSprav.Vymazane && zobrazNtf,
  );
  if (
    loading ||
    (windowSize.width <= breakpoints.m && dataState !== LoadingState.filled)
  )
    return <Skeleton height={50} width={380} />;
  const getLabel = (
    count: number,
    text: string,
    value: string,
    pureText?: boolean,
  ): ReactNode | string => {
    const str = `${text} (${count})`;
    return neprecitaneVymazane &&
      value === KategoriaSprav.Vymazane &&
      !pureText ? (
      <>
        <span className={classes.unreadDot} />
        {str}
      </>
    ) : (
      str
    );
  };
  if (windowSize.width <= breakpoints.m) {
    return (
      <>
        <Select
          id="message-tabs-select"
          onChange={(value) =>
            dispatch(vyberKategorieVlakien({ kategoria: value }))
          }
          // @ts-ignore
          options={calculateTabs().map((t) => ({
            label: getLabel(t.count, t.label, t.value, true),
            value: t.value,
          }))}
          value={kategoria}
        />
        {renderArchivNtf}
      </>
    );
  }
  return (
    <>
      <TabsFilter
        id="spravy-kategorie"
        onChange={(e) =>
          dispatch(
            vyberKategorieVlakien({
              kategoria: e?.panel?.id || KategoriaSprav.Vsetky,
            }),
          )
        }
      >
        {calculateTabs().map((t, key) => (
          <TabPanel
            key={`message-tab--${key}`}
            id={t.value}
            tab={getLabel(t.count, t.label, t.value)}
          />
        ))}
      </TabsFilter>
      {renderArchivNtf}
    </>
  );
};

export default MessageTabs;
