interface Props {
  active: boolean;
  id: string;
}

const IconEuroInvoice = ({ active, id }: Props) => (
  <svg
    fill="none"
    height="32"
    id={id}
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="16"
      cy="16"
      fill={active ? '#F8FCB9' : '#C4C4C4'}
      rx="16"
      ry="16"
    />
    <path
      clipRule="evenodd"
      d="M16.8019 10.3333C14.6909 10.3333 12.8513 11.474 11.8668 13.1667H17.2825C17.8348 13.1667 18.2825 13.6144 18.2825 14.1667C18.2825 14.7189 17.8348 15.1667 17.2825 15.1667H11.1657C11.1253 15.4387 11.1045 15.7169 11.1045 16C11.1045 16.1685 11.1119 16.3352 11.1263 16.5H17.2825C17.8348 16.5 18.2825 16.9477 18.2825 17.5C18.2825 18.0523 17.8348 18.5 17.2825 18.5H11.6876C12.6157 20.3746 14.5551 21.6667 16.8019 21.6667C18.5093 21.6667 20.0394 20.921 21.0844 19.7375C21.45 19.3235 22.082 19.2843 22.4959 19.6499C22.9099 20.0154 22.9492 20.6474 22.5836 21.0613C21.1738 22.6578 19.105 23.6667 16.8019 23.6667C13.4324 23.6667 10.5641 21.5086 9.52284 18.5H7.83325C7.28097 18.5 6.83325 18.0523 6.83325 17.5C6.83325 16.9477 7.28097 16.5 7.83325 16.5H9.12055C9.10988 16.3347 9.10446 16.168 9.10446 16C9.10446 15.7184 9.11969 15.4404 9.14937 15.1667H7.83325C7.28097 15.1667 6.83325 14.7189 6.83325 14.1667C6.83325 13.6144 7.28097 13.1667 7.83325 13.1667H9.64703C10.779 10.3333 13.5582 8.33333 16.8019 8.33333C19.105 8.33333 21.1738 9.34216 22.5836 10.9386C22.9492 11.3526 22.9099 11.9846 22.4959 12.3501C22.082 12.7157 21.45 12.6765 21.0844 12.2625C20.0394 11.079 18.5093 10.3333 16.8019 10.3333Z"
      fill="#464906"
      fillRule="evenodd"
    />
  </svg>
);

export default IconEuroInvoice;
