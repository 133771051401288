import { Grid, GridCol } from '@dovera/design-system';
import strings from '../../../../../constants/strings';
import { hotjarMasking } from '../../../../../utils/strings.utils';
import useStyles from '../../../Proposals.styles';
import {
  PROPOSAL_SUMMARY_LABEL_BREAKPOINTS,
  PROPOSAL_SUMMARY_VALUE_BREAKPOINTS,
} from '../../../../../constants/proposals';

const texts = strings.proposals.spa.new;

const Summary = ({ ambulance, doctor }) => {
  const classes = useStyles({ proposalType: 'LN' });

  return (
    <div className={classes.summary}>
      <Grid className="mb-small">
        <GridCol size={PROPOSAL_SUMMARY_LABEL_BREAKPOINTS}>
          {texts.labels.doctor}
        </GridCol>
        <GridCol size={PROPOSAL_SUMMARY_VALUE_BREAKPOINTS}>
          <span className="text-bold">
            {hotjarMasking(`${doctor.name} (${doctor.code})`, 'b')}
          </span>
        </GridCol>
      </Grid>
      <Grid className="mb-small">
        <GridCol size={PROPOSAL_SUMMARY_LABEL_BREAKPOINTS}>
          {texts.labels.ambulance}
        </GridCol>
        <GridCol size={PROPOSAL_SUMMARY_VALUE_BREAKPOINTS}>
          <span className="text-bold">
            {ambulance.code}
            {ambulance.name && ` (${ambulance.name})`}
          </span>
        </GridCol>
      </Grid>
      <Grid>
        <GridCol size={PROPOSAL_SUMMARY_LABEL_BREAKPOINTS}>
          {texts.labels.email}
        </GridCol>
        <GridCol size={PROPOSAL_SUMMARY_VALUE_BREAKPOINTS}>
          <span className="text-bold">
            {hotjarMasking(doctor.email || strings.undefined)}
          </span>
        </GridCol>
      </Grid>
    </div>
  );
};

export default Summary;
