import { IconAttributes } from '../../../types/jsonObjects.types';
import { Icon as DDSIcon } from '@dovera/design-system';
import IconCircleDashed from '../../CustomIcons/IconCircleDashed';

const Icon = ({ attributes: { id } }: IconAttributes) => {
  if (id === 'empty' || id.includes('icon-')) {
    return <IconCircleDashed />;
  }

  // @ts-ignore
  return <DDSIcon name={id} />;
};

export default Icon;
