import React from 'react';
import { Icon } from '@dovera/design-system';
import cx from 'classnames';

import useStyles from './NotificationBox.styles';

import { AlignIconWithComponent, IconInfo } from '..';
import IconErrorNotification from '../CustomIcons/IconErrorNotification';
import IconWarning from '../CustomIcons/IconWarning';

type NotificationBoxProps = {
  className?: any;
  displayCloseIcon?: boolean;
  iconTop?: boolean;
  id: string;
  onCloseClick?: () => void;
  type?:
    | 'default'
    | 'grey'
    | 'info'
    | 'infoBlue'
    | 'warning'
    | 'transparent'
    | 'error';
} & React.JSX.IntrinsicElements['div'];

function getIcon(icon: NotificationBoxProps['type']) {
  switch (icon) {
    case 'info':
    case 'infoBlue':
      return (
        <IconInfo
          color={icon === 'infoBlue' ? '#1B4888' : ''}
          id="icon-notification-info"
        />
      );
    case 'warning':
      return <IconWarning id="icon-notification-warning" />;
    case 'error':
      return <IconErrorNotification id="icon-notification-error" />;
    case 'transparent':
      return (
        <Icon color="#A7A9AC" id="icon-notification-transparent" name="info" />
      );
    case 'grey':
      return null;
    default:
      return <IconInfo color="#1F57A5" id="icon-notification-info" />;
  }
}

function NotificationBox({
  children,
  className,
  displayCloseIcon,
  iconTop,
  id,
  onCloseClick,
  type = 'info',
  ...props
}: NotificationBoxProps) {
  const classes = useStyles();
  return (
    <div
      className={cx(
        classes.notificationbox,
        classes[type],
        className,
        iconTop && classes.iconTop,
      )}
      {...props}
    >
      <AlignIconWithComponent
        component={children}
        displayCloseIcon={displayCloseIcon}
        icon={getIcon(type)}
        id={`${id}--alignIcon`}
        onCloseClick={onCloseClick}
        smallPadding={type === 'transparent'}
      />
    </div>
  );
}

export default NotificationBox;
