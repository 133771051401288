import { ReactNode, useEffect, useRef, useState } from 'react';
import { cx } from '../../utils/exports';
import useStyles from './styles';
import { calculateTextMinWidth } from './utils';
import { Tooltip } from '@dovera/design-system';
import { useWindowSize } from '../../hooks/useWindowSize';

interface Props {
  classes?: string;
  hjMask?: boolean;
  text: ReactNode | string;
}

const TruncateText = ({ classes, hjMask, text }: Props) => {
  const styles = useStyles();
  const windowSize = useWindowSize();
  const textRef = useRef<HTMLDivElement>(null);
  const [isEllipsisActive, setIsEllispisActive] = useState(false);
  useEffect(() => {
    const checkEllipsis = () => {
      if (textRef.current) {
        const hasEllipsis =
          textRef.current.scrollWidth > textRef.current.clientWidth;
        setIsEllispisActive(hasEllipsis);
      }
    };

    checkEllipsis();
    window.addEventListener('resize', checkEllipsis);

    return () => {
      window.removeEventListener('resize', checkEllipsis);
    };
  }, [textRef]);
  return (
    <div
      ref={textRef}
      className={cx(classes, styles.text)}
      data-hj-masked={!!hjMask}
      style={calculateTextMinWidth(windowSize.width)}
    >
      {isEllipsisActive ? (
        // @ts-ignore
        <Tooltip dialog={text}>{text}</Tooltip>
      ) : (
        text
      )}
    </div>
  );
};

export default TruncateText;
