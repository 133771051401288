import { useMemo } from 'react';
import { JsonObjectType } from '../../types/jsonObjects.types';
import { cx } from '../../utils/exports';
import CMSText from '../CMSText/CMSText';
import FormulaVisualization from '../FormulaVisualization/FormulaVisualization';
import SafeHtml from '../SafeHtml/SafeHtml';
import {
  Banner,
  Card,
  Carousel,
  Graph,
  Grid,
  Icon,
  Link,
  Structure,
  Table,
} from './common';
import useStyles from './styles';

interface Props {
  data: JsonObjectType[];
  isModalContent?: boolean;
}

const JsonObject = ({ data, isModalContent }: Props) => {
  const classes = useStyles();

  const sortedData = useMemo(
    () => data.sort((a, b) => a.order - b.order),
    [data],
  );

  const renderItem = (item: JsonObjectType, index: number) => {
    const key = `${item.type}-${index}--${item.order}`;
    switch (item.type) {
      case 'banner':
        return <Banner key={key} {...item} />;
      case 'card':
        return <Card key={key} {...item} />;
      case 'link':
        return <Link key={key} {...item} />;
      case 'grid':
        return <Grid key={key} {...item} />;
      case 'html':
        return <SafeHtml key={key} html={item.attributes.text} wrapper="div" />;
      case 'text':
        return <SafeHtml key={key} html={item.attributes.text} />;
      case 'title':
        return (
          <h2 key={key} className="mb-small">
            {item.attributes.text}
          </h2>
        );
      case 'cms':
        return (
          <CMSText
            key={key}
            classes={cx(isModalContent && classes.cmsModalContent)}
            params={item.attributes.params}
            path={item.attributes.id}
          />
        );
      case 'structure':
        return <Structure key={key} {...item} />;
      case 'carousel':
        return <Carousel key={key} {...item} />;
      case 'graph':
        return <Graph key={key} {...item} />;
      case 'formula':
        return <FormulaVisualization key={key} attributes={item.attributes} />;
      case 'table':
        return <Table key={key} isModalContent={isModalContent} {...item} />;
      case 'icon':
        return <Icon key={key} {...item} />;
      // TODO: napojenie HP na tieto JSON objekty
      default:
        return null;
    }
  };

  return sortedData.map((item, index) => renderItem(item, index));
};

export default JsonObject;
