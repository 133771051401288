export default {
  title: 'Prehľad diagnóz',
  mostCommonDiagnoses: 'Najčastejšie diagnózy',
  preview: {
    buttons: {
      compareOthers: 'Porovnať s inými lekármi',
      totalCosts: 'Prehľad nákladov na diagnózy',
    },
    modals: {
      detailDg: {
        subtitleExpertises: 'Vaši pacienti s diagnózou',
        subtitleOccurence: 'Výskyt diagnózy v rámci SR',
        subtitleYourPatients: 'Vaši pacienti',
        labels: {
          count: 'Počet Vašich pacientov',
          countAll: 'Počet pacientov',
          share: 'Počet Vašich pacientov',
          shareAll: 'Podiel ku všetkým pacientom v rámci SR',
          shareYours: 'Podiel ku všetkým Vašim pacientom',
        },
        tooltips: {
          count: 'Pacienti, ktorých ste diagnostikovali na uvedenú diagnózu',
          countAll:
            'Pacienti, ktorých diagnostikovali kolegovia vo Vašej odbornosti na celom Slovensku. Výberom okresu alebo kraja viete porovnať počet diagnostikovaných pacientov pre akýkoľvek okres alebo kraj.',
          share:
            '% pacientov s danou diagnózou voči ostatným, ktoré ste diagnostikovali vo Vašej ambulancii',
          shareAll:
            '% pacientov s danou diagnózou voči ostatným, ktoré diagnostikovali Vaši kolegovia v danej odbornosti',
        },
      },
    },
  },
  costs: {
    title: 'Prehľad nákladov podľa diagnóz',
    personalizedTitle: 'Zobrazenie nákladov',
    personalizedLabels: {
      all: 'Celkové',
      my: 'Moje',
    },
    emptyStates: {
      noDataExp: 'Pre danú odbornosť sme nenašli žiadne náklady.',
      noFilterData: {
        title: 'Pre tento filter sme nenašli žiadne údaje',
        description: 'Upravením filtra naľavo si vyberte iný prehľad nákladov.',
      },
    },
    labels: {
      detail: 'Detail',
      goToTop: 'Ísť hore',
      show: 'Zobraziť',
      findMore: 'Zistiť viac',
    },
    segmentChart: {
      label: 'Vaša hodnota:',
      ref: 'REF.:',
    },
    stickChart: {
      label: 'Vaša hodnota:',
      ref: 'REF.:',
    },
    notifications: {
      result: (
        percentage: string | number,
        diagnose: string,
      ) => `${percentage} lekárov rovnakej odbornosti má nižšie
                    náklady na diagnózu. Vo zvolenej diagnóze vidíme najväčší
                    rozdiel v porovnaní s inými lekármi ${diagnose}.`,
    },
    segments: {
      V: 'Zdravotný výkon',
      S: 'SVLZ výkony',
    },
    tips: {
      title: 'Vysvetlenie pojmov a tipy na prácu s prehľadom nákladov.',
      description:
        '<li><b>Vaša hodnota</b> je náklad na lieky, zdravotnícke pomôcky, zdravotné výkony a SVLZ výkony pre jedného pacienta vo zvolenej diagnóze za dané obdobie. Hodnota je v eurách (€).</li><li><b>Referencia</b> (REF.:) je priemerný náklad v eurách, ktorý majú na pacienta ostatní lekári rovnakej odbornosti. Hodnota je vypočítaná vždy pre zvolenú diagnózu a za obdobie uvedené v grafe.</li><li>V grafoch vidíte porovnanie svojej hodnoty nákladov s referenciou.</li><li>Pre porovnanie s referenciou vo Vašom kraji zvoľte vo filtri možnosť <b>Kraj</b>.</li><li>Diagnózy sú vo filtri zoradené podľa frekvencie výskytu u Vašich pacientov. Prehľad o inej diagnóze zvolíte kliknutím na jej názov vo filtri. Diagnózu si môžete aj vyhľadať podľa názvu alebo kódu.</li><li>Detailný prehľad nákladov na lieky, zdravotnícke pomôcky, zdravotné výkony či SVLZ výkony nájdete po kliknutí na <b>Detail</b>.</li>',
      detailDescription:
        '<li><b>Vaša hodnota</b> je náklad na lieky, zdravotnícke pomôcky, zdravotné výkony a SVLZ výkony pre jedného pacienta vo zvolenej diagnóze za dané obdobie. Hodnota je v eurách (€).</li><li><b>Referencia</b> (REF.:) je priemerný náklad v eurách, ktorý majú na pacienta ostatní lekári rovnakej odbornosti. Hodnota je vypočítaná vždy pre zvolenú diagnózu a za obdobie uvedené v grafe.</li><li>V grafoch vidíte porovnanie svojej hodnoty nákladov s referenciou.</li><li>Pre porovnanie s referenciou vo Vašom kraji zvoľte vo filtri možnosť <b>Kraj</b>.</li><li>Diagnózy sú vo filtri zoradené podľa frekvencie výskytu u Vašich pacientov. Prehľad o inej diagnóze zvolíte kliknutím na jej názov vo filtri. Diagnózu si môžete aj vyhľadať podľa názvu alebo kódu.</li><li>Detailný prehľad nákladov nájdete po kliknutí na <b>Detail</b>.</li>',
      extraVAS: {
        L: '<li>Filter <b>Vami predpísané</b> obsahuje lieky predpísané Vami. Lieky, ktoré ste predpísali na odporúčanie iného poskytovateľa sa nezapočítavajú do Vašich nákladov, preto nie sú zahrnuté.</li><li>Filter <b>Predpísané iným poskytovateľom</b> obsahuje lieky predpísané iným poskytovateľom primárnej starostlivosti na Vašich kapitovaných pacientov. Započítavajú sa do Vašich nákladov.</li>',
        S: '<li>Filter <b>Vami indikované</b> obsahuje SVLZ výkony indikované Vami. SVLZ výkony, ktoré ste predpísali na odporúčanie iného poskytovateľa sa nezapočítavajú do Vašich nákladov, preto nie sú zahrnuté.</li><li>Filter <b>Indikované iným poskytovateľom</b> obsahuje SVLZ vyšetrenia indikované iným poskytovateľom primárnej starostlivosti na Vašich kapitovaných pacientov. Započítavajú sa do Vašich nákladov.</li>',
      },
      extraSAS: {
        L: '<li>Filter <b>Predpísané na Vaše odporúčanie</b> obsahuje lieky, ktoré ste odporučili a predpísal ich iný lekár.</li>',
        S: '<li>Filter <b>Indikované na Vaše odporúčanie</b> obsahuje výkony, ktoré ste odporučili a indikoval ich iný lekár.</li>',
      },
    },
  },
};
