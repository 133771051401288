import { useFetchReportData } from '../../../api/queries/reports';
import SkeletonLoader from './SkeletonLoader';
import { NoDataIllustration } from '../../../components/EmptyState';
import JsonObject from '../../../components/JsonObject/JsonObject';
import useStyles from '../styles';
import { ModalProvider } from '../../../providers/ModalProvider';
import strings from '../../../constants/strings';

const texts = strings.virtualBudget.reportDetail;

interface Props {
  isStructureError?: boolean;
  reportId: number;
  strFilter?: string;
  tabId: number;
}

const ReportContent = ({
  isStructureError,
  reportId,
  strFilter,
  tabId,
}: Props) => {
  const classes = useStyles();
  const { data, isError, isFetching } = useFetchReportData({
    reportId,
    strFilter,
    tabId,
  });

  if (isFetching || (!tabId && !isStructureError)) return <SkeletonLoader />;

  if (isError || isStructureError)
    return <NoDataIllustration illustration="error" title={texts.error} />;

  if (!data && !isFetching && tabId)
    return <NoDataIllustration title={texts.empty} />;

  return (
    <ModalProvider dynamicContent={data?.reportContent.modals}>
      <div className={classes.contentLayout}>
        <JsonObject data={data?.reportContent.pageContent || []} />
      </div>
    </ModalProvider>
  );
};

export default ReportContent;
