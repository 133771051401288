import strings from '../../../constants/strings';
import { NoDataIllustration } from '../../../components/EmptyState';
import SkeletonLoaderContent from './SkeletonLoader/SkeletonLoaderContent';
import {
  useFetchCnpDetail,
  useFetchProposalDocuments,
} from '../../../api/drugProposals';
import ContentCNP from './Content/ContentCNP';

interface Props {
  proposalId: number;
  proposalNumber: string;
}

const DetailCNP = ({ proposalId, proposalNumber }: Props) => {
  const { data, error, isFetching } = useFetchCnpDetail({
    detailType: 'CNP',
    proposalId,
    proposalNumber,
  });

  const documents = useFetchProposalDocuments({ proposalId, proposalNumber });

  const renderError = error && (
    <NoDataIllustration title={strings.technickaChyba} />
  );
  if (isFetching) return <SkeletonLoaderContent />;
  return (
    <>
      {renderError}
      {data && (
        <ContentCNP
          detail={data.detail}
          documents={documents}
          information={data.information}
          patient={data.patient}
          proposalDoctor={data.doctor}
          proposalId={proposalId}
        />
      )}
    </>
  );
};

export default DetailCNP;
