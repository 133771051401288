import { color } from '@dovera/design-system';
import { Path, Svg } from '@react-pdf/renderer';

const HourglassIcon = ({ ...props }) => (
  // @ts-ignore
  <Svg height="24" viewBox="0 0 24 24" width="24" {...props}>
    <Path
      d="M4.57918 2.66459C4.70623 2.4105 4.96593 2.25 5.25 2.25H18.75C19.0341 2.25 19.2938 2.4105 19.4208 2.66459C19.5479 2.91868 19.5205 3.22274 19.35 3.45L12.9375 12L19.35 20.55C19.5205 20.7773 19.5479 21.0813 19.4208 21.3354C19.2938 21.5895 19.0341 21.75 18.75 21.75H5.25C4.96593 21.75 4.70623 21.5895 4.57918 21.3354C4.45214 21.0813 4.47956 20.7773 4.65 20.55L11.0625 12L4.65 3.45C4.47956 3.22274 4.45214 2.91868 4.57918 2.66459ZM15.5409 6.02885L17.25 3.75H6.75L9.12305 6.91406L15.5409 6.02885ZM12 13.25L6.97132 19.9549L15.6958 18.1777L12 13.25Z"
      fill={color('black')}
    />
  </Svg>
);

export default HourglassIcon;
