import { createUseStyles } from 'react-jss';
import { ReactNode, useState } from 'react';
import routes from '../../../../../routes';
import {
  Bar,
  BarItem,
  Button,
  ButtonLayout,
  ButtonLink,
  Illustration,
  Modal,
  Notification,
  color,
} from '@dovera/design-system';
import { useNavigate } from 'react-router-dom';
import IconExternalLink from '../../../../../components/CustomIcons/IconExternalLink';
import SafeHtml from '../../../../../components/SafeHtml/SafeHtml';
import {
  StavSpracovaniaCL,
  StavyOdoslaniaEnum,
} from '../../../../../types/cakacky.types';
import strings from '../../../../../constants/strings';

const useStyles = createUseStyles({
  root: {
    height: '70vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: '30% auto 0',
    transform: 'translateY(-30%)',
    '& h4': {
      marginTop: 30,
    },
    '& .notification': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  successTextColor: { color: color('primary', 600) },
  errorTextColor: { color: color('error', 500) },
});

export enum BatchSaveState {
  isError = 'isError',
  isLoading = 'isLoading',
  isSuccess = 'isSuccess',
  isTechnicalError = 'isTechnicalError',
}

interface Props {
  results?: StavSpracovaniaCL[];
  state?: BatchSaveState;
}

type PageData = {
  description: ReactNode | string;
  illustration: ReactNode;
  title: ReactNode | string;
};

const { batchResults } = strings.cakacky;

const ResultState = ({ results, state }: Props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const [modalVisible, setModalVisible] = useState(false);
  const [errModalTitle, setErrModalTitle] = useState('');
  const [errModalContent, setErrModalContent] = useState('');

  const getPageData = (batchState?: BatchSaveState): PageData => {
    const defaultPageData: PageData = {
      illustration: (
        <Illustration height={256} name="large_waiting_state" width={256} />
      ),
      title: <h4>{batchResults.default.title}</h4>,
      description: (
        <SafeHtml html={batchResults.default.description} wrapper="div" />
      ),
    };

    switch (batchState) {
      case BatchSaveState.isSuccess:
        return {
          illustration: (
            <Illustration height={256} name="large_sucess_state" width={256} />
          ),
          title: (
            <h4 className={classes.successTextColor}>
              {batchResults.success.title}
            </h4>
          ),
          description: (
            <>
              <div className="mb-large">
                <SafeHtml html={batchResults.success.description} />
              </div>
              <ButtonLayout
                className="align-items-center"
                direction="horizontal"
              >
                <Button
                  className="mb-small"
                  onClick={() => navigate(routes.cakackyPrehladDavok)}
                >
                  {batchResults.success.btn}
                </Button>
              </ButtonLayout>
            </>
          ),
        };
      case BatchSaveState.isError:
        return {
          illustration: (
            <Illustration height={256} name="large_error_state" width={256} />
          ),
          title: (
            <h4 className={classes.errorTextColor}>
              {batchResults.error.title}
            </h4>
          ),
          description: (
            <SafeHtml html={batchResults.error.description} wrapper="div" />
          ),
        };
      case BatchSaveState.isTechnicalError:
        return {
          illustration: (
            <Illustration height={256} name="large_warning_state" width={256} />
          ),
          title: (
            <h4 className={classes.errorTextColor}>
              {batchResults.warning.title}
            </h4>
          ),
          description: (
            <>
              <div className="mb-large">{batchResults.warning.description}</div>
              <ButtonLayout
                className="align-items-center"
                direction="horizontal"
              >
                <Button
                  className="mb-small"
                  onClick={() => navigate(routes.cakackyOdoslanieDavok)}
                >
                  {batchResults.warning.btn}
                </Button>
              </ButtonLayout>
            </>
          ),
        };
      default:
        return defaultPageData;
    }
  };

  const renderResults = (list: StavSpracovaniaCL[]) =>
    list
      ?.filter((l) => !!l.chyboveHlasenieTxs)
      ?.map((r, index) => {
        let m = <SafeHtml html={r.nazovDavky} wrapper={'div'} />;
        const respStateData: {
          btnName: string;
          color: 'success' | 'error';
          linkClass: string;
        } =
          r.vysledokSpracovaniaTxs === StavyOdoslaniaEnum.uspesne
            ? {
                btnName: 'Zobraziť výsledok',
                color: 'success',
                linkClass: 'text-color-primary',
              }
            : {
                btnName: 'Zobraziť chyby',
                color: 'error',
                linkClass: 'text-color-error',
              };
        m = (
          <Bar className="no-mrg-bottom">
            <BarItem>
              <SafeHtml
                html={batchResults.errorWhileProcessing(r.nazovDavky)}
              />
            </BarItem>
            <BarItem>
              <ButtonLink
                className={`inline-btn icon-with-text ${respStateData.linkClass}`}
                onClick={() => {
                  setErrModalTitle(
                    `Výsledok spracovania dávky ${r.nazovDavky}`,
                  );
                  setErrModalContent(r.chyboveHlasenieTxs || '');
                  setModalVisible(true);
                }}
              >
                {respStateData.btnName}
                <IconExternalLink
                  color={color(respStateData.color, 600)}
                  id={`icon--${index}--show-errors`}
                />
              </ButtonLink>
            </BarItem>
          </Bar>
        );
        return (
          <Notification
            key={`result-ntf--${r.idDavky}-${r.nazovDavky}`}
            className="mb-xsmall"
            message={m}
            variant={
              r.vysledokSpracovaniaTxs === StavyOdoslaniaEnum.uspesne
                ? 'success'
                : 'error'
            }
          />
        );
      });

  return (
    <div className={classes.root}>
      {getPageData(state).illustration}
      <div className="mb">
        {getPageData(state).title}
        {getPageData(state).description}
      </div>

      <Modal
        closeOnOverlayClick={false}
        data-modal-initial-focus
        header={errModalTitle}
        id="vysledok-odoslania-cl-modal"
        isVisible={modalVisible}
        onHide={() => {
          setErrModalContent('');
          setErrModalTitle('');
          setModalVisible(false);
        }}
      >
        <SafeHtml html={errModalContent} withoutRemoveEmptyTd wrapper={'div'} />
      </Modal>

      <div className="mb-small content-center-horiz">
        <div className="fit-content">
          {state !== BatchSaveState.isLoading &&
            results?.some((s) => !!s.chyboveHlasenieTxs) &&
            renderResults(results)}
        </div>
      </div>
    </div>
  );
};

export default ResultState;
