import { createUseStyles } from 'react-jss';
import { getRem } from '@dovera/design-system';

export default createUseStyles({
  offline: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: getRem(600),
    textAlign: 'center',
    justifyContent: 'center',
    marginTop: '25vh',
    transform: 'translateY(-30%)',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& svg': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});
