import { arraySort } from '../../../utils/array.utils';
import SafeHtml from '../../SafeHtml/SafeHtml';
import { StructureType } from '../types';
import Icon from '../common/Icon';
import useStyles from '../styles';
import { cx } from '../../../utils/exports';

interface Props {
  props: StructureType;
}

const Structure = ({ props }: Props) => {
  const classes = useStyles();
  return (
    <div
      className={cx(
        classes.structureWrapper,
        props.attributes?.style,
        props.content?.some((p) => p.attributes?.text?.includes('h2')) &&
          'mb-small',
      )}
    >
      {props.content &&
        arraySort(props.content, 'order').map((p) => (
          <div key={p.order}>
            {p.type === 'html' && <SafeHtml html={p.attributes.text} />}
            {p.type === 'icon' && <Icon iconKey={p.attributes.id || ''} />}
          </div>
        ))}
    </div>
  );
};

export default Structure;
