import { Grid as DDSGrid, GridCol } from '@dovera/design-system';
import { GridAttributes } from '../../../types/jsonObjects.types';
import JsonObject from '../JsonObject';
import useStyles from '../styles';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { calculateResponsiveCols } from '../utils';
import useResize from '../../../hooks/useResize';

const Grid = ({ attributes, content }: GridAttributes) => {
  const classes = useStyles();
  const windowSize = useWindowSize();
  const { ref: gridRef, wrapperWidth } = useResize();

  return (
    <div ref={gridRef}>
      <DDSGrid className={classes.grid}>
        {content.map((item) => (
          <GridCol
            key={`grid--${item.order}`}
            size={calculateResponsiveCols(
              attributes.columns,
              wrapperWidth,
              windowSize.width,
            )}
          >
            <JsonObject data={[item]} />
          </GridCol>
        ))}
      </DDSGrid>
    </div>
  );
};

export default Grid;
