import { AutocompleteApp } from '@dovera/design-system';
import { Choice } from 'choices.js';
import { useEffect, useState } from 'react';
import { useQuery } from '../../hooks/useQuery';
import { OdbornostHodnoteneho } from '../../types/poskytovatel.types';
import useStyles from './SelectOdbornosti.styles';

interface Props {
  data: {
    kodOdbNz: string;
    kodTypZS: string;
    nazov: string;
    predvolena: boolean;
  }[];
  defaultValue?: string;
  expertise: OdbornostHodnoteneho;
  labelText: string;
  onChange: (
    newValue: string,
    kodOdbNz: string,
    kodTypZS: string,
    userAction: boolean,
    autoChange?: boolean,
  ) => void;
  placeholder?: string;
}

const SelectOdbornosti = ({
  data,
  defaultValue,
  expertise,
  labelText,
  onChange,
  placeholder,
}: Props) => {
  const [value, setValue] = useState<string | undefined>(defaultValue);

  const classes = useStyles();
  const query = useQuery();

  const nz = query.get('nz');
  const typZS = query.get('kodtypzs');

  useEffect(() => {
    if (expertise) {
      setValue(expertise.nazovOdbNz);
    }
  }, [expertise]);

  useEffect(() => {
    if (!nz || !typZS) return;
    const expertise = data.find(
      (d) => d.kodOdbNz === nz && (!typZS || typZS === d.kodTypZS),
    );
    if (!expertise) return;

    setValue(expertise.nazov);
    onChange(
      expertise.nazov,
      expertise.kodOdbNz,
      expertise.kodTypZS,
      false,
      true,
    );
  }, [data, nz, typZS, onChange]);

  const onSelect = (option) => {
    const expCode = option?.value.split('-')?.[0] || '';
    const healthCareCode = option?.value.split('-')?.[1] || '';
    const expertise = data.find(
      (d) => d.kodOdbNz === expCode && d.kodTypZS === healthCareCode,
    );
    if (!expertise) return;

    setTimeout(() => {
      setValue(expertise.nazov);
      onChange(expertise.nazov, expertise.kodOdbNz, expertise.kodTypZS, true);
    }, 50);
  };

  return (
    <AutocompleteApp
      className={classes.autocomplete}
      disableSuccessFrame
      id="autocomplete-expertise"
      inputType="input"
      label={labelText}
      minLength={0}
      onBlur={() => {
        if (!value) setValue(expertise.nazovOdbNz);
      }}
      onChange={(value: string) => setValue(value)}
      onSelect={onSelect}
      placeholder={placeholder}
      source={async (): Promise<Choice[]> =>
        data.map((item) => ({
          label: item.nazov,
          value: `${item.kodOdbNz}-${item.kodTypZS}`,
        }))
      }
      value={value}
    />
  );
};

export default SelectOdbornosti;
