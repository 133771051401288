import { getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  epNotifications: {
    '& > div': {
      marginBottom: getRem(32),
    },
  },
  pageContent: {
    '& > div:last-child': {
      marginBottom: 0,
      width: 'fit-content',
      maxWidth: getRem(700),
    },
  },
});
