import { useEffect } from 'react';
import { Button, Notification } from '@dovera/design-system';
import { useSelector } from 'react-redux';
import {
  changeActiveStep,
  changeStepStatus,
  storeStep1,
} from '../../../../slices/spaProposals.slice';
import strings from '../../../../constants/strings';
import { RootState } from '../../../../rootReducer';
import { useField, useForm } from 'react-final-form-hooks';
import { validationMsg } from '../../../../utils/form.utils';
import { VALIDATION } from '../../../../types/validation.types';
import { useAppDispatch } from '../../../../hooks/useStore';
import { StepWrapper } from '../common';
import { Form, Summary } from '../common/DoctorStep';
import { getListOfAvailableDoctors } from '../../../../api/spaProposals';
import { PROPOSAL_SPA_STEP_COUNT } from '../../../../constants/proposals';

const texts = strings.proposals.spa.new;
interface Props {
  onContinue: () => void;
}

const validate = (values: { [x: string]: any }) => {
  const errors: any = {};

  if (!values.doctor) errors.doctor = validationMsg(VALIDATION.RequiredEmpty);

  if (!values.ambulance)
    errors.ambulance = validationMsg(VALIDATION.RequiredEmpty);

  return errors;
};

const Step1 = ({ onContinue }: Props) => {
  const dispatch = useAppDispatch();
  const { pouzivatelId, vztahId } = useSelector(
    (state: RootState) => state.auth,
  );
  const { data, error, isLoading } = useSelector(
    (state: RootState) => state.spaProposals.new.data.doctors,
  );
  const {
    isReseted,
    stepper: { activeStep },
  } = useSelector((state: RootState) => state.spaProposals.new);
  const { step1 } = useSelector(
    (state: RootState) => state.spaProposals.new.stepper,
  );
  const { form, handleSubmit, values } = useForm({
    initialValues: {
      doctor: data?.length === 1 ? data[0].idLekarZdravPrac.toString() : '',
      ambulance:
        data?.length === 1 && data[0].ambulancie?.length === 1
          ? data[0].ambulancie[0].idNZ.toString()
          : '',
    },
    onSubmit: (values) => {
      dispatch(
        storeStep1({ ambulanceId: values.ambulance, doctorId: values.doctor }),
      );
      dispatch(changeStepStatus({ step: 1, status: 'completed' }));
      onContinue();
    },
    validate,
  });
  const doctorField = useField('doctor', form);
  const ambulanceField = useField('ambulance', form);
  useEffect(() => {
    if (!isLoading && !data)
      dispatch(
        getListOfAvailableDoctors({
          idPouzivatel: pouzivatelId || 0,
          idVZ: vztahId || 0,
        }),
      );
    // eslint-disable-next-line
  }, [dispatch, data, pouzivatelId, vztahId]);
  useEffect(() => {
    // if PZS has only 1 doctor with only 1 ambulance
    if (data?.length === 1 && data?.[0]?.ambulancie?.length === 1)
      handleSubmit();
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    if (isReseted) form.restart();
  }, [form, isReseted]);
  const renderButton = values.doctor && !error && (
    <>
      <div className="mb-small" />
      <Button className="no-mrg" onClick={() => handleSubmit()} submit>
        {texts.buttons.continue}
      </Button>
    </>
  );
  const renderError = error && <Notification message={error} variant="error" />;
  return (
    <StepWrapper
      activeStep={activeStep}
      changeActiveStep={changeActiveStep}
      // eslint-disable-next-line
      content={
        <form onSubmit={handleSubmit}>
          {renderError}
          {!error && (
            <Form
              ambulanceField={ambulanceField}
              doctorData={data}
              doctorField={doctorField}
              form={form}
              isLoading={isLoading}
              values={values}
            />
          )}
          {renderButton}
        </form>
      }
      step={1}
      stepCount={PROPOSAL_SPA_STEP_COUNT}
      stepStatus={step1.stepStatus}
      summary={<Summary ambulance={step1.ambulance} doctor={step1.doctor} />}
      title={texts.stepTitles.step1}
    />
  );
};

export default Step1;
