import { color, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 518,
    margin: '0 auto',
    '& img': {
      width: getRem(264),
    },
    '& h4': {
      color: color('black'),
      marginTop: 30,
    },
  },
  warning: {
    '& h4': {
      color: color('warning', 600),
    },
  },
  error: {
    '& h4': {
      color: color('error', 500),
    },
  },
  rootSuccess: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& img': {
      marginTop: 0,
      maxWidth: getRem(200),
    },
    '& div': {
      '& h6': {
        display: 'inline-flex',
        marginLeft: 11,
      },
      '& svg': {
        verticalAlign: 'middle',
        marginTop: -3,
      },
    },
  },
  rootSuccessDocs: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& img': {
      marginTop: -70,
      maxWidth: 299,
      marginLeft: 50,
      marginRight: 0,
    },
    '& div': {
      '& h6': {
        display: 'inline-flex',
        marginLeft: 11,
      },
      '& svg': {
        verticalAlign: 'middle',
        marginTop: -3,
      },
    },
  },
  wider: {
    maxWidth: '645px !important',
  },
  buttonLayout: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    '& button': {
      marginBottom: 0,
      '&:first-child': {
        marginRight: 28,
      },
    },
  },
});
