import { useCallback, useEffect, useState } from 'react';
import { Button, Icon } from '@dovera/design-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import ContentWrapper from '../../../../components/ContentWrapper/ContentWrapper';
import { RootState } from '../../../../rootReducer';
import routes from '../../../../routes';
import Dokumenty from './Dokumenty/Dokumenty';
import DoplnujuceDokumenty from './DoplnujuceDokumenty/DoplnujuceDokumenty';
import SpatneDavky from './SpatneDavky/SpatneDavky';
import { EPSections } from '../../../../constants/systemConstants';
import { InvoiceActionType } from '../../../../types/zuctovanie.types';
import { hasSection } from '../../../../utils/poskytovatel.utils';
import InvoiceActionModal from '../../components/Modals/InvoiceActionModal';
import DispensingRecordsCard from './DispensingRecords/DispensingRecordsCard';
import strings from '../../../../constants/strings';
import CardBubble from '../../../../components/CardBubble/CardBubble';

const { confirmation, invoiceAddition } =
  strings.zuctovanie.invoices.detail.buttons;

const DetailFaktury = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [canShowBtn, setCanShowBtn] = useState(true);
  const invoiceNumber = location.pathname.split(`${routes.faktury}/`)?.[1];
  const { idHz } = useSelector((state: RootState) => state.poskytovatel);
  const { detail } = useSelector(
    (state: RootState) => state.zuctovanie.invoices,
  );
  const [invoiceId, setInvoiceId] = useState(detail?.idFaktury || 0);

  const refreshDocuments = useCallback(() => {
    setCanShowBtn(false);
    setInvoiceId(0);
    if (detail?.idFaktury)
      setTimeout(() => {
        setInvoiceId(detail.idFaktury);
      }, 100);
  }, [detail]);

  useEffect(() => {
    if (!detail?.idFaktury) navigate(routes.faktury);
    setInvoiceId(detail?.idFaktury || 0);
  }, [detail, navigate]);

  const renderDolozenieBtn = canShowBtn &&
    detail?.stavFaktury === 'C' &&
    detail.zuctovanie2 && (
      <Button
        className="no-mrg content-center-vert"
        onClick={() => setModalVisible(true)}
      >
        <Icon
          className="icon--left"
          id="btn-plus-invoice--icon"
          name="plus"
          size="medium"
        />
        {invoiceAddition}
      </Button>
    );

  const renderAcceptInvoiceBtn = canShowBtn &&
    detail?.stavFaktury === 'C' &&
    !detail.zuctovanie2 && (
      <Button
        className="no-mrg content-center-vert"
        onClick={() => setModalVisible(true)}
      >
        {confirmation}
      </Button>
    );

  const isInvoiceInReview =
    detail?.stavFaktury === 'R' && hasSection(EPSections.Fkt, 'Zuctovanie');

  const renderContent = isInvoiceInReview ? (
    <DoplnujuceDokumenty
      invoiceId={invoiceId || 0}
      refreshDocuments={refreshDocuments}
    />
  ) : (
    <>
      <SpatneDavky invoiceId={invoiceId || 0} />
      {detail?.stavFaktury === 'C' && (
        <InvoiceActionModal
          invoiceSum={detail.fakturovanaSuma}
          isVisible={modalVisible}
          onCancel={() => setModalVisible(false)}
          onConfirm={() => {
            setModalVisible(false);
            setTimeout(() => {
              navigate(routes.faktury);
            }, 300);
          }}
          refreshDocuments={refreshDocuments}
          type={
            detail.zuctovanie2
              ? InvoiceActionType.Dolozenie
              : InvoiceActionType.Potvrdenie
          }
        />
      )}
    </>
  );

  return (
    <ContentWrapper className="no-mrg" id="zuc-container">
      <CardBubble>
        <Dokumenty
          idHZ={idHz}
          invoiceId={invoiceId}
          invoiceNumber={invoiceNumber}
          invoiceState={detail?.stavFaktury || ''}
        />
        {renderAcceptInvoiceBtn}
        {hasSection(EPSections.Fkt, 'Zuctovanie') && renderDolozenieBtn}
      </CardBubble>

      {renderContent}
      {!isInvoiceInReview && hasSection(EPSections.DispZaz, 'Zuctovanie') && (
        <DispensingRecordsCard
          invoiceId={invoiceId}
          invoiceNumber={invoiceNumber}
        />
      )}
    </ContentWrapper>
  );
};

export default DetailFaktury;
