import useStyles from './AppError.styles';
import { Button, Illustration } from '@dovera/design-system';
import SafeHtml from '../../components/SafeHtml/SafeHtml';
import strings from '../../constants/strings';

const AppError = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.root} id="notSupportedBrowser">
        <Illustration
          height={256}
          name="large_warning_state"
          size="large"
          width={256}
        />
        <SafeHtml
          className="mb-xsmall text-color-warning"
          html={strings.technickaChyba}
          wrapper="h4"
        />
        <p>
          Ak bude chyba pretrvávať, napíšte nám na{' '}
          <a href="mailto:poskytovatel@dovera.sk">poskytovatel@dovera.sk</a>
        </p>
        <Button
          className={classes.button}
          onClick={() => {
            window.location.href = '/';
          }}
        >
          Späť na úvodnú stránku
        </Button>
      </div>
    </div>
  );
};

export default AppError;
