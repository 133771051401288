import { Button, ButtonLayout } from '@dovera/design-system';
import { ReactNode } from 'react';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';
import { cx } from '../../../../utils/exports';
import useStyles from './styles';

interface Props {
  btnCancel?: string;
  btnSubmit?: string;
  children?: any;
  className?: any;
  description?: ReactNode;
  illustration?: ReactNode;
  isDestructive?: boolean;
  isLoadingSubmit?: boolean;
  onCancelClick?: () => void;
  onSubmitClick?: () => void;
  title?: string;
}

const ModalContent = ({
  btnCancel,
  btnSubmit,
  children,
  className,
  description,
  illustration,
  isDestructive,
  isLoadingSubmit,
  onCancelClick,
  onSubmitClick,
  title,
}: Props) => {
  const classes = useStyles();
  if (children)
    return (
      <div className={cx(classes.modalContent, className)}>{children}</div>
    );
  return (
    <div className={cx(classes.modalContent, className)}>
      {illustration}
      <SafeHtml className="mb-xsmall" html={title} wrapper="h4" />
      <SafeHtml className="mb" html={description} wrapper="div" />
      <ButtonLayout direction="horizontal">
        <Button
          isLoading={isLoadingSubmit}
          onClick={onSubmitClick}
          type={isDestructive ? 'destructive' : undefined}
        >
          {btnSubmit}
        </Button>
        <Button onClick={onCancelClick} type="secondary">
          {btnCancel}
        </Button>
      </ButtonLayout>
    </div>
  );
};

export default ModalContent;
