import { Icon, Pagination, TableApp, color } from '@dovera/design-system';
import strings from '../../../constants/strings';
import { KontaktnaOsoba } from '../../../types/profil.types';
import { formatPhone, hotjarMasking } from '../../../utils/strings.utils';
import { useState } from 'react';
import useStyles from './KontaktneOsoby.styles';

const { labels } = strings.profile.contactPersons;

interface Props {
  data: KontaktnaOsoba[][];
}

const Table = ({ data }: Props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  return (
    <TableApp
      caption=""
      className="table-caption--hide"
      columns={[
        {
          Header: labels.name,
          accessor: 'col1',
        },
        {
          Header: labels.phone,
          accessor: 'col2',
        },
        {
          Header: labels.email,
          accessor: 'col3',
        },
        {
          Header: labels.mandate,
          accessor: 'col4',
        },
      ]}
      data={data[page].map((d) => ({
        col1: (
          <div className={classes.nameRole}>
            {hotjarMasking(d.menoKontaktnejOsoby || '-', 'b')}
            <br />
            {hotjarMasking(
              d.nazovTypOsoby,
              'span',
              'text-color-grey-light font-weight-normal',
            )}
          </div>
        ),
        col2: (
          <div className={classes.phoneNum}>
            {d.kontaktnyUdaj
              .filter(
                (u) =>
                  u.kodTypKontaktUdaj === 'PL' || u.kodTypKontaktUdaj === 'M',
              )
              .map((k, key) => (
                <>
                  {key > 0 && <br />}
                  <span key={`telefon-${key}`}>
                    {hotjarMasking(
                      formatPhone(k.kontakt, k.kodTypKontaktUdaj === 'M'),
                    )}
                  </span>
                </>
              ))}
            {!d.kontaktnyUdaj.some(
              (u) =>
                u.kodTypKontaktUdaj === 'PL' || u.kodTypKontaktUdaj === 'M',
            ) && '-'}
          </div>
        ),
        col3: (
          <div className={classes.email}>
            {d.kontaktnyUdaj
              .filter((u) => u.kodTypKontaktUdaj === 'E')
              .map((k, key) => (
                <>
                  {key > 0 && <br />}
                  <span key={`email-${key}`}>{hotjarMasking(k.kontakt)}</span>
                </>
              ))}
            {!d.kontaktnyUdaj.some((u) => u.kodTypKontaktUdaj === 'E') && '-'}
          </div>
        ),
        col4: (
          <div className="content-center-horiz">
            {d.plnaMoc ? (
              <Icon color={color('primary', 700)} name="check" size="medium" />
            ) : (
              <Icon color={color('error', 600)} name="close" size="medium" />
            )}
          </div>
        ),
      }))}
      // @ts-ignore
      pagination={
        <Pagination
          currentPage={page + 1}
          onChange={(page) => setPage(page - 1)}
          totalPages={data.length}
        />
      }
    />
  );
};

export default Table;
