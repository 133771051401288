import {
  Button,
  Card,
  CardSection,
  Illustration,
  PageSection,
} from '@dovera/design-system';
import { useCallback, useEffect } from 'react';
import useStyles from './styles';
import { useQuery } from '../../hooks/useQuery';
import routes from '../../routes';
import { useNavigate } from 'react-router';
import { isJWTValid } from '../../utils/auth.utils';
import { cx } from '../../utils/exports';

const LogoutPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const query = useQuery();
  const isAutoLogout = query.get('auto');
  const loginCallback = useCallback(() => {
    navigate(routes.login);
  }, [navigate]);
  useEffect(() => {
    setTimeout(() => {
      if (isJWTValid()) navigate(routes.dashboard);
    }, 200);
  }, [navigate]);
  return (
    <PageSection bg="grey" className={classes.root}>
      <Card>
        <CardSection isCondensed>
          <div className="align-items-center">
            <Illustration
              className="mb-small"
              height={256}
              name={isAutoLogout ? 'large_warning_state' : 'large_sucess_state'}
              width={256}
            />
            {isAutoLogout ? (
              <h4 className={cx(isAutoLogout && 'is-warning')}>
                Boli ste odhlásený z dôvodu nečinnosti
              </h4>
            ) : (
              <h4 className="text-color-success">Boli ste úspešne odhlásený</h4>
            )}
            <Button onClick={loginCallback}>Prihlásiť sa</Button>
          </div>
        </CardSection>
      </Card>
    </PageSection>
  );
};

export default LogoutPage;
