import { Skeleton } from '@dovera/design-system';

const CampaignListWidget = () => (
  <div>
    <h1 className="text-left mb-small">
      <Skeleton width="75%" />
    </h1>
    <p className="no-mrg-bottom">
      <Skeleton width="45%" />
    </p>
    <p className="no-mrg-bottom">
      <Skeleton width="35%" />
    </p>
  </div>
);

export default CampaignListWidget;
