export default {
  diagnozy: {
    ziadneDiagnozy:
      'Diagnózy sú zobrazované len pre konkrétnu odbornosť (vyberte odbornosť v hornej lište)',
    pocet: {
      title: 'Počet pacientov',
      titleDoctor: 'Počet pacientov',
      tooltip: 'Pacienti, ktorých ste diagnostikovali na uvedenú diagnózu',
    },
    pocetSR: {
      title: 'Počet pacientov',
      tooltip:
        'Pacienti, ktorých diagnostikovali kolegovia vo Vašej odbornosti na celom Slovensku. Výberom okresu alebo kraja viete porovnať počet diagnostikovaných pacientov pre akýkoľvek okres alebo kraj.',
    },
    podiel: {
      title: 'Podiel ku všetkým Vašim pacientom',
      titleDoctor: 'Podiel ku všetkým Vašim pacientom',
      tooltip:
        '% pacientov s danou diagnózou voči ostatným, ktoré ste diagnostikovali vo Vašej ambulancii',
    },
    podielSR: {
      title: 'Podiel ku všetkým pacientom v rámci SR',
      tooltip:
        '% pacientov s danou diagnózou voči ostatným, ktoré diagnostikovali Vaši kolegovia v danej odbornosti',
    },
    vyskytDiagnoz: {
      title: 'Pre porovnanie vyberte kraj alebo okres:',
      tooltip:
        'Výberom okresu alebo kraja viete porovnať počet diagnostikovaných pacientov aj voči priemeru pre akýkoľvek okres alebo kraj.',
    },
  },
  pacienti: {
    POC_OSET: {
      title: 'Ošetrení unikátni pacienti',
      tooltip:
        'Pacienti poistení v SR, ktorých ste ošetrili za uvedené obdobie. Ak Vašu ambulanciu pacient navštívil viackrát, je uvedený len raz. Ak máte vo svojej ambulancii evidovaných aj kapitovaných poistencov, títo nie sú započítaní ako unikátni ošetrení pacienti.',
    },
    POC_OSET_EU: {
      title: 'Ošetrení unikátni pacienti (EÚ)',
      tooltip:
        'Pacienti poistení v EÚ. Ak Vašu ambulanciu pacient navštívil viackrát, je uvedený len raz.',
    },
    POC_KONT: {
      title: 'Kontakt s unikátnymi pacientami',
      tooltip:
        'Osobné návštevy, telefonické konzultácie, t.j. všetky interakcie s pacientami kedy bola vykázaná ZS za uvedené obdobie.',
    },
    POC_NOVODIAG: {
      title: 'Novodiagnostikovaní pacienti',
      tooltip:
        'Pacienti, ktorí boli  Vami diagnostikovaní na novú chorobu za obdobie posledného roka a súčasne nemali žiadny kontakt s lekárom, napríklad vyšetrenie alebo telefonickú konzultáciu na danú diagnózu za obdobie posledné 2 roky.',
    },
    POC_NAROC: {
      title: 'Najdrahší pacienti',
      tooltip:
        'Pacienti, ktorých zdravotný stav spôsobuje vysoké náklady. Títo pacienti sa nezohľadňujú ani pri parametri: "Náklady na ZS" ak sa pre danú ambulanciu vypočítava.',
    },
    POC_KAPIT: {
      title: 'Kapitovaní pacienti',
      tooltip:
        'Pacienti, ktorí sú evidovaní vo Vašej ambulancii s podpísanou dohodou.',
    },
    IR: {
      title: 'Index rizika pacienta',
      tooltip:
        'Hodnota na základe, ktorej je možné porovnať rizikovosť pacienta. Pacient s priemerným rizikom má hodnotu 1,0. Ak je hodnota nižšia ako 1,0 máte pacientov s menším rizikom a ak je vyššia ako 1,0 tak s vyšším rizikom.',
    },
    prehlad: {
      title: 'Prehľad zdravotnej starostlivosti',
      stats: {
        title: 'Aktuálne štatistiky',
        riskiness: 'Rizikovosť zohľadňujeme pri výpočte parametra',
        expenses: 'Náklady',
      },
      detail: {
        basefilterLabel: 'Prehľad zdravotnej starostlivosti',
        patientsTypes: 'Typy pacientov',
        modal: {
          age: (age: number) => `${age} rokov`,
          exclusionLimitInExpertise:
            'Hranica vylúčenia pacienta v danej odbornosti:',
          totalExpenses:
            'Celkové náklady na POI u všetkých lekárov v odbornosti:',
          yourCostsForPatient: 'Váš náklad na daného pacienta:',
          patientAmongMostExpensive:
            'Pacient patrí medzi 2% najdrahších pacientov.',
          cancel: 'Zrušiť',
        },
      },
      ZS: {
        title: 'Čerpanie zdravotnej starostlivosti',
        titleFilter: 'Čerpanie zdravotnej starostlivosti',
        ZS_HOSP: {
          title: 'Hospitalizovaní pacienti',
          description: () =>
            'Tu zobrazujeme Vašich pacientov, ktorí v poslednom roku absolvovali aspoň 1 hospitalizáciu.',
          tooltip:
            'Pacienti, ktorých ste ošetrili a zároveň boli hospitalizovaní za uplynulé obdobie. ',
        },
        POC_NAROC: {
          title: 'Najdrahší pacienti',
          description: () =>
            'Tu si môžete pozrieť Vašich najnákladnejších pacientov. Zobrazujeme 50 Vašich pacientov v poradí od najdrahšieho.',
          tooltip:
            'Pacienti s najvyššími nákladmi, ktorých ošetrujete a ktorým indikujete ďalšiu zdravotnú starostlivosť',
          tooltipCard1:
            'Informatívna suma za celkové náklady na pacienta, ktoré ste vytvorili (Vaše a odporúčané) za lieky, SVLZ a výkony. Táto suma nevstupuje do výpočtu hodnotiacich parametrov.',
          tooltipCard2:
            'Suma po očistení 2% najdrahších pacientov a po odpočítaní vylúčených výkonov, napríklad preventívne prehliadky, očkovanie, ...)',
        },
        ZS_NEOCK: {
          title: 'Pacienti nezaočkovaní proti covidu-19',
          description: (obdobieOd: string, obdobieDo: string) =>
            obdobieOd && obdobieDo
              ? `Informácie o zaočkovaných pacientoch dostávame do poisťovne s mesačným oneskorením a aktualizujeme ich do 10. dňa v mesiaci. Napríklad: Ak bol pacient očkovaný v máji, do prehľadu sa táto informácia dostane po 10. júli. Údaje o rizikových pacientoch sú vyhodnotené za obdobie <b>${obdobieOd}</b> až <b>${obdobieDo}</b>. Definíciu diagnózy, ktorá môže zvýšiť riziko závažného priebehu covidu-19 nájdete vo <a href="https://www.slov-lex.sk/static/pdf/2021/96/ZZ_2021_96_20210605.pdf" target="_blank">vyhláške</a>.`
              : '',
          tooltip:
            'Nezaočkovaní pacienti podľa vekových skupín a diagnózy, ktorá môže zvýšiť riziko závažného priebehu covidu-19.',
        },
        ZS_POS_COV: {
          title: 'Pacienti vhodní na podanie monoklonálnych protilátok',
          description: () =>
            'Na základe vykázaného laboratórneho vyšetrenia (PCR test) môžu títo pacienti spĺňať <b>indikačné kritéria na liečbu monoklonálnymi protilátkami (MAB).</b><br /><br />Zobrazujeme pacientov:<br /><ul class="no-mrg-bottom"><li>nad 65 rokov bez ohľadu na pridružené diagnózy,</li><li>mladších ako 65 rokov, ktorí majú diagnózu spôsobujúcu ťažký priebeh covidu-19.</li></ul><br />Predtým, ako pacientovi vystavíte výmenný lístok a objednáte ho na podanie MAB, <b>skontrolujte, prosím, jeho zdravotný stav</b>, či spĺňa indikačné kritériá na podanie MAB.<br /><br /><b>Dopravnú zdravotnú službu</b> je možné poskytnúť pacientom, ktorým zdravotný stav neumožňuje si zabezpečiť vlastnú dopravu do centra.',
          tooltip:
            'Vaši kapitovaní pacienti, ktorým môže byť indikované podanie liečby monoklonálnymi protilátkami.',
        },
      },
      CHR: {
        title: 'Chronickí pacienti vo Vašom kmeni',
        titleFilter: 'Chronickí pacienti',
        CHR_HYP: {
          title: 'Diagnostikovaní hypertonici',
          description: () =>
            'Nižšie nájdete podiel hypertonikov vo Vašom kmeni a informáciu o rozdelení starostlivosti o nich medzi Vás a lekárov - špecialistov.',
          tooltip:
            'Informácie o pacientoch s hypertenziou vrátane informácie o farmakoterapii a prípadných komorbiditách pacienta',
        },
        CHR_HYP_STAR: {
          title: 'Hypertonici s možnou zmenou v starostlivosti',
          description: () =>
            'Pri týchto pacientoch sme indentifikovali možnosť presunu starostlivosti medzi Vami a lekárom - špecialistom. Zvážte, kedy je vhodná liečba pacienta výhradne vo Vašej starostlivosti a kedy je potrebná spolupráca so špecialistom.',
          tooltip:
            'Informácie o štruktúre pacientov s hypertenziou vo Vašom kmeni podľa komorbidít a o rozložení starostlivosti medzi Vás a lekárov ŠAS.',
        },
        CHR_DIA: {
          title: 'Diagnostikovaní diabetici',
          description: () =>
            'Nižšie nájdete prehľad diabetikov vo Vašej starostlivosti a informácie o priebehu edukácie pacientov.',
          tooltip:
            'Informácie o pacientoch s diabetom vrátane informácie o potrebe edukácie pacienta',
        },
        CHR_DISP: {
          title: 'Dispenzarizovaní pacienti',
          description: () =>
            'Na tejto stránke nájdete prehľad Vašich dispenzarizovaných pacientov. Na ľavo môžete vyhľadať konkrétneho pacienta alebo vybrať zoznam pacientov podľa diagnózy, s ktorou sú dispenzarizovaní.',
          tooltip:
            'Informácie o všetkých dispenzarizovaných pacientoch, ktorých máte vo svojej starostlivosti',
        },
        CHR_GDM: {
          title: 'Gestačné diabetičky',
          description: (text: string) =>
            `Tu môžete vidieť prehľad gestačných diabetičiek, u ktorých od pôrodu neevidujeme kontrolu (oGTT, HbA1c alebo vyšetrenie glykémie) a sú vo vyššom riziku rozvoja diabetu 2.typu, po ktorom treba pátrať celoživotne. Údaje sú aktualizované k ${text}.`,
          tooltip:
            'Tehotné pacientky s rizikom rozvoja diabetu 2. typu a prediabetu.',
          tooltipDiscard: 'Pacientku môžete zo zoznamu vyradiť.',
          archiv: {
            description:
              'Po vyradení už pacientku v zozname neuvidíte a nemôžete ho vrátiť späť.',
            title: 'Naozaj chcete pacienta vyradiť?',
          },
        },
      },
      FARM: {
        title: 'Farmakoterapia Vašich pacientov',
        titleFilter: 'Farmakoterapia',
        FARM_POSUD: {
          title: 'Pacienti s klinickým posudkom',
          description:
            'Pacienti, ktorým bola počas hospitalizácie prehodnotená farmakoterapia. Odporúčania na úpravu medikácie nájdete v prepúšťacej správe pacienta.',
          tooltip:
            'Pacienti, ktorým bola počas hospitalizácie prehodnotená farmakoterapia. Odporúčania na úpravu medikácie nájdete v prepúšťacej správe pacienta.',
          tooltipDiscard: 'Pacienta môžete zo zoznamu vyradiť.',
          archiv: {
            description:
              'Po vyradení už pacienta v zozname pacientov s klinickým posudkom neuvidíte a nemôžete ho vrátiť späť.',
            title: 'Naozaj chcete pacienta vyradiť?',
          },
        },
        FARM_PROB01: {
          title: 'Pacienti s medikačným problémom',
          description:
            'Pacienti s možným klinickým problémom v preskripcii liekov. Viac o probléme a možnostiach riešenia nájdete v odbornom usmernení Inhibítory protónovej pumpy – nesprávna indikácia.',
          tooltip:
            'Pacienti s možným klinickým problémom v preskripcii liekov.',
          tooltipDiscard: 'Pacienta môžete zo zoznamu vyradiť.',
          archiv: {
            description:
              'Po vyradení už pacienta v zozname pacientov s medikačným problémom neuvidíte a nemôžete ho vrátiť späť.',
            title: 'Naozaj chcete pacienta vyradiť?',
          },
        },
        FARM_HYP_NESP: {
          title: 'Hypertonici s neštandardnou farmakoterapiou',
          description:
            'V tejto časti nájdete informácie o hypertonikoch bez komorbidít alebo s diabetom, pri ktorých môže nastávať nesúlad farmakoterapie so štandardom.',
          tooltip:
            'Informácie o hypertonikoch bez komorbidít alebo s diabetom, pri ktorých môže nastávať nesúlad farmakoterapie so štandardom.',
        },
        FARM_HYP_NEVH: {
          title: 'Hypertonici s možnou nevhodnou kombináciou liekov',
          description:
            'Pri týchto pacientoch môže dochádzať k nevhodným, resp. kontraindikovaným kombináciám liekov vo farmakoterapii.',
          tooltip:
            'Informácie o hypertonikoch, pri ktorých môže dochádzať k nevhodnej kombinácii liekov vo farmakoterapii.',
        },
        FARM_POLY: {
          title: 'Pacienti s väčším počtom užívaných liekov',
          description:
            'Pacienti, ktorí užívajú dlhodobo viacero druhov liekov. Úpravou preskripcie je možné znížiť riziká ako sú interakcie, kontraindikácie a nežiadúce účinky.<br /><br />U pacientov označených „Pacient s klinickým posudkom” farmakoterapiu prehodnotil klinický farmakológ/farmaceut počas hospitalizácie. Odporúčania k úprave medikácie nájdete v prepúšťacej správe.',
          tooltip:
            'Pacienti, ktorí užívajú vyšší počet liekov a bolo by vhodné prehodnotiť užívané lieky.',
        },
        FARM_NEVYB: {
          title: 'Nevybrané lieky ',
          description:
            'Tu si môžete pozrieť informácie o pacientoch, o ktorých evidujeme, že si v lekárni nevybrali Vami predpísané lieky. Pacienti sú zobrazení od posledného predpisu lieku. V prípade, že v zozname nenájdete pacienta, ktorého hľadáte, môžete použiť vyhľadávanie cez meno alebo rodné číslo pacienta. V zozname liekov sa môžu nachádzať aj lieky, ktoré pacient vybral, avšak lekáreň informáciu o ich výbere neposkytla zdravotnej poisťovni.',
          tooltip:
            'Pacienti, o ktorých evidujeme, že si nevybrali predpísaný liek a tým pádom by mohla byť ovplyvnená ich následná liečba',
        },
        FARM_ZAM: {
          title: 'Zamenený liek v lekárni',
          description:
            'Tu si môžete pozrieť informácie o liekoch, ktoré boli Vašim pacientom v lekárni zamenené za iný liek. Obsahuje zoznam liekov, ktoré sú predpisované lekárom, avšak lekáreň ich, napríklad z dôvodu aktuálneho nedostatku liekov alebo iných okolností, zamenila za iný liek. V tomto zozname však nie sú uvedené lieky, ktoré sa zamieňajú v rámci predpisu na základe účinnej látky (generická substitúcia).',
          tooltip:
            'Pacienti, ktorým aj napriek predpisu konkrétneho lieku bol zamenený v lekárni iný liek.',
        },
      },
    },
  },
};
