import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import routes, { diagnozyRoutes, kapRoute, navrhyRoutes } from '../../routes';
import {
  MenuItemDropdown,
  openItem,
  toggleItem,
  toggleMenu,
  wrapAllMenuItems,
} from '../../slices/menu.slice';
import { RootState } from '../../rootReducer';
import { NavigationProps } from '../Navigation/Navigation';
import { useLocation } from 'react-router';
import { canShowPatientTypes } from '../../utils/pacienti.utils';
import { useWindowSize } from '../../hooks/useWindowSize';
import {
  Icon,
  Navigation,
  NavigationList,
  breakpoints,
} from '@dovera/design-system';
import { inlineStrCondition } from '../../utils/app.utils';
import { useAppDispatch } from '../../hooks/useStore';
import strings from '../../constants/strings';
import useStyles from './Menu.styles';
import {
  getNavigationItems,
  getNavigationItemsAsoc,
} from '../../utils/navigation';
import MenuSkeleton from './MenuSkeleton';
import { cx } from '../../utils/exports';
import NavigationPZSPanel from '../NavigationPZSPanel/NavigationPZSPanel';
import { ExpertisesCombo } from '../SelectOdbornosti';
import { useAuthContext } from '../../hooks/useAuthContext';
import { noAccess } from '../../utils/auth.utils';
import { useFetchMenuItems } from '../../api/queries/reports';
import { ReportThemes } from '../../types/reports.types';

const { submenu } = strings.app;

const Menu = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const authContext = useAuthContext();
  const { isASOC, token } = useSelector((state: RootState) => state.auth);
  const { isOpen } = useSelector((state: RootState) => state.menu);
  const { kodyHodnoty } = useSelector(
    (state: RootState) => state.pacienti.prehladPacientov.prehlad,
  );
  const { isLoaded, isLoading, kodTypZs, odbornosti, poslednaZmenaOdbornosti } =
    useSelector((state: RootState) => state.poskytovatel);
  const menuParameters = useSelector(
    (state: RootState) => state.parameters.menuParameters,
  );

  const [isWrapped, setIsWrapped] = useState(false);

  const location = useLocation();
  const windowSize = useWindowSize();

  const selectedExp = odbornosti.find(
    (exp) =>
      exp.kodOdbNz === poslednaZmenaOdbornosti && exp.kodTypZS === kodTypZs,
  );

  const { data } = useFetchMenuItems({
    expCode: poslednaZmenaOdbornosti,
    healthCareCode: kodTypZs,
    themes: [
      ...(selectedExp?.povolenyVstupNAKL ? [ReportThemes.Expenses] : []),
      ...(selectedExp?.povolenyVstupVB ? [ReportThemes.VB] : []),
    ],
  });

  useEffect(() => {
    /** call only with reload */
    /** wrap all menu items and unwrap only correct subitems, which child is currently active */
    if (
      (location.pathname.includes(routes.parameters) ||
        location.pathname.includes(routes.pp) ||
        location.pathname.includes(routes.pdp) ||
        location.pathname.includes(navrhyRoutes.index) ||
        location.pathname.includes(kapRoute) ||
        location.pathname.includes(diagnozyRoutes.index)) &&
      !isLoaded
    ) {
      dispatch(wrapAllMenuItems());
      if (location.pathname.includes(routes.parameters)) {
        dispatch(toggleItem(MenuItemDropdown.zdravotnaStarostlivost));
        dispatch(toggleItem(MenuItemDropdown.parametre));
        if (location.pathname.includes('kvalita'))
          dispatch(toggleItem(MenuItemDropdown.kvalita));
        if (location.pathname.includes('efektivnost'))
          dispatch(toggleItem(MenuItemDropdown.efektivnost));
        if (location.pathname.includes('inovacie'))
          dispatch(toggleItem(MenuItemDropdown.inovacie));
      }
      if (
        location.pathname.includes(routes.pp) ||
        location.pathname.includes(routes.pdp) ||
        location.pathname.includes(diagnozyRoutes.index)
      ) {
        dispatch(toggleItem(MenuItemDropdown.zdravotnaStarostlivost));
        dispatch(toggleItem(MenuItemDropdown.pacienti));
        if (location.pathname.includes(routes.pp))
          dispatch(toggleItem(MenuItemDropdown.prehladPacientov));
      }
      if (location.pathname.includes(navrhyRoutes.index)) {
        dispatch(toggleItem(MenuItemDropdown.navrhy));
      }
      if (location.pathname.includes(kapRoute))
        dispatch(toggleItem(MenuItemDropdown.pacienti));
    }
    if (isOpen) {
      toggleMenu();
      setIsWrapped(false);
    }
    // eslint-disable-next-line
  }, [location.pathname, isLoaded]);
  useEffect(() => {
    if (windowSize.width < breakpoints.l && isOpen) dispatch(toggleMenu());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname, windowSize.width]);

  useEffect(() => {
    if (
      (location.pathname.includes(routes.parameters) ||
        location.pathname.includes(routes.pp) ||
        location.pathname.includes(navrhyRoutes.index) ||
        location.pathname.includes(kapRoute) ||
        location.pathname.includes(routes.vbIndex)) &&
      isLoaded
    ) {
      if (location.pathname.includes(routes.parameters)) {
        dispatch(openItem(MenuItemDropdown.parametre));
      }
      if (location.pathname.includes(routes.vbIndex)) {
        dispatch(openItem(MenuItemDropdown.vb));
      }
      if (location.pathname.includes(navrhyRoutes.index)) {
        dispatch(openItem(MenuItemDropdown.navrhy));
      }
      if (
        location.pathname.includes(kapRoute) ||
        location.pathname.includes(routes.pp)
      ) {
        dispatch(openItem(MenuItemDropdown.zdravotnaStarostlivost));
        dispatch(openItem(MenuItemDropdown.pacienti));
      }
    }
  }, [location.pathname, isLoaded, dispatch]);

  useEffect(() => {
    if (isOpen) setTimeout(() => setIsWrapped(true), 100);
  }, [isOpen]);

  const pacientiRoutes: NavigationProps[] = [];
  if (canShowPatientTypes('ZS', kodyHodnoty))
    pacientiRoutes.push({
      route: routes.ppCerpanieZS,
      title: submenu.zs,
    });
  if (canShowPatientTypes('FARM', kodyHodnoty))
    pacientiRoutes.push({
      route: routes.ppFarmakoterapia,
      title: submenu.farm,
    });
  if (canShowPatientTypes('CHR', kodyHodnoty))
    pacientiRoutes.push({
      route: routes.ppChronicki,
      title: submenu.chr,
    });
  /** Ak nema ziadne povolene sekcie alebo token */
  if (noAccess(!token || location.pathname.includes(routes.autentifikacia)))
    return <div />;

  if (!isLoaded || isLoading) return <MenuSkeleton />;

  if (windowSize.width < breakpoints.l) {
    return (
      <div
        className={cx(classes.navigationMobileWrapper, isOpen && 'is-active')}
      >
        <div
          className={cx(classes.navigationMobile)}
          style={{
            right: 0,
            transform:
              windowSize.width <= breakpoints.l
                ? inlineStrCondition(
                    isWrapped,
                    'translateX(0px)',
                    'translateX(340px)',
                  )
                : 'none',
          }}
        >
          <button
            className={classes.btnClose}
            id="menu-close"
            onClick={() => {
              setIsWrapped(false);
              setTimeout(() => dispatch(toggleMenu()), 500);
            }}
            type="button"
          >
            <Icon name="close" size="large" />
          </button>
          <NavigationPZSPanel />
          <ExpertisesCombo />
          <NavigationList
            items={
              isASOC
                ? getNavigationItemsAsoc()
                : getNavigationItems({
                    menuThemes: data?.themes ?? [],
                    parameterMenuGroups: menuParameters?.groups ?? [],
                    patientCodes: kodyHodnoty,
                  })
            }
          />
          <button
            className={classes.logout}
            onClick={() => authContext.logout(routes.logout)}
            type="button"
          >
            <Icon name="log-out" size="medium" />
            Odhlásiť sa
          </button>
        </div>
      </div>
    );
  }

  return (
    <Navigation
      className={classes.navigation}
      hasBackLink={false}
      items={
        isASOC
          ? getNavigationItemsAsoc()
          : getNavigationItems({
              menuThemes: data?.themes ?? [],
              parameterMenuGroups: menuParameters?.groups ?? [],
              patientCodes: kodyHodnoty,
            })
      }
    />
  );
};

export default Menu;
