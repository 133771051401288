import { ElementType, ReactElement, ReactNode } from 'react';
import { forOwn, get } from 'lodash-es';
import removeAccents from 'remove-accents';

import strings from '../constants/strings';
import { ButtonLink, Icon, Tooltip } from '@dovera/design-system';
import { Nullable } from '../types';
import store from '../store';
import { CustomTooltip } from '../components/CustomTooltip';
import { Link } from 'react-router-dom';
import SafeHtml from '../components/SafeHtml/SafeHtml';
import regex from '../constants/regex';

export function onlyText(id: string): string {
  const content = get(strings, id);
  return content || '';
}

export function dynamicText(
  text: string,
  tag: any = 'span',
): string | ReactNode {
  const Tag = tag;
  if (!text) return '';
  return (
    <Tag dangerouslySetInnerHTML={{ __html: text.replace(/<\\/g, '</') }} />
  );
}

export const textWithValue = (
  text: string,
  hodnotaMax: string | number,
  hodnotaMin?: string | number,
  link?: {
    route: string;
    text: string;
  },
): ReactNode => {
  const matches: Nullable<string[]> = text.match(/@hr(\d+)*/gm);
  const valueReplacer: string =
    (text.includes('@hr') && matches && matches[0]) || '@hr';
  if (matches && (matches.length === 1 || (matches.length && !hodnotaMin))) {
    const valueRepl =
      text.includes('@hr2') && hodnotaMin ? hodnotaMin : hodnotaMax;
    return (
      <>
        <span
          dangerouslySetInnerHTML={{
            __html: replaceVariable(
              text,
              valueReplacer,
              `<strong>${valueRepl}</strong>`,
            ),
          }}
        />
        {link && <Link to={link.route}>{link.text}</Link>}.
      </>
    );
  }

  if (matches && matches.length === 2) {
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: replaceVariable(
            replaceVariable(text, '@hr2', `<strong>${hodnotaMin}</strong>`),
            '@hr',
            `<strong>${hodnotaMax}</strong>`,
          ),
        }}
      />
    );
  }

  return (
    <>
      <span
        dangerouslySetInnerHTML={{
          __html: replaceVariable(
            text,
            valueReplacer,
            `<strong>${hodnotaMax}</strong>`,
          ),
        }}
      />
      {link && <Link to={link.route}>{link.text}</Link>}.
    </>
  );
};

export const textWithValueString = (
  text: string,
  hodnotaMax: string | number,
  hodnotaMin?: string | number,
): string => {
  if (!text) return '';
  const matches: Nullable<string[]> = text.match(/@hr(\d+)*/gm);
  const valueReplacer: string =
    (text.includes('@hr') && matches && matches[0]) || '@hr';
  if (matches && (matches.length === 1 || (matches.length && !hodnotaMin))) {
    const valueRepl =
      text.includes('@hr2') && hodnotaMin ? hodnotaMin : hodnotaMax;
    return replaceVariable(text, valueReplacer, valueRepl);
  }

  if (matches && matches.length === 2) {
    return replaceVariable(
      replaceVariable(text, '@hr2', `<strong>${hodnotaMin}</strong>`),
      '@hr',
      hodnotaMax,
    );
  }
  return replaceVariable(text, valueReplacer, `<strong>${hodnotaMax}</strong>`);
};

const replaceAdresaTooltip = (
  text: string,
  idTooltip: string,
  Tag: any,
): ReactNode => {
  const splitted = text.split('{{adresaTooltip}}');
  return (
    <Tag>
      <span
        className="inlineLastP"
        dangerouslySetInnerHTML={{ __html: splitted[0] }}
      />
      {getTooltipMsg(idTooltip, strings.adresaTooltip, true)}
      <span dangerouslySetInnerHTML={{ __html: splitted[1] }} />
    </Tag>
  );
};

// 2. Function to replace {{vld|vldd}}
const replaceVldVldd = (text) => {
  const aktivnaOdbornost =
    store?.getState().poskytovatel.odbornosti.filter((d) => d.aktivna)[0] ||
    null;
  const replStr =
    aktivnaOdbornost && aktivnaOdbornost.kodTypZS === '102'
      ? 'deti a dorast'
      : 'dospelých';
  return text
    ?.replace(/\{\{vld\|vldd\}\}/g, replStr)
    ?.replace(/\[vld\|vldd\]/g, replStr);
};

const replaceDynamicParameters = (text: string, idTooltip: string): string => {
  let identificator =
    idTooltip
      .split('detailParametra.parametre.')[1]
      .split('.statickyText')[0] || '';
  if (identificator === 'prev-vas-rok') identificator = 'prev-vas';
  if (text.includes(`{{${identificator}-`)) {
    text.split(`{{${identificator}-`).forEach((s) => {
      if (s.includes('}}')) {
        const extraTextId = s.split('}}')[0];
        let replaceExtraText = onlyText(
          `hp.detailParametra.parametre.${identificator}.${extraTextId}`,
        );
        const aktivnaOdbornost =
          store
            ?.getState()
            .poskytovatel.odbornosti.filter((d) => d.aktivna)[0] || null;
        if (
          extraTextId === 'vldd-extra' &&
          aktivnaOdbornost &&
          aktivnaOdbornost.kodTypZS !== '102'
        )
          replaceExtraText = '';
        text = text.replace(
          `{{${identificator}-${extraTextId}}}`,
          replaceExtraText,
        );
      }
    });
  }
  return text;
};

function replaceDynamicInStaticText(
  idTooltip: string,
  text: string,
  Tag: any,
): ReactNode {
  if (text && text.includes('{{adresaTooltip}}')) {
    return replaceAdresaTooltip(text, idTooltip, Tag);
  }
  /**
   * replace statickeho textu pre vas parametre, ktore maju spolocne texty pre
   * VLD a VLDD (rozdiely su len v pomenovani "deti a dorast" | "dospelych"
   */
  text = replaceVldVldd(text);

  if (idTooltip && idTooltip.includes('statickyText') && text) {
    text = replaceDynamicParameters(text, idTooltip);
  }

  return (
    <Tag
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  );
}

export function text(
  id: string,
  data?: any,
  tag: any = 'span',
): string | ReactNode {
  const genericPlaceholderPattern = '(\\{{\\s{0,500}[^\\s]{1,150}\\s{0,500}}})';
  // const htmlPattern = '<[^>]*>|[{{aA-zZ+}}]*';
  const rawContent = get(strings, id);
  const Tag = tag;
  // const htmlRegex = new RegExp(htmlPattern, 'g');

  // ref - https://github.com/ryandrewjohnson/react-localize-redux/blob/master/src/utils.js
  const splitStrings =
    rawContent &&
    rawContent
      ?.split(new RegExp(genericPlaceholderPattern, 'gmi'))
      .filter((str: string) => !!str)
      .map((templatePortion: string) => {
        let matched: string | undefined;

        forOwn(data, (prop, key) => {
          if (matched) {
            return false;
          }
          const pattern = `\\{{\\s{0,500}${key}\\s{0,500}}}`;
          const regex = new RegExp(pattern, 'gmi');

          if (regex.test(templatePortion)) {
            matched = data[key];
          }
          return true;
        });

        if (typeof matched === 'undefined') {
          return templatePortion;
        }
        return matched;
      })
      .join('');

  const content = replaceDynamicInStaticText(id, splitStrings, Tag);

  return splitStrings ? content : '[TEXT NOT FOUND]';
}

export function replaceVariable(
  str: string,
  variable: string,
  data: any,
): string {
  return str?.replace(variable, data);
}

// vrati inicialky (iba prve pismena)
export function getAcronym(text: string): string {
  if (!text) return '';
  const workingText = removeAccents(text)
    .toLowerCase()
    .split(',')[0]
    .split('s.r.o.')[0]
    .split('a.s.')[0]
    .split(/spol. s(.)*/)[0]
    .replace('mudr.', '');
  // @ts-ignore
  const acronym = workingText
    .trim()
    .toUpperCase()
    .match(/\b(\w)/g)
    .join('');
  return acronym || text.charAt(0).toUpperCase();
}

// osekanie textu na max zadany pocet znakov
export const splitTextWithTooltip = (
  text: string,
  maxLength?: number,
  differentTooltipText?: string,
  hotjarMask?: boolean,
  hotjarMaskClasses?: string,
): ReactNode | string => {
  if (!text) return '';
  if (!maxLength || text.length <= maxLength)
    return hotjarMaskClasses ? (
      <span className={hotjarMaskClasses}>{text}</span>
    ) : (
      text
    );
  const resultText = maxLength ? splitText(text, maxLength) : text;
  return (
    <Tooltip dialog={differentTooltipText || text}>
      <span
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          display: 'block',
        }}
      >
        {hotjarMask
          ? hotjarMasking(resultText, 'span', hotjarMaskClasses || '')
          : resultText}
      </span>
    </Tooltip>
  );
};

export const splitText = (text: string, maxLength: number): string => {
  if (!text) return '';
  if (text.length <= maxLength) return text;
  return `${text.slice(0, maxLength)}...`;
};

export const getTooltipMsg = (
  id: string,
  dialogText: string | ReactNode,
  infoIcon: boolean,
): ReactNode | ReactElement => {
  if (typeof dialogText !== 'string') return <span />;
  return (
    <CustomTooltip dialog={dynamicText(dialogText)} id={`tooltip-${id}`}>
      {infoIcon ? <Icon className="text-color-grey" name="info" /> : dialogText}
    </CustomTooltip>
  );
};

export function getTypHodnotyPostfix(
  type: 'B' | 'R' | 'N' | 'M',
  value: number,
  br?: boolean,
): string {
  const lineBreak = br ? '<br class="hide-xxl-s" />' : '';
  switch (type) {
    case 'B':
      return `${lineBreak}bod${getPostfix(value, { first: '', second: 'y', third: 'ov' })}`;
    case 'R':
      return `${lineBreak}pacient${getPostfix(value, { first: ' s PP', second: 'i s PP', third: 'ov s PP' })}`;
    case 'N':
      return `${lineBreak}návštev${getPostfix(value, { first: 'a', second: 'y', third: '' })}`;
    case 'M':
      return `${lineBreak}prístroj${getPostfix(value, { first: '', second: 'e', third: 'ov' })}`;
    default:
      return '';
  }
}

const getPostfix = (
  value: number,
  postfixOptions: { first: string; second: string; third: string },
): string => {
  if (value === 0 || value > 4 || value < 1 || !Number.isInteger(value))
    return postfixOptions.third;
  if (value === 1) return postfixOptions.first;
  return postfixOptions.second;
};

export function hotjarMasking(
  s: string | ReactNode,
  // eslint-disable-next-line
  tag: any = 'span',
  classes?: string,
): ReactNode {
  const Tag = tag;
  return (
    <Tag
      className={classes || ''}
      dangerouslySetInnerHTML={{ __html: s }}
      data-hj-masked
    />
  );
}

export function safeString(s: any, replaceWith?: string): string {
  if (!s) return replaceWith || strings.undefined;
  return s.toString();
}

export const uppercaseWords = (str) => {
  const regex = /(^(.)|\s{1,150}(.))/g;
  return str.replace(regex, (c) => c.toUpperCase());
};

export const getRangeStrings = (str: string): string => {
  if (!/\d+/.test(str) || !str.includes('-')) return str;
  const [char, char2] = extractChars(str);
  let response: string = '';

  if (char2 !== char) {
    response += generateRange(
      char,
      Number(str.split(char)[1].split('-')[0]),
      99,
    );
    response += generateRange(char2, 0, Number(str.split(char2)[1]));
  } else {
    const rangeStart = Number(str.split(char)[1].split('-')[0]);
    const rangeEnd = Number(str.split(char)[2]);
    response += generateRange(char, rangeStart, rangeEnd);
  }

  return response;
};

const extractChars = (str: string): [string, string] => {
  const char = str.split(/\d+/)[0];
  const char2 =
    str.split(/\d+/)[1] && str.split(/\d+/)[1] !== char
      ? str.split(/\d+/)[1].replace('-', '')
      : char;
  return [char, char2];
};

const generateRange = (char: string, start: number, end: number): string => {
  let response: string = '';
  for (let i = start; i <= end; i++) {
    response += i.toString().length === 1 ? ` ${char}0${i}` : ` ${char}${i}`;
  }
  return response;
};

const getAllWordsFirstUpper = (str: string) => {
  let response: string = '';
  str.split(' ').forEach((s) => {
    const st = s;
    if (s.includes('-'))
      st.split('-').forEach((s2, key) => {
        if (key === 0) s = '';
        s += `${s2.charAt(0).toUpperCase()}${s2.substring(1).toLowerCase()}${
          key === 0 ? '-' : ' '
        }`;
      });
    response += s.includes('-')
      ? s
      : `${s.charAt(0).toUpperCase()}${s.substring(1).toLowerCase()} `;
  });
  return response.trim();
};

export const firstCharToUpper = (
  str: string,
  allWordsFirstUpper?: boolean,
): string => {
  if (!str) return '-';
  if (allWordsFirstUpper && str.includes(' '))
    return getAllWordsFirstUpper(str);

  return `${str.charAt(0).toUpperCase()}${str.substring(1).toLowerCase()}`;
};

export const splitLinkWithText = (data: {
  route?: string;
  str: string;
  target?: string;
  url?: string;
}): ReactNode => {
  const SEPARATOR1 = '{';
  const SEPARATOR2 = '}';
  if (!data.str.includes(SEPARATOR1) || !data.str.includes(SEPARATOR2))
    return <span>{data.str}</span>;
  return (
    <span>
      {data.str.split(SEPARATOR1)[0]}
      <Link
        className="link text-color-black text-semibold"
        target={data.target || ''}
        to={data.route || data.url || '#' || ''}
      >
        {data.str.split(SEPARATOR1)[1].split(SEPARATOR2)[0]}
      </Link>
      {data.str.split(SEPARATOR2)[1]}
    </span>
  );
};

export const justNumbers = (s: string): number[] => {
  const arr: number[] = [];
  const patern = /[^0-9]/g;
  if (!s.includes('/')) {
    const numsStr = s.replace(patern, '');
    arr.push(Number(numsStr));
  } else {
    s.split('/').forEach((t) => {
      const numsStr = t.replace(patern, '');
      if (numsStr) arr.push(Number(numsStr));
    });
  }
  return arr;
};

export const formatIBAN = (iban: string): string => {
  if (!iban) return ' - ';
  return iban
    .replace(/[^\dA-Z]/g, '')
    .replace(/(.{4})/g, '$1 ')
    .trim();
};

export function formatPhone(phone: string, mobileType: boolean): string {
  if (!phone) return '-';
  const isMobile =
    mobileType ||
    (phone.startsWith('+421') && phone.split('+421')[1].startsWith('9'));
  if (phone.startsWith('+421') && isMobile)
    return `${phone.substr(0, 4)} ${phone.substr(4, 3)} ${phone.substr(
      7,
      3,
    )} ${phone.substr(10, 3)}`;
  if (!phone.startsWith('+421') && isMobile)
    return `${phone.substr(0, 4)} ${phone.substr(4, 3)} ${phone.substr(7, 3)}`;
  if (phone.startsWith('+421') && !isMobile)
    return `${phone.substr(0, 4)} ${phone.substr(4, 2)} ${phone.substr(
      6,
      phone.length - 6,
    )}`;
  return `${phone.substr(0, 3)} ${phone.substr(3, 2)} ${phone.substr(
    5,
    phone.length - 5,
  )}`;
}

export const formatNumberToString = (n: number): string => {
  if (!n) return '';
  return Number(n)
    .toFixed(2)
    .replace(/\B(?=(\d{3}){1,150}(?!\d))/g, ' ')
    .replace('.', ',')
    .replace('-', '- ');
};

export const addressLowerCases = (
  text: string,
  upperCaseAfterSpace: boolean,
): string => {
  if (!text) return '-';
  const getChunkedText = (text: string, separator: string): string => {
    const chunks = text.split(separator);
    let result = '';
    if (chunks.length > 1)
      chunks.forEach((ch, key) => {
        result +=
          key === 0
            ? `${ch.charAt(0).toUpperCase()}${ch.slice(1).toLowerCase()}`
            : `${separator}${ch.charAt(0).toUpperCase()}${ch
                .slice(1)
                .toLowerCase()}`;
      });
    result = result.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
    return result || text;
  };

  let edited = `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
  if (edited.indexOf('-') > -1) {
    edited = getChunkedText(edited, '-');
  } else if (upperCaseAfterSpace) {
    edited = getChunkedText(edited, '.');
    edited = getChunkedText(edited, '. ');
    edited = getChunkedText(edited, ' ');
  }
  return edited;
};

/**
 * Replace all spaces with &nbsp; in string
 */
export const nbsp = (str: string): ReactNode => (
  <SafeHtml html={str.replace(/\s/g, '&nbsp')} wrapper="span" />
);

export const nbspStr = (str: string): string => str.replace(/\s/g, '&nbsp;');

/** Replace string with dynamic parameters */
export const replaceStringWithParams = (
  str: ReactNode | string,
  parameters: { id: string; value: string | ReactNode }[],
  htmlWrapper?: ElementType,
  className?: string,
): ReactNode => {
  if (!parameters || !parameters.length) return <SafeHtml html={str} />;
  parameters.forEach((p) => {
    if (typeof p.value === 'string') {
      str = str?.toString().replace(p.id, p.value);
    }
  });
  if (
    parameters.some((p) => typeof p.value !== 'string') &&
    typeof str === 'string'
  ) {
    // only for first occurances of ReactNode param
    const param = parameters.filter((p) => typeof p.value !== 'string')[0];
    return addNodeToText(param.value, param.id, str);
  }
  return (
    <SafeHtml className={className} html={str} wrapper={htmlWrapper || 'div'} />
  );
};

export const addNodeToText = (
  node: ReactNode,
  findStr: string,
  text: string,
): ReactNode => {
  if (text.includes(findStr)) {
    return (
      <div>
        <SafeHtml
          className="d-inline"
          html={text.split(findStr)[0]}
          wrapper="div"
        />
        {node}
        <SafeHtml
          className="d-inline"
          html={text.split(findStr)[1]}
          wrapper="div"
        />
      </div>
    );
  }
  return <SafeHtml html={text} wrapper="div" />;
};

export const textWithAction = (
  str: string,
  action: () => void,
  actionType: 'error' | 'success' | 'warning',
): ReactNode => {
  if (str.includes('(') && str.split('(')[1].includes(')')) {
    const className = `text-color-${actionType} no-pad no-mrg-bottom`;
    return (
      <>
        <SafeHtml html={str.split('(')[0]} wrapper="span" />
        <ButtonLink
          className={className}
          onClick={action}
          style={{ minHeight: 0, marginTop: -4 }}
        >
          <SafeHtml html={str.split('(')[1].split(')')[0]} wrapper="span" />
        </ButtonLink>
        <SafeHtml html={str.split(')')[1]} wrapper="span" />
      </>
    );
  }
  return <SafeHtml html={str} wrapper="span" />;
};

export const textWithActions = ({
  actions,
  className,
  text,
}: {
  actions: { key: string; link: string }[];
  className?: any;
  text: string;
}): ReactNode => {
  let parts: ReactNode[] | string[] = [text];
  actions.forEach((action) => {
    const { key, link } = action;
    const newParts: ReactNode[] = [];

    parts.forEach((part) => {
      if (typeof part === 'string') {
        const splitParts = part.split(key);
        for (let i = 0; i < splitParts.length; i++) {
          newParts.push(<SafeHtml html={splitParts[i]} />);
          if (i < splitParts.length - 1) {
            newParts.push(
              <Link key={link} to={link}>
                {key.replace(/[[\]]/g, '')}
              </Link>,
            );
          }
        }
      } else {
        newParts.push(part);
      }
    });
    parts = newParts;
  });
  return <div className={className}>{parts}</div>;
};

export const getSalutation = (
  titleBefore: string | null,
  firstName: string | null,
  lastName: string | null,
  titleAfter: string,
): string => {
  if (!firstName || !lastName) return '-';
  return `${titleBefore ? `${titleBefore} ` : ''}${firstCharToUpper(
    firstName,
  )} ${firstCharToUpper(lastName)}${titleAfter ? `, ${titleAfter}` : ''}`;
};

export const formatNameStr = (str: string): string => {
  if (!str) return '';
  const titles: { replace: string; value: string }[] = [
    {
      value: 'mudr.',
      replace: 'MUDr.',
    },
    {
      value: 'ing.',
      replace: 'Ing.',
    },
    {
      value: 'mvdr.',
      replace: 'MVDr.',
    },
    {
      value: 'prof.',
      replace: 'prof.',
    },
    {
      value: 'csc.',
      replace: 'CSc.',
    },
    {
      value: 'phd.',
      replace: 'PhD.',
    },
    {
      value: 'mph',
      replace: 'MPH.',
    },
    {
      value: 'mba',
      replace: 'MBA.',
    },
  ];
  str = str.toLowerCase();
  titles.forEach((t) => {
    str = str.replace(t.value, t.replace);
  });
  const chunks = str.split(' ');
  let resp: string = '';

  const toLower = ['S.r.o.', 'A.s.', 'Spol s.r.o.'];
  const toUpper = ['Ičo'];
  chunks.forEach((c, index) => {
    resp += titles.some((t) => c.toLowerCase().includes(t.value))
      ? `${c} `
      : c
        ? chunks.length === index + 1
          ? firstCharToUpper(c)
          : `${firstCharToUpper(c)} `
        : '';
  });
  toLower.forEach((o) => {
    resp = resp.replace(o, o.toLowerCase());
  });
  toUpper.forEach((o) => {
    resp = resp.replace(o, o.toUpperCase());
  });
  if (resp.includes('('))
    resp = `${resp.split('(')[0]} (${resp.split('(')[1].toUpperCase()}`;
  return resp;
};

export const stripHtml = (html: string): string => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export const stringWithSpaces = (params: string[] | null[]): string => {
  const res = params.map((p) => p && p.trimStart().trimEnd());
  if (!res || !res.length || !params.some((p) => p)) return strings.undefined;
  return res.join(' ');
};

export const getListFromString = (str: string): string[] => {
  if (!str) return [];
  const rows = str.replace(/(<BR>|<br>)+/g, ' ').split('\n');
  return rows.map((r) => r.replace(/^-\s?/, ''));
};

/**
 * Metoda pre nahradenie specialnych znakov novych riadkov v stringu
 * Dovod: firewall problem so specialnymi znakmi v textovych poliach (v pripade, ze sa text kopiruje z ext. systemu)
 * @date 21. 2. 2023 - 10:42:01
 *
 * @param {string} someString
 * @param {string} [replacementString='\n']
 * @returns {string}
 */
export const replaceNewLineChars = (
  someString: string,
  replacementString: string = '\n',
  replacementChar: string = '',
): string => {
  const charReplacer = [
    {
      char: '[',
      repl: '(',
    },
    {
      char: ']',
      repl: ')',
    },
    {
      char: '{',
      repl: '(',
    },
    {
      char: '}',
      repl: ')',
    },
    {
      char: '<',
      repl: '(',
    },
    {
      char: '>',
      repl: ')',
    },
    {
      char: ';',
      repl: ',',
    },
    {
      char: '_',
      repl: '-',
    },
  ];
  const specialChars = /['"~`^]/gm;
  const LF = `\u{000a}`; // Line Feed (\n)
  const VT = `\u{000b}`; // Vertical Tab
  const FF = `\u{000c}`; // Form Feed
  const CR = `\u{000d}`; // Carriage Return (\r)
  const CRLF = `${CR}${LF}`; // (\r\n)
  const NEL = `\u{0085}`; // Next Line
  const LS = `\u{2028}`; // Line Separator
  const PS = `\u{2029}`; // Paragraph Separator
  const BN = `\u{0082}`; // Break New Line
  // eslint-disable-next-line
  const unicodeRegex = /[\u0000-\u001F\u007F-\u009F\u2028\u2029]/g;
  const lineTerminators = [LF, VT, FF, CR, CRLF, NEL, LS, PS, BN];
  let finalString = someString.normalize(`NFD`);
  charReplacer.forEach((ch) => {
    finalString = finalString.replace(
      new RegExp(`[${ch.char}]`, 'gm'),
      ch.repl,
    );
  });
  lineTerminators.forEach((lt) => {
    if (finalString.includes(lt)) {
      const regex = new RegExp(lt.normalize('NFD'), 'gu');
      finalString = finalString.replace(regex, replacementString);
    }
  });
  // clean string from special unicode characters
  finalString = finalString.replace(unicodeRegex, replacementChar);
  return finalString.replace(specialChars, ' ').normalize('NFC');
};

/**
 * Check if string consists sql commands to inject script
 * @date 6. 3. 2023 - 13:01:48
 *
 * @param {string} str
 * @returns {boolean}
 */
export const isSqlInjection = (str: string): boolean =>
  regex.SQL_INJECTION.test(str.toUpperCase());

/**
 * Get regex matches (or first single result)
 * @date 6. 3. 2023 - 13:23:03
 *
 * @param {string} str
 * @param {string} regex
 * @param {?boolean} [singleResult]
 * @returns {(string | string[])}
 */
export const getFirstMatch = (str: string, regex: RegExp): string => {
  const result =
    str.toUpperCase().match(regex) || str.toLowerCase().match(regex) || [];
  return result?.[0]?.trim() || '';
};

/**
 * Metoda vrati pismeno abecedy podla zadaneho indexu na vstupe (0 = 'A')
 * @date 12. 7. 2023 - 15:08:46
 *
 * @param {number} index
 * @returns {string}
 */
export const getAlphabetLetter = (
  index: number,
  isExcelTable?: boolean,
): string => {
  if (!isExcelTable) return String.fromCharCode(index + 'A'.charCodeAt(0));

  // specific chars for excel table columns
  const chars = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'AA',
    'AB',
    'AC',
    'AD',
    'AE',
    'AF',
    'AG',
    'AH',
    'AI',
    'AJ',
    'AK',
    'AL',
    'AM',
    'AN',
    'AO',
    'AP',
    'AQ',
    'AR',
    'AS',
    'AT',
    'AU',
    'AV',
    'AW',
    'AX',
    'AY',
    'AZ',
    'BA',
    'BB',
    'BC',
    'BD',
    'BE',
    'BF',
    'BG',
    'BH',
    'BI',
    'BJ',
    'BK',
    'BL',
    'BM',
    'BN',
    'BO',
    'BP',
    'BQ',
    'BR',
    'BS',
    'BT',
    'BU',
    'BV',
    'BW',
    'BX',
    'BY',
    'BZ',
  ];
  return chars[index];
};

export const getColoredText = (color: string, text: string): string =>
  `<span style="color:${color};">${text}</span>`;

export const getPlainText = (str: string): string => {
  if (!str) return '';
  const el = document.createElement('div');

  if (str.includes('</style>'))
    str = str.split('</style>')[str.split('</style>').length - 1];

  el.innerHTML = str;
  const plain = el.textContent || '';
  el.remove();
  return plain.replace(/\s+/gi, ' ');
};

export const removeLineBreaks = (str: string): string =>
  str.replace(/\r?\n|\r|\t/g, '');

export const splitStringWithBrAfterHalfCountWords = (input: string): string => {
  const words = input.split(' ');
  const splitIndex = Number.parseInt(
    Math.ceil(words.length / 1.1).toString(),
    10,
  );
  const firstHalf = words.slice(0, splitIndex);
  const secondHalf = words.slice(splitIndex);
  const result = `${firstHalf.join(' ')}<br>${secondHalf.join(' ')}`;
  return result;
};

export const extractTextFromHTML = (htmlString?: string): string =>
  htmlString?.replace(/<[^>]*>/g, '') || '';

export const safeUrl = (url?: string | ReactNode): string | ReactNode => {
  if (!url || typeof url !== 'string') return url;
  const entities = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': "'",
  };
  return url.replace(
    /&amp;|&lt;|&gt;|&quot;|&#39;/g,
    (match) => entities[match],
  );
};

/**
 * Checks if a string contains HTML tags.
 * @param str - The string to check.
 * @returns True if the string contains HTML tags, false otherwise.
 */
export const containsHtmlTags = (str: string): boolean => {
  const htmlTagPattern = /<\/?[a-z][\s\S]*>/i;
  return htmlTagPattern.test(str);
};

export const replaceEuroSymbol = (v?: string): ReactNode => {
  if (!v) return '';
  return (
    <SafeHtml html={v.replace('€', '<span class="euro-symbol">€</span>')} />
  );
};
