import React, { ReactNode } from 'react';
import { cx } from '../../../../../utils/exports';
import useStyles from '../../../Proposals.styles';

interface Props {
  className?: string;
  extra?: string | ReactNode;
  rows?: {
    extraText?: string | ReactNode;
    label: string;
    value: string | ReactNode;
  }[];
  title?: string | ReactNode;
  titleIcon?: ReactNode;
  withBorder?: boolean;
}

const Section = ({
  className,
  extra,
  rows,
  title,
  titleIcon,
  withBorder,
}: Props) => {
  const classes = useStyles({ proposalType: 'KN' });

  return (
    <div
      className={cx(
        withBorder && classes.sectionWithBorder,
        titleIcon && classes.sectionWithTitleIcon,
        classes.contentSection,
        className,
      )}
    >
      {title && (
        <h4 className="mb-small">
          {titleIcon || ''}
          {title}
        </h4>
      )}
      {rows && (
        <div className={classes.sectionGrid}>
          {rows.map((r, key) => (
            <React.Fragment key={`proposal-detail-section--${key}`}>
              <span>{r.label}</span>
              <b>{r.value}</b>
              {r.extraText || ''}
            </React.Fragment>
          ))}
        </div>
      )}
      {!!extra && extra}
    </div>
  );
};

export default Section;
