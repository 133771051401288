import { breakpoints, color, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  dashboardBanner: {
    marginBottom: `${getRem(24)} !important`,
    '& img': {
      width: getRem(198),
      textAlign: 'right',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
      [`@media screen and (max-width: ${breakpoints.m}px)`]: {
        display: 'none',
      },
    },
    '& a': {
      textDecoration: 'none !important',
    },
    '& span': {
      color: color('grey', 500),
    },
    '& .grid__col': {
      '&:last-child': {
        textAlign: 'center',
        position: 'relative',
      },
    },
  },
  dashboardBannerContent: {
    padding: `0 ${getRem(32)} ${getRem(16)}`,
    '& h3': {
      paddingLeft: '0 !important',
    },
  },
  notificationBanner: {
    '& .notification__iconArea': {
      alignItems: 'flex-start',
    },
    '& .notification__contentArea': {
      rowGap: 0,
    },
  },
});
