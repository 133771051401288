import { breakpoints, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    '& .card__section--condensed': {
      padding: getRem(16),
      [`@media screen and (min-width: ${breakpoints.m}px)`]: {
        padding: getRem(32),
      },
    },
  },
});
