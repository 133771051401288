import { Grid, GridCol, Skeleton } from '@dovera/design-system';
import useStyles from '../styles';

const SkeletonLoader = () => {
  const classes = useStyles();

  return (
    <div className={classes.skeletonLoader}>
      <h5 className="mb-large">
        <Skeleton width={242} />
      </h5>
      <Skeleton className="mb-large" height={112} width="70%" />
      <Grid className="d-flex content-center-vert">
        <GridCol size={{ m: 12, l: 9 }}>
          <Skeleton height={280} width="100%" />
        </GridCol>
        <GridCol size={{ m: 12, l: 3 }}>
          <Skeleton
            className={classes.skeletonCircle}
            height={180}
            width={180}
          />
        </GridCol>
      </Grid>
      <hr className="mb-large" />
      <h5 className="mb">
        <Skeleton width={242} />
      </h5>
      <Grid>
        <GridCol size={{ m: 12, l: 5 }}>
          <Skeleton className="d-block mb-small" width={246} />
          <Skeleton height={72} width="100%" />
        </GridCol>
        <GridCol size={{ m: 12, l: 5 }}>
          <Skeleton className="d-block mb-small" width={246} />
          <Skeleton height={72} width="100%" />
        </GridCol>
      </Grid>
      <hr className="mb-large" />
      <h5 className="mb">
        <Skeleton width={242} />
      </h5>
      <Grid>
        <GridCol size={{ m: 12, l: 5 }}>
          <Skeleton className="d-block mb-small" width={246} />
          <Skeleton height={72} width="100%" />
        </GridCol>
        <GridCol size={{ m: 12, l: 5 }}>
          <Skeleton className="d-block mb-small" width={246} />
          <Skeleton height={72} width="100%" />
        </GridCol>
      </Grid>
    </div>
  );
};

export default SkeletonLoader;
