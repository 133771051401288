import { Skeleton } from '@dovera/design-system';
import { cx } from '../../utils/exports';

interface Props {
  autoWidth?: boolean;
  displayBlock?: boolean;
}

const InputPreloader = ({ autoWidth, displayBlock }: Props) => (
  <div>
    <Skeleton
      className={cx(displayBlock && 'd-block', 'mb-xxsmall')}
      height={16}
      width={autoWidth ? '60%' : 150}
    />
    <Skeleton
      className={cx(displayBlock && 'd-block')}
      height={48}
      width={autoWidth ? '100%' : 211}
    />
  </div>
);

export default InputPreloader;
