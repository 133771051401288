import React, { useEffect, useState } from 'react';
import useStyles from '../../../../Proposals.styles';
import strings from '../../../../../../constants/strings';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../rootReducer';
import { useAppDispatch } from '../../../../../../hooks/useStore';
import { checkProposalData } from '../../../../../../api/drugProposals';
import { CheckType } from '../../../../../../types/proposals.types';
import {
  DATE_FROMAT_SK_DATEPICKER,
  DATE_INTERNATIONAL,
  getMoment,
} from '../../../../../../utils/date.utils';
import moment from 'moment';
import { Datepicker, Notification } from '@dovera/design-system';
import { storeStep3 } from '../../../../../../slices/drugProposals.slice';
import CheckNotifications from '../../../common/CheckNotifications/CheckNotifications';
import { FieldElementIdEnum } from '../../../../../../types/drugProposals.types';
import { scrollToField } from '../../../../../../utils/form.utils';
import { cx } from '../../../../../../utils/exports';

const texts = strings.proposals.drugs.new;

interface DateFromProps {
  field: any;
}

const checkTypes: CheckType[] = [
  'DatumPlatnostiOd',
  'ExistDuplicita',
  'EsteJePriskoro',
];

const DateFrom = ({ field }: DateFromProps) => {
  const [minDate, setMinDate] = useState<Date | undefined>(undefined);
  const [error, setError] = useState('');
  const classes = useStyles({ proposalType: 'LN' });
  const [initDatepicker, setInitDatepicker] = useState(false);
  const [date, setDate] = useState('');
  const dispatch = useAppDispatch();
  const {
    data: { controls },
    stepper: {
      step3: { hasMV },
    },
  } = useSelector((state: RootState) => state.drugProposals.new);

  useEffect(() => {
    if (date?.length === 10 && moment(date).isValid()) {
      setError('');
      dispatch(
        checkProposalData({
          checkTypes,
          dateValidityFrom: getMoment(date).format(DATE_INTERNATIONAL),
        }),
      );
      dispatch(storeStep3({ dateFrom: date }));
    } else {
      setError(texts.notifications.error.notValidDate);
      dispatch(storeStep3({ dateFrom: '' }));
    }
  }, [date, dispatch]);

  useEffect(() => {
    setInitDatepicker(true);
    setTimeout(() => {
      setInitDatepicker(false);
    }, 500);
  }, [hasMV]);

  useEffect(() => {
    const initialDate =
      controls?.data?.find((c) => c.checkType === 'TypLiecby')?.additionalInfo
        ?.value4 || '';
    setTimeout(() => {
      field.input.onChange(
        moment(initialDate).format(DATE_FROMAT_SK_DATEPICKER),
      );
      setMinDate(new Date(initialDate));
      setDate(moment(initialDate).format(DATE_INTERNATIONAL));
    }, 500);
    // eslint-disable-next-line
  }, [dispatch, initDatepicker]);

  const renderNotification = error && !initDatepicker && (
    <div className="fit-content">
      <Notification message={error} variant="error" />
    </div>
  );
  if (!minDate) return null;
  return (
    <div className={cx(classes.drugsWrapper, 'mb')}>
      <Datepicker
        {...field.input}
        autoComplete="off"
        error={field.meta.touched && field.meta.error}
        id={FieldElementIdEnum.DateFrom}
        isDisabled={
          controls.isLoadingControl?.includes('DatumPlatnostiOd') ||
          initDatepicker
        }
        isRequired
        label={texts.labels.dateFrom}
        minDate={minDate}
        onClick={() => scrollToField(FieldElementIdEnum.DateFrom)}
        // eslint-disable-next-line
        onChange={(value) => {
          field.input.onChange(
            moment(value)
              .set({
                hour: 12,
                minute: 0,
                second: 0,
              })
              .utcOffset(1)
              .format('YYYY-MM-DD'),
          );
          setTimeout(
            () => setDate(moment(value).format(DATE_INTERNATIONAL)),
            100,
          );
          scrollToField(FieldElementIdEnum.DateFrom);
        }}
        pikadayOptions={{
          toString(date) {
            return moment(date).format(DATE_FROMAT_SK_DATEPICKER);
          },
        }}
      />
      {renderNotification}
      <CheckNotifications checkTypes={checkTypes} />
    </div>
  );
};

export default DateFrom;
