import { breakpoints, color } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

interface StylesProps {
  env: any;
}

const getBadgeColor = (env: string): string => {
  if (env === 'TEST-VNS') return color('secondary');
  return env === 'TEST' ? color('primary', 600) : color('error', 400);
};

export default createUseStyles({
  badgeVersion: ({ env }: StylesProps) => ({
    backgroundColor: getBadgeColor(env),
    color: 'white',
    borderRadius: '0 0 5px 5px',
    padding: '2px 8px',
    marginRight: 40,
    position: 'absolute',
    top: 0,
    left: 192,
    minWidth: 90,
    textAlign: 'center',
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      width: 50,
      left: 60,
      '& span': {
        display: 'none',
      },
    },
    '@media (min-width: 768px) and (max-width: 991px)': {
      left: 250,
    },
  }),
  table: {},
});
