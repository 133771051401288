const IconCircleDashed = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 25 24"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2589_16794)">
      <circle
        cx="12.3335"
        cy="12"
        r="11"
        stroke="#B1AEAA"
        strokeDasharray="4 4"
        strokeWidth="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_2589_16794">
        <rect
          fill="white"
          height="24"
          transform="translate(0.333496)"
          width="24"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IconCircleDashed;
