import { useEffect, useState } from 'react';
import { Button, Container, Icon, TabPanel, Tabs } from '@dovera/design-system';
import { navrhyRoutes } from '../../../routes';
import ContentHeader from '../../../components/ContentHeader/ContentHeader';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import { reset } from '../../../slices/spaProposals.slice';
import strings from '../../../constants/strings';
import { useQuery } from '../../../hooks/useQuery';
import ProposalDetail from '../ProposalDetail/ProposalDetail';
import { useAppDispatch } from '../../../hooks/useStore';
import { useNavigate } from 'react-router';
import {
  resetDrugProposals,
  setLoadedCNP,
} from '../../../slices/drugProposals.slice';
import { PROPOSAL_TYPES } from '../../../types/proposals.types';
import TableKN from './TableKN';
import TableLN from './TableLN';
import CardBubble from '../../../components/CardBubble/CardBubble';
import { hasSection } from '../../../utils/poskytovatel.utils';
import { EPSections } from '../../../constants/systemConstants';
import {
  getFilteredTabs,
  hasLnCnpSection,
} from '../../../utils/proposals.utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { LoadingState } from '../../../types';
import { SUBMITTED_POPOSALS_TABS } from '../../../constants/proposals';

const texts = strings.proposals.common.submittedProposals;

const SubmittedProposals = () => {
  const { dataState } = useSelector(
    (state: RootState) => state.app.systemoveParametre,
  );
  const isLoadedThemes: boolean = useSelector(
    (state: RootState) => !!state.poskytovatel.temy?.length,
  );
  const [tabs, setTabs] = useState(SUBMITTED_POPOSALS_TABS);
  const dispatch = useAppDispatch();
  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(reset());
    dispatch(resetDrugProposals());
    dispatch(setLoadedCNP({ loaded: false }));
  }, [dispatch]);

  useEffect(() => {
    if (!query.get('typ'))
      navigate(
        `${navrhyRoutes.zoznamPodanychNavrhov}?typ=${tabs[0]}${query.get('refnum') ? `&refnum=${query.get('refnum')}` : ''}`,
      );
  }, [navigate, query, tabs]);

  useEffect(() => {
    if (!(dataState === LoadingState.filled || isLoadedThemes)) return;
    setTabs(getFilteredTabs());
  }, [dataState, isLoadedThemes]);

  useEffect(() => {
    if (
      query.get('typ') !== PROPOSAL_TYPES.SPA &&
      !hasSection(EPSections.Ln, 'Navrhy') &&
      !hasSection(EPSections.Cnp, 'Navrhy')
    ) {
      navigate(
        `${navrhyRoutes.zoznamPodanychNavrhov}?typ=${PROPOSAL_TYPES.SPA}`,
      );
    }
  }, [navigate, query]);

  const renderTabs = query.get('typ') && hasLnCnpSection(query.get('typ')) && (
    <Tabs
      activeTabIndex={tabs.findIndex((t) => t === query.get('typ')) || 0}
      disableURLChange
      onChange={(val) =>
        navigate(`${navrhyRoutes.zoznamPodanychNavrhov}?typ=${val.panel.id}`)
      }
    >
      {tabs.map((t) => (
        <TabPanel key={t} id={t} tab={texts.tabs[t]} />
      ))}
    </Tabs>
  );
  const renderHeader = (
    <ContentHeader flex>
      <Container>
        <Breadcrumbs
          items={[
            {
              name: texts.breadcrumbs.proposals,
              route: navrhyRoutes.novyNavrh,
            },
            { name: texts.breadcrumbs.listOfProposals },
          ]}
        />
        <h2 className="mb-small">{texts.title}</h2>
        <Button
          className="mb-xlarge"
          onClick={() =>
            navigate(
              `${navrhyRoutes.novyNavrh}?typ=${query.get('typ') === 'KN' ? 'KN' : 'LN'}`,
            )
          }
        >
          <Icon name="16-plus" size="small" />
          <span className="text-space-half-left">{texts.buttonNew}</span>
        </Button>
      </Container>
    </ContentHeader>
  );

  if (query.get('id') && query.get('cisloNavrhu'))
    return (
      <ProposalDetail
        id={Number(query.get('id'))}
        proposalNumber={query.get('cisloNavrhu') || ''}
        type={query.get('typ') || 'KN'}
      />
    );

  const shouldRenderLnCnpTables =
    [PROPOSAL_TYPES.Drugs.toString(), PROPOSAL_TYPES.CNP.toString()].includes(
      query.get('typ') || '',
    ) && hasLnCnpSection(query.get('typ'));

  return (
    <StaticMenuLayout
      contentHeader={renderHeader}
      contentWrapperId="dlek-proposals-list"
    >
      <CardBubble tabs={renderTabs}>
        {query.get('typ') === PROPOSAL_TYPES.SPA && (
          <TableKN shouldReload={query.get('reload') === '1'} />
        )}
        {shouldRenderLnCnpTables && (
          <TableLN
            proposalType={query.get('typ') || ''}
            shouldReload={query.get('reload') === '1'}
          />
        )}
      </CardBubble>
    </StaticMenuLayout>
  );
};

export default SubmittedProposals;
