import { getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  exportWrapper: {
    position: 'relative',
    '& button': {
      position: 'absolute',
      right: 0,
      top: getRem(-124),
      '& svg': {
        marginRight: `${getRem(8)} !important`,
      },
    },
  },
});
