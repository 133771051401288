import { Dokument } from '../types/models/Dokument';
import { decode } from 'base64-arraybuffer';
import FileSaver from 'file-saver';
import { isIE } from './app.utils';
import { getValueWithNDecimal } from './number.utils';
import mime from 'mime';

export const saveBase64Document = (content: string, fileName: string) => {
  if (!content) return;
  const element = document.createElement('a');
  element.setAttribute('href', `data:text/plain;base64,${content}`);
  element.setAttribute('download', fileName);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();
  element.remove();
};

const getDocType = (fileName?: string | null, type?: string | null): string => {
  // @ts-ignore
  if (type) return type.includes('/') ? type : mime.getType(type);
  if (fileName) {
    const mimeFile = fileName.includes('.')
      ? mime.getType(fileName.split('.')[fileName.split('.').length - 1])
      : '';
    return mimeFile || '';
  }
  return '';
};

export const saveDocument = (document: Dokument | null, openFile?: boolean) => {
  if (!document || !document.obsah) return;
  const type = getDocType(document.nazovDokumentu, document.typ);
  const content =
    type?.includes('text/') &&
    (/\s/g.test(document.obsah) || document.obsah.length < 10)
      ? document.obsah
      : decode(document.obsah);
  const blob = new Blob([content], {
    type: `${type || 'application/pdf'};charset=utf-8`,
  });
  const nameParts = document?.nazovDokumentu?.split('.');
  let extension =
    // @ts-ignore
    nameParts && nameParts.length > 0 ? '' : mime.getExtension(type || '');

  // workaround for downloading pdf files in IE:
  if (isIE && type === 'application/pdf') {
    extension = '.pdf';
  }

  if (openFile) {
    const url = window.URL.createObjectURL(blob);
    fetch(url)
      .then((resp) => resp.arrayBuffer())
      .then((resp) => {
        const file = new Blob([resp], {
          type: `${type || 'application/pdf'};charset=utf-8`,
        });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL, '_blank')?.focus();
      });
  } else {
    FileSaver.saveAs(blob, `${document.nazovDokumentu}${extension}`);
  }
};

export const convertFileSize = (bytes: number): string => {
  const kbs = (bytes * 0.978) / 1000; // bytes in binary
  if (kbs < 1000) return `${getValueWithNDecimal(kbs)} kB`;
  const mbs = kbs / 1000;
  return `${getValueWithNDecimal(mbs)} MB`;
};

export const isFileSizeLimit = (bytes: number, maxMB: number): boolean => {
  const kbs = (bytes * 0.978) / 1000; // bytes in binary
  const mbs = kbs / 1000;
  return mbs > maxMB;
};

export const toBase64 = (
  file: File,
  handleLoad?: (base64?: string | ArrayBuffer | null) => void,
  handleError?: (error: string) => void,
) => {
  if (!handleLoad && !handleError) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const blob = new Blob([file]);
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result?.toString()?.split(',')?.[1]);
      reader.onerror = (error) => reject(error);
    });
  }
  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = () =>
    // @ts-ignore
    handleLoad(reader.result.split(',')[1]);

  reader.onerror = (error) =>
    // @ts-ignore
    handleError(JSON.stringify(error));

  return null;
};

export const uploadHandler = async (files: File[]) => {
  const filesToUpload: any = [];
  const temp: Dokument[] = [];
  files.forEach((f) => {
    filesToUpload.push(toBase64(f));
    temp.push({
      id: 0,
      jeKomprimovany: f.type.includes('zip'),
      nazov: f.name,
      obsah: '',
      typ: f.type,
      velkost: f.size,
    });
  });
  const filePaths = await Promise.all(filesToUpload);
  return filePaths.map((base64, key) => ({
    ...temp[key],
    obsah: base64,
  }));
};

export const filesToBase64 = (
  files: File[],
  callback: (
    filesArr: {
      mimeTyp: string;
      nazov: string;
      obsah: string;
      typ?: string;
    }[],
  ) => void,
  docType?: 'FKT' | 'INE' | string,
) => {
  const base64Arr: {
    mimeTyp: string;
    nazov: string;
    obsah: string;
    typ?: string;
  }[] = [];

  if (!files?.length) callback([]);

  files.forEach((file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const base64Str = event.target?.result?.toString()?.split(',')?.[1];
      base64Arr.push({
        mimeTyp: file.type,
        nazov: file.name,
        obsah: base64Str?.toString() || '',
        typ: docType || undefined,
      });

      if (base64Arr.length === files.length) callback(base64Arr);
    };

    if (file) reader.readAsDataURL(file);
  });
};
