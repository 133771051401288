import { FooterApp, Link, color } from '@dovera/design-system';
import { Link as RouterLink } from 'react-router-dom';
import routes from '../../routes';
import useStyles from './Footer.styles';
import { isJWTValid } from '../../utils/auth.utils';

const Footer = () => {
  const classes = useStyles();
  if (!isJWTValid()) return <span />;
  return (
    <div className={classes.footerWrapper}>
      <FooterApp style={{ position: 'relative', zIndex: 2 }}>
        <Link
          color={color('secondary')}
          href="https://www.dovera.sk/lekar/aktuality"
          placeholder
          target="_blank"
        >
          Aktuality
        </Link>
        <Link
          color={color('secondary')}
          href="https://www.dovera.sk/lekarom"
          placeholder
          target="_blank"
        >
          Kontakt
        </Link>
        <Link
          color={color('secondary')}
          href="https://www.dovera.sk/sitemap.xml"
          placeholder
          target="_blank"
        >
          Mapa stránky
        </Link>
        <Link
          color={color('secondary')}
          href="https://www.dovera.sk/poistenec/potrebujem-poradit/o-dovere/uradna-tabula/informacie-o-spracuvani-osobnych-udajov"
          placeholder
          target="_blank"
        >
          Ochrana súkromia
        </Link>
        <RouterLink
          className="link"
          color={color('secondary')}
          to={routes.cookies}
        >
          Cookies
        </RouterLink>
      </FooterApp>
    </div>
  );
};

export default Footer;
