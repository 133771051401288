import { useState } from 'react';
import ContentHeader from '../../../../../components/ContentHeader/ContentHeader';
import { ButtonLink, Container, Icon } from '@dovera/design-system';
import Breadcrumbs from '../../../../../components/Breadcrumbs/Breadcrumbs';
import strings from '../../../../../constants/strings';
import { navrhyRoutes } from '../../../../../routes';
import { YoutubeModal } from '../../../../../components/YoutubeEmbed';
import { getCMSText } from '../../../../../utils/cms.utils';
import { cmsPath } from '../../../../../constants/cmsPath';
import useStyles from '../../../Proposals.styles';

const { common, drugs } = strings.proposals;

const Header = () => {
  const classes = useStyles({ proposalType: 'LN' });
  const [isModalVisible, setIsModalVisible] = useState(false);
  return (
    <ContentHeader padBottom>
      <Container>
        <Breadcrumbs
          items={[
            {
              name: common.new.breadcrumbs.proposals,
              route: '#',
            },
            {
              name: common.new.breadcrumbs.listOfProposals,
              route: navrhyRoutes.zoznamPodanychNavrhov,
            },
            {
              name: common.new.title,
            },
          ]}
        />
      </Container>
      <h2 className="no-mrg">{drugs.title}</h2>
      <div className={classes.headerDescription}>
        <div>
          {getCMSText(
            cmsPath.proposals.videomanual.lncnp.description,
            '',
            true,
          )}
        </div>
        <ButtonLink
          className="inline-btn"
          onClick={() => setIsModalVisible(true)}
        >
          <Icon name="social-youtube" size="medium" />
          {getCMSText(cmsPath.proposals.videomanual.lncnp.btn, '', true)}
        </ButtonLink>
      </div>
      <YoutubeModal
        embedId="SLNfs4-8igs"
        id="drug-and-cnp-proposal-creation"
        isVisible={isModalVisible}
        onHide={() => setIsModalVisible(false)}
        // @ts-ignore
        title={
          getCMSText(
            cmsPath.proposals.videomanual.lncnp.videoTitle,
            '',
            true,
          ) || ''
        }
      />
    </ContentHeader>
  );
};

export default Header;
