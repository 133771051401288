import { color } from '@dovera/design-system';

interface Props {
  active?: boolean;
  id: string;
}

const IconPatient = ({ active, id }: Props) => (
  <svg
    fill="none"
    height="32"
    id={id}
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="16"
      cy="16"
      fill={active ? color('warning', 100) : '#C4C4C4'}
      rx="16"
      ry="16"
    />
    <path
      d="M21.9988 22.75V21.25C21.9988 20.4544 21.6828 19.6913 21.1201 19.1287C20.5575 18.5661 19.7945 18.25 18.9988 18.25H12.9988C12.2032 18.25 11.4401 18.5661 10.8775 19.1287C10.3149 19.6913 9.99883 20.4544 9.99883 21.25V22.75"
      stroke={color('warning', 600)}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M16.0012 15.25C17.658 15.25 19.0012 13.9069 19.0012 12.25C19.0012 10.5931 17.658 9.25 16.0012 9.25C14.3443 9.25 13.0012 10.5931 13.0012 12.25C13.0012 13.9069 14.3443 15.25 16.0012 15.25Z"
      stroke={color('warning', 600)}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default IconPatient;
