import {
  CheckProposalDataResponse,
  GetCnpDetailResponse,
  GetDrugsDetailResponse,
  GetProposalDetailDocumentsResponse,
  GetProposalsListResponse,
  SaveCompletionResponse,
} from '../../types/drugProposals.types';
import { safeString } from '../../utils/strings.utils';
import {
  CheckProposalDataResponseSK,
  GetCnpDetailResponseSK,
  GetDrugsDetailResponseSK,
  GetProposalDetailDocumentsResponseSK,
  GetProposalsListResponseSK,
  SaveCompletionResponseSK,
} from '../apiTypes/drugProposals.types';

export const convertCheckProposal = (
  data: CheckProposalDataResponseSK,
): CheckProposalDataResponse => ({
  data: data.udaje.map((udaj) => ({
    additionalInfo: {
      value1: udaj.doplnujuceInfo?.hodnota1 || '',
      value2: udaj.doplnujuceInfo?.hodnota2 || '',
      value3: udaj.doplnujuceInfo?.hodnota3 || '',
      value4: udaj.doplnujuceInfo?.hodnota4 || '',
      value5: udaj.doplnujuceInfo?.hodnota5 || '',
      value6: udaj.doplnujuceInfo?.hodnota6 || '',
    },
    checkResult: udaj.vysledokKontroly,
    checkType: udaj.kontrolaTyp,
    errorCode: udaj.kodChyby,
    errorDescription: udaj.popisChyby,
    errorDetail: udaj.detailChyby,
    errorWeightCode: udaj.kodVahaChyby,
  })),
  formId: data.formularId,
});

export const convertGetDurgsDetail = (
  data: GetDrugsDetailResponseSK,
): GetDrugsDetailResponse => ({
  detail: {
    accepted: data.detail.prijaty,
    completionTxs: data.detail.doplnenieTxs,
    decided: data.detail.rozhodnuty,
    exposed: data.detail.vystaveny,
    exposeType: data.detail.typVystavenia,
    insuranceStatement: data.detail.vyjadreniePoistovne,
    proposalId: data.detail.idNavrhNaZS,
    proposalNumber: data.detail.cisloNavrhu,
    proposalValidityFrom: data.detail.platnostNavrhuOd,
    proposalValidityTo: data.detail.platnostNavrhuDo,
    state: data.detail.stav,
  },
  doctor: {
    ambulance: data.lekar.ambulancia,
    doctor: data.lekar.lekar,
    email: data.lekar.email,
    hospital: data.lekar.nemocnica,
  },
  information: {
    codeIL: data.informacie.kodIo,
    diagnosisCode: data.informacie.kodDiagnoza,
    diagnosisName: data.informacie.nazovDiagnoza,
    dosage: data.informacie.davkovanie,
    epicrisis: data.informacie.epikriza,
    hasContraintication: data.informacie.priznakKontraindikacie,
    hasME: data.informacie.maMv,
    nameIL: data.informacie.nazovIo,
    productCode: data.informacie.kodProduktPzs,
    productName: data.informacie.nazovProduktPzs,
    quantity: data.informacie.mnozstvo,
    treatmentJustification: data.informacie.zdovodnenieLiecby,
  },
  patient: {
    address: data.pacient.adresa,
    dateOfBirth: data.pacient.datumNarodenia,
    insureeId: data.pacient.rodneCislo,
    insureeIdTxs: data.pacient.idPoistenec,
    mobile: data.pacient.mobil,
    personName: data.pacient.meno,
    sex: data.pacient.pohlavie,
  },
});

export const convertGetCnpDetail = (
  data: GetCnpDetailResponseSK,
): GetCnpDetailResponse => ({
  detail: {
    accepted: data.detail.prijaty,
    completionTxs: data.detail.doplnenieTxs,
    decided: data.detail.rozhodnuty,
    exposed: data.detail.vystaveny,
    exposeType: data.detail.typVystavenia,
    insuranceStatement: data.detail.vyjadreniePoistovne,
    proposalId: data.detail.idNavrhNaZS,
    proposalNumber: data.detail.cisloNavrhu,
    proposalValidityFrom: data.detail.platnostNavrhuOd,
    proposalValidityTo: data.detail.platnostNavrhuDo,
    state: data.detail.stav,
  },
  doctor: {
    ambulance: data.lekar.ambulancia,
    doctor: data.lekar.lekar,
    email: data.lekar.email,
    hospital: data.lekar.nemocnica,
  },
  information: {
    codeIL: data.informacie.kodIo,
    diagnosisCode: data.informacie.kodDiagnoza,
    diagnosisName: data.informacie.nazovDiagnoza,
    dosage: data.informacie.davkovanie,
    epicrisis: data.informacie.epikriza,
    hasContraintication: data.informacie.priznakKontraindikacie,
    hasME: data.informacie.maMv,
    nameIL: data.informacie.nazovIo,
    productCode: data.informacie.kodProduktPzs,
    productName: data.informacie.nazovProduktPzs,
    quantityApproved: data.informacie.mnozstvoSchvalene,
    quantityConsumed: data.informacie.mnozstvoSpotrebovane,
    quantityDelivered: data.informacie.mnozstvoDodane,
    quantityOrdered: data.informacie.mnozstvoObjednane,
    quantityRequired: data.informacie.mnozstvoPozadovane,
    quantityTransfered: data.informacie.mnozstvoPrenesene,
    treatmentJustification: data.informacie.zdovodnenieLiecby,
  },
  patient: {
    address: data.pacient.adresa,
    dateOfBirth: data.pacient.datumNarodenia,
    insureeId: data.pacient.rodneCislo,
    insureeIdTxs: data.pacient.idPoistenec,
    mobile: data.pacient.mobil,
    personName: data.pacient.meno,
    sex: data.pacient.pohlavie,
  },
});

export const convertGetProposalDetailDocuments = (
  data: GetProposalDetailDocumentsResponseSK,
): GetProposalDetailDocumentsResponse => ({
  documents: data.dokumenty.map((dokument) => ({
    barCode: safeString(dokument.ciarovyKod || dokument.kluc),
    name: safeString(dokument.nazovDokumentu || dokument.nazov),
    validatedCode: safeString(dokument.overovaciKod),
  })),
});

export const convertGetProoposalsList = (
  data: GetProposalsListResponseSK,
): GetProposalsListResponse => ({
  count: data.pocet,
  proposals: data.navrhy.map((proposal) => ({
    doctor: {
      ambulanceCode: proposal.lekar.kodAmbulancia,
      ambulanceName: proposal.lekar.nazovAmbulancia,
      code: proposal.lekar.kod,
      personName: proposal.lekar.meno,
      personSurname: proposal.lekar.priezvisko,
      titleAfter: proposal.lekar.titulZa,
      titleBefore: proposal.lekar.titulPred,
    },
    id: proposal.id,
    insuree: {
      insureeId: proposal.poistenec.rodneCislo,
      personName: proposal.poistenec.meno,
      personSurname: proposal.poistenec.priezvisko,
    },
    productCode: proposal.kodProduktPzs,
    productName: proposal.nazovProduktPzs,
    proposalId: proposal.idNavrhNaZS,
    proposalNumber: proposal.cisloNavrhu,
    proposalType: proposal.typNavrhu,
    proposalValidityFrom: proposal.platnostNavrhuOd,
    proposalValidityTo: proposal.platnostNavrhuDo,
    state: proposal.stav,
  })),
});

export const convertSaveCompletionResponse = (
  data: SaveCompletionResponseSK,
): SaveCompletionResponse => ({
  error: data.error,
  result: data.vysledok,
});
