import { Input, Select, Textarea } from '@dovera/design-system';
import useStyles from '../../../../Proposals.styles';
import { FieldRenderProps } from 'react-final-form-hooks';
import strings from '../../../../../../constants/strings';
import { useAppDispatch } from '../../../../../../hooks/useStore';
import { storeStep3 } from '../../../../../../slices/drugProposals.slice';
import RemainingChars from '../../../../../../components/RemainingChars/RemainingChars';
import { systemConstants } from '../../../../../../constants/systemConstants';
import SafeHtml from '../../../../../../components/SafeHtml/SafeHtml';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../rootReducer';
import { useEffect, useState } from 'react';
import { CheckType } from '../../../../../../types/proposals.types';
import { checkProposalData } from '../../../../../../api/drugProposals';
import { CUSTOM_DOSAGE } from '../../../../../../constants/drugProposals';
import { FieldElementIdEnum } from '../../../../../../types/drugProposals.types';
import { scrollToField } from '../../../../../../utils/form.utils';
import { TextareaPreloader } from '../../../../../../components/Preloader';

const { labels } = strings.proposals.drugs.new;

interface Props {
  dosageDescriptionField: FieldRenderProps<string, string>;
  dosageField: FieldRenderProps<string, string>;
  quantityField: FieldRenderProps<string | number, string>;
  storedValues: {
    dosage: string;
    dosageDescription: string;
    quantity: number;
  };
}

const checkTypes: CheckType[] = ['Mnozstvo'];

const QuantityDosage = ({
  dosageDescriptionField,
  dosageField,
  quantityField,
  storedValues,
}: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles({ proposalType: 'LN' });
  const {
    formData: { data },
  } = useSelector((state: RootState) => state.drugProposals.new.data);
  const [defaultDosage, setDefaultDosage] = useState('');
  const [loadingDosageDescription, setLoadingDosageDescription] =
    useState(false);
  useEffect(() => {
    const dosage = data?.udajParLiekNavrhuCnp?.davkovanie;
    if (dosage) {
      setDefaultDosage(dosage);
      dosageField.input.onChange(dosage);
      dispatch(storeStep3({ dosage }));
    } else {
      setDefaultDosage('');
      dosageField.input.onChange(CUSTOM_DOSAGE);
      dispatch(storeStep3({ dosage: CUSTOM_DOSAGE }));
    }
    // eslint-disable-next-line
  }, [data, dispatch]);
  useEffect(() => {
    if (dosageField.input.value === CUSTOM_DOSAGE) {
      setLoadingDosageDescription(true);
      setTimeout(() => {
        setLoadingDosageDescription(false);
      }, 1500);
    }
  }, [dosageField.input.value]);
  const renderQuantity = (
    <Input
      {...quantityField.input}
      crossOrigin
      error={quantityField.meta.modified && quantityField.meta.error}
      id={FieldElementIdEnum.Quantity}
      inputType="number"
      isRequired
      label={labels.quantity}
      max={systemConstants.MAX_INPUT_INT}
      min={1}
      onBlur={(e) => {
        const { value } = e.target;
        if (value)
          dispatch(
            checkProposalData({
              checkTypes,
              quantity: Number(value),
            }),
          );
      }}
      onFocus={() => {
        quantityField.input.onFocus();
        scrollToField(FieldElementIdEnum.Quantity);
      }}
      // eslint-disable-next-line
      onChange={(e) => {
        quantityField.input.onChange(e.target.value);
        dispatch(storeStep3({ quantity: e.target.value }));
      }}
      step="1"
    />
  );
  const renderDosage = !defaultDosage ? (
    <Input
      {...dosageField.input}
      crossOrigin
      id={FieldElementIdEnum.Dosage}
      isDisabled
      label={labels.dosage}
      value={CUSTOM_DOSAGE}
    />
  ) : (
    <Select
      {...dosageField.input}
      id={FieldElementIdEnum.Dosage}
      label={labels.dosage}
      onFocus={() => {
        dosageField.input.onFocus();
        scrollToField(FieldElementIdEnum.Dosage);
      }}
      // eslint-disable-next-line
      onChange={(value) => {
        dosageField.input.onChange(value);
        dispatch(storeStep3({ dosage: value }));
      }}
      options={[
        { label: CUSTOM_DOSAGE, value: CUSTOM_DOSAGE },
        { label: defaultDosage, value: defaultDosage },
      ]}
    />
  );
  const renderSkeletonDosageDescription = loadingDosageDescription &&
    dosageField.input.value === CUSTOM_DOSAGE && (
      <TextareaPreloader displayBlock />
    );
  const renderDescription = dosageField.input.value === CUSTOM_DOSAGE && (
    <Textarea
      {...dosageDescriptionField.input}
      error={
        dosageDescriptionField.meta.touched &&
        dosageDescriptionField.meta.error && (
          <SafeHtml html={dosageDescriptionField.meta.error} wrapper="span" />
        )
      }
      help={
        <RemainingChars
          alignWithLabel
          formHelper=" "
          maxLength={200}
          valueLength={dosageDescriptionField.input.value?.length}
        />
      }
      id={FieldElementIdEnum.DosageDescription}
      label={labels.dosageDescription}
      maxLength={200}
      onBlur={(e) => {
        dosageDescriptionField.input.onBlur();
        if (storedValues.dosageDescription !== e.target.value)
          dispatch(storeStep3({ dosageDescription: e.target.value }));
      }}
      onFocus={() => {
        dosageDescriptionField.input.onFocus();
        scrollToField(FieldElementIdEnum.DosageDescription);
      }}
    />
  );
  return (
    <div className={classes.drugsWrapper}>
      <div className={classes.quantity}>{renderQuantity}</div>
      {renderDosage}
      {renderSkeletonDosageDescription || renderDescription}
    </div>
  );
};

export default QuantityDosage;
