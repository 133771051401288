import { Grid, GridCol, Skeleton } from '@dovera/design-system';

const DocumentsSkeleton = () => (
  <Grid>
    <GridCol className="mb-small" size={{ s: 12, m: 5, l: 12, xl: 4 }}>
      <Skeleton className="d-block mb-xsmall" height={20} width="80%" />
      <Skeleton className="d-block mb-xsmall" height={20} width="40%" />
      <Skeleton className="d-block mb-xsmall" height={20} width="40%" />
    </GridCol>
    <GridCol size={{ s: 12, m: 5, l: 12, xl: 4 }}>
      <Skeleton className="d-block mb-xsmall" height={20} width="80%" />
      <Skeleton className="d-block mb-xsmall" height={20} width="40%" />
      <Skeleton className="d-block mb-xsmall" height={20} width="40%" />
    </GridCol>
  </Grid>
);

export default DocumentsSkeleton;
