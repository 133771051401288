import { useContext, useEffect, useState } from 'react';
import { FilterContext } from '../../../providers/FilterProvider';
import { Select, Skeleton } from '@dovera/design-system';
import { useQuery } from '../../../hooks/useQuery';
import { FILTER_PREFIX } from '../constants';
import { SelectType } from '../types';
import { createSelectEvent } from '../utils';

interface Props extends SelectType {
  type?: 'inline';
}

const SelectFilter = ({ ...props }: Props) => {
  const {
    id,
    initWithDelay,
    onChange = () => {},
    options,
    value,
    ...restProps
  } = props;

  const [filterValue, setFilterValue] = useState(value);
  const [isLoading, setIsLoading] = useState(true);

  const { searchParams, setSearchParams } = useContext(FilterContext);
  const query = useQuery();

  useEffect(() => {
    // timeout for fixing 'Cannot read properties of null (reading 'getAttribute')'
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 100);

    return () => clearTimeout(loadingTimeout);
  }, []);

  useEffect(() => {
    const queryValue = query.get(`${FILTER_PREFIX}_${id}`);
    if (queryValue && options?.length) {
      const timeout = initWithDelay ? 700 : 200;
      setTimeout(() => {
        handleSelect(queryValue);
      }, timeout);
    }
    // eslint-disable-next-line
  }, [initWithDelay]);

  const handleSelect = (val) => {
    searchParams.set(`${FILTER_PREFIX}_${id}`, val);
    setSearchParams(searchParams);
    if (
      typeof onChange === 'function' &&
      id &&
      document.querySelector(`#${id}`)
    ) {
      onChange(val.toString(), createSelectEvent(id, val.toString()));
    }
    setFilterValue(val);
  };

  return isLoading ? (
    <div className="d-block">
      <Skeleton className="mb-xxxsmall d-block" height={16} width="30%" />
      <Skeleton height={45} width="100%" />
    </div>
  ) : (
    <Select
      id={id || 'select-filter'}
      onChange={(val) => handleSelect(val)}
      options={options}
      value={filterValue}
      {...restProps}
    />
  );
};

export default SelectFilter;
