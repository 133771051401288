import { breakpoints, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  odbornosti: {
    display: 'inline-block',
    width: getRem(251),
    '& input': {
      cursor: 'pointer',
    },
    [`@media screen and (min-width: ${
      breakpoints.m + 1
    }px) and (max-width: 1100px)`]: {
      width: getRem(200),
    },
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      marginRight: getRem(90),
      width: '100%',
      position: 'relative',
    },
  },
  autocomplete: {
    '& > .dropdown': {
      width: getRem(345),
      maxWidth: 'initial',
    },
  },
});
