import { Modal } from '@dovera/design-system';
import { useSelector } from 'react-redux';
import { Body, Footer } from './common';
import { getParamTitle } from '../../../../utils/parameters.utils';
import { ModalType } from '../../../../types/modal.types';
import { useModalContext } from '../../../../hooks/useModalContext';
import { RootState } from '../../../../rootReducer';
import { LoadingState } from '../../../../types';

interface Props {
  isRadio: boolean;
  isSelected: boolean;
  modalID: ModalType;
  onCancel: () => void;
  onClick: () => void;
  shouldDisplayButtons: boolean;
  texts: {
    btnCancel: string;
    btnSelect: string;
    btnUnselect: string;
  };
}

const ParameterDetailModal = ({
  isRadio,
  isSelected,
  modalID,
  onCancel,
  onClick,
  shouldDisplayButtons,
  texts,
  ...other
}: Props) => {
  const { closeModal, isOpenModal } = useModalContext();
  const { data, dataState } = useSelector(
    (state: RootState) => state.parameters.parameterDetail,
  );
  return (
    <Modal
      closeOnOverlayClick={false}
      data-keyboard="false"
      data-modal-initial-focus
      header={getParamTitle(data?.detail)}
      id="parameter-detail--modal"
      isVisible={isOpenModal(modalID)}
      onHide={() => closeModal(modalID)}
      {...other}
    >
      <Body data={data?.detail} dataState={dataState} modalID={modalID} />
      <Footer
        isRadio={isRadio}
        isSelected={isSelected}
        onCancel={onCancel}
        onClick={onClick}
        shouldDisplayButtons={shouldDisplayButtons}
        shouldDisplayCancelBtn={dataState !== LoadingState.error}
        texts={texts}
      />
    </Modal>
  );
};
export default ParameterDetailModal;
