import { useCallback } from 'react';
import {
  ButtonLink,
  Card,
  Grid,
  GridCol,
  Illustration,
  Link,
  PageSection,
  Step,
} from '@dovera/design-system';
import { AppHeader } from '../../containers';
import useStyles from './ChybajuceSekcie.styles';
import { epSekcie } from '../../constants/epLinks';
import { useAuthContext } from '../../hooks/useAuthContext';

const ChybajuceSekcie = () => {
  const classes = useStyles();
  const authContext = useAuthContext();
  const handleLogout = useCallback(() => {
    authContext.logout();
  }, [authContext]);
  const renderInfoBox = (
    <Card className={classes.card}>
      <h4 className="mb-small">Ako pridať sekcie</h4>
      <Grid>
        <GridCol
          offset={{
            m: 'center',
          }}
          size={{
            m: 8,
          }}
        >
          <Step stepNumber={1}>
            V Správe prístupov kliknite na <b>Číslo karty.</b>
            <div className="mb-small" />
            <Link
              className="btn btn--primary btn--small"
              href={epSekcie['sprava-pristupov']}
              placeholder=""
              target="_blank"
            >
              Prejsť do Správy prístupov
            </Link>
          </Step>
          <Step stepNumber={2}>
            Zobrazia sa <b>Priradené sekcie</b> - pridajte Hodnotiace parametre,
            Náklady pacientov, Zmluvy. Kliknite na <b>Uložiť sekcie.</b>
          </Step>
          <Step stepNumber={3}>
            Sekcie budú po pridaní dostupné v DôveraLekárom približne{' '}
            <b>o 5 minút.</b> Ak ich tu nevidíte,{' '}
            <ButtonLink className="no-pad" onClick={handleLogout}>
              znova sa prihláste
            </ButtonLink>
            .
          </Step>
        </GridCol>
      </Grid>
    </Card>
  );
  return (
    <div className={classes.root}>
      <AppHeader />
      <PageSection className={classes.section} space="small">
        <div className="text-center">
          <Illustration
            height={173}
            name="large_empty_state"
            size="large"
            width={208}
          />
          <h4 className="text-color-warning mb-small">
            Na zobrazenie obsahu si pridajte sekcie
          </h4>
          <p>
            Obsah pre službu DôveraLekárom si sprístupnite v Správe prístupov v
            Elektronickej pobočke. Tieto úpravy môže robiť{' '}
            <b>iba držiteľ hlavnej GRID karty.</b>
          </p>
          {renderInfoBox}
        </div>
      </PageSection>
    </div>
  );
};

export default ChybajuceSekcie;
