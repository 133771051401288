import { Skeleton } from '@dovera/design-system';
import { cx } from '../../utils/exports';
import useStyles from './Menu.styles';

const MenuSkeleton = () => {
  const classes = useStyles();
  return (
    <div className={cx(classes.navigation, classes.navigationPreloader)}>
      <Skeleton className="d-block" height={48} width="100%" />
      <Skeleton className="d-block" height={48} width="80%" />
      <Skeleton className="d-block" height={48} width="100%" />
      <Skeleton className="d-block" height={48} width="90%" />
    </div>
  );
};

export default MenuSkeleton;
