import {
  Button,
  ButtonLayout,
  ButtonSecondary,
  Icon,
  Notification,
  color,
} from '@dovera/design-system';
import { useCallback, useEffect, useState } from 'react';
import { useField, useForm } from 'react-final-form-hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { VALIDATION } from '../../../types/validation.types';
import { DEFAULT_SELECT_VALUE } from '../../../utils/dds.utils';
import { cx } from '../../../utils/exports';
import { validationMsg } from '../../../utils/form.utils';
import useStyles from '../Spravy.styles';
import useAppStyles from '../../../App.styles';
import UploadFiles from '../UploadFiles/UploadFiles';
import strings from '../../../constants/strings';
import { useAppDispatch } from '../../../hooks/useStore';
import { ulozSpravu } from '../../../api/schrankaSprav';
import regex from '../../../constants/regex';
import { useFilterContext } from '../../../hooks/useFilterContext';
import usePreventAnchorClick from '../../../hooks/usePreventAnchorClick';
import { getPZS } from '../../../slices/auth.slice';
import { feedbackTrigger } from '../../../slices/feedback.slices';
import {
  resetSchranky,
  resetTem,
  zobrazitToast,
} from '../../../slices/spravy.slice';
import { ApiError } from '../../../types/api';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { appActionsVisibility } from '../../../utils/app.utils';
import FormFields from './FormFields';
import { callThreadDetail } from '../../../utils/spravy.utils';
import { Dokument } from '../../../types/models/Dokument';
import { getUserId } from '../../../utils/auth.utils';
import { useModalContext } from '../../../hooks/useModalContext';
import { ModalType } from '../../../types/modal.types';

interface Props {
  escapeModal?: boolean;
  hasPzsEmail?: boolean;
  isReply?: boolean;
  onCancel: (isCancelVisible?: boolean) => void;
  onContinueModal?: () => void;
  pzsEmail: string | null;
  threadId?: number;
}

const validate = (
  values: { [x: string]: any },
  hasPzsEmail?: boolean,
  isReply?: boolean,
) => {
  const errors: any = {};
  if (!hasPzsEmail && !values.email)
    errors.email = validationMsg(VALIDATION.RequiredEmpty);
  if (!hasPzsEmail && values.email && !regex.EMAIL.test(values.email))
    errors.email = validationMsg(VALIDATION.EmailIncorrect);
  if (!isReply && (!values.theme || values.theme === DEFAULT_SELECT_VALUE))
    errors.theme = validationMsg(VALIDATION.RequiredEmpty);
  if (
    !isReply &&
    values.theme &&
    (!values.subtheme || values.subtheme === DEFAULT_SELECT_VALUE)
  )
    errors.subtheme = validationMsg(VALIDATION.RequiredEmpty);
  if (!values.message) errors.message = validationMsg(VALIDATION.RequiredEmpty);
  return errors;
};

const texts = strings.schrankaSprav;

const MessageForm = ({
  escapeModal,
  hasPzsEmail,
  isReply,
  onCancel,
  onContinueModal,
  pzsEmail,
  threadId,
}: Props) => {
  const classes = useStyles({});
  const classesApp = useAppStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const filterContext = useFilterContext();
  const [requiredAttachmentUrl, setRequiredAttachmentUrl] = useState('');
  const [uploadErrorValidation, setUploadErrorValidation] = useState('');
  const [files, setFiles] = useState<Dokument[] | null>(null);
  const [resetFiles, setResetFiles] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [subthemeLoading, setSubthemeLoading] = useState(false);
  const [routeAfterCancel, setRouteAfterCancel] = useState('#');
  const pzs = useSelector((state: RootState) => getPZS(state.auth));
  const modalContext = useModalContext();
  const {
    detail,
    temy: { data, dataState },
  } = useSelector((state: RootState) => state.spravy);
  const { form, handleSubmit } = useForm({
    onSubmit: (values) => {
      if (requiredAttachmentUrl && !files?.length) {
        setUploadErrorValidation('Nahrajte súbor.');
      } else {
        setError('');
        setLoading(true);
        let { email } = values;
        if (hasPzsEmail) email = detail.emailVlakna;
        filterContext.removeParams(['tema-spravy', 'podtema-spravy']);
        saveMessage(values, email);
      }
    },
    validate: (values: any) => validate(values, hasPzsEmail, isReply),
  });

  const saveMessage = (values, email) => {
    ulozSpravu({
      email: email || pzsEmail,
      obsahSpravy: values.message,
      prilohy: files,
      temaId: Number(values.subtheme || values.theme) || null,
      vlaknoId: threadId || 0,
    })
      .then((data) => {
        appActionsVisibility(true);
        if (data.chyba || !data.vysledok)
          setError(texts.sendMessage.error || strings.defaultError);
        if (!data.chyba && data.vysledok) {
          dispatch(zobrazitToast({ typ: 'odoslanie' }));
          if (!isReply) {
            dispatch(resetSchranky());
          } else {
            callThreadDetail({ id: threadId, scrollToBottom: true });
          }
          resetForm();
          setTimeout(() => {
            onCancel();
          });
        }
      })
      .catch((err: AxiosError<ApiError>) => {
        const { response } = err;
        setError(
          texts.sendMessage.error ||
            response?.data?.text ||
            strings.defaultError,
        );
      })
      .finally(() => {
        setLoading(false);
        dispatch(feedbackTrigger({ delay: 5, userId: getUserId(pzs.userId) }));
      });
  };

  const email = useField('email', form);
  const theme = useField('theme', form, (value) => {
    if (value !== theme.input.value) {
      setSubthemeLoading(true);
      if (data.some((d) => d.id === Number(value) && d.podtypy?.length))
        setTimeout(() => {
          setSubthemeLoading(false);
        }, 500);
    }
  });
  const subtheme = useField('subtheme', form);
  const message = useField('message', form);

  const resetForm = useCallback(
    (onlyFieldStates?: boolean) => {
      form.resetFieldState('email');
      form.resetFieldState('message');
      form.resetFieldState('theme');
      form.resetFieldState('subtheme');
      if (!onlyFieldStates) {
        dispatch(resetTem());
        appActionsVisibility(true);
        form.reset();
        setFiles(null);
        setResetFiles(true);
        setError('');
        setTimeout(() => {
          setResetFiles(false);
        }, 500);
      }
    },
    [dispatch, form],
  );

  const isFilledField: boolean =
    email.input.value ||
    theme.input.value ||
    message.input.value ||
    files?.length;

  const openCancelModal = useCallback(() => {
    modalContext.showModal(ModalType.CANCEL_ACTION, {
      isFormModal: true,
      onCancel: () => {
        modalContext.closeModal(ModalType.CANCEL_ACTION);
        if (routeAfterCancel) navigate(routeAfterCancel);
        resetForm();
        if (onCancel) onCancel();
      },
      onContinue: () => {
        modalContext.closeModal(ModalType.CANCEL_ACTION);
        if (onContinueModal) onContinueModal();
      },
      texts: {
        btnCancel: texts.modals.cancelNewMessage.buttons.cancel,
        btnContinue: texts.modals.cancelNewMessage.buttons.continue,
        description: texts.modals.cancelNewMessage.description,
        title: texts.modals.cancelNewMessage.title,
      },
    });
  }, [
    modalContext,
    navigate,
    onCancel,
    onContinueModal,
    resetForm,
    routeAfterCancel,
  ]);

  useEffect(() => {
    appActionsVisibility(!isFilledField);
    if (onContinueModal) resetForm(true);
    // eslint-disable-next-line
  }, [onContinueModal, isFilledField]);

  useEffect(() => {
    if (escapeModal && !!isFilledField) openCancelModal();
  }, [escapeModal, isFilledField, openCancelModal]);

  useEffect(() => {
    const currentTheme =
      data?.find((d) => d.id.toString() === theme.input.value) || null;
    setRequiredAttachmentUrl(
      currentTheme?.podtypy?.find(
        (p) =>
          p.id.toString() === subtheme.input.value &&
          p.povinnostPrilohy &&
          p.dokumentUrl,
      )?.dokumentUrl || '',
    );
  }, [data, theme.input.value, subtheme.input.value]);

  useEffect(() => {
    if (!requiredAttachmentUrl) setUploadErrorValidation('');
  }, [requiredAttachmentUrl]);

  const onCancelClick = useCallback(() => {
    if (isFilledField) {
      if (onContinueModal) onCancel(true);
      openCancelModal();
    } else onCancel(false);
  }, [isFilledField, onCancel, onContinueModal, openCancelModal]);

  usePreventAnchorClick(!!isFilledField, (e) => {
    const t = e?.target;
    // @ts-ignore
    const href = !t?.href ? t?.closest('a')?.href : t?.href || '#';
    setRouteAfterCancel(href);
    onCancelClick();
    return false;
  });

  const renderUploadArea = (
    <UploadFiles
      onChange={(f) => {
        setUploadErrorValidation('');
        setFiles(f);
      }}
      onUploadError={(isErr) => setUploadError(isErr)}
      reset={resetFiles}
      validationError={uploadErrorValidation}
    />
  );
  const renderCancelMsgBtn = isReply ? (
    <Button
      className={cx(classes.button, 'text-color-error no-mrg')}
      onClick={onCancelClick}
      size="s"
      type="destructive-secondary"
    >
      <Icon
        className="icon--left"
        color={color('error', 600)}
        name="16-close"
        size="small"
      />
      {texts.buttons.cancel}
    </Button>
  ) : (
    <ButtonSecondary onClick={onCancelClick}>
      {texts.buttons.cancelModal}
    </ButtonSecondary>
  );
  const renderButtons = (
    <ButtonLayout
      className={!isReply ? classes.newMessageButtons : classes.buttons}
      direction="horizontal"
    >
      <Button
        className={cx('no-mrg', classes.button)}
        isDisabled={uploadError || loading}
        isLoading={loading}
        submit
      >
        {!loading && <Icon className="icon--left" name="send" size="medium" />}
        {texts.buttons.submit}
      </Button>
      {!isReply && renderCancelMsgBtn}
    </ButtonLayout>
  );
  const renderError = error && <Notification message={error} variant="error" />;
  return (
    <form
      className={cx(
        isReply ? classes.replyForm : classes.newMessageForm,
        classesApp.appForm,
      )}
      data-testid="messageForm"
      onSubmit={handleSubmit}
    >
      {isReply && (
        <div className={cx(classes.replyTitle, 'mb-small')}>
          <h4 className="no-mrg">Odpovedať</h4>
          {renderCancelMsgBtn}
        </div>
      )}
      <FormFields
        attachmentUrl={requiredAttachmentUrl}
        emailField={email}
        hasPzsEmail={hasPzsEmail}
        isReply={isReply}
        messageField={message}
        subthemeField={subtheme}
        subthemeLoading={subthemeLoading}
        themeField={theme}
        themes={{
          data,
          dataState,
        }}
      />
      {renderUploadArea}
      {renderError}
      {renderButtons}
    </form>
  );
};

export default MessageForm;
