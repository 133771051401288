import { useCallback, useEffect, useState } from 'react';
import {
  ButtonLink,
  Icon,
  Pagination,
  TableApp,
  color,
} from '@dovera/design-system';
import strings from '../../../../constants/strings';
import { hotjarMasking } from '../../../../utils/strings.utils';
import {
  DATE_INTERNATIONAL,
  formatDate,
  getMoment,
} from '../../../../utils/date.utils';
import routes from '../../../../routes';
import { DajZoznamAmbulanciiPZSPayload } from '../../../../types/api/poskytovatel';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import { fetchAmbulances } from '../../../../api/poskytovatel';
import { useAppDispatch } from '../../../../hooks/useStore';
import { TABLE_PAGINATION } from '../../../../constants/misc';
import { AmbulanciaPZS } from '../../../../types/poskytovatel.types';
import _ from 'lodash';
import FilterAmbulances from '../Filter/FilterAmbulances';
import { NoDataIllustration } from '../../../../components/EmptyState';
import { LoadingState } from '../../../../types';
import { mergeMultidimensionalArray } from '../../../../utils/array.utils';
import Export from '../Export/Export';
import { useNavigate } from 'react-router';
import TablePreloaderSkeleton from '../../../../components/TablePreloaderSkeleton/TablePreloaderSkeleton';
import { getPZS } from '../../../../slices/auth.slice';

const texts = strings.profile.overeniaPrehlady.filter.ambulances.columns;

const TableAmbulances = () => {
  const navigate = useNavigate();
  const [actualPage, setActualPage] = useState(0);
  const [activeFilter, setActiveFilter] = useState(false);
  const [filteredData, setFilteredData] = useState<AmbulanciaPZS[][]>([]);
  const dispatch = useAppDispatch();
  const pzs = useSelector((state: RootState) => getPZS(state.auth));
  const ambulances = useSelector(
    (state: RootState) => state.pzs.overeniaPrehlady.ambulances,
  );
  const { autentifikovaneOsoby, preLekara } = useSelector(
    (state: RootState) => state.poskytovatel,
  );
  const onFiltered = useCallback(
    ({
      ambulanceName,
      contractual,
      date,
      expertise,
    }: {
      ambulanceName: string;
      contractual: string;
      date: string;
      expertise: string;
    }) => {
      const filteredData = ambulances.data?.filter(
        (a) =>
          (!ambulanceName ||
            a.nazov.toLowerCase().includes(ambulanceName.toLowerCase()) ||
            a.kod.toLowerCase().includes(ambulanceName.toLowerCase()) ||
            `${a.kod} - ${a.nazov}`.toLowerCase() ===
              ambulanceName.toLowerCase()) &&
          (!expertise ||
            expertise === 'all' ||
            a.odbornostKod.includes(expertise) ||
            a.odbornostNazov.toLowerCase().includes(expertise)) &&
          (!contractual ||
            contractual === 'all' ||
            (a.jeZmluvny && contractual === 'áno') ||
            (!a.jeZmluvny && contractual === 'nie')) &&
          (!date ||
            (getMoment(a.datumOd).isBefore(getMoment(date)) &&
              (!a.datumDo ||
                (a.datumDo && getMoment(a.datumDo).isAfter(getMoment(date)))))),
      );
      const filtered =
        (_.chunk(filteredData, TABLE_PAGINATION) as AmbulanciaPZS[][]) || null;
      setFilteredData(filtered);
      setTimeout(() => {
        setActiveFilter(true);
      }, 500);
    },
    [ambulances.data],
  );
  useEffect(() => {
    const payload: DajZoznamAmbulanciiPZSPayload = {
      datumDo: getMoment().format(DATE_INTERNATIONAL),
      idLekarOsoba: preLekara
        ? autentifikovaneOsoby?.lekarOsoba?.lekarOsobaId || null
        : null,
    };
    dispatch(fetchAmbulances(payload));
  }, [
    autentifikovaneOsoby?.lekarOsoba?.lekarOsobaId,
    dispatch,
    preLekara,
    pzs.vzId,
  ]);
  useEffect(() => {
    onFiltered({
      ambulanceName: '',
      contractual: 'áno',
      date: getMoment().format(DATE_INTERNATIONAL),
      expertise: '',
    });
    // eslint-disable-next-line
  }, [ambulances.data]);
  const renderNoData =
    (ambulances.dataState === LoadingState.filled &&
      !ambulances.data?.length) ||
    (!filteredData.length && ambulances.data?.length && activeFilter) ? (
      <NoDataIllustration title="Neevidujeme žiadne ambulancie" />
    ) : (
      ambulances.dataState === LoadingState.error && (
        <NoDataIllustration
          illustration="technical"
          title={strings.defaultError}
        />
      )
    );
  const renderFilter = (
    <FilterAmbulances
      ambulances={ambulances.filterOptions.ambulances}
      expertises={ambulances.filterOptions.expertises}
      onChange={(ambulanceName, contractual, date, expertise) => {
        onFiltered({ ambulanceName, contractual, date, expertise });
        setActualPage(0);
      }}
    />
  );
  const rednerTable = (
    <TableApp
      caption=""
      className="table-caption--hide"
      columns={[
        { accessor: 'col1', Header: texts.name },
        { accessor: 'col2', Header: texts.expertise },
        { accessor: 'col3', Header: texts.valid },
        { accessor: 'col4', Header: texts.contractual },
        { accessor: 'col5', Header: '' },
      ]}
      data={
        filteredData?.[actualPage]?.map((d) => {
          const hasDetail =
            !d.datumDo ||
            (d.datumDo && getMoment(d.datumDo).isAfter(getMoment()));
          return {
            col1: (
              <>
                {hotjarMasking(d.kod, 'b', 'd-block')}
                {hotjarMasking(
                  d.nazov || '-',
                  'small',
                  'text-color-grey-light',
                )}
              </>
            ),
            col2: `${d.odbornostKod} - ${d.odbornostNazov || ''}`,
            col3: `${formatDate(d.datumOd)} - ${
              d.datumDo ? formatDate(d.datumDo) : ''
            }`,
            col4: (
              <div className="content-center-horiz">
                {d.jeZmluvny ? (
                  <Icon
                    color={color('primary', 700)}
                    name="check"
                    size="medium"
                  />
                ) : (
                  <Icon
                    color={color('error', 600)}
                    name="close"
                    size="medium"
                  />
                )}
              </div>
            ),
            col5: hasDetail && (
              <ButtonLink
                className="no-pad no-mrg"
                onClick={() =>
                  navigate(`${routes.overeniaPrehlady}/ambulancie/${d.kod}`)
                }
              >
                Zobraziť
              </ButtonLink>
            ),
          };
        }) || []
      }
      // @ts-ignore
      pagination={
        <Pagination
          currentPage={actualPage + 1}
          onChange={(pageNumber) => setActualPage(pageNumber - 1)}
          totalPages={filteredData.length || 0}
        />
      }
    />
  );
  const renderExport = (
    <Export
      ambulances={
        filteredData ? mergeMultidimensionalArray(filteredData) : null
      }
      doctors={null}
    />
  );
  if (ambulances.dataState === LoadingState.fetching)
    return <TablePreloaderSkeleton columns={5} filterCols={4} hasFilter />;

  return (
    <>
      {renderExport}
      {renderFilter}
      {renderNoData || rednerTable}
    </>
  );
};

export default TableAmbulances;
