import { color as ddsColor } from '@dovera/design-system';

interface Props {
  color?: string;
  height?: number;
  id: string;
  width?: number;
}

const IconCloseModal = ({ color, height, id, width }: Props) => (
  <svg
    fill="none"
    height={height || 32}
    id={id}
    viewBox="0 0 32 32"
    width={width || 32}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1434_9245)">
      <circle
        cx="16"
        cy="16"
        r="15"
        stroke={color || ddsColor('primary', 700)}
        strokeWidth="2"
      />
      <path
        d="M12 12L16.0354 16M16.0354 16L20 12M16.0354 16L20 19.9646M16.0354 16L12 19.9646"
        stroke={color || ddsColor('primary', 700)}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default IconCloseModal;
