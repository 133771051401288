import { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import routes from '../routes';
import { shallowEqual, useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import { Footer, ScrollToTop } from '../components';
import LoginSSO from '../pages/login/LoginSSO';
import PageNotFound from '../pages/404/PageNotFound';
import CallbackPage from '../pages/callback';
import { useAuthContext } from '../hooks/useAuthContext';
import { RootState } from '../rootReducer';
import { fetchDataVZ, nepovolenyPristup } from '../slices/poskytovatel.slice';
import { AppHeader } from '../containers';
import AppVersion from '../pages/verzie/AppVersion';
import SilentRenew from '../pages/callback/SilentRenew';
import ChybaPrihlasenia from '../pages/chyba-prihlasenia/ChybaPrihlasenia';
import { fetchCMSContent } from '../slices/cms.slice';
import Cookies from '../pages/cookies/Cookies';
import RouterProvider from '../providers/RouterProvider';
import { useAppDispatch } from '../hooks/useStore';
import PrivateRouter from './PrivateRouter';
import LogoutPage from '../pages/logout/LogoutPage';
import { removeToken } from '../utils/auth.utils';

function AppRouter() {
  const authContext = useAuthContext();
  const dispatch = useAppDispatch();
  const {
    auth: { guid, pouzivatelId, token, vztahId },
    personalizedDataReady,
    preLekara,
  } = useSelector(
    (state: RootState) => ({
      autentifikovaneOsoby: state.poskytovatel.autentifikovaneOsoby,
      auth: state.auth,
      dostupneOdbornosti: state.poskytovatel.odbornosti,
      errorType: state.poskytovatel.errorType,
      ico: state.poskytovatel.ico,
      idHodnotenyPZS: state.poskytovatel.idHodnotenyPZS,
      personalizedDataReady: state.poskytovatel.personalizedDataReady,
      preLekara: state.poskytovatel.preLekara,
    }),
    shallowEqual,
  );

  useEffect(() => {
    authContext
      .getUserManager()
      .getUser()
      .then((user) => {
        if (!user) removeToken(true);
      })
      .catch(() => removeToken(true));
  }, [authContext, dispatch]);

  useEffect(() => {
    if (!vztahId && token && !guid) dispatch(nepovolenyPristup(true));
    if (
      vztahId &&
      token &&
      !guid &&
      !personalizedDataReady &&
      window.sessionStorage.getItem('authority') !== 'idp'
    ) {
      dispatch(fetchCMSContent('/'));
      dispatch(fetchDataVZ(vztahId, pouzivatelId, preLekara));
    }
  }, [
    dispatch,
    vztahId,
    token,
    pouzivatelId,
    guid,
    preLekara,
    personalizedDataReady,
  ]);
  // useEffect(() => {
  //   if (ico)
  //     dispatch(
  //       checkAuthAvailability({
  //         idPouzivatel: pouzivatelId || 0,
  //         idVZ: vztahId || 0,
  //         ico,
  //       }),
  //     );
  // }, [dispatch, ico, pouzivatelId, vztahId]);
  return (
    <Router>
      <RouterProvider>
        <ScrollToTop />
        <AppHeader />
        <div id="content">
          <Routes>
            <Route element={<LoginSSO />} path={routes.login} />
            <Route element={<Cookies />} path={routes.cookies} />
            <Route element={<CallbackPage />} path={routes.callback} />
            <Route element={<SilentRenew />} path={routes.silentrenew} />
            <Route
              element={<ChybaPrihlasenia />}
              path={routes.chybaPrihlasenia}
            />
            <Route element={<AppVersion />} path={routes.verzie} />
            <Route element={<PrivateRoute />}>
              <Route element={<PrivateRouter />} path="*" />
            </Route>
            <Route element={<LogoutPage />} path={routes.logout} />
            <Route element={<PageNotFound />} path="*" />
          </Routes>
          <Footer />
        </div>
      </RouterProvider>
    </Router>
  );
}

export default AppRouter;
