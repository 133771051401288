import { useSelector } from 'react-redux';
import { useCallback, useRef } from 'react';
import { Stepper } from '@dovera/design-system';
import StaticMenuLayout from '../../../../layouts/StaticMenuLayout';
import strings from '../../../../constants/strings';
import { navrhyRoutes } from '../../../../routes';
import { createViewUrl, hideFooter } from '../../../../utils/app.utils';
import { RootState } from '../../../../rootReducer';
import Step1 from './Step1';
import Step2 from './Step2/Step2';
import { useAppDispatch } from '../../../../hooks/useStore';
import {
  changeActiveStep,
  resetDrugProposals,
} from '../../../../slices/drugProposals.slice';
import Step3 from './Step3/Step3';
import { useModalContext } from '../../../../hooks/useModalContext';
import { ModalType } from '../../../../types/modal.types';
import { useNavigate } from 'react-router';
import usePreventAnchorClick from '../../../../hooks/usePreventAnchorClick';
import {
  isCheckWithSameMessage,
  saveDrugProposal,
} from '../../../../utils/drugProposals.utils';
import { LoadingState } from '../../../../types';
import SaveProposalResult from '../common/SaveProposalResult';
import { Dokument } from '../../../../types/models/Dokument';
import CardBubble from '../../../../components/CardBubble/CardBubble';
import useStyles from '../../Proposals.styles';
import Header from './components/Header';

const { drugs } = strings.proposals;

const DrugsStepper = () => {
  const classes = useStyles({ proposalType: 'LN' });
  const navigate = useNavigate();
  const modalContext = useModalContext();
  const dispatch = useAppDispatch();
  const newProposalRef = useRef(null);

  const isLoadingSystemStatus = useSelector(
    (state: RootState) => state.app.isLoadingSystemStatus,
  );
  const isLoaded = useSelector(
    (state: RootState) => state.poskytovatel.isLoaded,
  );
  const {
    data: { controls },
    save,
    stepper: { activeStep, step1 },
  } = useSelector((state: RootState) => state.drugProposals.new);

  const submitCallback = useCallback(
    (files: Dokument[] | null) => {
      if (
        controls?.data
          ?.filter((c) => !c.checkType.includes('Dlznik'))
          .some((c) => c.result === 'NOK' && c.errorWeight === 'E') ||
        isCheckWithSameMessage(controls?.data)
      ) {
        modalContext.showModal(ModalType.DP_NOT_VALID_CONDITIONS, {
          files,
          handleCancel: () => {
            modalContext.closeModal(ModalType.DP_NOT_VALID_CONDITIONS);
            dispatch(resetDrugProposals());
            navigate(navrhyRoutes.novyNavrh);
          },
        });
      } else saveDrugProposal(files);
    },
    [controls?.data, modalContext, dispatch, navigate],
  );

  const onContinueCallback = useCallback(
    (step: number) => {
      hideFooter(true);
      dispatch(changeActiveStep({ step }));
      setTimeout(() => {
        hideFooter(false);
      }, 500);
    },
    [dispatch],
  );
  const onProposalCancel = useCallback(
    (routeAfterCancel: string) => {
      modalContext.showModal(ModalType.CANCEL_ACTION, {
        isFormModal: true,
        onCancel: () => {
          modalContext.closeModal(ModalType.CANCEL_ACTION);
          dispatch(resetDrugProposals());
          navigate(createViewUrl(routeAfterCancel || navrhyRoutes.novyNavrh));
        },
        onContinue: () => modalContext.closeModal(ModalType.CANCEL_ACTION),
        texts: {
          btnCancel: drugs.new.modals.cancelAction.btnCancel,
          btnContinue: drugs.new.modals.cancelAction.btnContinue,
          description: drugs.new.modals.cancelAction.description,
          title: drugs.new.modals.cancelAction.title,
        },
      });
    },
    [dispatch, modalContext, navigate],
  );

  usePreventAnchorClick(
    !!step1.doctor.id && !save.data && !save.error,
    (e) => {
      const t = e?.target;
      // @ts-ignore
      const href = !t?.href ? t?.closest('a')?.href : t?.href || '/';
      onProposalCancel(href);
    },
    true,
  );

  const renderStepper = (
    <Stepper activeStep={activeStep} className={classes.stepper}>
      <Step1 onContinue={() => onContinueCallback(1)} />
      <Step2
        onCancel={() => onProposalCancel('/')}
        onContinue={() => onContinueCallback(2)}
      />
      <Step3 onContinue={submitCallback} />
    </Stepper>
  );

  if ((save.data || save.error) && save.dataState !== LoadingState.fetching)
    return (
      <SaveProposalResult
        error={save?.data?.chyba}
        isCnp={save?.data?.jeCnp}
        isTechnicalError={!!save.error}
        proposalNumber={save?.data?.evidencneCislo}
        proposalType="LN"
      />
    );

  return (
    <StaticMenuLayout
      contentHeader={<Header />}
      mustCheckSystems={{ systems: ['TXS'] }}
    >
      <CardBubble>
        <div ref={newProposalRef}>
          {isLoadingSystemStatus || !isLoaded ? <span /> : renderStepper}
        </div>
      </CardBubble>
    </StaticMenuLayout>
  );
};

export default DrugsStepper;
