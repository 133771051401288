import { useContext, useEffect } from 'react';
import { FilterContext } from '../../../providers/FilterProvider';
import DDSDatepicker from '../../DDSDatepicker/DDSDatepicker';
import { useQuery } from '../../../hooks/useQuery';
import { FILTER_PREFIX } from '../constants';
import moment from 'moment';
import {
  DATE_FORMAT_ORACLE_DAY,
  DATE_INTERNATIONAL,
} from '../../../utils/date.utils';
import { DatepickerType } from '../types';

const DDSDatepickerFilter = ({ ...props }: DatepickerType) => {
  const { id, initialSet = true, onChange, value, ...restProps } = props;

  const { searchParams, setSearchParams } = useContext(FilterContext);
  const query = useQuery();

  useEffect(() => {
    const queryValue = query.get(`${FILTER_PREFIX}_${id}`);
    if (queryValue && initialSet) {
      handleChange(new Date(moment(queryValue).format(DATE_INTERNATIONAL)));
    }
    // eslint-disable-next-line
  }, [initialSet]);

  const handleChange = (val) => {
    if (val) {
      searchParams.set(
        `${FILTER_PREFIX}_${id}`,
        moment(val).format(DATE_FORMAT_ORACLE_DAY),
      );
    } else if (searchParams.get(`${FILTER_PREFIX}_${id}`)) {
      searchParams.delete(`${FILTER_PREFIX}_${id}`);
    }
    setSearchParams(searchParams);
    onChange(val);
  };

  return (
    <DDSDatepicker
      id={id}
      onChange={(val) => handleChange(val)}
      value={value}
      {...restProps}
    />
  );
};

export default DDSDatepickerFilter;
