import {
  Button,
  ButtonSecondary,
  Icon,
  Notification,
  Skeleton,
  color,
} from '@dovera/design-system';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import SafeHtml from '../../../components/SafeHtml/SafeHtml';
import strings from '../../../constants/strings';
import { RootState } from '../../../rootReducer';
import { cx } from '../../../utils/exports';
import Message from '../Message/Message';
import useStyles from '../Spravy.styles';
import useAppStles from '../../../App.styles';
import { systemConstants } from '../../../constants/systemConstants';
import { LoadingState } from '../../../types';
import { MessageForm } from '../MessageForm';
import { KategoriaSprav } from '../../../types/spravy.types';
import RestoreThread from '../RestoreThread/RestoreThread';
import { useAppDispatch } from '../../../hooks/useStore';
import { scrollDetail } from '../../../slices/spravy.slice';

interface Props {
  onClickNewMessage: () => void;
  onRemove: () => void;
}

const texts = strings.schrankaSprav.thread.detail;

const ThreadDetail = ({ onClickNewMessage, onRemove }: Props) => {
  const dispatch = useAppDispatch();
  const detailRef = useRef(null);
  const titleRef = useRef(null);
  const classes = useStyles({});
  const classesApp = useAppStles();
  const [showReplyForm, setShowReplyForm] = useState(false);
  const email = useSelector((state: RootState) => state.poskytovatel.email);
  const {
    detail: {
      dataState,
      emailVlakna,
      id,
      jeMoznostOdpovedat,
      nadpis,
      scroll,
      spravy,
      typObmedzenia,
    },
    vlakna,
  } = useSelector((state: RootState) => state.spravy);
  /**
   * Osetrenie nemoznosti vymazat/obnovit archivne vlakno z dovodu velkeho mnozstva sprav
   */
  const isArchived: boolean = vlakna?.data?.some(
    (v) => v.id === id && v.typBadge === KategoriaSprav.Archiv,
  );
  const isDeleted: boolean = vlakna?.data?.some(
    (v) => v.id === id && v.typVlakna === KategoriaSprav.Vymazane,
  );
  const isCountLimit: boolean =
    spravy.length >= systemConstants.MAX_MESSAGES_IN_THREAD;
  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      // @ts-ignore
      detailRef?.current?.scrollTo({ top: 10000, behavior: 'smooth' });
    }, 50);
  }, []);
  const showReplyFormCallback = useCallback(() => {
    setShowReplyForm(true);
    scrollToBottom();
  }, [scrollToBottom]);
  useEffect(() => {
    setShowReplyForm(false);
    // eslint-disable-next-line
  }, [nadpis, id]);
  useEffect(() => {
    if (dataState === LoadingState.filled && scroll === 'bottom') {
      scrollToBottom();
      dispatch(scrollDetail({ to: null }));
    }
  }, [dataState, dispatch, scroll, scrollToBottom]);
  const notReplyInfo = (
    <div className={cx(classes.replyElement, classesApp.notification)}>
      <Notification
        message={<SafeHtml html={texts.noReply} />}
        variant="info"
      />
    </div>
  );
  const tooLongThread = (
    <div
      className={cx(
        classes.replyElement,
        classesApp.notification,
        classesApp.notificationWithBtn,
      )}
    >
      <Notification
        message={
          <span>
            <SafeHtml html={texts.tooLongThread} />
            <ButtonSecondary className="no-mrg" onClick={onClickNewMessage}>
              {texts.buttons.newMessage}
            </ButtonSecondary>
          </span>
        }
        variant="blue"
      />
    </div>
  );
  const renderSkeleton = (
    <div className={classes.message}>
      <Skeleton className="mb" height={40} width="100%" />
      <Skeleton height={18} width="70%" />
      <Skeleton height={18} width="50%" />
      <Skeleton height={18} width="90%" />
      <Skeleton className="mb-small" height={18} width="90%" />
      <Skeleton height={40} width="50%" />
    </div>
  );
  if (
    dataState === LoadingState.fetching ||
    !id ||
    (vlakna.dataState === LoadingState.fetching && !vlakna.offset)
  )
    return renderSkeleton;
  return (
    <>
      <h4 ref={titleRef} className={classes.threadDetailTitle}>
        <span>{nadpis}</span>
        {!isArchived && !isDeleted && (
          <button onClick={onRemove} type="button">
            <Icon
              color={color('error', 600)}
              id={`sprava-vlakna--${id}`}
              name="24-trash-2"
            />
          </button>
        )}
      </h4>
      <div ref={detailRef} className={classes.threadDetailMessages}>
        <div className={classes.messages}>
          {spravy.map((s) => (
            <Message
              key={`sprava-vlakna-${id}--${s.id}`}
              attachments={s.prilohy}
              content={s.obsah}
              date={s.datumVytvorenia}
              fromDZP={!s.jeAutorPzs}
              id={s.id}
            />
          ))}
          {showReplyForm && (
            <MessageForm
              hasPzsEmail={!!(email || emailVlakna)}
              isReply
              onCancel={() => setShowReplyForm(false)}
              pzsEmail={email}
              threadId={id}
            />
          )}
        </div>
        {!showReplyForm &&
          !isCountLimit &&
          jeMoznostOdpovedat &&
          !isArchived &&
          !typObmedzenia &&
          !isDeleted && (
            <Button
              className={cx(classes.replyElement, classes.button)}
              onClick={showReplyFormCallback}
            >
              <Icon
                className="icon--left"
                id={`message-reply--${id}`}
                name="reply"
                size="medium"
              />
              {texts.buttons.reply}
            </Button>
          )}
        {typObmedzenia === 'Automaticky_mail' && !isDeleted && notReplyInfo}
        {typObmedzenia &&
          ['Sucet_sprav_odoslane', 'Sucet_sprav_vsetky'].includes(
            typObmedzenia,
          ) &&
          !isDeleted &&
          tooLongThread}
        {isDeleted && !isArchived && <RestoreThread threadId={id} />}
      </div>
    </>
  );
};

export default ThreadDetail;
