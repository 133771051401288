// eslint-disable-next-line
import NavigationItem from '@dovera/design-system/dist/src/components/Navigation/NavigationItem';
import { history } from '../helpers/history';
import routes, {
  diagnozyRoutes,
  expensesRoutes,
  navrhyRoutes,
} from '../routes';
import strings from '../constants/strings';
import store from '../store';
import { refresh } from '../slices/spravy.slice';
import { hasSection } from './poskytovatel.utils';
import { EPSections } from '../constants/systemConstants';
import { epSekcie } from '../constants/epLinks';
import { canShowSection, userHasSection } from './app.utils';
import { Link } from 'react-router-dom';
import { getParametersMenuItems } from './parameters.utils';
import { ParameterMenuGroup } from '../types/parameters.types';
import { KodHodnoty } from '../types/pacienti.types';
import { canShowPatientTypes } from './pacienti.utils';
import { MenuItemDropdown, toggleItem } from '../slices/menu.slice';
import { MenuTheme, ReportThemes } from '../types/reports.types';

const { menu, submenu } = strings.app;

const navHome = (): NavigationItem => ({
  iconProps: {
    name: 'home',
  },
  isActive: history.location.pathname === routes.dashboard,
  href: <Link to={routes.dashboard} />,
  title: <span className="text-semibold">{menu.home}</span>,
});

const navProfile = (): NavigationItem => ({
  iconProps: {
    name: '16-user',
  },
  href: <Link to={routes.profil} />,
  isActive: history.location.pathname?.includes(routes.profil),
  title: <span className="text-semibold">{menu.prof}</span>,
});

const navMessages = (): NavigationItem => ({
  iconProps: {
    name: 'mail',
  },
  href: <Link to={routes.spravy} />,
  isActive: history.location.pathname?.includes(routes.spravy),
  onClick: () => {
    store.dispatch(refresh());
  },
  title: <span className="text-semibold">{menu.msg}</span>,
});

const navContracts = (): NavigationItem[] => {
  if (userHasSection('ZMLUVY'))
    return [
      {
        iconProps: {
          name: 'open-book',
        },
        href: <Link to={routes.zmluvyDodatky} />,
        isActive: history.location.pathname?.includes(routes.zmluvyDodatky),
        title: <span className="text-semibold">{menu.zml}</span>,
      },
    ];
  return [];
};

const navZuc = (): NavigationItem[] => {
  if (
    hasSection(EPSections.Zuc, 'Zuctovanie') ||
    hasSection(EPSections.Fkt, 'Zuctovanie')
  )
    return [
      {
        iconProps: {
          name: 'money-insert',
        },
        isOpen: !!store.getState()?.menu?.openedItems.zuctovanie,
        items: [
          ...(hasSection(EPSections.Zuc, 'Zuctovanie')
            ? [
                {
                  href: <Link to={routes.odoslanieDavok} />,
                  isActive: history.location.pathname?.includes(
                    routes.odoslanieDavok,
                  ),
                  title: submenu.sendBatches,
                },
                {
                  href: <Link to={routes.prehladDavok} />,
                  isActive: history.location.pathname?.includes(
                    routes.prehladDavok,
                  ),
                  title: submenu.batchPreview,
                },
              ]
            : []),
          {
            href: <Link to={routes.faktury} />,
            isActive: history.location.pathname?.includes(routes.faktury),
            title: menu.invoices,
          },
          ...(hasSection(EPSections.DispZaz, 'Zuctovanie')
            ? [
                {
                  href: epSekcie['dispenzarizovani-poistenci'],
                  title: submenu.disp,
                },
              ]
            : []),
        ],
        onClick: () => store.dispatch(toggleItem(MenuItemDropdown.zuctovanie)),
        title: <span className="text-semibold">{menu.zuc}</span>,
      },
    ];
  return [];
};

const navCL = (): NavigationItem[] => {
  if (hasSection(EPSections.Hosp, 'CakacieListiny'))
    return [
      {
        iconProps: {
          name: 'hourglass',
        },
        isOpen: !!store.getState()?.menu?.openedItems['cakacie-listiny'],
        items: [
          {
            href: <Link to={routes.cakackyOdoslanieDavok} />,
            isActive: history.location.pathname?.includes(
              routes.cakackyOdoslanieDavok,
            ),
            title: submenu.sendBatches,
          },
          {
            href: <Link to={routes.cakackyPrehladDavok} />,
            isActive: history.location.pathname?.includes(
              routes.cakackyPrehladDavok,
            ),
            title: submenu.batchPreview,
          },
        ],
        onClick: () => store.dispatch(toggleItem(MenuItemDropdown.cakacky)),
        title: <span className="text-semibold">{menu.cl}</span>,
      },
    ];
  return [];
};

const navPcp = (): NavigationItem[] => {
  if (
    userHasSection('ZMLUVY') &&
    !!store.getState()?.poskytovatel?.odbornosti?.length
  )
    return [
      {
        iconProps: {
          name: '24-calc',
        },
        href: <Link to={routes.manazerskyPrehladZmluv} />,
        isActive: history.location.pathname?.includes(
          routes.manazerskyPrehladZmluv,
        ),
        title: <span className="text-semibold">{menu.pcp}</span>,
      },
    ];
  return [];
};

const navHpKei = (groups: ParameterMenuGroup[]): NavigationItem[] => {
  if (userHasSection('PZSHPKEI'))
    return [
      {
        iconProps: {
          name: 'graphs-circle',
        },
        isOpen:
          !!store.getState()?.menu.openedItems.parametre ||
          history.location.pathname === routes.parameters,
        items: [
          {
            href: <Link to={routes.parameters} />,
            isActive: history.location.pathname === routes.parameters,
            title: submenu.parametersPreview,
          },
          ...getParametersMenuItems(groups),
        ],
        onClick: () => store.dispatch(toggleItem(MenuItemDropdown.parametre)),
        title: <span className="text-semibold">{menu.parameters}</span>,
      },
    ];
  return [];
};

const navVB = (menuThemes: MenuTheme[]): NavigationItem[] => {
  if (menuThemes?.some((m) => m.theme === ReportThemes.VB && m.sections.length))
    return [
      {
        iconProps: {
          name: '24-coins',
        },
        isOpen:
          !!store.getState()?.menu.openedItems['virtualny-rozpocet'] ||
          history.location.pathname?.includes(routes.vbIndex),
        items: menuThemes
          .find((m) => m.theme === ReportThemes.VB)
          ?.sections.map((section) => ({
            href: <Link to={routes.vbRoute(section.sectionCode)} />,
            isActive:
              history.location.pathname ===
                routes.vbRoute(section.sectionCode) ||
              (history.location.pathname.includes(routes.vbIndex) &&
                menuThemes.find((m) => m.theme === ReportThemes.VB)?.sections
                  .length === 1),
            title: section.sectionName,
          })),
        onClick: () => store.dispatch(toggleItem(MenuItemDropdown.vb)),
        title: <span className="text-semibold">{menu.vb}</span>,
      },
    ];
  return [];
};

const navExpenses = (menuThemes: MenuTheme[]): NavigationItem[] => {
  if (
    menuThemes?.some(
      (m) => m.theme === ReportThemes.Expenses && m.sections.length,
    )
  )
    return [
      {
        iconProps: {
          name: 'stethoscope',
        },
        isOpen: !!store.getState()?.menu.openedItems.naklady,
        items: menuThemes
          .find((m) => m.theme === ReportThemes.Expenses)
          ?.sections.map((section) => ({
            href: <Link to={expensesRoutes(section.sectionCode)} />,
            isActive:
              history.location.pathname === expensesRoutes(section.sectionCode),
            title: section.sectionName,
          })),
        onClick: () => store.dispatch(toggleItem(MenuItemDropdown.naklady)),
        title: <span className="text-semibold">{menu.expenses}</span>,
      },
    ];
  return [];
};

const navProposals = (): NavigationItem[] => {
  if (
    hasSection(EPSections.Kn, 'Navrhy') ||
    hasSection(EPSections.Ln, 'Navrhy') ||
    hasSection(EPSections.Nkup, 'Navrhy')
  )
    return [
      {
        iconProps: {
          name: 'new-message',
        },
        isOpen: !!store.getState()?.menu.openedItems.navrhy,
        items: [
          ...(hasSection(EPSections.Ln, 'Navrhy') ||
          hasSection(EPSections.Kn, 'Navrhy')
            ? [
                {
                  href: <Link to={navrhyRoutes.novyNavrh} />,
                  isActive: history.location.pathname?.includes(
                    navrhyRoutes.novyNavrh,
                  ),
                  title: submenu.newProposal,
                },
                {
                  href: <Link to={navrhyRoutes.zoznamPodanychNavrhov} />,
                  isActive: history.location.pathname?.includes(
                    navrhyRoutes.zoznamPodanychNavrhov,
                  ),
                  title: submenu.proposalList,
                },
              ]
            : []),
          ...(hasSection(EPSections.Nkup, 'Navrhy')
            ? [
                {
                  href: <Link to={navrhyRoutes.zoznamSchvalenychNavrhov} />,
                  isActive: history.location.pathname?.includes(
                    navrhyRoutes.zoznamSchvalenychNavrhov,
                  ),
                  title: submenu.approvedPorposalList,
                },
              ]
            : []),
          ...(hasSection(EPSections.Cnp, 'Navrhy')
            ? [
                {
                  href: <Link to={epSekcie['produkty-na-sklade']} />,
                  title: submenu.productsOnStock,
                },
                {
                  href: <Link to={epSekcie['ziadost-o-presun']} />,
                  title: submenu.moveRequest,
                },
                {
                  href: <Link to={epSekcie['prehlad-objednavok']} />,
                  title: submenu.ordersPreview,
                },
              ]
            : []),
        ],
        onClick: () => store.dispatch(toggleItem(MenuItemDropdown.navrhy)),
        title: <span className="text-semibold">{menu.kn}</span>,
      },
    ];
  return [];
};

const navKAPPatiens = (): NavigationItem[] => {
  if (userHasSection('KAP') && canShowSection('KAP'))
    return [
      {
        href: <Link to={routes.sporniPoistenci} />,
        isActive:
          history.location.pathname?.includes(routes.sporniPoistenci) ||
          history.location.pathname?.includes(routes.kapitovaniPoistenci),
        title: submenu.kap,
      },
    ];
  return [];
};

// eslint-disable-next-line
const navPatients = (codes: KodHodnoty[]): NavigationItem[] => {
  if (store.getState()?.auth?.hasPZSNAKL || userHasSection('KAP'))
    return [
      {
        iconProps: {
          name: 'ic_users_24px',
        },
        isOpen: !!store.getState()?.menu.openedItems.pacienti,
        items: [
          ...navKAPPatiens(),
          ...(store?.getState()?.auth?.hasPZSNAKL
            ? [
                {
                  isActive: history.location.pathname?.includes(routes.pp),
                  isOpen:
                    !!store.getState()?.menu.openedItems[
                      'zdravotna-starostlivost'
                    ],
                  items: [
                    ...(canShowPatientTypes('ZS', codes)
                      ? [
                          {
                            href: <Link to={routes.ppCerpanieZS} />,
                            isActive: history.location.pathname?.includes(
                              routes.ppCerpanieZS,
                            ),
                            title: submenu.zs,
                          },
                        ]
                      : []),
                    ...(canShowPatientTypes('FARM', codes)
                      ? [
                          {
                            href: <Link to={routes.ppFarmakoterapia} />,
                            isActive: history.location.pathname?.includes(
                              routes.ppFarmakoterapia,
                            ),
                            title: submenu.farm,
                          },
                        ]
                      : []),
                    ...(canShowPatientTypes('CHR', codes)
                      ? [
                          {
                            href: <Link to={routes.ppChronicki} />,
                            isActive: history.location.pathname?.includes(
                              routes.ppChronicki,
                            ),
                            title: submenu.chr,
                          },
                        ]
                      : []),
                  ],
                  onClick: () => {
                    history.navigate(routes.pp);
                    store.dispatch(
                      toggleItem(MenuItemDropdown.zdravotnaStarostlivost),
                    );
                  },
                  title: submenu.zsPreview,
                },
                // TODO add pacientiRoutes
              ]
            : []),
        ],
        onClick: () => store.dispatch(toggleItem(MenuItemDropdown.pacienti)),
        title: <span className="text-semibold">{menu.pac}</span>,
      },
    ];
  return [];
};

const navDg = (): NavigationItem[] => {
  if (store.getState()?.auth?.hasPZSNAKL)
    return [
      {
        iconProps: {
          name: 'stethoscope',
        },
        href: <Link to={diagnozyRoutes.index} />,
        isActive: history.location.pathname?.includes(diagnozyRoutes.index),
        title: <span className="text-semibold">{menu.diag}</span>,
      },
    ];
  return [];
};

export const getNavigationItems = ({
  menuThemes,
  parameterMenuGroups,
  patientCodes,
}: {
  menuThemes: MenuTheme[];
  parameterMenuGroups: ParameterMenuGroup[];
  patientCodes?: KodHodnoty[];
}): NavigationItem[] => [
  navHome(),
  ...navZuc(),
  ...navCL(),
  ...navPcp(),
  ...navContracts(),
  ...navHpKei(parameterMenuGroups),
  ...navVB(menuThemes),
  ...navProposals(),
  ...navPatients(patientCodes ?? []),
  ...navExpenses(menuThemes),
  ...navDg(),
  navProfile(),
  navMessages(),
];

export const getNavigationItemsAsoc = (): NavigationItem[] => [
  navHome(),
  ...navContracts(),
  navProfile(),
  navMessages(),
];
