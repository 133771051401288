import {
  Button,
  ButtonLayout,
  ButtonLink,
  Grid,
  GridCol,
  Icon,
} from '@dovera/design-system';
import { formatDate } from '../../../../utils/date.utils';
import {
  getProposalType,
  getStateProperties,
} from '../../../../utils/proposals.utils';
import InfoRow from './InfoRow';
import strings from '../../../../constants/strings';
import { getListFromString } from '../../../../utils/strings.utils';
import { cx } from '../../../../utils/exports';
import useStyles from '../../Proposals.styles';
import ColoredLabel from '../../../../components/ColoredLabel/ColoredLabel';

const spaTexts = strings.proposals.spa.detail;

interface Props {
  accepted: string;
  exposedDate: string;
  exposedType: string;
  processDate: string;
  spaData?: {
    insuranceStatement?: string | null;
    isApproved?: boolean;
    onReservationClick?: (type: 'create' | 'update' | 'delete') => void;
    pin?: string | null;
    reservation?: {
      create?: boolean;
      delete?: boolean;
      id?: number;
      show?: boolean;
      update?: boolean;
    };
    reservationFrom?: string;
    reservationTo?: string;
    treatment?: string;
  };
  state: string;
  type: 'CNP' | 'LN' | 'KN';
  validityFrom: string;
  validityTo: string;
}

const ProposalHeader = ({
  accepted,
  exposedDate,
  exposedType,
  processDate,
  spaData,
  state,
  type,
  validityFrom,
  validityTo,
}: Props) => {
  const classes = useStyles({ proposalType: type });
  const renderKNActions =
    spaData?.onReservationClick &&
    (spaData?.reservation?.id ? (
      <ButtonLayout
        className={cx('mb-small', classes.proposalDetailButtons)}
        direction="horizontal"
      >
        {spaData?.reservation?.update && (
          <ButtonLink
            className="no-mrg"
            onClick={() => {
              if (spaData?.onReservationClick)
                spaData.onReservationClick('update');
            }}
          >
            <Icon
              className="text-space-half-right"
              name="16-edit"
              size="medium"
            />
            {spaTexts.buttons.edit}
          </ButtonLink>
        )}
        {spaData?.reservation?.delete && (
          <ButtonLink
            className="no-mrg text-color-error"
            onClick={() => {
              if (spaData?.onReservationClick)
                spaData.onReservationClick('delete');
            }}
          >
            {spaTexts.buttons.delete}
          </ButtonLink>
        )}
      </ButtonLayout>
    ) : (
      spaData?.reservation?.create && (
        <Button
          className="no-mrg"
          onClick={() => {
            if (spaData?.onReservationClick)
              spaData.onReservationClick('create');
          }}
        >
          <Icon color="white" name="16-calendar" size="medium" />
          <span className="text-space-left">{spaTexts.buttons.create}</span>
        </Button>
      )
    ));
  const stateProperties = getStateProperties(state);
  return (
    <Grid>
      <GridCol className="mb" size={spaData ? { l: 6, xl: 5 } : 12}>
        <InfoRow iconName="check" value={getProposalType(type)} />
        <InfoRow
          icon={
            <Icon
              color={stateProperties.color}
              name={stateProperties.name}
              size="medium"
            />
          }
          value={
            <ColoredLabel color={stateProperties.color}>
              {stateProperties.text}
            </ColoredLabel>
          }
        />
        <InfoRow
          iconName={exposedDate ? 'check' : 'collection'}
          label={`Vystavený ${exposedType}`}
          value={formatDate(exposedDate)}
        />

        <InfoRow
          iconName={accepted ? 'check' : 'collection'}
          label={`Prijatý poisťovňou`}
          value={formatDate(accepted)}
        />
        <InfoRow
          iconName={processDate ? 'check' : 'collection'}
          label={`Dátum spracovania`}
          value={formatDate(processDate)}
        />
        <InfoRow
          iconName={validityFrom ? 'check' : 'collection'}
          label={`Platnosť návrhu`}
          value={`${formatDate(validityFrom)} - ${formatDate(validityTo)}`}
        />
        {spaData?.reservationFrom && (
          <InfoRow
            iconName="check"
            label="Rezervácia kúpeľov"
            value={`${formatDate(spaData.reservationFrom)} - ${formatDate(spaData.reservationTo)}`}
          />
        )}
        {spaData?.isApproved && renderKNActions}
      </GridCol>
      {spaData && (
        <GridCol className="mb" size={{ l: 6, xl: 7 }}>
          {spaData?.pin && (
            <div className="d-flex mb-small">
              <Icon name="key" size="medium" />
              <span className="text-space-half-left text-space-half-right">{`${spaTexts.labels.pin}: `}</span>
              <b>{spaData?.pin}</b>
            </div>
          )}
          <span className="d-block">{`${spaTexts.labels.insuranceStatemenet}:`}</span>
          {!spaData?.insuranceStatement && strings.undefined}
          <ul className={classes.statementPoints}>
            {getListFromString(spaData?.insuranceStatement || '').map(
              (d, key) => (
                <li
                  key={`insuranceStatement--point--${key}`}
                  className="text-bold"
                >
                  {d}
                </li>
              ),
            )}
          </ul>
          <span>{`${spaTexts.labels.treatment}: `}</span>
          <b>{spaData?.treatment || strings.undefined}</b>
        </GridCol>
      )}
    </Grid>
  );
};

export default ProposalHeader;
