import ResultState, {
  BatchSaveState,
} from './components/ResultState/ResultState';
import { useCallback, useState } from 'react';
import useInterval from '../../../hooks/useInterval';
import { useQuery } from '../../../hooks/useQuery';
import { dajStavSpracovaniaCL } from '../../../api/cakacky';
import {
  StavSpracovaniaCL,
  StavyOdoslaniaEnum,
} from '../../../types/cakacky.types';

const VysledokOdoslaniaCLDavok = () => {
  const query = useQuery();
  const [sendingState, setSendingState] = useState<BatchSaveState>(
    BatchSaveState.isLoading,
  );
  // eslint-disable-next-line prefer-const
  let [counterOfCallingPingService, setCounterOfCallingPingService] =
    useState<number>(0);
  const [results, setResults] = useState<StavSpracovaniaCL[]>([]);

  const groupId = query.get('id');

  useInterval(
    () => {
      if (
        groupId &&
        sendingState === BatchSaveState.isLoading &&
        counterOfCallingPingService < 50
      ) {
        pingSendingStatus();
      }
      // after every 5 minutes, set interval Xtimes more
    },
    counterOfCallingPingService === 0
      ? 15000
      : 5000 * (counterOfCallingPingService % 120),
  );

  const pingSendingStatus = useCallback(() => {
    setCounterOfCallingPingService(counterOfCallingPingService++);
    // checking status
    if (groupId) {
      dajStavSpracovaniaCL({ idOdoslania: groupId })
        .then(({ stavySpracovania }) => {
          // eslint-disable-next-line no-console

          // some process finished
          if (
            stavySpracovania &&
            stavySpracovania.every((s) => !!s.vysledokSpracovaniaTxs)
          ) {
            /**
             * all services finished
             */
            setResults(stavySpracovania);
            setSendingState(BatchSaveState.isTechnicalError);
            if (
              stavySpracovania.every(
                (s) =>
                  s.vysledokSpracovaniaTxs === StavyOdoslaniaEnum.neuspesne,
              )
            )
              setSendingState(BatchSaveState.isError);

            if (
              stavySpracovania.every(
                (s) => s.vysledokSpracovaniaTxs === StavyOdoslaniaEnum.uspesne,
              )
            ) {
              setSendingState(BatchSaveState.isSuccess);
            }
          }
        })
        .catch(() => {
          setSendingState(BatchSaveState.isTechnicalError);
        });
    }
  }, [counterOfCallingPingService, groupId]);

  return <ResultState results={results} state={sendingState} />;
};

export default VysledokOdoslaniaCLDavok;
