import { ComponentProps, ReactNode } from 'react';
import routes from '../routes';
import { BATCH_STATES, INVOICE_STATES } from '../constants/zuctovanie';
import {
  OdosliDavkyForRequest,
  STAV_DAVKY,
  UdajDavky,
  UdajeRozdeleniaNaZalozky,
} from '../types/zuctovanie.types';
import { uploadHandler } from './file.utils';
import { getSysParamValue } from './app.utils';
import { SystemoveParametreNazovConfigu } from '../types/api/poskytovatel';
import { FileErrorTypes, systemConstants } from '../constants/systemConstants';
import { VALIDATION } from '../types/validation.types';
import { validation } from '../constants/validation';
import { currencyNumberFormat } from './number.utils';
import { STAV_DAVKY_CAKACKY } from '../types/cakacky.types';
import { Icon, color } from '@dovera/design-system';
import strings from '../constants/strings';
import ColoredLabel from '../components/ColoredLabel/ColoredLabel';

const { processingBatch } = strings.zuctovanie.stateLables;

export const getBatchState = (
  state: 'V' | 'U' | 'E' | STAV_DAVKY | STAV_DAVKY_CAKACKY | string,
  isListView?: boolean,
  withIcon?: boolean,
  shouldReturnString?: boolean,
): ReactNode | string => {
  const isProcessingState =
    isListView &&
    ['E', STAV_DAVKY.PrebiehaKontrola, STAV_DAVKY_CAKACKY.Spracovanie].includes(
      state,
    );

  const batchState = BATCH_STATES.find((b) => b.value === state);

  const label = isProcessingState ? (
    <ColoredLabel className="text-semibold" color={color('black')}>
      {processingBatch}
    </ColoredLabel>
  ) : (
    <span className="text-semibold">{batchState?.label}</span>
  );

  const icon = isProcessingState ? (
    <Icon color={color('black')} name="hourglass" size="medium" />
  ) : (
    batchState?.icon
  );

  if (shouldReturnString) return batchState?.stringLabel;
  if (!withIcon) return batchState?.label;
  return (
    <span className="icon-with-text">
      {icon}
      {label}
    </span>
  );
};

export const getBatchCode = (pathname: string): string => {
  if (
    pathname !== routes.prehladChybDavok &&
    pathname.includes(routes.prehladChybDavok)
  )
    return pathname.split(`${routes.prehladChybDavok}/`)?.[1];
  return '';
};

export const getInvoiceState = (
  state: 'R' | 'Z' | 'C' | 'P' | string,
  withIcon?: boolean,
): ReactNode | string => {
  const invoiceState = INVOICE_STATES.find((b) => b.value === state);

  if (!withIcon) return invoiceState?.label;
  return (
    <span
      className="icon-with-text text-semibold"
      style={{ color: invoiceState?.color }}
    >
      {invoiceState?.icon}
      {invoiceState?.label}
    </span>
  );
};

const processAttachments = async (batch) => {
  const fakturaDocsPromise: Promise<any> = uploadHandler(
    batch?.nahrateFaktury?.prilohyFaktury || [],
  );

  // odvovodnenie
  const odvovodnenieDocsPromise: Promise<any> = uploadHandler(
    batch?.odovodnenieDokument || [],
  );

  // ostatne dokumenty
  const ostatneDocsPromise: Promise<any> = uploadHandler(
    batch.ostatneDokumenty || [],
  );

  const docs = await Promise.allSettled([
    fakturaDocsPromise,
    odvovodnenieDocsPromise,
    ostatneDocsPromise,
  ]);

  return docs;
};

const processBatch = async (batch) =>
  new Promise((resolve) => {
    const outputItem: OdosliDavkyForRequest = {
      idZalozka: 0,
      udajeFaktury: undefined,
      fakturaDokument: undefined,
      odovodnenieDokument: undefined,
      ostatneDokumenty: [],
      idDavok: [],
    };
    outputItem.idZalozka = batch.idZalozka;

    // udaje faktury
    if (
      batch &&
      batch.nahrateFaktury &&
      Object.values(batch?.nahrateFaktury).length > 0 &&
      batch?.nahrateFaktury?.prilohyFaktury
    ) {
      outputItem.udajeFaktury = {
        cisloFaktury: undefined,
        vs: batch?.nahrateFaktury?.VS,
        suma: currencyNumberFormat(batch?.nahrateFaktury?.suma),
      };
    }

    // id davok
    outputItem.idDavok = batch.udajeDavky.map((c) => c.id);

    processAttachments(batch).then((docs: any) => {
      outputItem.fakturaDokument =
        docs[0]?.value.length > 0 ? docs[0]?.value[0] : null;
      outputItem.odovodnenieDokument =
        docs[1]?.value.length > 0 ? docs[1]?.value[0] : null;
      outputItem.ostatneDokumenty = docs[2]?.value;
      setTimeout(() => {
        resolve(outputItem);
      }, 1000);
    });
  });

export function mapDataToSend(
  input: UdajeRozdeleniaNaZalozky[],
): Promise<OdosliDavkyForRequest[]> {
  // https://www.cloudnweb.dev/2019/7/promises-inside-a-loop-javascript-es6
  return new Promise((resolve: any) => {
    if (!input || input.length === 0) {
      resolve([]);
    }
    Promise.all(input.map((d) => processBatch(d))).then((output) =>
      resolve(output),
    );
  });
}

export const getSysParamForZuctovanie = (
  paramName: string,
): undefined | string | number =>
  getSysParamValue(SystemoveParametreNazovConfigu.zuctovanie, paramName);

export const getZucUploadProps = (): ComponentProps<any> => ({
  allowedExtensions: getSysParamForZuctovanie('PovoleneTypyPriloh')
    ?.toString()
    ?.split(';')
    .map((v) => v.trim().toLocaleLowerCase()) ?? [
    'doc',
    'docx',
    'xls',
    'xlsx',
    'rtf',
    'pdf',
    'jpg',
    'png',
    'gif',
    'tiff',
  ],
  accept: getSysParamForZuctovanie('PovoleneTypyPriloh')?.toString(),
  customErrorMessages: {
    [FileErrorTypes.AllFilesSizeExceeds]: (size) =>
      validation[VALIDATION.MaximalnaVelkostPrilohSpolu](size),
    [FileErrorTypes.SingleFileSizeExceeds]: (size) =>
      validation[VALIDATION.MaximalnaVelkostPrilohy](size),
    [FileErrorTypes.UnsupportedFileType]: (type) =>
      validation[VALIDATION.PovoleneTypyPriloh](type),
  },
  maxAllFilesSize: Number(
    getSysParamForZuctovanie('MaximalnaVelkostPrilohSpolu')
      ? (getSysParamForZuctovanie('MaximalnaVelkostPrilohSpolu') as string)
      : systemConstants.MAX_UPLOAD_SIZE_SUM.toString(),
  ),
  maxFileSize: Number(
    getSysParamForZuctovanie('MaximalnaVelkostJednejPrilohy')
      ? (getSysParamForZuctovanie('MaximalnaVelkostJednejPrilohy') as string)
      : systemConstants.MAX_UPLOAD_SIZE.toString(),
  ),
});

/**
 * Ak PZS odosiela kapitacnu davku "748N" a sucasne neposiela aspon jednu davku "751B"
 * @date 3/25/2024 - 4:04:47 PM
 *
 * @param {UdajDavky[]} data
 * @returns {boolean}
 */
export const visibleCapWarning = (data: UdajDavky[]): boolean =>
  data.some((d) => d.typDavky === '748N') &&
  !data.some((d) => d.typDavky === '751B');
