import {
  Card,
  CardSection,
  Icon,
  List,
  ListItem,
  color,
} from '@dovera/design-system';
import { Link } from 'react-router-dom';
import routes from '../../../../routes';
import strings from '../../../../constants/strings';
import useStyles from '../../Profil.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';

const { links } = strings.profile;

const ProfileLinks = () => {
  const classes = useStyles();
  const { maZmluvnePristroje } = useSelector(
    (state: RootState) => state.poskytovatel,
  );
  const getLabel = (label: string) => (
    <h6>
      <span>{label}</span>
      <Icon name="chevron-right" size="medium" />
    </h6>
  );
  return (
    <Card isBordered>
      <CardSection className={classes.profileLinks} isCondensed>
        <List>
          <ListItem>
            <Link id="link-kontaktneOsoby" to={routes.kontaktneOsoby}>
              <Icon
                color={color('secondary', 500)}
                name="ic_users_24px"
                size="medium"
              />
              {getLabel(links.kontaktneOsoby)}
            </Link>
          </ListItem>
          <ListItem>
            <Link id="link-bankoveSpojenie" to={routes.bankoveSpojenie}>
              <Icon
                color={color('secondary', 500)}
                name="credit-card"
                size="medium"
              />
              {getLabel(links.bankoveSpojenie)}
            </Link>
          </ListItem>
          {maZmluvnePristroje && (
            <ListItem>
              <Link id="link-zmluvnePristroje" to={routes.zmluvnePristroje}>
                <Icon
                  color={color('secondary', 500)}
                  name="stethoscope"
                  size="medium"
                />
                {getLabel(links.zmluvnePristroje)}
              </Link>
            </ListItem>
          )}
          <ListItem>
            <Link id="link-overenia-prehlady" to={routes.overeniaPrehlady}>
              <Icon color={color('secondary', 500)} name="flag" size="medium" />
              {getLabel(links.overeniaPrehlady)}
            </Link>
          </ListItem>
          <ListItem>
            <Link id="link-prihlasovacie-udaje" to={routes.prihlasovacieUdaje}>
              <Icon color={color('secondary', 500)} name="key" size="medium" />
              {getLabel(links.prihlasovacieUdaje)}
            </Link>
          </ListItem>
        </List>
      </CardSection>
    </Card>
  );
};

export default ProfileLinks;
