import { ReactNode, useCallback, useMemo, useState } from 'react';
import { TableAttributes } from '../../../types/jsonObjects.types';
import { Pagination, TableApp } from '@dovera/design-system';
import SafeHtml from '../../SafeHtml/SafeHtml';
import _ from 'lodash';
import {
  TABLE_MODAL_PAGINATION,
  TABLE_PAGINATION,
} from '../../../constants/misc';
import { scrollParentToTop } from '../../../utils/app.utils';

interface Props extends TableAttributes {
  isModalContent?: boolean;
}

const Table = ({ attributes: { columns, data }, isModalContent }: Props) => {
  const [page, setPage] = useState(0);
  const pagination = isModalContent ? TABLE_MODAL_PAGINATION : TABLE_PAGINATION;

  const handlePageChange = useCallback((newPage: number) => {
    setPage(newPage - 1);
    scrollParentToTop();
  }, []);

  const tableCols = useMemo(
    () =>
      columns.map((c) => ({
        accessor: c.key,
        Header: c.label,
      })),
    [columns],
  );

  const chunkedData = useMemo(
    () => _.chunk(data, pagination),
    [data, pagination],
  );

  const mergedData = useMemo(
    () =>
      chunkedData?.[page]?.map((d) =>
        columns.reduce(
          (acc, c) => {
            acc[c.key] = <SafeHtml html={d[c.key]} />;
            return acc;
          },
          {} as { [key: string]: ReactNode },
        ),
      ),
    [columns, chunkedData, page],
  );

  return (
    <TableApp
      caption=""
      className="mb-small"
      columns={tableCols}
      data={mergedData}
      // @ts-ignore
      pagination={
        <Pagination
          currentPage={page + 1}
          onChange={(page) => handlePageChange(page)}
          totalPages={chunkedData.length}
        />
      }
    />
  );
};

export default Table;
