const convertor = {
  period: 'obdobie',
  periods: 'obdobia',
  action: 'akcia',
  isActiveAction: 'jeAktivnaAkcia',
  codePeriodType: 'kodTypObdobie',
  periodTitle: 'nadpisObdobie',
  periodDescription: 'popisObdobie',
  banners: 'banery',
  image: 'obrazok',
  type: 'typ',
  cycleId: 'idPZSOdbCyklus',
  periodCalculationTo: 'obdobieVypocetDo',
  periodCalculationFrom: 'obdobieVypocetOd',
  priceContent: 'obsahCeny',
  value: 'hodnota',
  title: 'nadpis',
  name: 'nazov',
  tips: 'tipy',
  chartContent: 'obsahGraf',
  chartData: 'datagraf',
  groupType: 'typSkupina',
  contentTitleParameter: 'obsahNadpisParametra',
  subTitle: 'podpnadpis',
  description: 'popis',
  parameterGroups: 'skupinyParametrov',
  parameterCards: 'kartyParametrov',
  dataForExchange: 'dataVyberuParametrov',
  groupId: 'idSkupiny',
  exchangeGroupId: 'idSkupinyVymeny',
  exchangeGroup: 'skupinaVymena',
  selectionType: 'typVyberu',
  exchangeType: 'typVymena',
  selected: 'vybrane',
  parameterCode: 'kodParameter',
  parameter: 'parameter',
  parameters: 'parametre',
  state: 'stav',
  tags: 'tagy',
  quantityToSelect: 'mnozstvoNaVyber',
  filling: 'plnenie',
  group: 'skupina',
  valuesArray: 'poleHodnot',
  valueName: 'nazovHodnoty',
  periodTo: 'obdobieDo',
  periodFrom: 'obdobieOd',
  expertiseCode: 'kodOdbAmb',
  expCode: 'kodOdbNz',
  healthCareCode: 'kodTypZs',
  idCycleRow: 'idRiadokCyklu',
  idEvaluatedHCP: 'idHodnotenyPzs',
  idUser: 'idPouzivatel',
  idVZ: 'idVZ',
  order: 'poradie',
  amountMin: 'mnozstvoMin',
  amountMax: 'mnozstvoMax',
  resultParamId: 'idVysledokParam',
  required: 'povinny',
  contentCardParameter: 'obsahKartaParam',
  periodId: 'idObdobie',
  codeParam: 'kodParametra',
  priceType: 'typCeny',
  price: 'cena',
  selectedParameters: 'parametreVymeny',
  parameterId: 'idParametra',
  choosed: 'vybrany',
  maxValue: 'hranicnaHodnotaMax',
  minValue: 'hranicnaHodnotaMin',
  positionHCP: 'poziciaPzs',
  averageValue: 'priemernaHodnota',
  weight: 'vaha',
  closed: 'uzavrete',
  dateValidityFrom: 'datumPlatnostiOd',
  formId: 'formularId',
  doctorId: 'idLekarZdravPrac',
  isCNP: 'jeCNP',
  diagnosisCode: 'kodDiagnoza',
  productCode: 'kodProduktPZS',
  checkTypes: 'kontrolaTypy',
  checkType: 'kontrolaTyp',
  checkResult: 'vysledokKontroly',
  proposingExpertise: 'navrhujucaNZ',
  proposingDoctor: 'navrhujuciLekar',
  insureeId: 'rodneCislo',
  insureeIdTxs: 'idPoistenec',
  errorCode: 'kodChyby',
  errorWeightCode: 'kodVahaChyby',
  errorDescription: 'popisChyby',
  errorDetail: 'detailChyby',
  additionalInfo: 'doplnujuceInfo',
  hodnota1: 'value1',
  hodnota2: 'value2',
  hodnota3: 'value3',
  hodnota4: 'value4',
  hodnota5: 'value5',
  hodnota6: 'value6',
  expertise: 'odbornost',
  code: 'kod',
  quantity: 'mnozstvo',
  proposalNumber: 'cisloNavrhu',
  proposalId: 'idNavrhNaZS',
  proposalValidityTo: 'platnostNavrhuDo',
  proposalValidityFrom: 'platnostNavrhuOd',
  accepted: 'prijaty',
  decided: 'rozhodnuty',
  exposeType: 'typVystavenia',
  insuranceStatement: 'vyjadreniePoistovne',
  information: 'informacie',
  dosage: 'davkovanie',
  epicrisis: 'epikriza',
  codeIL: 'kodIo',
  hasME: 'maMv',
  diagnosisName: 'nazovDiagnoza',
  nameIL: 'nazovIo',
  productName: 'nazovProduktPzs',
  hasContraintication: 'priznakKontraindikacie',
  treatmentJustification: 'zdovodnenieLiecby',
  doctor: 'lekar',
  ambulance: 'ambulancia',
  hospital: 'nemocnica',
  patient: 'pacient',
  address: 'adresa',
  dateOfBirth: 'datumNarodenia',
  personName: 'meno',
  personSurname: 'priezvisko',
  mobile: 'mobil',
  sex: 'pohlavie',
  detailType: 'typDetailu',
  listType: 'typZoznamu',
  reloadListFromTXS: 'nacitatZoznamTxs',
  insuree: 'poistenec',
  titleBefore: 'titulPred',
  titleAfter: 'titulZa',
  ambulanceCode: 'kodAmbulancia',
  ambulanceName: 'nazovAmbulancia',
  proposalType: 'typNavrhu',
  search: 'vyhladavanie',
  proposalState: 'stavNavrhu',
  dateFrom: 'datumOd',
  dateTo: 'datumDo',
  completionPzs: 'doplneniePzs',
  attachments: 'prilohy',
  themes: 'temy',
  theme: 'tema',
  sections: 'sekcie',
  sectionCode: 'kodSekcie',
  sectionName: 'nazovSekcie',
  reportId: 'idReport',
  contentHeader: 'obsahHlavicka',
  values: 'hodnoty',
  tabs: 'taby',
  filterId: 'idFiltra',
  valueId: 'idHodnoty',
  tabId: 'idTab',
  strFilter: 'strFilter',
  isDefault: 'priznakDefault',
  isDefaultTab: 'priznakDefaultTab',
  filterValue: 'hodnotaFilter',
  filterContent: 'obsahFilter',
  reportContent: 'obsahReport',
  content: 'obsah',
  column: 'stlpec',
  date: 'datum',
  widgetCode: 'kodWidgetu',
  widgets: 'widgety',
};

export default convertor;
