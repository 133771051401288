import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../rootReducer';
import strings from '../../../../../../constants/strings';
import { firstCharToUpper } from '../../../../../../utils/strings.utils';
import {
  getEventTypeLabel,
  getRadioResult,
  hasLocalNOKMessages,
  hasNOKControls,
} from '../../../../../../utils/spaProposals.utils';
import { formatDate } from '../../../../../../utils/date.utils';
import SafeHtml from '../../../../../../components/SafeHtml/SafeHtml';
import useStyles from '../../../../Proposals.styles';
import { Grid, GridCol } from '@dovera/design-system';
import {
  PROPOSAL_SUMMARY_LABEL_BREAKPOINTS,
  PROPOSAL_SUMMARY_VALUE_BREAKPOINTS,
} from '../../../../../../constants/proposals';

const texts = strings.proposals.spa.new;

const Summary = () => {
  const classes = useStyles({ proposalType: 'LN' });
  const {
    data: {
      controls,
      formData: { data },
    },
    stepper: { step3 },
  } = useSelector((state: RootState) => state.spaProposals.new);

  const filteredControls = controls.data.filter(
    (d) => d.checkType !== 'PoistnyVztah',
  );

  const eventLabel = getEventTypeLabel(
    data?.udajParUdalIndSkup?.udalost || null,
    true,
  );

  return (
    <div className={classes.summary}>
      <Grid className="mb-small">
        <GridCol size={PROPOSAL_SUMMARY_LABEL_BREAKPOINTS}>
          {texts.summary.indicatorGroup}
        </GridCol>
        <GridCol size={PROPOSAL_SUMMARY_VALUE_BREAKPOINTS}>
          <b
            className={
              hasNOKControls(
                ['Diagnoza', 'Dlznik', 'DlznikVociCSParNeodklZS'],
                filteredControls,
              )
                ? 'text-color-error'
                : ''
            }
          >{`${step3.indicatorGroup?.kod} ${firstCharToUpper(
            step3.indicatorGroup?.nazov || '',
          )}`}</b>
        </GridCol>
      </Grid>
      <Grid className="mb-small">
        <GridCol size={PROPOSAL_SUMMARY_LABEL_BREAKPOINTS}>
          {texts.summary.diagnose}
        </GridCol>
        <GridCol size={PROPOSAL_SUMMARY_VALUE_BREAKPOINTS}>
          <b>{`${step3.diagnose?.kod} - ${step3.diagnose?.nazov}`}</b>
        </GridCol>
      </Grid>
      {step3.spaConfirmation && (
        <Grid className="mb-small">
          <GridCol size={PROPOSAL_SUMMARY_LABEL_BREAKPOINTS}>
            {texts.summary.doctorConfirmation}
          </GridCol>
          <GridCol size={PROPOSAL_SUMMARY_VALUE_BREAKPOINTS}>
            <b
              className={
                hasLocalNOKMessages(['spaConfirmation'], step3)
                  ? 'text-color-error'
                  : ''
              }
            >
              {getRadioResult(step3.spaConfirmation)}
            </b>
          </GridCol>
        </Grid>
      )}
      {eventLabel && (
        <Grid className="mb-small">
          <GridCol size={PROPOSAL_SUMMARY_LABEL_BREAKPOINTS}>
            {eventLabel}
          </GridCol>
          <GridCol size={PROPOSAL_SUMMARY_VALUE_BREAKPOINTS}>
            <b
              className={
                hasNOKControls(['DatumPlatnostiOd'], filteredControls)
                  ? 'text-color-error'
                  : ''
              }
            >
              {formatDate(step3.eventDate)}
            </b>
          </GridCol>
        </Grid>
      )}
      <Grid className="mb-small">
        <GridCol size={PROPOSAL_SUMMARY_LABEL_BREAKPOINTS}>
          {texts.summary.contraindications}
        </GridCol>
        <GridCol size={PROPOSAL_SUMMARY_VALUE_BREAKPOINTS}>
          <b
            className={
              hasLocalNOKMessages(['spaContraindications'], step3)
                ? 'text-color-error'
                : ''
            }
          >
            {getRadioResult(step3.spaContraindications, true)}
          </b>
        </GridCol>
      </Grid>
      <Grid className="mb-small">
        <GridCol size={PROPOSAL_SUMMARY_LABEL_BREAKPOINTS}>
          {texts.summary.examinations}
        </GridCol>
        <GridCol size={PROPOSAL_SUMMARY_VALUE_BREAKPOINTS}>
          <b
            className={
              hasLocalNOKMessages(['spaExaminations'], step3)
                ? 'text-color-error'
                : ''
            }
          >
            {getRadioResult(step3.spaExaminations)}
          </b>
        </GridCol>
      </Grid>
      <Grid className="mb-small">
        <GridCol size={PROPOSAL_SUMMARY_LABEL_BREAKPOINTS}>
          {texts.summary.svlz}
        </GridCol>
        <GridCol size={PROPOSAL_SUMMARY_VALUE_BREAKPOINTS}>
          <b
            className={
              hasLocalNOKMessages(['spaSVLZ'], step3) ? 'text-color-error' : ''
            }
          >
            {getRadioResult(step3.spaSVLZ)}
          </b>
        </GridCol>
      </Grid>
      <Grid>
        <GridCol size={PROPOSAL_SUMMARY_LABEL_BREAKPOINTS}>
          {texts.summary.epicrisis}:
        </GridCol>
        <GridCol size={PROPOSAL_SUMMARY_VALUE_BREAKPOINTS}>
          <SafeHtml html={step3.reasonDescription} wrapper="b" />
        </GridCol>
      </Grid>
    </div>
  );
};

export default Summary;
