import * as React from 'react';
import useStyles from './ContentHeader.styles';
import IconCloseModal from '../../CustomIcons/IconCloseModal';

interface Props {
  contentTitle: string;
  expanded?: boolean;
  onCloseClick: () => void;
  searchField?: React.ReactNode;
}

const ContentHeader = ({
  contentTitle,
  expanded,
  onCloseClick,
  searchField,
}: Props) => {
  const classes = useStyles({ expanded });

  return (
    <div className={`${classes.root} ${classes.borderRadius}`}>
      <div className={classes.header}>
        <h4 className="no-mrg">{contentTitle}</h4>
        <button id="helper-close-btn" onClick={onCloseClick} type="button">
          <IconCloseModal id="feedback-close-btn--icon" />
        </button>
      </div>
      {searchField}
    </div>
  );
};

export default ContentHeader;
