import { color, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

interface StylesProps {
  optionsTop: boolean;
  width?: number;
}

export default createUseStyles({
  multiSelectWrapper: ({ width }: StylesProps) => ({
    width: width || 'auto',
    position: 'relative',
    marginBottom: getRem(32),
    marginRight: getRem(32),
    '& > .form-control': {
      margin: 0,
    },
    '& textarea[readonly]': {
      height: getRem(48),
      padding: getRem(12),
      minHeight: `0 !important`,
      cursor: 'pointer',
      '.input:focus': {
        border: 'inherit',
      },
    },
    '& .input-addons__item': {
      height: 'auto',
      minHeight: 0,
      padding: 0,
    },
  }),
  optionsWrapper: ({ optionsTop }: StylesProps) => ({
    position: 'absolute',
    boxShadow: '0 10px 15px 0 rgb(0 0 0 / 4%)',
    background: color('white'),
    zIndex: 9,
    padding: getRem(12),
    maxWidth: getRem(382),
    width: '100%',
    top: optionsTop ? getRem(-76) : 'auto',
    '& .form-control--group': {
      margin: '0 !important',
    },
    '& .radiocheck-group .form-control:not(:last-child)': {
      marginBottom: `${getRem(16)} !important`,
    },
  }),
  options: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: getRem(225),
  },
  chevronBtn: {
    height: getRem(48),
    minHeight: getRem(48),
    marginBottom: 0,
    padding: `${getRem(12)} ${getRem(16)}`,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
});
