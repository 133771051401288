import { breakpoints } from '@dovera/design-system';

export const calculateTextMinWidth = (
  windowWidth: number,
): { maxWidth: number } => {
  switch (true) {
    case windowWidth < breakpoints.xl && windowWidth >= breakpoints.l:
      return { maxWidth: 100 };
    case windowWidth < breakpoints.l && windowWidth >= breakpoints.s:
      return { maxWidth: 120 };
    case windowWidth < breakpoints.s:
      return { maxWidth: 80 };
    default:
      return { maxWidth: 200 };
  }
};
