import { useCallback, useEffect, useState } from 'react';
import useStyles from '../Profil.styles';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import { Container, Loader, PageSection } from '@dovera/design-system';
import IconUserBlue from '../../../components/CustomIcons/IconUserBlue';
import { cx } from '../../../utils/exports';
import {
  GridColTable,
  GridRowTable,
  GridTable,
} from '../../../components/GridTable';
import Form from './Form/Form';
import ModalDisapproval from './ModalDisapproval/ModalDisapproval';
import { getIdFromUrl } from '../../../utils/app.utils';
import strings from '../../../constants/strings';
import { useLocation } from 'react-router';
import { NoDataIllustration } from '../../../components/EmptyState';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import {
  DajDataAutentifikaciePayload,
  DajSekciePristupuPayload,
  ZmenUdajeAutentifikaciePayload,
} from '../../../types/api/poskytovatel';
import {
  fetchDataAutentifikacie,
  fetchSections,
  updateAuthData,
} from '../../../api/poskytovatel';
import { firstCharToUpper } from '../../../utils/strings.utils';
import { uniqueArray } from '../../../utils/array.utils';
import StepResult from '../../auth-pzs/Stepper/StepResult';
import { useAppDispatch } from '../../../hooks/useStore';

const texts = strings.auth.page.approvalAccess;
const textsResult = strings.auth.page.stepResult;

const SchvaleniePristupu = () => {
  const classes = useStyles();
  const [resultTitle, setResultTitle] = useState('');
  const [isVisibleModalDisapproval, setIsVisibleModalDisapproval] =
    useState(false);
  const [resultDescription, setResultDescription] = useState('');
  const [resultState, setResultState] = useState('success');
  const { isSUPR, pouzivatelId, vztahId } = useSelector(
    (state: RootState) => state.auth,
  );
  const {
    authData,
    sections,
    stepper: { stepResult },
  } = useSelector((state: RootState) => state.pzsAuth);
  const newUserName = `${
    authData.data?.[0]?.titulPred ? `${authData.data?.[0]?.titulPred} ` : ''
  }${firstCharToUpper(authData.data?.[0]?.meno || '-')} ${firstCharToUpper(
    authData.data?.[0]?.priezvisko || '-',
  )}${authData.data?.[0]?.titulZa ? `, ${authData.data?.[0]?.titulZa}` : ''}`;
  const dispatch = useAppDispatch();
  const [isInvalid, setIsInvalid] = useState(false);
  const location = useLocation();
  const submitCallback = useCallback(
    (
      values: any,
      selectedSections: string[] | null,
      disapprovalText: string | null,
    ) => {
      if (authData.data) {
        const ad = authData.data[0];
        setResultTitle(
          disapprovalText
            ? textsResult.warning.titleDisapproval
            : textsResult.success.titleApproval,
        );
        setResultDescription(
          disapprovalText
            ? textsResult.warning.descriptionDisapproval(newUserName)
            : textsResult.success.descriptionApproval(newUserName),
        );
        if (disapprovalText) setResultState('warning');
        const payload: ZmenUdajeAutentifikaciePayload = {
          dovodZamietnutia: disapprovalText,
          email: values?.email || ad.email,
          idPouzivatel: ad.idPouzivatel,
          ids: authData.data.map((d) => d.id) || [],
          jeAktivnyPristup: authData?.data[0]?.jeAktivnyPristup || false,
          kodLekar:
            authData.data.filter((d) => d.kodLekara)?.[0]?.kodLekara || null,
          pocetOdmietnuti: disapprovalText ? 3 : ad.pocetOdmietnuti,
          sekcie:
            sections.data.map((s) => ({
              ...s,
              zapnuta: selectedSections?.includes(s.kodSekcie) || false,
            })) || null,
          stav: disapprovalText ? 'O' : 'P',
          stavPouzivatela: ad.jeAktivnyPristup,
          zmenilPouzivatel: pouzivatelId || 0,
        };
        dispatch(updateAuthData(payload));
      }
    },
    [authData.data, dispatch, newUserName, pouzivatelId, sections.data],
  );
  useEffect(() => {
    if (!authData.data) {
      const userId = getIdFromUrl(location.pathname);
      if (!userId) setIsInvalid(true);
      if (userId && !authData.isLoading) {
        const payload: DajDataAutentifikaciePayload = {
          idPouzivatel: userId,
          idVZ: vztahId || 0,
          jeHlavnaKarta: isSUPR,
        };
        dispatch(fetchDataAutentifikacie(payload));
      }
    }
  }, [authData, dispatch, isSUPR, location.pathname, vztahId]);
  useEffect(() => {
    if (authData.data) {
      const userId = getIdFromUrl(location.pathname);
      const payloadSections: DajSekciePristupuPayload = {
        idPouzivatel: userId,
        idVZ: vztahId || 0,
        zoznamRoli: uniqueArray(authData.data.map((d) => d.typRole)),
      };
      dispatch(fetchSections(payloadSections));
    }
  }, [authData, dispatch, location.pathname, vztahId]);
  const renderHeadInfo = (
    <GridTable>
      <GridRowTable>
        <GridColTable>
          <b>{texts.labels.status}</b>
        </GridColTable>
        <GridColTable spaceLeft={32}>
          {authData?.data?.[0]?.jeAktivnyPristup ? 'Aktívny' : 'Neaktívny'}
        </GridColTable>
      </GridRowTable>
      <GridRowTable>
        <GridColTable>
          <b>{texts.labels.type}</b>
        </GridColTable>
        <GridColTable spaceLeft={32}>Štandardný prístup</GridColTable>
      </GridRowTable>
      {authData.data?.filter((d) => d.kodLekara)?.[0]?.kodLekara && (
        <GridRowTable>
          <GridColTable>
            <b>{texts.labels.doctorCode}</b>
          </GridColTable>
          <GridColTable spaceLeft={32}>
            {authData.data.filter((d) => d.kodLekara)[0].kodLekara}
          </GridColTable>
        </GridRowTable>
      )}
    </GridTable>
  );
  if (isInvalid)
    return (
      <StaticMenuLayout hideEPNotifications>
        <PageSection className={classes.section}>
          <Container>
            <NoDataIllustration
              description={
                isSUPR
                  ? 'Identifikovali sme neplatné id používateľa, ktorého prístup chcete schváliť. Skúste neskôr, prosím.'
                  : 'Sekcia je dostupná iba pre hlavnú kartu'
              }
              title="Sekcia pre schválenie prístupu je nedostupná"
            />
          </Container>
        </PageSection>
      </StaticMenuLayout>
    );
  if (stepResult.processingState || stepResult.error) {
    return (
      <StaticMenuLayout hideEPNotifications>
        <PageSection className={classes.section}>
          <Container>
            <StepResult
              description={stepResult.error ? '' : resultDescription}
              result={stepResult.error ? 'error' : resultState}
              title={
                stepResult.error ? textsResult.error.titleApproval : resultTitle
              }
            />
          </Container>
        </PageSection>
      </StaticMenuLayout>
    );
  }

  return (
    <StaticMenuLayout hideEPNotifications>
      <PageSection className={classes.section}>
        <Container>
          {authData?.isLoading ? (
            <Loader size="large" />
          ) : (
            <>
              <h3 className={cx(classes.title, 'mb-large')}>
                <IconUserBlue id="schvalenie-pristupu-title" /> {newUserName}
              </h3>
              {renderHeadInfo}
              <Form
                defaultPosition={
                  authData.data?.some((d) => d?.typRole === 'I')
                    ? authData.data?.filter((d) => d?.typRole === 'I')[0]
                        .kategoriaRole
                    : ''
                }
                email={authData.data?.[0]?.email || ''}
                mobile={authData.data?.[0]?.mobil || ''}
                onDisapproval={() => setIsVisibleModalDisapproval(true)}
                onSubmitForm={(values, selectedSections) =>
                  submitCallback(values, selectedSections, null)
                }
                phone={authData.data?.[0]?.pevnaLinka || ''}
                positions={authData.data?.map((d) => d?.typRole) || []}
                sections={{
                  data: sections.data,
                  isLoading: sections.isLoading,
                }}
              />
              <ModalDisapproval
                isLoadingSubmit={false}
                isVisible={isVisibleModalDisapproval}
                onHide={() => setIsVisibleModalDisapproval(false)}
                onSubmit={(reason: string) =>
                  submitCallback(null, null, reason)
                }
              />
            </>
          )}
        </Container>
      </PageSection>
    </StaticMenuLayout>
  );
};

export default SchvaleniePristupu;
