import * as React from 'react';
import cx from 'classnames';
import useStyles from './FloatingButtonMenu.styles';

interface FloatingButtonMenuItem {
  icon: React.ReactNode;
  id?: string;
  onClick?: () => void;
  title: string;
}

interface OwnProps {
  menuItems: Array<FloatingButtonMenuItem>;
  onMenuItemClick: (menuItem: FloatingButtonMenuItem) => void;
}

type Props = OwnProps;

const FloatingButtonMenu = ({ menuItems, onMenuItemClick }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {menuItems.map((menuItem) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <button
          key={menuItem.title}
          className={cx('d-flex-row align-items-middle', classes.menuItem)}
          id={menuItem.id}
          onClick={() => !menuItem.id && onMenuItemClick(menuItem)}
          type="button"
        >
          {menuItem.icon}
          <p className="no-mrg-bottom text-space-left">{menuItem.title}</p>
        </button>
      ))}
    </div>
  );
};

export default FloatingButtonMenu;
