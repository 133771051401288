import strings from '../../../../../constants/strings';
import { useSelector } from 'react-redux';
import { changeActiveStep } from '../../../../../slices/drugProposals.slice';
import { RootState } from '../../../../../rootReducer';
import { Summary } from '../../common/PatientStep';
import { StepWrapper } from '../../common';
import PatientForm from './PatientForm';
import { PROPOSAL_DRUGS_STEP_COUNT } from '../../../../../constants/proposals';

const texts = strings.proposals.spa.new;

interface Props {
  onCancel: () => void;
  onContinue: () => void;
}

const Step2 = ({ onCancel, onContinue }: Props) => {
  const { insureeId, poiData, stepStatus } = useSelector(
    (state: RootState) => state.drugProposals.new.stepper.step2,
  );
  const {
    stepper: { activeStep },
  } = useSelector((state: RootState) => state.drugProposals.new);

  return (
    <StepWrapper
      activeStep={activeStep}
      changeActiveStep={changeActiveStep}
      // eslint-disable-next-line
      content={
        <div id="drugProposal-step2">
          <PatientForm onCancel={onCancel} onContinue={onContinue} />
        </div>
      }
      step={2}
      stepCount={PROPOSAL_DRUGS_STEP_COUNT}
      stepStatus={stepStatus}
      summary={<Summary insureeId={insureeId} poiData={poiData} />}
      title={texts.stepTitles.step2}
    />
  );
};

export default Step2;
