import { color, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  subjectAccess: {
    marginLeft: `${getRem(130)} !important`,
    '& .choices__inner': {
      border: 'none',
    },
    '& .choices__list.choices__list--single .choices__item--selectable': {
      color: color('primary', 600),
      opacity: 1,
      marginRight: 15,
    },
    '& .choices__list--dropdown .choices__list': {
      '& > div:first-child': {
        display: 'none',
      },
    },
  },
});
