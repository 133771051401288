import useStyles from '../../../../Proposals.styles';
import strings from '../../../../../../constants/strings';
import { storeStep3 } from '../../../../../../slices/spaProposals.slice';
import { Choice } from 'choices.js';
import { getIndicatorGroups } from '../../../../../../api/spaProposals';
import { getDiagnosesList } from '../../../../../../api/proposals';
import { ProposalDiagnose } from '../../../../../../types/proposals.types';
import { ProposalIndicatorGroup } from '../../../../../../types/spaProposals.types';
import { AutocompleteApp, Link } from '@dovera/design-system';
import { useAppDispatch } from '../../../../../../hooks/useStore';
import { getCMSText } from '../../../../../../utils/cms.utils';
import { cmsPath } from '../../../../../../constants/cmsPath';
import { safeUrl } from '../../../../../../utils/strings.utils';
import { cx } from '../../../../../../utils/exports';

interface Props {
  diagnose: ProposalDiagnose | null;
  diagnoseField: any;
  expertiseAmbulance: string;
  expertiseDoctor: string;
  indicatorGroup: ProposalIndicatorGroup | null;
  indicatorGroupField: any;
}

const texts = strings.proposals.spa.new;

const IsDg = ({
  diagnose,
  diagnoseField,
  expertiseAmbulance,
  expertiseDoctor,
  indicatorGroup,
  indicatorGroupField,
}: Props) => {
  const classes = useStyles({ proposalType: 'KN' });
  const dispatch = useAppDispatch();
  const renderIS = (
    <div className={classes.autocompleteWrapper}>
      <AutocompleteApp
        {...indicatorGroupField.input}
        addonsInside
        autofillSingleResult
        delay={200}
        disableSuccessFrame
        error={
          indicatorGroupField.meta.touched &&
          indicatorGroupField.meta.error &&
          indicatorGroupField.meta.error
        }
        id="proposals--indicator-group"
        isRequired
        label={texts.labels.indicatorGroup}
        minLength={2}
        notFilterOptions
        onChange={(value) => {
          indicatorGroupField.input.onChange(value);
        }}
        onSelect={(option: Choice | any) => {
          if (option) {
            indicatorGroupField.input.onChange(option.label);
            dispatch(
              storeStep3({
                indicatorGroup: {
                  nazov: option.label.split(`${option.value} - `)?.[1],
                  kod: option.value,
                },
              }),
            );
          }
        }}
        source={(query) =>
          getIndicatorGroups({
            hladanaIndikacnaSkupina: query,
            kodDiagnoza: diagnose?.kod || null,
            odbornostLek: expertiseDoctor,
            odbornostNZ: expertiseAmbulance,
          })
        }
      />
    </div>
  );
  const renderDG = (
    <div className={cx(classes.autocompleteWrapper, 'mb')}>
      <AutocompleteApp
        {...diagnoseField.input}
        addonsInside
        autofillSingleResult
        delay={200}
        disableSuccessFrame
        error={
          diagnoseField.meta.touched &&
          diagnoseField.meta.error &&
          diagnoseField.meta.error
        }
        id="proposal--step3--diagnose"
        isRequired
        label={texts.labels.diagnose}
        minLength={2}
        notFilterOptions
        onChange={(value) => {
          diagnoseField.input.onChange(value);
        }}
        onSelect={(option: Choice | any) => {
          if (option) {
            diagnoseField.input.onChange(option.label);
            dispatch(
              storeStep3({
                diagnose: {
                  kod: option.value,
                  nazov: option.label.split(`${option.value} - `)?.[1],
                },
              }),
            );
          }
        }}
        source={(query) =>
          getDiagnosesList({
            hladanaDiagnoza: query,
            kodIndikacnaSkupina: indicatorGroup?.kod || null,
            odbornostLek: expertiseDoctor,
            odbornostNZ: expertiseAmbulance,
          })
        }
      />
    </div>
  );
  const renderIndicatorListLink = (
    <div className="mb">
      <Link
        className={classes.indicatorListLink}
        href={safeUrl(
          getCMSText(cmsPath.proposals.common.indicatorListUrl, '', true),
        )}
        placeholder
        target="_blank"
      >
        {texts.buttons.showIndicatorsList}
      </Link>
    </div>
  );
  return (
    <>
      {renderIS}
      {renderDG}
      {renderIndicatorListLink}
    </>
  );
};

export default IsDg;
