import { color as ddsColor } from '@dovera/design-system';

interface Props {
  backgroundColor?: string;
  color?: string;
  id: string;
}

const IconBluePlus = ({ backgroundColor, color, id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill={backgroundColor || ddsColor('primary', 600)}
    />
    <path
      d="M19.0205 12.6485H12.6485V19.0205H11.3525V12.6485H4.98047V11.3525H11.3525V4.98047H12.6485V11.3525H19.0205V12.6485Z"
      fill={color || 'white'}
    />
  </svg>
);

export default IconBluePlus;
