import { useSelector } from 'react-redux';
import ProposalHeader from './ProposalHeader';
import { RootState } from '../../../../rootReducer';
import SkeletonLoaderHeader from '../SkeletonLoader/SkeletonLoaderHeader';
import { navrhyRoutes } from '../../../../routes';
import { useCallback, useState } from 'react';
import ReservationDateModal from '../../Modals/ReservationDateModal';
import { useAppDispatch } from '../../../../hooks/useStore';
import { getProposalDetail } from '../../../../api/spaProposals';
import { resetApprovedProposals } from '../../../../slices/spaProposals.slice';
import { Toast } from '../../../../components';
import {
  BlobPDF,
  TemplateProposalDetail,
} from '../../../../components/PdfTemplate';
import { sendDataLayer } from '../../../../utils/gtm.utils';
import strings from '../../../../constants/strings';
import useStyles from '../../Proposals.styles';
import { cx } from '../../../../utils/exports';

const texts = strings.proposals.spa.detail;

interface Props {
  pin?: string | null;
  proposalId: number;
  proposalNumber: string;
}

const HeaderKN = ({ pin, proposalId, proposalNumber }: Props) => {
  const classes = useStyles({ proposalType: 'KN' });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reservationType, setReservationType] = useState<
    'create' | 'update' | 'delete' | null
  >(null);
  const [toastMessage, setToastMessage] = useState('');
  const dispatch = useAppDispatch();
  const { data, isLoading } = useSelector(
    (state: RootState) => state.spaProposals.detail,
  );
  const pzsName = useSelector(
    (state: RootState) => state.poskytovatel.nazovZobrazPzs,
  );
  const isApprovedProposal: boolean =
    navrhyRoutes.zoznamSchvalenychNavrhov.includes(location.pathname);
  const showReservationCallback = useCallback((value) => {
    setReservationType(value);
    setIsModalVisible(true);
  }, []);
  const renderPrintBtn = data && (
    <BlobPDF
      buttonLabel={texts.buttons.printPdf}
      buttonType="secondary"
      iconPosition="right"
      iconType="download"
      onClick={() => sendDataLayer('kupele_detail_download')}
      pdfContent={
        <TemplateProposalDetail
          data={data}
          proposalNumber={proposalNumber}
          proposalPin={pin}
          pzsName={pzsName}
        />
      }
    />
  );
  const renderTitle = (
    <h2 className={cx('mb', classes.title)}>
      <span>{texts.title(proposalNumber)}</span>
      {!isLoading && data && renderPrintBtn}
    </h2>
  );
  if (isLoading)
    return (
      <>
        {renderTitle}
        <SkeletonLoaderHeader />
      </>
    );
  if (!data) return renderTitle;
  return (
    <>
      {renderTitle}
      <ProposalHeader
        accepted={data.detail.prijaty}
        exposedDate={data.detail.vystaveny}
        exposedType={data.detail.typVystavenia}
        processDate={data.detail.rozhodnuty}
        spaData={{
          insuranceStatement: data.detail.vyjadreniePoistovne,
          isApproved: isApprovedProposal,
          onReservationClick: (value: 'create' | 'update' | 'delete') =>
            showReservationCallback(value),
          pin,
          reservation: {
            create: data.detail.rezervaciaNavrhu.zadatRezervaciu,
            delete: data.detail.rezervaciaNavrhu.zmazatRezervaciu,
            id: data.detail.rezervaciaNavrhu.id,
            show: data.detail.rezervaciaNavrhu.zobrazit,
            update: data.detail.rezervaciaNavrhu.upravitRezervaciu,
          },
          reservationFrom: data.detail.rezervaciaNavrhu.datumOd,
          reservationTo: data.detail.rezervaciaNavrhu.datumDo,
          treatment: data.detail.kupelnaLiecba,
        }}
        state={data.detail.stav}
        type="KN"
        validityFrom={data.detail.platnostNavrhuOd}
        validityTo={data.detail.platnostNavrhuDo}
      />
      <ReservationDateModal
        id={data.detail.idNavrhNaZS || 0}
        isVisible={isModalVisible}
        onHide={(reload: boolean, toastMessage: string) => {
          if (toastMessage) {
            setToastMessage(toastMessage);
            dispatch(resetApprovedProposals());
            setTimeout(() => {
              setToastMessage('');
              dispatch(
                getProposalDetail({
                  cisloNavrhu: proposalNumber,
                  idNavrhNaZS: proposalId,
                }),
              );
            }, 3000);
          }
          setIsModalVisible(false);
        }}
        pin={pin}
        reservation={{
          from: data.detail.rezervaciaNavrhu.datumOd,
          id: data.detail.rezervaciaNavrhu.id,
          to: data.detail.rezervaciaNavrhu.datumDo,
        }}
        type={reservationType}
      />
      {toastMessage && (
        <Toast iconType={'success'} show={!!toastMessage} text={toastMessage} />
      )}
    </>
  );
};

export default HeaderKN;
