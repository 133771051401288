import { breakpoints, color } from '@dovera/design-system';
import { ResponsiveCols } from './types';

export const getNotificationType = (
  type: string,
): 'blue' | 'warning' | 'error' => {
  if (type === 'info') return 'blue';
  return type as 'blue' | 'warning' | 'error';
};

export const getGraphColor = (type: string): string => {
  switch (type) {
    case 'K':
      return color('secondary');
    case 'E':
      return color('blue');
    case 'I':
      return color('primary', 600);
    default:
      return color('grey', 300);
  }
};

export const calculateResponsiveCols = (
  columns: number,
  elementWidth: number,
  windowWidth: number,
): ResponsiveCols => {
  if (windowWidth < breakpoints.s)
    return { xs: 12, s: 12, m: 12, l: 12, xl: 12 };
  switch (columns) {
    case 1:
      return { xs: 12, s: 12, m: 12, l: 12, xl: 12 };
    case 2:
      return { xs: 12, s: 6, m: 6, l: 6, xl: 6 };
    case 3:
      return elementWidth > 450 && windowWidth > breakpoints.xl
        ? { xs: 12, s: 4, m: 4, l: 4, xl: 4 }
        : { xs: 12, s: 6, m: 6, l: 6, xl: 6 };
    case 4:
      return elementWidth > 550
        ? { xs: 12, s: 3, m: 3, l: 3, xl: 3 }
        : { xs: 12, s: 12, m: 6, l: 6, xl: 6 };
    case 6:
      return elementWidth > 650
        ? { xs: 12, s: 2, m: 2, l: 2, xl: 2 }
        : { xs: 12, s: 12, m: 6, l: 6, xl: 4 };
    default:
      return { xs: 12, s: 12, m: 12, l: 12, xl: 12 };
  }
};
