import { Carousel } from 'nuka-carousel';
import useStyles from './styles';
import Dots from './atoms/Dots';
import Arrows from './atoms/Arrows';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { getCMSText, getCMSTexts } from '../../../utils/cms.utils';
import { cmsPath } from '../../../constants/cmsPath';
import { getRandomNumber } from '../../../utils/number.utils';
import CardBubble from '../../../components/CardBubble/CardBubble';

const getRandomItems = (items: ReactNode[], count: number) => {
  const shuffled = [...items].sort(() => 0.5 - getRandomNumber());
  return shuffled.slice(0, count);
};

const ReviewCarousel = () => {
  const classes = useStyles();
  const [slides, setSlides] = useState<ReactNode[]>([]);
  const { texty } = useSelector((state: RootState) => state.cms);
  const getSlides = useCallback(() => {
    if (!slides?.length && texty?.length) {
      const data =
        getCMSTexts({
          path: '/referencie',
          withoutPath: '/referencie/systemove-parametre',
        }) || [];
      const count = Number(getCMSText(cmsPath.references.count, '', true));
      setSlides(getRandomItems(data, count));
    }
  }, [slides, texty]);
  useEffect(() => {
    getSlides();
    // eslint-disable-next-line
  }, []);
  if (!slides?.length) return <span />;
  return (
    <CardBubble className={classes.carouselContainer} id="review-carousel">
      <Carousel
        arrows={<Arrows />}
        autoplay
        autoplayInterval={15000}
        className={classes.carousel}
        dots={<Dots />}
        showArrows={false}
        showDots
        wrapMode="wrap"
      >
        {slides.map((s, index) => (
          <div key={`slide--${s}-${index}`} className={classes.slide}>
            {s}
          </div>
        ))}
      </Carousel>
    </CardBubble>
  );
};

export default ReviewCarousel;
