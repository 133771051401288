import { createUseStyles } from 'react-jss';
import { breakpoints, color, getRem } from '@dovera/design-system';
import { DEFAULT_SELECT_VALUE } from './utils/dds.utils';

export default createUseStyles({
  pageLayout: {
    padding: 0,
    zIndex: 1,
    '& .container': {
      margin: 0,
      padding: 0,
      maxWidth: '100%',
    },
  },
  padLayoutTop: {
    paddingTop: `${getRem(48)} !important`,
  },
  appTable: {
    '& table': {
      border: 'none',
    },
    '& th': {
      backgroundColor: 'transparent',
      paddingBottom: getRem(16),
    },
    '& tbody tr': {
      '& td': {
        border: 'none',
        verticalAlign: 'middle',
        padding: getRem(16),
        '&:first-child': {
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
        },
        '&:last-child': {
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
        },
        '&:after': {
          display: 'none',
        },
        '& u': {
          fontWeight: 500,
        },
        '& button': {
          minHeight: 0,
          verticalAlign: 'middle',
          marginTop: -5,
          marginBottom: 0,
        },
        '& svg': {
          verticalAlign: 'middle',
        },
      },
    },
    '&.table-content--fit': {
      width: 'fit-content',
      minWidth: '70%',
    },
  },
  pagination: {
    maxWidth: 800,
    margin: '30px auto 0',
  },
  transparentNotification: {
    '& > div': {
      backgroundColor: 'unset',
      padding: 0,
    },
  },
  notification: {
    '& > .notification--blue': {
      '& a': {
        color: color('secondary', 500),
        textDecoration: 'underline',
        fontWeight: 500,
      },
    },
  },
  notificationWithBtn: {
    '& .notification__textContent': {
      '& > p': {
        '& > span': {
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          '& span': {
            width: '50%',
            marginRight: getRem(8),
          },
          [`@media screen and (max-width: ${breakpoints.xl}px)`]: {
            display: 'block',
            '& span': {
              width: '100%',
            },
          },
        },
      },
    },
  },
  appForm: {
    '& .choices__item': {
      [`&[data-value="${DEFAULT_SELECT_VALUE}"]`]: {
        display: 'none',
      },
    },
  },
  textTooltipRight: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: getRem(8),
    },
  },
  filterWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexBasis: '100%',
    '& > div, & > form': {
      width: getRem(211),
      marginRight: getRem(32),
    },
  },
  headerWrapper: {
    '&': {
      [`@media screen and (min-width: 1350px)`]: {
        paddingLeft: `${getRem(84)} !important`,
        paddingRight: `${getRem(84)} !important`,
      },
      [`@media screen and (max-width: 1349px)`]: {
        paddingLeft: `${getRem(48)} !important`,
        paddingRight: `${getRem(48)} !important`,
      },
      [`@media screen and (max-width: ${breakpoints.s}px)`]: {
        paddingLeft: `${getRem(16)} !important`,
        paddingRight: `${getRem(16)} !important`,
      },
    },
  },
  modal: {
    '& .modal__footer': {
      justifyContent: 'flex-start',
    },
  },
  section: {
    padding: `0 ${getRem(48)}`,
  },
  textarea: {
    minHeight: getRem(96),
  },
});
