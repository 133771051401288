import { UvazokLekaraAmbPZS } from '../../../../../types/poskytovatel.types';
import strings from '../../../../../constants/strings';
import { Pagination, TableApp } from '@dovera/design-system';
import {
  firstCharToUpper,
  formatNumberToString,
  hotjarMasking,
  nbsp,
} from '../../../../../utils/strings.utils';
import { UvazokTyp } from '../../../../../types/api/poskytovatel';
import { formatDate } from '../../../../../utils/date.utils';
import { useState } from 'react';

interface Props {
  data: UvazokLekaraAmbPZS[][];
}

const texts = strings.profile.overeniaPrehlady.ambulanceDetail.table;

const TableAmbDoctors = ({ data }: Props) => {
  const [page, setPage] = useState(0);
  return (
    <TableApp
      caption=""
      className="table-caption--hide"
      columns={[
        { accessor: 'col1', Header: texts.labels.name },
        { accessor: 'col2', Header: texts.labels.expertise },
        { accessor: 'col3', Header: texts.labels.workingTime },
        { accessor: 'col4', Header: texts.labels.validity },
      ]}
      data={data?.[page].map((d) => ({
        col1: (
          <>
            {hotjarMasking(d.lekarKod, 'b', 'd-block')}
            {hotjarMasking(
              firstCharToUpper(d.lekarMeno, true),
              'small',
              'text-color-grey-light',
            )}
          </>
        ),
        col2: `${d.odbornostLekKod} - ${d.odbornostLekNazov}`,
        col3: d.uvazokHodnota
          ? nbsp(
              `${UvazokTyp[d.uvazokTyp]}: ${formatNumberToString(
                d.uvazokHodnota,
              )}`,
            )
          : '0',
        col4: (
          <>
            {d.uvazokDatumOd && (
              <span className="d-block">{`Od: ${formatDate(
                d.uvazokDatumOd,
              )}`}</span>
            )}
            {d.uvazokDatumDo && (
              <span className="d-block">{`Do: ${formatDate(
                d.uvazokDatumDo,
              )}`}</span>
            )}
          </>
        ),
      }))}
      data-testid="table-amb-doctors"
      // @ts-ignore
      pagination={
        <Pagination
          currentPage={page + 1}
          onChange={(pageNumber) => setPage(pageNumber - 1)}
          totalPages={data?.length || 0}
        />
      }
    />
  );
};

export default TableAmbDoctors;
