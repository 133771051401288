import { color, colors, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  carousel: {
    '& + .nuka-page-container': {
      '& .nuka-page-indicator': {
        backgroundColor: colors.grey[100],
        width: getRem(8),
        height: getRem(8),
        '&.nuka-page-indicator-active': {
          backgroundColor: colors.grey[500],
        },
      },
    },
  },
  arrowsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: `${getRem(16)} ${getRem(64)} 0`,
  },
  arrow: {
    cursor: 'pointer',
    '&.disabled': {
      cursor: 'initial',
      '& svg': {
        fill: colors.grey[100],
      },
    },
  },
  dotsWrapper: {
    position: 'relative',
    width: '90%',
    margin: `${getRem(-16)} auto 0`,
    textAlign: 'center',
  },
  dots: {
    position: 'absolute',
    width: '100%',
    marginTop: getRem(-32),
  },
  dot: {
    backgroundColor: color('grey', 300),
    borderRadius: '100%',
    cursor: 'pointer',
    height: getRem(8),
    width: getRem(8),
    margin: `0 ${getRem(4)}`,
    '&.active': {
      backgroundColor: color('primary', 500),
    },
  },
  slide: {
    minWidth: '100%',
    padding: `${getRem(32)} ${getRem(32)} ${getRem(52)}`,
    '& span': {
      fontSize: getRem(14),
      color: colors.grey[500],
      paddingLeft: getRem(24),
    },
    '& h6': {
      borderLeft: `3px solid ${color('secondary', 500)}`,
      paddingLeft: getRem(21),
      '& + div': {
        paddingLeft: getRem(24),
      },
    },
  },
  carouselContainer: {
    overflow: 'hidden',
  },
});
