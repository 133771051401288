import useStyles from './OfflineSystem.styles';
import { checkSystemsStatus } from '../../api';
import useInterval from '../../hooks/useInterval';
import { useAppDispatch } from '../../hooks/useStore';
import { Illustration } from '@dovera/design-system';

interface Props {
  doIntervalCalls?: boolean;
  systems: ('TXS' | 'MD' | 'EP' | 'DMS')[];
}

const OfflineSystem = ({ doIntervalCalls, systems }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  useInterval(() => {
    if (doIntervalCalls)
      dispatch(
        checkSystemsStatus({
          systemy: [...systems],
        }),
      );
  }, 30000);
  return (
    <div className={classes.offline}>
      <Illustration
        className="mb"
        height={256}
        name="large_warning_state"
        width={256}
      />
      <h4 className="text-color-warning">Cieľový systém je nedostupný</h4>
      <p>
        Ospravedlňujeme sa, momentálne je cieľový systém offline. Pokračovať v
        tejto sekcii budete môcť po návrate cieľového systému do stavu online.
      </p>
      <small>
        Kontrola dostupnosti cieľového systému prebieha každých 30 sekúnd.
      </small>
    </div>
  );
};

export default OfflineSystem;
