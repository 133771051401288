import { useEffect, useState } from 'react';
import useStyles from '../Profil.styles';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import { Container, Radio, RadioGroup } from '@dovera/design-system';
import routes from '../../../routes';
import strings from '../../../constants/strings';
import SafeHtml from '../../../components/SafeHtml/SafeHtml';
import { Outlet, useLocation, useNavigate } from 'react-router';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import CardBubble from '../../../components/CardBubble/CardBubble';
import ContentHeader from '../../../components/ContentHeader/ContentHeader';

const texts = strings.profile;
const { baseFilter } = texts.overeniaPrehlady;

const OvereniaPrehlady = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [baseFilterValue, setBaseFilterValue] = useState(baseFilter[0].value);
  useEffect(() => {
    if (location.pathname.includes('ambulancie')) {
      setBaseFilterValue(baseFilter[1].value);
      navigate(`${routes.overeniaPrehlady}/ambulancie${location.search || ''}`);
    } else {
      navigate(`${routes.overeniaPrehlady}/lekari${location.search || ''}`);
    }
    // eslint-disable-next-line
  }, []);
  const renderTitle = (
    <h2 className="mb-small">{texts.links.overeniaPrehlady}</h2>
  );
  const renderDescription = (
    <SafeHtml
      className="mb-large"
      html={texts.overeniaPrehlady.description}
      wrapper="div"
    />
  );
  const renderSubtitle = <h5>{texts.overeniaPrehlady.subtitle}</h5>;
  const renderBaseFilter = (
    <div className={classes.filterExportWrapper}>
      <RadioGroup
        className={classes.horizontalFilter}
        id="radio-group-overenia-prehlady"
        onChange={(e) => {
          const { value } = e.target as HTMLInputElement;
          setBaseFilterValue(value);
          navigate(
            `${routes.overeniaPrehlady}/${
              value === baseFilter[0].value ? 'lekari' : 'ambulancie'
            }`,
          );
        }}
      >
        {texts.overeniaPrehlady.baseFilter.map((b, key) => (
          <Radio
            key={`overenia-prehlady-baseFilter-${key}`}
            id={`overenia-prehlady-baseFilter-${key}`}
            isChecked={baseFilterValue === b.value}
            name="overenia-prehlady-baseFilter"
            value={b.value}
          >
            {b.text}
          </Radio>
        ))}
      </RadioGroup>
    </div>
  );
  return (
    <StaticMenuLayout
      contentHeader={
        <ContentHeader flex>
          <Container>
            <Breadcrumbs
              items={[
                { name: texts.title, route: routes.profil },
                { name: texts.overeniaPrehlady.title },
              ]}
            />
            {renderTitle}
            {renderDescription}
          </Container>
        </ContentHeader>
      }
      contentWrapperId="dlek-profile"
      hideEPNotifications
    >
      <CardBubble>
        {renderSubtitle}
        {renderBaseFilter}
        <Outlet />
      </CardBubble>
    </StaticMenuLayout>
  );
};

export default OvereniaPrehlady;
