import { Icon, Tooltip, color } from '@dovera/design-system';
import useStyles from '../../styles/detail.styles';
import { cx } from '../../../../utils/exports';

interface Props {
  title: string;
  tooltip: string;
  value: string;
}

const AdditionalText = ({ title, tooltip, value }: Props) => {
  const classes = useStyles();
  return (
    <div className={cx(classes.graphInfo, 'content-center-vert')}>
      <span>{title}</span> <span className="text-color-black">{value}</span>
      {tooltip && (
        <Tooltip dialog={tooltip}>
          <Icon color={color('grey', 300)} name="info" size="small" />
        </Tooltip>
      )}
    </div>
  );
};

export default AdditionalText;
