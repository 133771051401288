import { LinkAttributes } from '../../../types/jsonObjects.types';
import { ButtonLink, Link as DDSLink } from '@dovera/design-system';
import useStyles from '../styles';
import { cx } from '../../../utils/exports';
import { useModalContext } from '../../../hooks/useModalContext';
import { safeString } from '../../../utils/strings.utils';
import { useNavigate } from 'react-router';
import { createQueryParams } from '../../../utils/api.utils';

const SpecificLink = ({ attributes }: LinkAttributes) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = () => {
    if (attributes.specific?.some((s) => s.key === 'url')) {
      const params: Record<string, string> = {};
      let url = '';

      attributes.specific.forEach((s) => {
        if (s.key === 'url') url = s.value;
        else params[s.key] = s.value;
      });

      navigate(
        `${url}${createQueryParams({
          withoutEmpty: true,
          queryParams: {
            ...params,
          },
        })}`,
      );
    }
  };
  if (attributes.specific?.some((s) => s.key === 'url')) {
    return (
      <ButtonLink
        className={cx(classes.link, attributes.display)}
        onClick={handleClick}
      >
        {attributes.text}
      </ButtonLink>
    );
  }

  return null;
};

const Link = (props: LinkAttributes) => {
  const { attributes } = props;
  const classes = useStyles();
  const modalContext = useModalContext();
  const navigate = useNavigate();

  if (attributes.link) {
    if (attributes.link.startsWith('http')) {
      return (
        <DDSLink href={attributes.link} placeholder="" target="_blank">
          {attributes.text}
        </DDSLink>
      );
    }
    return (
      <ButtonLink
        className={cx(classes.link, attributes.display)}
        onClick={() => navigate(attributes.link || '')}
      >
        {attributes.text}
      </ButtonLink>
    );
  }

  if (attributes.modalLinkId)
    return (
      <ButtonLink
        className={cx(classes.link, attributes.display)}
        onClick={() =>
          modalContext.showModal(safeString(attributes.modalLinkId))
        }
      >
        {attributes.text}
      </ButtonLink>
    );

  return <SpecificLink {...props} />;
};

export default Link;
