import { Icon, color } from '@dovera/design-system';
import { cx } from '../../../../utils/exports';
import useStyles from '../../Proposals.styles';
import { ReactNode } from 'react';

type InfoRowType = {
  icon?: ReactNode;
  iconName?: 'check' | 'collection';
  label?: string;
  value: string | ReactNode;
};

const InfoRow = ({ icon, iconName, label, value }: InfoRowType) => {
  const classes = useStyles({ proposalType: 'KN' });
  return (
    <div className={cx(classes.proposalHeaderRow, 'mb-small')}>
      {icon ||
        (iconName && (
          <Icon
            color={color(iconName === 'check' ? 'success' : 'black', 600)}
            name={iconName}
            size="medium"
          />
        ))}
      {label && (
        <span
          className={cx('text-space-half-right', 'text-semibold')}
          style={{
            color: color(iconName === 'check' ? 'success' : 'black', 600),
          }}
        >
          {`${label}:`}
        </span>
      )}
      <span
        className="text-semibold"
        style={{
          color: color(iconName === 'check' ? 'success' : 'black', 600),
        }}
      >
        {value}
      </span>
    </div>
  );
};

export default InfoRow;
