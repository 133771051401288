import useStyles from '../../Profil.styles';
import { useField, useForm } from 'react-final-form-hooks';
import strings from '../../../../constants/strings';
import { ButtonSecondary, Input, Notification } from '@dovera/design-system';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';
import regex from '../../../../constants/regex';
import { validationMsg } from '../../../../utils/form.utils';
import { VALIDATION } from '../../../../types/validation.types';

const texts = strings.profile.loginDetails;

const validateForm = (values: { [x: string]: any }, username: string) => {
  const errors: any = {};
  if (!values.currentLogin)
    errors.currentLogin = validationMsg(VALIDATION.LoginEmpty);
  if (!values.newLogin)
    errors.newLogin = validationMsg(VALIDATION.LoginNewEmpty);
  if (!errors.currentLogin && values.currentLogin !== username)
    errors.currentLogin = validationMsg(VALIDATION.LoginIncorrect);
  if (!errors.newLogin && !regex.LOGIN_CHARACTER.test(values.newLogin))
    errors.newLogin = validationMsg(VALIDATION.LoginRegex);
  if (!errors.newLogin && !regex.LOGIN_LENGTH.test(values.newLogin))
    errors.newLogin = validationMsg(VALIDATION.LoginLength);

  return errors;
};

interface Props {
  error: string | null;
  isLoading: boolean;
  newLoginError?: string | null;
  // eslint-disable-next-line
  onSubmit: (data?: any) => void;
  success: boolean | null;
  username: string;
}

const FormLogin = ({
  error,
  isLoading,
  newLoginError,
  onSubmit,
  success,
  username,
}: Props) => {
  const classes = useStyles();
  const { form, handleSubmit } = useForm({
    onSubmit: (values: any) => onSubmit(values),
    initialValues: {
      currentLogin: '',
      newLogin: '',
    },
    validate: (valuesToValid) => validateForm(valuesToValid, username),
  });

  const currentLoginField = useField('currentLogin', form);
  const newLoginField = useField('newLogin', form);

  const renderForm = (
    <form
      data-testid="profile--login-form"
      id="profile--login-form"
      onSubmit={handleSubmit}
    >
      <Input
        {...currentLoginField.input}
        crossOrigin
        error={
          currentLoginField.meta.touched &&
          currentLoginField.meta.error && (
            <SafeHtml html={currentLoginField.meta.error} wrapper="span" />
          )
        }
        id="form-login--currentLogin"
        isDisabled={error === VALIDATION.MaxAttemptsLogin}
        isRequired
        label={texts.labels.currentLogin}
        role="textbox"
      />
      <Input
        {...newLoginField.input}
        crossOrigin
        error={
          (newLoginError && (
            <SafeHtml html={validationMsg(newLoginError)} wrapper="span" />
          )) ||
          (newLoginField.meta.touched && newLoginField.meta.error && (
            <SafeHtml html={newLoginField.meta.error} wrapper="span" />
          ))
        }
        id="form-login--newLogin"
        isDisabled={error === VALIDATION.MaxAttemptsLogin}
        isRequired
        label={texts.labels.newLogin}
        role="textbox"
      />
      {error && (
        <div className={classes.notification}>
          <Notification
            message={<SafeHtml html={validationMsg(error)} wrapper="span" />}
            variant={error === VALIDATION.InProgress ? 'blue' : 'error'}
          />
        </div>
      )}
      {success && (
        <div className={classes.notification}>
          <Notification
            message={strings.form.successFormDefault}
            variant="success"
          />
        </div>
      )}
      {error !== VALIDATION.MaxAttemptsLogin && (
        <ButtonSecondary isLoading={isLoading} role="button" submit>
          {texts.labels.button}
        </ButtonSecondary>
      )}
    </form>
  );
  return (
    <div className={classes.form}>
      <h5 className="mb">{texts.subtitleLogin}</h5>
      {renderForm}
    </div>
  );
};

export default FormLogin;
