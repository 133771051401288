import { ReactNode } from 'react';
import { Button, Illustration } from '@dovera/design-system';
import useStyles from './NoDataIllustration.styles';
import SafeHtml from '../SafeHtml/SafeHtml';
import { cx } from '../../utils/exports';

interface Props {
  button?: string;
  description?: string;
  illustration?: 'box' | 'error' | 'woman' | 'technical';
  onButtonClick?: () => void;
  reloadOnClick?: boolean;
  success?: boolean;
  successDocs?: boolean;
  title: string | ReactNode;
}

const NoDataIllustration = ({
  button,
  description,
  illustration,
  onButtonClick,
  reloadOnClick,
  success,
  successDocs,
  title,
}: Props) => {
  const classes = useStyles();
  const getIllustration = (): ReactNode => {
    if (illustration === 'error')
      return (
        <Illustration
          height={256}
          name="large_error_state"
          size="large"
          width={256}
        />
      );
    return (
      <Illustration
        height={256}
        name={
          illustration === 'technical'
            ? 'large_warning_state'
            : 'large_empty_state'
        }
        size="large"
        width={256}
      />
    );
  };
  if ((success || successDocs) && title)
    return (
      <div className={classes.rootSuccess}>
        <div className="d-flex">
          <h6>{title}</h6>
        </div>
        <Illustration name="large_sucess_state" />
      </div>
    );
  return (
    <div
      className={cx(
        classes.root,
        illustration === 'technical' && classes.warning,
        illustration === 'error' && classes.error,
      )}
    >
      {getIllustration()}
      <SafeHtml className="mb-small" html={title} wrapper="h4" />
      {description && (
        <SafeHtml className="mb-small" html={description} wrapper="div" />
      )}
      {button && reloadOnClick && (
        <Button onClick={() => window.location.reload()} size="s">
          {button}
        </Button>
      )}
      {button && onButtonClick && (
        <Button onClick={() => onButtonClick()} size="s">
          {button}
        </Button>
      )}
    </div>
  );
};

export default NoDataIllustration;
