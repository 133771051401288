import { ReactNode } from 'react';
import useStyles from './ContentHeader.styles';
import { cx } from '../../utils/exports';

interface Props {
  children?: ReactNode;
  fixed?: boolean;
  flex?: boolean;
  illustration?: ReactNode;
  padBottom?: boolean;
}

const ContentHeader = ({
  children,
  fixed,
  flex,
  illustration,
  padBottom,
}: Props) => {
  const classes = useStyles({ padBottom: padBottom || false, fixed, flex });
  return (
    <div className={cx(classes.rootExtra)}>
      {children}
      {illustration}
    </div>
  );
};

export default ContentHeader;
