import { color, fontWeight, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  pageSection: {
    paddingTop: '2rem',
    paddingBottom: 0,
    '& p': {
      maxWidth: '100%',
    },
  },
  graphTitle: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      marginLeft: getRem(4),
    },
  },
  graphInfoGridWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > div:first-child': {
      marginRight: getRem(16),
    },
  },
  graphInfo: {
    fontSize: 14,
    color: color('grey', 500),
    '& > span:first-child': {
      marginTop: getRem(3),
      marginRight: getRem(8),
    },
    '& > span:not(:first-child)': {
      fontSize: 16,
      marginRight: 8,
      color: color('black'),
      fontWeight: fontWeight.semiBold,
    },
  },
  graphTitleValue: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: getRem(48),
    '& > h4': {
      marginBottom: `0 !important`,
    },
    '& > div': {
      display: 'flex',
      '& > div:last-child': {
        marginLeft: getRem(16),
      },
    },
  },
  plnenieBar: {
    '& > .bar__item': {
      color: color('grey', 300),
      fontSize: '0.875rem',
    },
    paddingBottom: 30,
  },
  formulaAccordionContainer: {
    textAlign: 'center',
  },
  modalSuperdetailFormula: {
    width: '100%',
    maxWidth: 1200,
    height: '100vh',
    maxHeight: '100vh',
    '& .modal__content': {
      overflowY: 'auto',
    },
  },
  formulaContainer: {
    margin: '8px 0 24px 0',
    textAlign: 'center',
  },
  htmlContent: {
    '& p': {
      fontSize: getRem(16),
    },
  },
});
