import { Aktualita } from '../../../types/poskytovatel.types';
import { Card, Grid, GridCol, Icon, Link } from '@dovera/design-system';
import { formatDate } from '../../../utils/date.utils';
import useStyles from './DoveraNews.styles';
import strings from '../../../constants/strings';
import DoveraNewsSkeleton from './DoveraNewsSkeleton';

const texts = strings.dashboard.news;

interface Props {
  isLoading: boolean;
  news: Aktualita[];
}

const DoveraNews = ({ isLoading, news }: Props) => {
  const classes = useStyles();
  const renderNews = news && (
    <Grid>
      {news
        .filter((n, key) => key < 2)
        .map((n) => (
          <GridCol key={`news-record--${n.id}`} size={{ m: 6, s: 12 }}>
            <Link
              className={classes.actuality}
              href={n.url}
              placeholder
              target="_blank"
            >
              <span className={classes.date}>{formatDate(n.datum)}</span>
              {n.nazov}
            </Link>
          </GridCol>
        ))}
    </Grid>
  );
  const renderShowAll = (
    <Link
      className="d-flex"
      href="https://www.dovera.sk/lekar/aktuality"
      placeholder
      target="_blank"
    >
      {texts.showAll}
      <Icon name="open" size="medium" />
    </Link>
  );
  return isLoading ? (
    <DoveraNewsSkeleton />
  ) : (
    <Card className={classes.root} isBordered>
      <h3 className={classes.title}>
        <span>{texts.title}</span>
        {renderShowAll}
      </h3>
      {renderNews}
    </Card>
  );
};

export default DoveraNews;
