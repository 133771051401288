import { useState } from 'react';
import { ZmluvnyPristroj } from '../../../types/profil.types';
import { Pagination, TableApp } from '@dovera/design-system';
import strings from '../../../constants/strings';

const { labels } = strings.profile.devices;

interface Props {
  devices: ZmluvnyPristroj[][];
}

const Table = ({ devices }: Props) => {
  const [page, setPage] = useState(0);
  return (
    <TableApp
      caption=""
      className="table-caption--hide"
      columns={[
        {
          accessor: 'col1',
          Header: labels.type,
        },
        {
          accessor: 'col2',
          Header: labels.name,
        },
        {
          accessor: 'col3',
          Header: labels.sn,
        },
      ]}
      data={devices[page].map((d) => ({
        col1: <div>{d.kodTypPristroj || strings.undefined}</div>,
        col2: <div>{d.nazov || strings.undefined}</div>,
        col3: <div>{d.cislo || strings.undefined}</div>,
      }))}
      // @ts-ignore
      pagination={
        <Pagination
          currentPage={page + 1}
          onChange={(page) => setPage(page - 1)}
          totalPages={devices.length}
        />
      }
    />
  );
};

export default Table;
