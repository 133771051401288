import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { ProposalDetailQueries } from '../../../types/spaProposals.types';
import { hasSection } from '../../../utils/poskytovatel.utils';
import { EPSections } from '../../../constants/systemConstants';
import { useAppDispatch } from '../../../hooks/useStore';
import {
  getProposalsList,
  useFetchProposalsList,
} from '../../../api/spaProposals';
import { createViewUrl, scrollToTop } from '../../../utils/app.utils';
import { useNavigate } from 'react-router';
import routes, { navrhyRoutes } from '../../../routes';
import { useQuery } from '../../../hooks/useQuery';
import { formatDate } from '../../../utils/date.utils';
import { NoDataIllustration } from '../../../components/EmptyState';
import {
  Button,
  ButtonLink,
  Icon,
  Notification,
  Pagination,
  TableApp,
} from '@dovera/design-system';
import TablePreloaderSkeleton from '../../../components/TablePreloaderSkeleton/TablePreloaderSkeleton';
import Filter from './Filter/Filter';
import strings from '../../../constants/strings';
import {
  PROPOSAL_TYPES,
  ProposalsFilter,
} from '../../../types/proposals.types';
import { useField, useForm } from 'react-final-form-hooks';
import moment from 'moment';
import { TABLE_PAGINATION } from '../../../constants/misc';
import {
  formatNameStr,
  hotjarMasking,
  stringWithSpaces,
} from '../../../utils/strings.utils';
import useStyles from '../Proposals.styles';
import { useDebounce } from '../../../hooks/useDebounce';
import { TruncateText } from '../../../components/TruncateText';
import { getStateProperties } from '../../../utils/proposals.utils';
import ColoredLabel from '../../../components/ColoredLabel/ColoredLabel';

const texts = strings.proposals.spa.list.submittedProposals;

const BLO_IDENTIFIER = 'refnum';

const { emptyState } = strings.proposals.spa.list;

interface Props {
  shouldReload: boolean;
}

const TableKN = ({ shouldReload }: Props) => {
  const classes = useStyles({ proposalType: 'KN' });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [filter, setFilter] = useState<ProposalsFilter | undefined>(undefined);
  const isLoadedVZ = useSelector(
    (state: RootState) => state.poskytovatel.isLoaded,
  );
  const [actualPage, setActualPage] = useState(0);

  const { form } = useForm({
    onSubmit: () => {},
    initialValues: {
      search: '',
      state: 'all',
      validityFrom: '',
      validityTo: '',
    },
  });
  const searchField = useField('search', form);
  const stateField = useField('state', form);
  const validityFromField = useField('validityFrom', form);
  const validityToField = useField('validityTo', form);

  const searchDebounced = useDebounce(searchField.input.value, 1000);

  const { data, isError, isFetching, refetch } = useFetchProposalsList(
    {
      filter,
      limit: TABLE_PAGINATION,
      offset: actualPage ? actualPage * TABLE_PAGINATION : 0,
    },
    {
      enabled: initialLoaded,
    },
  );

  useEffect(() => {
    setTimeout(() => {
      if (!query.get(BLO_IDENTIFIER)) setInitialLoaded(true);
    }, 1000);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setActualPage(0);
    setFilter({
      search: searchDebounced || undefined,
      proposalState:
        stateField.input.value && stateField.input.value !== 'all'
          ? stateField.input.value
          : undefined,
      dateFrom: moment(validityFromField.input.value).isValid()
        ? validityFromField.input.value
        : undefined,
      dateTo: moment(validityToField.input.value).isValid()
        ? validityToField.input.value
        : undefined,
    });
  }, [
    searchDebounced,
    stateField.input.value,
    validityFromField.input.value,
    validityToField.input.value,
  ]);

  useEffect(() => {
    if (
      !hasSection(EPSections.Kn, 'Navrhy') &&
      !hasSection(EPSections.Ln, 'Navrhy') &&
      isLoadedVZ
    ) {
      navigate(routes.dashboard);
    }
    // eslint-disable-next-line
  }, [dispatch, data?.proposals?.length]);
  useEffect(() => {
    if (actualPage) {
      scrollToTop();
    }
  }, [actualPage]);
  useEffect(() => {
    if (shouldReload) {
      navigate(
        `${navrhyRoutes.zoznamPodanychNavrhov}?typ=${PROPOSAL_TYPES.SPA}`,
      );
      refetch();
    }
  }, [navigate, refetch, shouldReload]);
  useEffect(() => {
    /**
     * Redirect z BLO
     */
    if (query.get(BLO_IDENTIFIER)) {
      const proposalNumber = query.get(BLO_IDENTIFIER) || '';
      getProposalsList({
        limit: 1,
        offset: 0,
        filter: { search: proposalNumber },
      })
        .then((response) => {
          const queries: ProposalDetailQueries = {
            cisloNavrhu: proposalNumber,
            id: response.proposals[0]?.proposalId,
            typ: PROPOSAL_TYPES.SPA,
          };
          if (response.count)
            navigate(
              createViewUrl(navrhyRoutes.zoznamPodanychNavrhov, queries),
            );
        })
        .finally(() => setInitialLoaded(true));
    }
    // eslint-disable-next-line
  }, [query, data]);

  const renderFilter = (
    <Filter
      search={searchField}
      state={stateField}
      type={PROPOSAL_TYPES.SPA}
      validityFrom={validityFromField}
      validityTo={validityToField}
    />
  );

  const renderTable = (
    <TableApp
      caption=""
      className={classes.listTable}
      columns={[
        {
          accessor: 'col1',
          Header: texts.labels.proposalNumber,
        },
        {
          accessor: 'col2',
          Header: texts.labels.insuree,
        },
        {
          accessor: 'col3',
          Header: texts.labels.doctorCode,
        },
        {
          accessor: 'col4',
          Header: texts.labels.validity,
        },
        {
          accessor: 'col5',
          Header: texts.labels.state,
        },
        {
          accessor: 'col6',
          Header: '',
        },
      ]}
      data={
        data?.proposals
          ? data?.proposals?.map((d) => {
              const stateProperties = getStateProperties(d.state);
              return {
                col1: (
                  <Button
                    className="text-normal no-mrg no-pad"
                    onClick={() =>
                      navigate(
                        navrhyRoutes.detail({
                          proposalId: d.proposalId,
                          proposalNumber: d.proposalNumber,
                          type: PROPOSAL_TYPES.SPA,
                        }),
                      )
                    }
                    type="link-secondary"
                  >
                    {d.proposalNumber}
                  </Button>
                ),
                col2: (
                  <>
                    {hotjarMasking(d.insuree.insureeId, 'b', 'd-block')}
                    <TruncateText
                      classes="d-block fs-14 text-color-grey-light"
                      hjMask
                      text={formatNameStr(
                        `${d.insuree.personName} ${d.insuree.personSurname}`,
                      )}
                    />
                  </>
                ),
                col3: (
                  <>
                    {hotjarMasking(d.doctor.code, 'b', 'd-block')}
                    <TruncateText
                      classes="d-block fs-14 text-color-grey-light"
                      hjMask
                      text={formatNameStr(
                        stringWithSpaces([
                          d.doctor.titleBefore || '',
                          d.doctor.personName,
                          `${d.doctor.personSurname}${d.doctor.titleAfter ? ',' : ''}`,
                          d.doctor.titleAfter || '',
                        ]),
                      )}
                    />
                  </>
                ),
                col4: (
                  <span>
                    {formatDate(d.proposalValidityFrom)}
                    <br />
                    {formatDate(d.proposalValidityTo)}
                  </span>
                ),
                col5: (
                  <div className="bolder-label">
                    <Icon
                      color={stateProperties.color}
                      name={stateProperties.name}
                      size="medium"
                    />
                    <ColoredLabel
                      className="text-space-half-left"
                      color={stateProperties.color}
                    >
                      {stateProperties.text}
                    </ColoredLabel>
                  </div>
                ),
                col6: (
                  <ButtonLink
                    className="no-mrg no-pad"
                    onClick={() =>
                      navigate(
                        navrhyRoutes.detail({
                          proposalId: d.proposalId,
                          proposalNumber: d.proposalNumber,
                          type: PROPOSAL_TYPES.SPA,
                        }),
                      )
                    }
                  >
                    Detail
                  </ButtonLink>
                ),
              };
            })
          : []
      }
      hiddenCaption
      // @ts-ignore
      pagination={
        <Pagination
          currentPage={actualPage + 1}
          onChange={(pageNumber) => setActualPage(pageNumber - 1)}
          totalPages={Math.ceil(Number(data?.count) / TABLE_PAGINATION)}
        />
      }
    />
  );

  const renderEmptyState = !data?.count && (
    <NoDataIllustration title={emptyState} />
  );
  if (isFetching || !initialLoaded)
    return (
      <>
        {initialLoaded && renderFilter}
        <TablePreloaderSkeleton columns={6} hasFilter={!initialLoaded} />
      </>
    );
  if (isError)
    return <Notification message={strings.defaultError} variant="error" />;
  return (
    <>
      {!isError && renderFilter}
      {!!data?.count && renderTable}
      {renderEmptyState}
    </>
  );
};

export default TableKN;
