import { Notification, Tag, Textarea } from '@dovera/design-system';
import useStyles from '../../../../Proposals.styles';
import useStylesApp from '../../../../../../App.styles';
import SafeHtml from '../../../../../../components/SafeHtml/SafeHtml';
import strings from '../../../../../../constants/strings';
import RemainingChars from '../../../../../../components/RemainingChars/RemainingChars';
import { MAX_LENGTH_TEXTAREA } from '../../../../../../constants/proposals';
import { HorizontalDivider } from '../../../../../../components/ContentSeparator';
import { Upload } from '../../../../../../components/UploadForm';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../rootReducer';
import { useAppDispatch } from '../../../../../../hooks/useStore';
import { storeStep3 } from '../../../../../../slices/drugProposals.slice';
import { useState } from 'react';
import { Dokument } from '../../../../../../types/models/Dokument';
import { SystemoveParametreNazovConfigu } from '../../../../../../types/api/poskytovatel';
import { FieldElementIdEnum } from '../../../../../../types/drugProposals.types';
import { scrollToField } from '../../../../../../utils/form.utils';
import { getEpicrisisMinLength } from '../../../../../../utils/proposals.utils';

interface Props {
  epicrisisField: any;
  onUploadError: (isErr: boolean) => void;
  onUploadFiles: (files: Dokument[] | null) => void;
  reasonField: any;
  storedValues: {
    epicrisis: string;
    reason: string;
  };
}

const texts = strings.proposals.drugs.new;

const ReasonAttachment = ({
  epicrisisField,
  onUploadError,
  onUploadFiles,
  reasonField,
  storedValues,
}: Props) => {
  const classes = useStyles({ proposalType: 'LN' });
  const [isUploadedFiles, setIsUploadedFiles] = useState(false);
  const minLength = getEpicrisisMinLength(isUploadedFiles);
  const dispatch = useAppDispatch();
  const classesApp = useStylesApp();
  const {
    app: {
      systemoveParametre: { parameters },
    },
    drugProposals: {
      new: {
        data: {
          formData: { data },
        },
      },
    },
  } = useSelector((state: RootState) => state);
  const isValidationError =
    ((epicrisisField.meta.touched && epicrisisField.meta.error) ||
      (reasonField.meta.touched && reasonField.meta.error)) &&
    (!epicrisisField.input.value || !reasonField.input.value) &&
    !isUploadedFiles;
  const renderEpicrisis = (
    <Textarea
      {...epicrisisField.input}
      className={classesApp.textarea}
      error={
        epicrisisField.meta.touched &&
        epicrisisField.meta.error && (
          <SafeHtml html={epicrisisField.meta.error} wrapper="span" />
        )
      }
      help={
        <RemainingChars
          alignWithLabel
          formHelper=" "
          maxLength={MAX_LENGTH_TEXTAREA}
          valueLength={epicrisisField.input.value?.length}
        />
      }
      id={FieldElementIdEnum.Epicrisis}
      label={texts.labels.epicrisis}
      maxLength={MAX_LENGTH_TEXTAREA}
      minLength={minLength}
      onBlur={(e) => {
        epicrisisField.input.onBlur();
        if (storedValues.epicrisis !== e.target.value)
          dispatch(storeStep3({ epicrisis: e.target.value }));
      }}
      onFocus={() => {
        epicrisisField.input.onFocus();
        scrollToField(FieldElementIdEnum.Epicrisis);
      }}
    />
  );
  const renderReason = (
    <Textarea
      {...reasonField.input}
      className={classesApp.textarea}
      error={
        reasonField.meta.touched &&
        reasonField.meta.error && (
          <SafeHtml html={reasonField.meta.error} wrapper="span" />
        )
      }
      help={
        <RemainingChars
          alignWithLabel
          formHelper={
            <div className={classes.tags}>
              {data?.udajParLiekNavrhuCnp?.zdovodnenieLiecby?.map((o, key) => (
                <Tag key={`reason-step3--${key}`} variant="outline">
                  {o}
                </Tag>
              ))}
            </div>
          }
          maxLength={MAX_LENGTH_TEXTAREA}
          valueLength={reasonField.input.value?.length}
        />
      }
      id={FieldElementIdEnum.Reason}
      label={texts.labels.reason}
      maxLength={MAX_LENGTH_TEXTAREA}
      minLength={minLength}
      onBlur={(e) => {
        reasonField.input.onBlur();
        if (storedValues.reason !== e.target.value)
          dispatch(storeStep3({ reason: e.target.value }));
      }}
      onFocus={() => {
        reasonField.input.onFocus();
        scrollToField(FieldElementIdEnum.Reason);
      }}
    />
  );
  const renderUploadForm = (
    <Upload
      allowedExtensions={
        parameters?.[SystemoveParametreNazovConfigu.navrhy]
          ?.find((c) => c.nazov === 'PovoleneTypyPrilohLN')
          ?.hodnota?.replace(/\s+/g, '')
          ?.toLowerCase()
          ?.split(';') || []
      }
      id={FieldElementIdEnum.UploadAttachments}
      maxFileSize={Number(
        parameters?.[SystemoveParametreNazovConfigu.navrhy]?.find(
          (c) => c.nazov === 'MaximalnaVelkostPrilohSpoluLN',
        )?.hodnota,
      )}
      onChange={(files) => {
        onUploadFiles(files);
        scrollToField(FieldElementIdEnum.UploadAttachments);
        setIsUploadedFiles(!!files?.length);
      }}
      // eslint-disable-next-line
      onUploadError={onUploadError}
      validationError={isValidationError ? ' ' : undefined}
    />
  );
  const renderErrNtf = isValidationError && (
    <Notification
      message={<SafeHtml html={texts.notifications.error.epicrisisReason} />}
      variant="error"
    />
  );
  return (
    <div className={classes.drugsWrapper}>
      {renderEpicrisis}
      {renderReason}
      <HorizontalDivider className="mb-large" text="alebo" />
      {renderUploadForm}
      {renderErrNtf}
    </div>
  );
};

export default ReasonAttachment;
