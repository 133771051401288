import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { dajMojeFaktury } from '../../../api/zuctovanie';
import { NoDataIllustration } from '../../../components/EmptyState';
import TablePreloaderSkeleton from '../../../components/TablePreloaderSkeleton/TablePreloaderSkeleton';
import strings from '../../../constants/strings';
import { useAppDispatch } from '../../../hooks/useStore';
import { RootState } from '../../../rootReducer';
import { resetTableOrder } from '../../../slices/table.slice';
import { ErrorServiceResponse, LoadingState } from '../../../types';
import {
  DajMojeFakturyQueries,
  Faktura,
  FilterInvoiceValues,
  InvoicesState,
} from '../../../types/zuctovanie.types';
import { groupByItem } from '../../../utils/array.utils';
import { translateErrorCode } from '../../../utils/validation.utils';
import Filter from './Filter/Filter';
import Table from './Table/Table';
import CardBubble from '../../../components/CardBubble/CardBubble';
import useStyles from '../styles';

const initialState: InvoicesState = {
  data: null,
  dataState: LoadingState.none,
  error: null,
  filteredData: [],
};

const Faktury = () => {
  const classes = useStyles();
  const [state, setState] = useState<InvoicesState>(initialState);
  const { filter } = useSelector(
    (state: RootState) => state.zuctovanie.invoices,
  );
  const { prevRoute } = useSelector((state: RootState) => state.app);
  const dispatch = useAppDispatch();

  const filterInvoices = (invoices: Faktura[], vals: FilterInvoiceValues) =>
    invoices?.filter(
      (f) =>
        (!vals.search || f.vs?.includes(vals.search)) &&
        (!vals.state || f.stavFaktury?.includes(vals.state)) &&
        (!vals.zuc20 ||
          (f.zuctovanie2 && vals.zuc20 === 'Y') ||
          (!f.zuctovanie2 && vals.zuc20 === 'N')) &&
        (!vals.paperInvoice ||
          (f.papierovaFaktura && vals.paperInvoice === 'Y') ||
          (!f.papierovaFaktura && vals.paperInvoice === 'N')) &&
        (!vals.invoiceType || f.typFaktury === vals.invoiceType) &&
        (!vals.kodPracoviska || f.kodPracoviska === vals.kodPracoviska),
    );

  const filterData = useCallback(
    ({
      invoicesData,
      values,
    }: {
      invoicesData?: Faktura[];
      values?: FilterInvoiceValues;
    }) => {
      let filteredData: Faktura[] = [];
      const vals: FilterInvoiceValues = values || filter;
      const { data } = state;
      const invoices = invoicesData || data;
      if (invoicesData) filteredData = invoicesData;
      if (invoices?.length && values) {
        filteredData = filterInvoices(invoices, vals);
      }
      setState({
        ...initialState,
        data: invoices || [],
        dataState: LoadingState.filled,
        filteredData: groupByItem(filteredData, 'cisloFaktury'),
      });
    },
    [filter, state],
  );
  const getInvoices = useCallback(
    (periods: number[]) => {
      setState({ ...initialState, dataState: LoadingState.fetching });
      const queries: DajMojeFakturyQueries = {
        obdobia: periods,
      };
      dajMojeFaktury(queries)
        .then((resp) => {
          dispatch(resetTableOrder());
          filterData({ invoicesData: resp.faktury, values: filter });
        })
        .catch((err: ErrorServiceResponse) => {
          setState({
            ...initialState,
            dataState: LoadingState.error,
            error: translateErrorCode(err?.response?.data.kod),
          });
        });
    },
    // eslint-disable-next-line
    [filter, prevRoute],
  );

  useEffect(() => {
    if (filter.periods) getInvoices(filter.periods);
    // eslint-disable-next-line
  }, [filter.periods]);

  const renderTable = state.dataState === LoadingState.filled &&
    !_.isEmpty(state.filteredData) && <Table data={state} />;
  const renderContent = () => {
    if (state.dataState === LoadingState.error && state.error)
      return <NoDataIllustration title={state.error} />;
    if (
      state.dataState === LoadingState.filled &&
      _.isEmpty(state.filteredData)
    )
      return <NoDataIllustration title={strings.filterNoDataDefault} />;
    return [LoadingState.fetching, LoadingState.none].includes(
      state.dataState,
    ) ? (
      <TablePreloaderSkeleton columns={6} />
    ) : (
      renderTable
    );
  };
  return (
    <CardBubble className={classes.cardBubble}>
      <Filter onChange={(values) => filterData({ values })} />
      {renderContent()}
    </CardBubble>
  );
};

export default Faktury;
