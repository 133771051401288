import { Skeleton, TableApp } from '@dovera/design-system';
import useStyles from './TablePreloaderSkeleton.styles';
import { cx } from '../../utils/exports';

interface Props {
  columns: number;
  filterCols?: number;
  hasFilter?: boolean;
  rows?: number;
}

const TablePreloaderSkeleton = ({
  columns,
  filterCols,
  hasFilter,
  rows,
}: Props) => {
  const classes = useStyles();
  const rowsCount = rows && rows <= 10 ? rows : 5;
  const getTableCols = (): { Header: string; accessor: string }[] => {
    const cols: { Header: string; accessor: string }[] = [];
    for (let i = 0; i < columns; i++) {
      cols.push({
        Header: '',
        accessor: `col${i + 1}`,
      });
    }
    return cols;
  };
  const getTableData = () => {
    const r = rowsCount;
    const data: any[] = [];
    for (let i = 0; i < r; i++) {
      const obj: any = {};
      getTableCols().forEach((c) => {
        obj[`${c.accessor}`] = <Skeleton width="90%" />;
      });
      data.push(obj);
    }
    return data;
  };
  const renderFilter = hasFilter && (
    <div className={cx(classes.filter, 'mb-large')}>
      {filterCols ? (
        [...Array(filterCols).keys()].map((i) => (
          <div key={i}>
            <span className="d-block">
              <Skeleton width="60%" />
            </span>
            <Skeleton height={48} width="100%" />
          </div>
        ))
      ) : (
        <>
          <div>
            <span className="d-block">
              <Skeleton width="60%" />
            </span>
            <Skeleton height={48} width="100%" />
          </div>
          <div>
            <span className="d-block">
              <Skeleton width="60%" />
            </span>
            <Skeleton height={48} width="100%" />
          </div>
          <div>
            <span className="d-block">
              <Skeleton width="60%" />
            </span>
            <Skeleton height={48} width="100%" />
          </div>
          <div>
            <span className="d-block">
              <Skeleton width="60%" />
            </span>
            <Skeleton height={48} width="100%" />
          </div>
        </>
      )}
    </div>
  );
  const renderTable = (
    <TableApp
      caption=""
      className="table-caption--hide"
      columns={getTableCols()}
      data={getTableData()}
    />
  );
  return (
    <>
      {renderFilter}
      {renderTable}
    </>
  );
};

export default TablePreloaderSkeleton;
