import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { RootState } from '../../rootReducer';
import routes from '../../routes';
import { Container, Skeleton } from '@dovera/design-system';
import ContentHeader from '../../components/ContentHeader/ContentHeader';
import StaticMenuLayout from '../../layouts/StaticMenuLayout';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import CardBubble from '../../components/CardBubble/CardBubble';
import Tabs from './common/Tabs';
import Filter from './common/Filter';
import JsonObject from '../../components/JsonObject/JsonObject';
import ReportContent from './common/ReportContent';
import { useAppDispatch } from '../../hooks/useStore';
import { setActiveTab } from '../../slices/reports.slice';
import { cx } from '../../utils/exports';
import useStyles from './styles';
import {
  useFetchMenuItems,
  useFetchReportStructure,
} from '../../api/queries/reports';
import { MenuSection, ReportThemes } from '../../types/reports.types';
import { getStrFilter } from '../../utils/virtualBudget';

const VirtualBudget = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [section, setSection] = useState<MenuSection | undefined>(undefined);

  const { kodTypZs, poslednaZmenaOdbornosti } = useSelector(
    (state: RootState) => state.poskytovatel,
  );
  const { activeTabId, filter } = useSelector(
    (state: RootState) => state.reports.virtualBudget,
  );

  const menuItems = useFetchMenuItems({
    expCode: poslednaZmenaOdbornosti,
    healthCareCode: kodTypZs,
    themes: [ReportThemes.VB],
  });

  const { data, isError, isFetching } = useFetchReportStructure({
    reportId: Number(section?.id),
  });

  const handleTabChange = useCallback(
    (id: number) => {
      if (id)
        dispatch(
          setActiveTab({
            tabId: Number(id),
            filter: data?.report.tabs.find((t) => t.id === id)?.filters?.[0],
          }),
        );
    },
    [data?.report.tabs, dispatch],
  );

  const contentHeader = data?.report.contentHeader;
  const title =
    contentHeader?.find((item) => item.type === 'title')?.attributes.text || '';

  const renderHeader = (
    <ContentHeader flex>
      <Container>
        <Breadcrumbs
          items={[
            {
              name: title,
            },
          ]}
        />
        {isFetching ? (
          <div className="d-block">
            <h2 className="mb-small">
              <Skeleton width={400} />
            </h2>
            <Skeleton className="mb-large" width={500} />
          </div>
        ) : (
          <div className={cx(classes.headerWrapper, 'mb-small')}>
            <JsonObject data={data?.report.contentHeader || []} />
          </div>
        )}
      </Container>
    </ContentHeader>
  );

  useEffect(() => {
    dispatch(setActiveTab({ tabId: 0 }));
    if (menuItems.data?.themes) {
      setSection(
        menuItems.data?.themes?.[0]?.sections.find(
          (section) =>
            routes.vbRoute(section.sectionCode) === location.pathname,
        ),
      );
    }
  }, [dispatch, location.pathname, menuItems.data?.themes]);

  useEffect(() => {
    dispatch(setActiveTab({ tabId: Number(data?.report.tabs[0].id) }));
  }, [data?.report.tabs, dispatch]);

  if (!section && !menuItems.isFetching) {
    const alternativeSectionCode =
      menuItems.data?.themes?.[0]?.sections?.[0].sectionCode;
    navigate(
      alternativeSectionCode
        ? routes.vbRoute(alternativeSectionCode)
        : routes.dashboard,
    );
    return null;
  }

  return (
    <StaticMenuLayout
      className={classes.root}
      contentHeader={renderHeader}
      contentWrapperId="dlek-virtual-budget"
      hideEPNotifications
    >
      <CardBubble
        tabs={
          isFetching || !data?.report.tabs.length ? (
            <Skeleton width="70%" />
          ) : (
            <Tabs
              activeTabId={activeTabId}
              onTabChange={handleTabChange}
              tabs={data?.report.tabs}
            />
          )
        }
      >
        <Filter
          filters={
            data?.report.tabs?.find((t) => t.id === activeTabId)?.filters || []
          }
        />
        <ReportContent
          isStructureError={isError}
          reportId={Number(section?.id)}
          strFilter={getStrFilter(activeTabId, data, filter)}
          tabId={activeTabId}
        />
      </CardBubble>
    </StaticMenuLayout>
  );
};

export default VirtualBudget;
