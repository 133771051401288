import useStyles from './styles';

interface Props {
  isDashboard?: boolean;
  withoutEffect?: boolean;
}

const BackgroundWarmEffect = ({ isDashboard, withoutEffect }: Props) => {
  const classes = useStyles();
  if (withoutEffect) return null;
  return (
    <>
      <div className={classes.orangeEffect} />
      {isDashboard && <div className={classes.mintEffect} />}
    </>
  );
};

export default BackgroundWarmEffect;
