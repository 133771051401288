import { Card, CardSection } from '@dovera/design-system';
import { PropsWithChildren, ReactNode } from 'react';
import useStyles from './CardBubble.styles';
import { cx } from '../../utils/exports';

interface Props {
  className?: string;
  id?: string;
  isClickable?: boolean;
  tabs?: ReactNode;
}

const CardBubble = ({
  children,
  className,
  id,
  isClickable,
  tabs,
  ...rest
}: PropsWithChildren<Props>) => {
  const classes = useStyles();

  return (
    <>
      {!!tabs && <div className={classes.tabsWrapper}>{tabs}</div>}

      <Card
        className={cx(className, classes.card)}
        id={id}
        isBordered
        isClickable={isClickable}
        {...rest}
      >
        <CardSection isCondensed>{children}</CardSection>
      </Card>
    </>
  );
};

export default CardBubble;
