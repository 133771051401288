import { Button, Grid, GridCol } from '@dovera/design-system';
import useStyles from '../Dashboard.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import routes from '../../../routes';
import { cx } from '../../../utils/exports';
import { useNavigate } from 'react-router-dom';
import CardBubble from '../../../components/CardBubble/CardBubble';
import strings from '../../../constants/strings';
import CardContent from './CardContent';

const { asoc } = strings.dashboard;

const Contracts = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { isASOC } = useSelector((state: RootState) => state.auth);
  const { zoznamKampaniWidget } = useSelector(
    (state: RootState) => state.zmluvy,
  );

  return zoznamKampaniWidget.stav && zoznamKampaniWidget.nadpis ? (
    <Grid className={cx('mb-large', classes.contracts)}>
      <GridCol size={12}>
        <CardBubble id="contracts-card">
          <CardContent
            className={zoznamKampaniWidget.stav === 'I' ? 'mb-small' : ''}
            description={zoznamKampaniWidget.popis}
            isLoading={zoznamKampaniWidget.isLoading}
            state={zoznamKampaniWidget.stav}
            title={zoznamKampaniWidget.nadpis}
          >
            {zoznamKampaniWidget.linkTlacidla ? (
              <a
                href={zoznamKampaniWidget.linkTlacidla}
                rel="noopener noreferrer"
                target="_blank"
              >
                {zoznamKampaniWidget.textTlacidla}
              </a>
            ) : (
              <Button onClick={() => navigate(routes.zmluvyDodatky)}>
                {zoznamKampaniWidget.textTlacidla}
              </Button>
            )}
          </CardContent>
        </CardBubble>
      </GridCol>
    </Grid>
  ) : (
    isASOC && (
      <Grid className={cx('mb-large', classes.contracts)}>
        <GridCol size={12}>
          <CardBubble id="contracts-card">
            <CardContent
              className={'mb-small'}
              isLoading={zoznamKampaniWidget.isLoading}
              state={zoznamKampaniWidget.stav}
              title={asoc.title}
            />
          </CardBubble>
        </GridCol>
      </Grid>
    )
  );
};

export default Contracts;
