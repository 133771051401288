import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import useScreenWidth from '../../../hooks/screen-width/useScreenWidth';
// @ts-ignore
import DoveraLogo from '../../../assets/dovera-logo.png';
// @ts-ignore
import DoveraLogoSmall from '../../../assets/dovera-logo-24.png';
import { HeaderLogo as HeaderLogoDDS } from '@dovera/design-system';
import useStyles from './HeaderLogo.styles';

interface Props {
  href: LinkProps['to'];
}

const HeaderLogo = ({ href }: Props) => {
  const { isMobile } = useScreenWidth();
  const classes = useStyles();
  return (
    <HeaderLogoDDS
      className={classes.headerlogo}
      href={<Link to={href} />}
      src={isMobile ? DoveraLogoSmall : DoveraLogo}
      srcSet={isMobile ? DoveraLogoSmall : DoveraLogo}
    />
  );
};

export default HeaderLogo;
