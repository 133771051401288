import { color, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  pzsPanelWrapper: {
    marginBottom: getRem(32),
  },
  pzsPanel: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingRight: 0,
    '& img': {
      width: getRem(48),
      height: getRem(48),
      marginRight: getRem(16),
    },
    '& > div': {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '&.open': {
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
  },
  pzsPanelSubjectAccess: {
    display: 'none',
    height: 0,
    marginTop: 0,
    transition: 'height 0.5s ease 0s',
    '&.active': {
      display: 'block',
      marginTop: getRem(24),
      height: '100%',
    },
    '& > h6': {
      width: '100%',
      color: color('primary', 600),
      backgroundColor: color('grey', 100),
      border: `1px solid ${color('grey', 100)}`,
      padding: `${getRem(9)} ${getRem(16)}`,
      fontSize: getRem(18),
      borderRadius: getRem(8),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '& > a': {
      width: '100%',
      display: 'block',
      border: `1px solid ${color('grey', 100)}`,
      fontSize: getRem(18),
      borderRadius: getRem(8),
      padding: `${getRem(9)} ${getRem(16)}`,
      textDecoration: 'none',
    },
  },
});
