import { GetReportStructureResponse } from '../types/reports.types';

export const getStrFilter = (
  activeTabId: number,
  data?: GetReportStructureResponse,
  filter?: {
    [key: string]: string;
  },
): string | undefined => {
  if (!filter || !data?.report.tabs?.length) return undefined;
  return data?.report.tabs?.find((t) => t.id === activeTabId)?.filters?.length
    ? Object.values(filter).join()
    : data?.report.tabs
        ?.find((t) => t.id === activeTabId)
        ?.filters?.[0]?.values?.[0]?.valueId.toString();
};
