import React from 'react';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';
import {
  getMessageAssignedToCheckTypeModal,
  getModalErrorChecks,
} from '../../../../utils/drugProposals.utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import strings from '../../../../constants/strings';
import { Illustration } from '@dovera/design-system';

const texts = strings.proposals.drugs.new.modals.notValid;

const Body = () => {
  const { controls } = useSelector(
    (state: RootState) => state.drugProposals.new.data,
  );

  return (
    <>
      <Illustration height={256} name="large_warning_state" width={256} />
      <SafeHtml className="mb-small" html={texts.title} wrapper="h4" />
      <SafeHtml className="mb" html={texts.description} wrapper="p" />
      <ul className="text-left">
        {getModalErrorChecks(controls?.data).map((i) => (
          <SafeHtml
            key={`drug-control--${i.checkType}`}
            className="mb-small"
            html={getMessageAssignedToCheckTypeModal(i.checkType)}
            wrapper="li"
          />
        ))}
      </ul>
    </>
  );
};

export default Body;
