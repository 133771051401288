import { createUseStyles } from 'react-jss';
import { color } from '../../constants';
import { Status } from '../../types/parameters.types';

interface StylesProps {
  firstMarkValue: number;
  percentage: number;
  secondMarkValue?: number;
  status: Status;
}

export default createUseStyles({
  root: {
    width: '100%',
    paddingBottom: 25,
  },
  baseGraph: {
    background: color('grey', 100),
    borderRadius: 60,
    width: '100%',
    height: 8,
    position: 'relative',
  },
  fillGraph: ({ percentage, status }: StylesProps) => ({
    background: status
      ? color(status === 'success' ? 'primary' : status, 600)
      : '#C5C6C8',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: `${percentage}%`,
    maxWidth: '100%',
    borderTopLeftRadius: 60,
    borderBottomLeftRadius: 60,
    borderTopRightRadius: percentage >= 100 ? 60 : 'auto',
    borderBottomRightRadius: percentage >= 100 ? 60 : 'auto',
  }),
  point1: ({ firstMarkValue }: StylesProps) => ({
    display: firstMarkValue ? 'block' : 'none',
    background: color('black'),
    width: 2,
    height: 20,
    position: 'absolute',
    top: 0,
    left: `${firstMarkValue}%`,
  }),
  point2: ({ secondMarkValue }: StylesProps) => ({
    display: secondMarkValue ? 'block' : 'none',
    background: color('black'),
    width: 2,
    height: 20,
    position: 'absolute',
    top: 0,
    left: `${secondMarkValue}%`,
  }),
  label1: ({ firstMarkValue }: StylesProps) => ({
    borderRadius: 60,
    fontWeight: 'bold',
    fontSize: 14,
    padding: '3px 10px',
    position: 'absolute',
    width: 180,
    marginTop: 20,
    left:
      firstMarkValue > 75
        ? `calc(${firstMarkValue}% - 100px)`
        : `calc(${firstMarkValue}% - 82.5px)`,
    textAlign: 'center',
    color: color('black'),
  }),
  label2: ({ secondMarkValue }: StylesProps) => ({
    borderRadius: 60,
    fontWeight: 'bold',
    fontSize: 14,
    padding: '3px 10px',
    position: 'absolute',
    width: 180,
    marginTop: 20,
    left:
      secondMarkValue && secondMarkValue < 25
        ? `calc(${secondMarkValue}% - 100px)`
        : `calc(${secondMarkValue}% - 82.5px)`,
    textAlign: 'center',
    color: color('black'),
  }),
});
