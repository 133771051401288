import strings from '../../../constants/strings';
import { NoDataIllustration } from '../../../components/EmptyState';
import SkeletonLoaderContent from './SkeletonLoader/SkeletonLoaderContent';
import ContentLN from './Content/ContentLN';
import {
  useFetchDrugsDetail,
  useFetchProposalDocuments,
} from '../../../api/drugProposals';

interface Props {
  proposalId: number;
  proposalNumber: string;
}

const DetailLN = ({ proposalId, proposalNumber }: Props) => {
  const { data, error, isFetching } = useFetchDrugsDetail({
    detailType: 'LN',
    proposalId,
    proposalNumber,
  });

  const documents = useFetchProposalDocuments({ proposalId, proposalNumber });

  const renderError = error && (
    <NoDataIllustration title={strings.technickaChyba} />
  );
  if (isFetching) return <SkeletonLoaderContent />;
  return (
    <>
      {renderError}
      {data && (
        <ContentLN
          detail={data.detail}
          documents={documents}
          information={data.information}
          patient={data.patient}
          proposalDoctor={data.doctor}
          proposalId={proposalId}
        />
      )}
    </>
  );
};

export default DetailLN;
