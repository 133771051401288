// @ts-nocheck
import useStyles from '../Spravy.styles';
import Company from '../../../assets/illustrations/pobocka-dlek.png';
import LogoDZP from '../../../assets/dovera-logo-32.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import {
  DATE_TIME_MESSAGE_FORMAT,
  formatDate,
} from '../../../utils/date.utils';
// @ts-check

interface Props {
  date?: string;
  fromDZP?: boolean;
}

const MessageHeader = ({ date, fromDZP }: Props) => {
  const classes = useStyles({});
  const { nazovZobrazPzs } = useSelector(
    (state: RootState) => state.poskytovatel,
  );
  return (
    <div className={classes.messageHeader}>
      <div>
        <img
          alt="Odosielateľ správy"
          height={32}
          src={fromDZP ? LogoDZP : Company}
          srcSet={fromDZP ? LogoDZP : Company}
          width={32}
        />
      </div>
      <div>
        <b>{!fromDZP ? nazovZobrazPzs : 'DÔVERA'}</b>
        {date && <span>{formatDate(date, DATE_TIME_MESSAGE_FORMAT)}</span>}
      </div>
    </div>
  );
};

export default MessageHeader;
