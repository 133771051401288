import { useEffect, useRef } from 'react';
import { Grid, GridCol } from '@dovera/design-system';
import StaticMenuLayout from '../../layouts/StaticMenuLayout';
import useStyles from './Dashboard.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import {
  fetchAktualityNovinky,
  fetchDataStatutara,
} from '../../slices/poskytovatel.slice';
import routes from '../../routes';
import { fetchZoznamKampaniPreWidget } from '../../slices/zmluvy.slice';
import { showModal } from '../../utils/app.utils';
import DoveraNews from './DoveraNews/DoveraNews';
import { sendDataLayer } from '../../utils/gtm.utils';
import { saveAuthRejection } from '../../api/poskytovatel';
import { useAppDispatch } from '../../hooks/useStore';
import DashboardBanner from '../../components/DashboardBanner/DashboardBanner';
import AuthBanner from '../auth-pzs/components/DashboardBanner/AuthBanner';
import { fetchBanners, hideBanner } from '../../api/baner';
import { FetchBannersPayload } from '../../types/baner.types';
import DashboardNtf from './DashboardNtf/DashboardNtf';
import EPNotifications from '../../components/EPNotifications/EPNotifications';
import ReviewCarousel from './ReviewCarousel/ReviewCarousel';
import { WidgetCurrentPrices } from './WidgetCurrentPrices';
import ParametersIndexBanner from '../parameters/common/IndexBanner';
import SolveWidget from './SolveWidget/SolveWidget';
import Contracts from './Contracts/Contracts';
import { useFetchReportWidgets } from '../../api/queries/reports';
import WidgetJsonObject from './WidgetJsonObject/WidgetJsonObject';

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isASOC, pouzivatelId, sekciaZmluvy, vztahId } = useSelector(
    (state: RootState) => state.auth,
  );
  const bannerToShow = useSelector(
    (state: RootState) => state.banner.bannerToShow,
  );
  const bannerVersion = useSelector(
    (state: RootState) => state.pzsAuth.isOpen.bannerVersion,
  );
  const authModalVisible = useSelector(
    (state: RootState) => state.pzsAuth.isOpen.modal,
  );
  const zoznamKampaniWidget = useSelector(
    (state: RootState) => state.zmluvy.zoznamKampaniWidget,
  );
  const {
    autentifikovaneOsoby,
    dashboard,
    ico,
    idHz,
    isLoaded,
    kodTypZs,
    poslednaZmenaOdbornosti,
    preLekara,
    statutar,
  } = useSelector((state: RootState) => state.poskytovatel);
  const { aktuality, error, isLoading, novinky } = dashboard;
  const isLoadedDashboard = dashboard.isLoaded;
  const authModalRef = useRef(null);
  const udajLekara = preLekara ? autentifikovaneOsoby?.lekarOsoba : null;
  const bannerVersionToShow = bannerVersion === 'A' ? '1' : '2';

  const reportWidgets = useFetchReportWidgets({
    expertiseCode: poslednaZmenaOdbornosti,
    healthCareCode: kodTypZs,
  });

  useEffect(() => {
    if (!statutar.isLoading && !statutar.data && !statutar.error)
      dispatch(fetchDataStatutara(pouzivatelId));
  }, [dispatch, pouzivatelId, statutar]);

  useEffect(() => {
    /** Zavislost je zmena isLoading - ak sluzba spadne na err 500, zavola sa znovu. */
    if (
      vztahId &&
      isLoaded &&
      !isLoadedDashboard &&
      !aktuality?.length &&
      !novinky?.length &&
      !isLoading &&
      !error
    )
      dispatch(fetchAktualityNovinky(vztahId, pouzivatelId));
    // eslint-disable-next-line
  }, [
    dispatch,
    error,
    isLoadedDashboard,
    isLoaded,
    isLoading,
    pouzivatelId,
    vztahId,
  ]);

  useEffect(() => {
    /** Prevolanie sluzby DajBanery, ak nemam ziadne data v state */
    const payload: FetchBannersPayload = {
      ico: ico || '',
      idLekarOsoba: udajLekara?.lekarOsobaId || null,
      idPouzivatel: pouzivatelId || 0,
      idVZ: vztahId || 0,
    };
    if (ico && !bannerToShow) dispatch(fetchBanners(payload));
  }, [
    bannerToShow,
    dispatch,
    ico,
    pouzivatelId,
    udajLekara?.lekarOsobaId,
    vztahId,
  ]);

  useEffect(() => {
    if (
      !zoznamKampaniWidget.isLoading &&
      !zoznamKampaniWidget.error &&
      !zoznamKampaniWidget.isLoaded &&
      (isASOC || sekciaZmluvy) &&
      idHz
    )
      dispatch(fetchZoznamKampaniPreWidget({ idHz }));
    // eslint-disable-next-line
  }, [idHz]);

  useEffect(() => {
    if (authModalVisible) setTimeout(() => showModal(authModalRef), 1000);
  }, [authModalVisible]);

  const getDashboardBanner = ({ isLarge }: { isLarge: boolean }) => (
    <DashboardBanner
      dataLayerEvent={
        bannerToShow?.theme?.includes('Aut')
          ? `aut_v${bannerVersionToShow}_banner_hp_show`
          : ''
      }
      isModal={bannerToShow?.visible.popup}
      onClose={() => {
        dispatch(saveAuthRejection({ idPouzivatel: pouzivatelId || 0 }));
        sendDataLayer('auth_banner_hp_close');
        dispatch(
          hideBanner({
            idBaner: bannerToShow?.idBaner || 0,
            idPouzivatel: pouzivatelId || 0,
            idVZ: vztahId || 0,
          }),
        );
      }}
      show={
        !!(
          bannerToShow?.visible.banner ||
          bannerToShow?.visible.popup ||
          bannerToShow?.visible.card
        )
      }
    >
      {bannerToShow?.theme?.includes('Aut') && bannerVersion ? (
        <AuthBanner isCard={!isLarge} version={bannerVersion} />
      ) : (
        <ParametersIndexBanner
          isLarge={isLarge}
          path={bannerToShow?.path || routes.multipleChoiceHp}
        />
      )}
    </DashboardBanner>
  );
  return (
    <StaticMenuLayout
      className={classes.dashboard}
      contentWrapperId="dlek-dashboard"
      hideEPNotifications
    >
      {(bannerToShow?.visible.banner || bannerToShow?.visible.popup) &&
        getDashboardBanner({ isLarge: true })}
      {isASOC && <Contracts />}
      {!isASOC && (
        <Grid>
          <GridCol
            className="mb"
            equalizeHeight={false}
            size={{ s: 12, l: 12, xl: 8 }}
          >
            <EPNotifications />
            <SolveWidget />
            <DashboardNtf />
            <DoveraNews isLoading={isLoading} news={aktuality} />
            {reportWidgets.data?.widgets
              ?.filter((w) => w.column === '1')
              ?.map((w) => (
                <WidgetJsonObject
                  key={`json-widget--${w.id}`}
                  content={w.content}
                  id={w.id}
                />
              ))}
          </GridCol>
          <GridCol equalizeHeight={false} size={{ s: 12, l: 12, xl: 4 }}>
            <WidgetCurrentPrices />
            {reportWidgets.data?.widgets
              ?.filter((w) => w.column === '2')
              ?.map((w) => (
                <WidgetJsonObject
                  key={`json-widget--${w.id}`}
                  content={w.content}
                  id={w.id}
                />
              ))}
            {bannerToShow?.visible.card &&
              getDashboardBanner({ isLarge: false })}
            <ReviewCarousel />
          </GridCol>
        </Grid>
      )}
    </StaticMenuLayout>
  );
};

export default Dashboard;
