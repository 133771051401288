import { Loader } from '@dovera/design-system';
import { ReactNode } from 'react';

export const getAutocompleteAddon = (
  loaderPresented: boolean,
  rightAddons?: ReactNode,
): ReactNode => {
  if (loaderPresented) return <Loader />;
  return rightAddons || <div>-</div>;
};
