import { useCallback, useState } from 'react';
import SafeHtml from '../../../../../components/SafeHtml/SafeHtml';
import UploadForm from '../../../../../components/UploadForm/UploadForm';
import strings from '../../../../../constants/strings';
import {
  Button,
  ButtonLink,
  Card,
  CardSection,
  Icon,
  Notification,
  color,
} from '@dovera/design-system';
import { OdosliPrilohyFakturyRequest } from '../../../../../types/zuctovanie.types';
import { filesToBase64 } from '../../../../../utils/file.utils';
import { odosliPrilohyFaktury } from '../../../../../api/zuctovanie';
import { translateErrorCode } from '../../../../../utils/validation.utils';
import { getZucUploadProps } from '../../../../../utils/zuctovanie.utils';

const texts = strings.zuctovanie.invoices.detail;

interface Props {
  invoiceId: number;
  refreshDocuments: () => void;
}

const DoplnujuceDokumenty = ({ invoiceId, refreshDocuments }: Props) => {
  const [files, setFiles] = useState<File[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const sendFiles = useCallback(() => {
    if (files?.length) {
      setError(null);
      setIsLoading(true);
      filesToBase64(
        files,
        (respFiles) => {
          const payload: OdosliPrilohyFakturyRequest = {
            dokumenty: respFiles,
            idFaktury: invoiceId,
          };
          odosliPrilohyFaktury(payload)
            .then(() => {
              refreshDocuments();
              setFiles([]);
            })
            .catch((err) =>
              setError(translateErrorCode(err?.response?.data?.kod)),
            )
            .finally(() => setIsLoading(false));
        },
        'INE',
      );
    }
  }, [files, invoiceId, refreshDocuments]);
  const renderUploadForm = (
    <UploadForm
      {...getZucUploadProps()}
      className="mb-large"
      files={
        files?.map((f) => ({
          name: f.name,
          size: f.size,
          type: f.type,
        })) || null
      }
      forbiddenExtensions={new Set()}
      isUploading={false}
      multiple
      onResetForm={(file, index) =>
        setFiles(files?.filter((f, key) => key !== index) || [])
      }
      onUpload={(f) => {
        setError(null);
        setFiles([...(files || []), ...f]);
      }}
      uploadFormLabelText={
        <div className="d-flex align-center">
          Presuňte súbor sem alebo kliknite na
          <ButtonLink className="no-mrg">
            <Icon
              className="text-space-half-left icon--left"
              color={color('primary', 700)}
              name="attachment"
              size="medium"
            />
            Priložiť súbor
          </ButtonLink>
        </div>
      }
    />
  );
  const renderBtn = (
    <Button className="no-mrg" isLoading={isLoading} onClick={sendFiles}>
      {texts.extraDocuments.button}
    </Button>
  );
  const renderError = error && (
    <Notification message={<SafeHtml html={error} />} variant="error" />
  );
  return (
    <Card isBordered>
      <CardSection>
        <SafeHtml
          className="mb"
          html={texts.extraDocuments.title}
          wrapper="h5"
        />
        <SafeHtml
          className="mb"
          html={texts.extraDocuments.description}
          wrapper="div"
        />
        {renderUploadForm}
        {renderError}
        {renderBtn}
      </CardSection>
    </Card>
  );
};

export default DoplnujuceDokumenty;
