import { Choice } from 'choices.js';
import strings from '../../../../../../constants/strings';
import useStyles from '../../../../Proposals.styles';
import RecommendedNtf from './RecommendedNtf/RecommendedNtf';
import {
  checkProposalData,
  getProducts,
} from '../../../../../../api/drugProposals';
import { useAppDispatch } from '../../../../../../hooks/useStore';
import {
  resetControls,
  storeStep3,
} from '../../../../../../slices/drugProposals.slice';
import { CheckType } from '../../../../../../types/proposals.types';
import CheckNotifications from '../../../common/CheckNotifications/CheckNotifications';
import { useEffect, useRef, useState } from 'react';
import {
  DrugProduct,
  FieldElementIdEnum,
} from '../../../../../../types/drugProposals.types';
import {
  DEFAULT_PRODUCT,
  DEFAULT_PRODUCT_CODE,
} from '../../../../../../constants/drugProposals';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../rootReducer';
import { scrollToField } from '../../../../../../utils/form.utils';
import { cx } from '../../../../../../utils/exports';
import { AutocompleteApp } from '@dovera/design-system';
import { highlightText } from '../../../../../../utils/proposals.utils';

interface Props {
  expertiseAmbulance: string;
  expertiseDoctor: string;
  field: any;
  onReset: () => void;
}

const texts = strings.proposals.drugs.new;
const checkNotifications: CheckType[] = [
  'ExistujeProdukt',
  'JePlatny',
  'MaSekciuPreProdukt',
  'JeNutnyNavrh',
  'MaOdbornost',
  'ProduktVekPoi',
  'PohlaviePoi',
  'EsteJePriskoro',
];
const checkTypes: CheckType[] = [
  'ExistujeProdukt',
  'JeKategorizovany',
  'JePlatny',
  'JeCnp',
  'JeNavrh',
  'MaSekciuPreProdukt',
  'JeNutnyNavrh',
  'MaOdbornost',
  'ProduktVekPoi',
  'PohlaviePoi',
  'TypLiecby',
  'EsteJePriskoro',
];

const ProductCode = ({
  expertiseAmbulance,
  expertiseDoctor,
  field,
  onReset,
}: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles({ proposalType: 'LN' });
  const lastRequestInfo = useRef<{
    controller: AbortController | null;
    query: string | null;
  }>({ controller: null, query: null });
  const {
    data: { controls, products },
    stepper: {
      step3: { product },
    },
  } = useSelector((state: RootState) => state.drugProposals.new);

  const [selectedOption, setSelectedOption] = useState<{
    atc: string;
    label: string;
    preferredProducts?: DrugProduct[];
    value: string;
  } | null>(null);
  const [recommendedValue, setRecommendedValue] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    if (selectedOption) {
      dispatch(
        storeStep3({
          product: {
            atc: products.find((p) => p.kodProduktPzs === selectedOption.value)
              ?.kodAtcSkupiny,
            code: selectedOption.value,
            label: selectedOption.label,
          },
        }),
      );
      dispatch(
        checkProposalData({
          checkTypes,
          productCode: selectedOption.value,
        }),
      );
    }
    // eslint-disable-next-line
  }, [dispatch, selectedOption]);

  return (
    <div className={cx(classes.drugsWrapper)}>
      <AutocompleteApp
        {...field.input}
        addonsInside
        autofillSingleResult
        className="mb"
        delay={500}
        disableSuccessFrame
        error={
          field.meta.touched &&
          !field.meta.active &&
          field.meta.error &&
          field.meta.error
        }
        id={FieldElementIdEnum.Product}
        isRequired
        label={texts.labels.productCode}
        minLength={3}
        noMarginBottom
        notFilterOptions
        onClick={() => scrollToField(FieldElementIdEnum.Product)}
        // eslint-disable-next-line
        onChange={(value: string) => {
          field.input.onChange(value);
          if (value === DEFAULT_PRODUCT_CODE)
            setSelectedOption({
              atc: '',
              label: DEFAULT_PRODUCT_CODE,
              value: DEFAULT_PRODUCT_CODE,
            });
          if (controls.data.some((c) => c.checkType === 'ExistujeProdukt')) {
            dispatch(resetControls({ resetExcept: 'PoistnyVztah' }));
            onReset();
          }
          setTimeout(() => {
            if (recommendedValue) setRecommendedValue(undefined);
          }, 300);
        }}
        onSelect={(option: Choice | any) => {
          if (option?.value === DEFAULT_PRODUCT_CODE) {
            field.input.onChange(option.value);
            setSelectedOption({
              atc: '',
              label: DEFAULT_PRODUCT_CODE,
              value: DEFAULT_PRODUCT_CODE,
            });
          }
          if (option) setSelectedOption(option);
        }}
        overwrittenValue={recommendedValue}
        source={async (query) => {
          if (query !== DEFAULT_PRODUCT_CODE && !recommendedValue) {
            if (
              query.includes(lastRequestInfo?.current?.query || '') &&
              lastRequestInfo.current.controller
            ) {
              // Abort the last request
              lastRequestInfo.current.controller.abort();
            }
            const controller = new AbortController();
            const { signal } = controller;
            if (product?.code && query.includes(product?.code))
              controller.abort();
            lastRequestInfo.current = { controller, query };
            let products = await getProducts(
              {
                hladanyProdukt: query,
                odbornostLekara: expertiseDoctor,
                odbornostNz: expertiseAmbulance,
              },
              signal,
            ).catch(() => null);
            if (products?.length === 0) {
              products = [DEFAULT_PRODUCT];
            }
            return products;
          }
          return null;
        }}
        suggestionTemplate={(suggestion: Choice) =>
          highlightText(suggestion.label, field.input.value)
        }
        value={field.input.value}
      />
      {!!selectedOption?.preferredProducts?.length && (
        <RecommendedNtf
          onSelect={(code: string, label: string) => {
            const text = `${code} - ${label}`;
            field.input.onChange(text);
            setRecommendedValue(text);

            setSelectedOption({
              atc:
                products.find((p) => p.kodProduktPzs === code)?.kodAtcSkupiny ||
                '',
              label,
              value: code,
            });
          }}
          products={selectedOption.preferredProducts}
        />
      )}
      <CheckNotifications
        checkTypes={checkNotifications}
        notLoadingTypes={['EsteJePriskoro']}
      />
    </div>
  );
};

export default ProductCode;
