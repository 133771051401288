import { color } from '@dovera/design-system';

interface Props {
  active?: boolean;
  id: string;
}

const IconDashboardDiagnose = ({ active, id }: Props) => (
  <svg
    fill="none"
    height="32"
    id={id}
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="16"
      cy="16"
      fill={active ? color('error', 100) : '#C4C4C4'}
      rx="16"
      ry="16"
    />
    <path
      d="M13.3333 8V9.66667H11.6667V13C11.6667 14.8417 13.1583 16.3333 15 16.3333C16.8417 16.3333 18.3333 14.8417 18.3333 13V9.66667H16.6667V8H19.1667C19.3877 8 19.5996 8.0878 19.7559 8.24408C19.9122 8.40036 20 8.61232 20 8.83333V13C19.9998 14.1816 19.5813 15.3251 18.8187 16.2276C18.056 17.1302 16.9984 17.7336 15.8333 17.9308V19.25C15.8329 19.933 16.0721 20.5945 16.5094 21.1192C16.9467 21.6439 17.5542 21.9985 18.2261 22.1212C18.898 22.2439 19.5917 22.1268 20.1862 21.7905C20.7806 21.4542 21.2382 20.9199 21.4792 20.2808C20.9429 20.0404 20.5086 19.6187 20.2523 19.0898C19.9961 18.561 19.9343 17.9587 20.0779 17.3889C20.2215 16.819 20.5613 16.3179 21.0375 15.9737C21.5138 15.6294 22.0961 15.4639 22.6822 15.5062C23.2684 15.5485 23.8209 15.796 24.2427 16.2052C24.6646 16.6143 24.9288 17.159 24.989 17.7436C25.0492 18.3282 24.9015 18.9153 24.5719 19.4018C24.2423 19.8884 23.7519 20.2433 23.1867 20.4042C22.9067 21.484 22.2426 22.4246 21.3188 23.0498C20.3949 23.675 19.2748 23.9418 18.1683 23.8003C17.0618 23.6587 16.0449 23.1184 15.3082 22.2807C14.5716 21.4431 14.1657 20.3655 14.1667 19.25V17.9308C13.0015 17.7337 11.9438 17.1304 11.1811 16.2278C10.4184 15.3252 9.99999 14.1817 10 13V8.83333C10 8.61232 10.0878 8.40036 10.2441 8.24408C10.4004 8.0878 10.6123 8 10.8333 8H13.3333ZM22.5 17.1667C22.279 17.1667 22.067 17.2545 21.9107 17.4107C21.7545 17.567 21.6667 17.779 21.6667 18C21.6667 18.221 21.7545 18.433 21.9107 18.5893C22.067 18.7455 22.279 18.8333 22.5 18.8333C22.721 18.8333 22.933 18.7455 23.0893 18.5893C23.2455 18.433 23.3333 18.221 23.3333 18C23.3333 17.779 23.2455 17.567 23.0893 17.4107C22.933 17.2545 22.721 17.1667 22.5 17.1667Z"
      fill="#67198C"
    />
  </svg>
);

export default IconDashboardDiagnose;
