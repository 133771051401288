import { fontWeight, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

interface StylesProps {
  isDefinedSize?: boolean;
}

export default createUseStyles({
  chart: ({ isDefinedSize }: StylesProps) => ({
    width: !isDefinedSize ? '100%' : 'auto',
    height: !isDefinedSize ? '100%' : 'auto',
    maxWidth: getRem(210),
    maxHeight: getRem(210),
  }),
  chartWrapperSingle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      height: 'auto',
      '&:not(:first-child)': {
        marginLeft: getRem(12),
      },
    },
  },
  chartWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: getRem(-8),
    '& svg': {
      height: 'auto',
      '&:not(:first-child)': {
        marginLeft: getRem(-100),
      },
    },
  },
  circleBackground: {
    transform: `rotate(-90deg)`,
    transformOrigin: '50% 50%',
  },
  circleForeground: {
    transition: 'stroke-dashoffset 2s ease-out',
    transform: 'rotate(-90deg)',
    transformOrigin: 'center',
  },
  labelForeign: {
    height: '70%',
    width: '100%',
    marginTop: '15%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontWeight: fontWeight.normal,
    '& h2, & h3, & h4': {
      textAlign: 'center',
      marginBottom: `${getRem(8)} !important`,
    },
    '& > div div[class]': {
      lineHeight: getRem(16),
    },
  },
});
