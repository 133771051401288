import { Icon, color } from '@dovera/design-system';
import {
  ExchangeParametersForRender,
  ExpertisePeriod,
  Filling,
  GetExchangeParametersResponse,
  GroupType,
  ParameterDetail,
  ParameterResult,
  PriceType,
  PriceTypeParameterResult,
} from '../types/parameters.types';
import { ChartData } from '../components/RadialChart/types';
import { ReactNode } from 'react';
import IconCircleDashed from '../components/CustomIcons/IconCircleDashed';
import { paramsForNewGraph } from '../constants/parameters';
import {
  getPercentage,
  getPriceFormat,
  getValueWithNDecimal,
  replaceWithComma,
} from './number.utils';
import { extractTextFromHTML, getTypHodnotyPostfix } from './strings.utils';
import { Nullable } from '../types';
import {
  ExchangeableParameter,
  MenuParameterGroup,
  Parameter,
} from '../types/jsonObjects.types';
import { MenuItemDropdown } from '../slices/menu.slice';
import routes from '../routes';
// eslint-disable-next-line
import NavigationItem from '@dovera/design-system/dist/src/components/Navigation/NavigationItem';
import { Link } from 'react-router-dom';
import IconCheckCircle from '../components/CustomIcons/IconCheckCircle';
import IconErrorCircle from '../components/CustomIcons/IconErrorCircle';

export const convertGroupShortcut = (
  shortcut: GroupType | string,
): {
  color: string;
  hoveredColor?: string;
  icon?: ReactNode;
  menuIcon?: Omit<Icon, 'ref'>;
  title?: string;
} => {
  switch (shortcut) {
    case 'K':
      return {
        color: color('warning', 500),
        hoveredColor: color('warning', 100),
        menuIcon: {
          name: 'quality',
          size: 'small',
        },
        title: 'Kvalita',
      };
    case 'E':
      return {
        color: color('blue'),
        hoveredColor: color('grey', 200),
        menuIcon: {
          name: 'effectivity',
          size: 'small',
        },
        title: 'Efektívnosť',
      };
    case 'I':
      return {
        color: color('primary', 600),
        hoveredColor: color('primary', 100),
        menuIcon: {
          name: 'innovations',
          size: 'small',
        },
        title: 'Inovácie',
      };
    case 'S':
      return {
        color: '',
        icon: <span />,
        title: 'Špeciálne parametre',
      };
    default:
      return { color: color('grey', 200) };
  }
};

export const getStateData = (
  state: 'success' | 'warning' | 'error' | string | null,
): { color: string; icon: ReactNode } => {
  switch (state) {
    case 'success':
      return {
        color: color('success', 600),
        icon: (
          <IconCheckCircle
            backgroundColor={color('primary', 600)}
            color="white"
            width={24}
          />
        ),
      };
    case 'warning':
      return {
        color: color('warning', 500),
        icon: (
          <IconErrorCircle
            backgroundColor={color('warning', 500)}
            color="white"
            height={24}
            width={24}
          />
        ),
      };
    case 'error':
      return {
        color: color('error', 500),
        icon: (
          <IconErrorCircle
            backgroundColor={color('error', 500)}
            color="white"
            height={24}
            width={24}
          />
        ),
      };
    default:
      return { color: color('black'), icon: <IconCircleDashed /> };
  }
};

export const convertChartObject = (data: string): ChartData[] | null => {
  if (!data || !Number(JSON.parse(data)?.[0]?.datagraf?.length)) return null;
  const parsedData = JSON.parse(data);
  return parsedData.map((p) => ({
    data: p.datagraf.map((item: any) => {
      const group = convertGroupShortcut(item?.typSkupina);
      return {
        color: group.color,
        hoveredColor: group.hoveredColor,
        title: group.title,
        value: item?.hodnota,
      };
    }),
    name: p.nazov,
    value: p.hodnota,
  }));
};

// reduce periods to have only one past period
export const reducePeriods = (
  acc: ExpertisePeriod[],
  current: ExpertisePeriod,
): ExpertisePeriod[] => {
  if (current.codePeriodType === 'A' || current.codePeriodType === 'Z') {
    acc.push(current);
  } else if (
    current.codePeriodType === null &&
    !acc.some((item) => item.codePeriodType === null)
  ) {
    acc.push(current);
  }
  return acc;
};

export const convertParameterCode = (code: string): string =>
  code.toLowerCase().replace(/_/g, '-');

export const getGroupUrlPrefix = (groupCode: string): string => {
  switch (groupCode) {
    case 'K':
      return 'kvalita';
    case 'E':
      return 'efektivnost';
    case 'I':
      return 'inovacie';
    default:
      return '';
  }
};

export const getParamCodeFromPathname = (path: string): string => {
  const pathArray = path.split('/');
  return pathArray[pathArray.length - 1] || '';
};

// Detail parameter

export const isParameterPartiallyDone = (parameter: ParameterResult): boolean =>
  parameter?.filling > 0 && parameter?.filling < 1;

export const fillingFormat = (
  filling: boolean | number,
  band: string,
): boolean => {
  if (!filling || (filling !== 1 && band === 'N')) {
    return false;
  }
  return !!(filling || band === 'P');
};

export const getFillingState = (
  parameter: ParameterResult,
  firstGraph?: boolean | null,
) =>
  isParameterPartiallyDone(parameter)
    ? parameter.parameterCode &&
      paramsForNewGraph.includes(parameter.parameterCode)
      ? 'success'
      : 'warning'
    : fillingFormat(
          parameter?.parameterCode === 'dostup-gyn'
            ? firstGraph
              ? parameter?.numeratorValue > parameter?.maxThresholdValue
              : parameter?.denominatorValue > parameter?.minThresholdValue
            : parameter?.filling,
          parameter?.band,
        )
      ? 'success'
      : 'error';

export const valueFormat = (
  value?: number | string | null,
  filling?: number | string,
  valueType?: string,
  nbsp?: boolean,
  br?: boolean,
): string | number => {
  if (value === null && Number(filling) === 1 && valueType === 'P')
    return '100 %';
  if (typeof value === 'string') return value;
  if (!value) return '';

  return getFormatedValue(value, valueType, nbsp, br);
};

export const priceCode = (
  priceType: PriceType,
  parameterCode: Nullable<string>,
  results: Nullable<PriceTypeParameterResult[]>,
): number =>
  results
    ? results.find(
        (r) => r.parameterCode === parameterCode && r.priceType === priceType,
      )?.price || 0
    : 0;

export const getFillingValue = (
  parameter?: ParameterResult,
  firstGraph?: boolean | null,
): number => {
  if (!parameter) return 0;
  return parameter.parameterCode === 'dostup-gyn'
    ? firstGraph
      ? parameter.numeratorValue > parameter.maxThresholdValue
        ? 1
        : 0
      : parameter.denominatorValue > parameter.minThresholdValue
        ? 1
        : 0
    : parameter.filling;
};

export const graphWithTwoPointers = ['atb-dp', 'nakl-vas-dp', 'starost-vas-dp'];

export const calculatePointer2Pdf = (parameter: Parameter, pdf?: boolean) => {
  const filling =
    parameter.cardContent?.state === 'success'
      ? 1
      : parameter.cardContent?.state === 'warning'
        ? 0.5
        : 0;
  return parameter.minValue &&
    parameter.minValue !== parameter.maxValue &&
    graphWithTwoPointers.includes(parameter.cardContent.parameterCode || '')
    ? `Hranica nad ${valueFormat(
        parameter.maxValue,
        filling,
        parameter.valueType,
        !pdf,
      )}`
    : ``;
};

export const calculatePointer1Pdf = (
  parameter: Parameter,
  pdf?: boolean,
  border?: number | null,
): string => {
  const {
    cardContent: { parameterCode },
    maxValue,
    minValue,
    value,
    valueType,
  } = parameter;

  let borderValue = minValue;
  const topBorderCodes = [
    'sch-dp',
    'atb',
    'nakl-vas',
    'nakl-sas',
    'starost-vas',
    'kont-sas',
    'body-log',
    'vys-log',
    'body-psyd',
    'vys-psyd',
    'body-psyt',
    'vys-psyt',
  ];
  if (topBorderCodes.includes(parameterCode || '')) borderValue = maxValue;

  if (parameterCode === 'subj-spok') return '';

  if (topBorderCodes.includes(parameterCode || ''))
    return `Horná hranica: ${valueFormat(
      borderValue,
      value?.toString(),
      valueType,
      !pdf,
    )}`;

  return minValue &&
    minValue !== maxValue &&
    graphWithTwoPointers.includes(parameterCode || '')
    ? `Hranica pod ${valueFormat(minValue, value?.toString(), valueType, !pdf)}`
    : `Dolná hranica: ${valueFormat(
        border || borderValue,
        value?.toString(),
        valueType,
        !pdf,
      )}`;
};

export const getBorder = (
  maxThresholdValue: number,
  minThresholdValue: number,
  band: string,
  parameterCode?: string,
): number => {
  const bottomBorder: string[] = [
    'prev-vas',
    'prev-vas-dp',
    'prev-gyn',
    'prev-kp-gyn',
    'kont-gyn',
  ];
  const topBorder: string[] = [
    'svlz-gyn',
    'nakl-vas',
    'starost-vas',
    'kont-sas',
  ];
  if (bottomBorder.some((p) => p === parameterCode)) return minThresholdValue;
  if (topBorder.some((p) => p === parameterCode)) return maxThresholdValue;
  const paramBorder = (): number => {
    switch (parameterCode) {
      case 'bezp-med':
      case 'elab':
      case 'ezuc':
      case 'erec':
      case 'enav':
      case 'subj-spok':
      case 'std-hba1c':
      case 'prev-kp-gyn':
      case 'dostup-gyn':
      case 'prev-gyn':
      case 'kont-gyn':
        return minThresholdValue;
      default:
        return 0;
    }
  };
  const hranicaPodlaPasma = (): number => {
    switch (band) {
      case 'V':
      case 'S':
        if (parameterCode === 'prev-gyn') return minThresholdValue;
        return maxThresholdValue;
      case 'D':
        return minThresholdValue;
      default:
        return paramBorder();
    }
  };

  return hranicaPodlaPasma();
};

export const parameterWithoutPrices = (
  priceTypes: PriceType[],
  parameterCode: Nullable<string>,
  results: Nullable<PriceTypeParameterResult[]>,
): boolean => {
  if (!results || !parameterCode || !priceTypes.length) return true;
  return priceTypes.every((c) =>
    results.find(
      (r) =>
        c === r.priceType &&
        parameterCode === r.parameterCode &&
        r.price === undefined,
    ),
  );
};

export const groupRadiosObjects = (data: GetExchangeParametersResponse) => ({
  ...data,
  parameterGroups: data?.parameterGroups.map((pg) => ({
    ...pg,
    parameters: pg.parameters.reduce((acc, current) => {
      if (!current.dataForExchange) {
        acc.push({
          ...current,
          exchangeGroupId: 0,
        });
        return acc;
      }
      if (current.dataForExchange.selectionType !== 'radioButton') {
        acc.push({
          ...current,
          exchangeGroupId: current.dataForExchange.exchangeGroupId,
        });
        return acc;
      }

      if (current.dataForExchange.selectionType === 'radioButton') {
        const foundIndx = acc.findIndex(
          (item) =>
            item.exchangeGroupId === current.dataForExchange?.exchangeGroupId,
        );

        if (foundIndx !== -1) {
          acc[foundIndx].radio?.push(current);
          return acc;
        }

        acc.push({
          ...current,
          exchangeGroupId: current.dataForExchange.exchangeGroupId,
          radio: [current],
        });
      }
      return acc;
    }, [] as ExchangeParametersForRender[]),
  })),
});

export const getSelecedParametersCountAndMin = (
  data: Nullable<GetExchangeParametersResponse>,
  groupType: GroupType,
) => {
  if (!data?.parameterGroups)
    return { selectedParamCount: -1, minParamCount: -1 };
  let selectedParamCount = 0;
  let minParamCount = 0;
  data?.parameterGroups.forEach((pg) => {
    if (pg.groupType === groupType) {
      minParamCount = pg.amountMin ?? 0;
      pg.parameters.forEach((p) => {
        if (p?.dataForExchange?.selected || p?.required) {
          selectedParamCount++;
        }
      });
    }
  });
  return { selectedParamCount, minParamCount };
};

export const getNumberOfParametersLeft = (
  groupType: GroupType,
  data: Nullable<GetExchangeParametersResponse>,
): number => {
  const { minParamCount, selectedParamCount } = getSelecedParametersCountAndMin(
    data,
    groupType,
  );

  return selectedParamCount < minParamCount
    ? minParamCount - selectedParamCount
    : 0;
};

export const calculateHranica1Hodn = (parameter: Parameter): number => {
  if (parameter.cardContent.parameterCode === 'subj-spok') return 0;
  if (parameter.cardContent.parameterCode === 'dostup-gyn') return 50;
  return (parameter.minValue &&
    parameter.minValue !== parameter.maxValue &&
    graphWithTwoPointers.includes(parameter.cardContent.parameterCode || '')) ||
    parameter.cardContent.parameterCode === 'prev-vas-dp'
    ? 25
    : parameter.maxValue > 1
      ? 75
      : parameter.maxValue * 100;
};

export const calculateHranica2Hodn = (parameter: Parameter) =>
  parameter.minValue &&
  parameter.minValue !== parameter.maxValue &&
  graphWithTwoPointers.includes(parameter.cardContent.parameterCode || '')
    ? 75
    : 0;
export const isGYN009 = (
  catTitle: string,
  kodOdbAmb: string,
  kodTypZS: string,
): boolean =>
  catTitle.toLowerCase().includes('špeciál') &&
  kodOdbAmb === '009' &&
  kodTypZS !== '200';

export function getParametersMenuItems(
  parameterGroups: MenuParameterGroup[],
): NavigationItem[] {
  let navs: NavigationItem[] = [];
  parameterGroups
    .filter((pg) => ['K', 'E', 'I'].includes(pg.code))
    .forEach((pg) => {
      navs.push({
        href: '#',
        iconProps: convertGroupShortcut(pg.code).menuIcon || undefined,
        title: convertGroupShortcut(pg.code).title || '',
        isLabel: true,
      });
      navs = [
        ...navs,
        ...pg.parameters.map((p) => ({
          href: (
            <Link
              to={`${routes.parameters}/${getGroupUrlPrefix(pg.code)}/${convertParameterCode(p.code)}`}
            />
          ),
          isActive:
            `${routes.parameters}/${getGroupUrlPrefix(pg.code)}/${convertParameterCode(p.code)}` ===
            window.location.pathname,
          title: p.name ?? '',
        })),
      ];
    });
  return navs;
}

export const convertMenuItemFromGroupTitle = (
  title: string,
): MenuItemDropdown => {
  switch (title) {
    case 'Kvalita':
      return MenuItemDropdown.kvalita;
    case 'Efektívnosť':
      return MenuItemDropdown.efektivnost;
    case 'Inovácie':
      return MenuItemDropdown.inovacie;
    default:
      return MenuItemDropdown.parametre;
  }
};

export const doesIncludesDetailUrl = (url: string): boolean => {
  const detailUrls = [
    'kvalita',
    'efektivnost',
    'inovacie',
    'subj-spok',
    'pristr-vyb',
    'bezp-med',
    'prev-vas',
    'nakl-vas',
    'starost-vas',
    'erec',
    'ezuc',
    'enav',
    'elab',
    'kont-sas',
  ];
  return detailUrls.some((du) => url.includes(du));
};

export const getMaxSelectionGroupAmount = ({
  amountAll,
  amountMax,
}: {
  amountAll: number;
  amountMax: number | null;
}): number => {
  if (!amountMax) return amountAll;
  return Math.min(amountMax, amountAll);
};

export const formatValue = (
  value?: number | string | null,
  filling?: Filling,
  valueType?: string,
  nbsp?: boolean,
  br?: boolean,
): string | number => {
  if (value === null && filling === 'Splnené' && valueType === 'P')
    return '100 %';
  if (typeof value === 'string') return value;
  if (!value) return '';

  return getFormatedValue(value, valueType, nbsp, br);
};

const getFormatedValue = (
  value: number,
  valueType?: string,
  nbsp?: boolean | undefined,
  br?: boolean | undefined,
) => {
  switch (valueType) {
    case 'P':
      return formatPercentage(nbsp, value);
    case 'D':
      return formatDecimal(value, 3);
    case 'D2':
      return formatDecimal(value, 2);
    case 'E':
      return formatCurrency(value, nbsp);
    case 'B':
    case 'N':
    case 'M':
      return formatWithPostfix(valueType, value, br);
    case 'R':
      return formatRoundedValue(value);
    case 'A':
      return '';
    case 'V':
      return getValueWithNDecimal(value || 0);
    case 'I':
      return formatInteger(value);
    default:
      return getValueWithNDecimal(value || 0) || 0;
  }
};

const formatPercentage = (
  nbsp?: boolean,
  valueType?: number | null,
): string => {
  const percentageString = valueType
    ? `${replaceWithComma(getPercentage(valueType))}`
    : '0';
  return nbsp ? `${percentageString}&nbsp;%` : `${percentageString} %`;
};

const formatDecimal = (value?: number, digits: number = 2): string =>
  value ? replaceWithComma(parseFloat(value.toFixed(digits))) : '0';

const formatCurrency = (value?: number, nbsp?: boolean): string => {
  const currencyString = value ? `${getPriceFormat(value.toFixed(2))}` : '0';
  return nbsp ? `${currencyString}&nbsp;€` : `${currencyString} €`;
};

const formatWithPostfix = (
  valueType: 'N' | 'M' | 'B',
  value?: number,
  br?: boolean,
): string =>
  `${getValueWithNDecimal(value || 0)} ${getTypHodnotyPostfix(valueType, value || 0, br)}`;

const formatRoundedValue = (value?: number): string =>
  `${getValueWithNDecimal(value || 0, 3)}`;

const formatInteger = (value?: number): string =>
  value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') || '';

export function updateDataForExchange(
  parameter: ExchangeableParameter,
  groupId: number | undefined,
  parameterId: number,
  isSelected: boolean,
  groupType: GroupType,
  group: string,
) {
  if (
    parameter.dataForExchange?.exchangeGroupId === groupId &&
    parameter.parameterId === parameterId &&
    parameter.dataForExchange
  ) {
    return { ...parameter.dataForExchange, selected: isSelected };
  }

  if (parameter.dataForExchange)
    return {
      ...parameter.dataForExchange,
      selected:
        parameter.dataForExchange.exchangeGroupId === groupId &&
        groupType === group
          ? false
          : parameter.dataForExchange.selected,
    };
  return parameter.dataForExchange;
}

export const getParamTitle = (pd?: ParameterDetail[]): string => {
  if (!pd) return '';
  const structures = pd?.find((item) => item.type === 'header')?.content;
  if (!structures)
    return extractTextFromHTML(
      pd?.find((item) => item.type === 'html')?.attributes?.text,
    );
  const htmlText = structures
    ?.find((s) => s.content?.find((c) => c.attributes.text?.includes('<h2')))
    ?.content?.find((c) => c.attributes.text?.includes('<h2'))
    ?.attributes?.text;
  return extractTextFromHTML(htmlText);
};
