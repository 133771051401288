import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    boxShadow: 'unset',
  },
  cardBubble: {
    overflow: 'hidden',
  },
});
