import { color } from '@dovera/design-system';

interface Props {
  className?: any;
}

const Equals = ({ className }: Props) => (
  <svg
    className={className}
    fill="none"
    height="9"
    viewBox="0 0 11 9"
    width="11"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.632 5.464H11V8.128H0.632V5.464ZM0.632 0.663999H11V3.328H0.632V0.663999Z"
      fill={color('primary', 600)}
    />
  </svg>
);

export default Equals;
