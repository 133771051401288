import { getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

interface StylesProps {
  expanded?: boolean;
}

export default createUseStyles({
  root: {
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflowY: 'hidden',
    '& h4': {
      paddingLeft: getRem(16),
    },
  },
  borderRadius: ({ expanded }: StylesProps) => ({
    borderTopLeftRadius: expanded ? 5 : 0,
    borderTopRightRadius: expanded ? 5 : 0,
  }),
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: getRem(16),
    '& button': {
      cursor: 'pointer',
    },
  },
  contentHeaderActions: {
    paddingTop: 23,
    paddingBottom: 8,
    '& button': {
      minHeight: 'auto',
      color: 'white',
      textDecoration: 'initial !important',
      '&:first-child': {
        lineHeight: 0,
        display: 'block',
      },
      '&:hover': {
        color: 'white',
      },
    },
  },
});
