/* eslint-disable */
export const getSize = (chartIndex: number, size: number) =>
  !chartIndex ? size : size / (chartIndex + 1);

export const getRadii = (groups: number): number[] => {
  if (groups === 1) return [80];
  if (groups === 2) return [72, 84];
  return [60, 72, 84];
};

export const calculateRadius = (
  chartIndex: number,
  size: number,
  strokeWidth: number,
) => (getSize(chartIndex, size) - strokeWidth) / (chartIndex ? 1 : 2);

export const getPercentage = (value: number, onlyInt?: boolean) => {
  const res = value <= 1 ? value * 100 : value;
  return Number.parseFloat(res.toFixed(onlyInt ? 0 : 2));
};

export const calculateCircumference = (radius: number) => 2 * Math.PI * radius;

export const calculateOffset = (
  percentage: number,
  index: number,
  radius: number,
) => {
  const circumference = calculateCircumference(radius);

  return circumference - (circumference * getPercentage(percentage)) / 100;
};
