import { color } from '@dovera/design-system';
import { IconDashedRing, IconSuccess } from '../../../../components';
import { Status } from '../../../../types/parameters.types';
import IconErrorCircle from '../../../../components/CustomIcons/IconErrorCircle';

interface Props {
  iconSize?: number;
  status: Status;
}

const StatusIcon = ({ iconSize = 22, status }: Props) => {
  switch (status) {
    case 'success':
      return (
        <IconSuccess
          color={color('primary', 600)}
          height={iconSize}
          id="icon-success-value"
          width={iconSize}
        />
      );
    case 'warning':
      return (
        <IconErrorCircle
          color={color('warning')}
          height={iconSize}
          id="icon-warning-value"
          width={iconSize}
        />
      );
    case 'error':
      return (
        <IconErrorCircle
          color={color('error')}
          height={iconSize}
          id="icon-error-value"
          width={iconSize}
        />
      );
    default:
      return (
        <IconDashedRing
          height={iconSize}
          id="icon-dashed-value"
          width={iconSize}
        />
      );
  }
};

export default StatusIcon;
