import { Button, CardAction, Icon, color } from '@dovera/design-system';
import { epSekcie } from '../../../../../constants/epLinks';
import { createQueryParams } from '../../../../../utils/api.utils';
import useStyles from '../../../styles';
import strings from '../../../../../constants/strings';
import CardBubble from '../../../../../components/CardBubble/CardBubble';

const { buttons } = strings.zuctovanie.invoices.detail;

interface Props {
  invoiceId: number;
  invoiceNumber: string;
}

const DispensingRecordsCard = ({ invoiceId, invoiceNumber }: Props) => {
  const classes = useStyles();

  return (
    <CardBubble isClickable>
      <CardAction
        className={classes.cardAction}
        onClick={() => {
          window.location.href = `${epSekcie['dispenzarizovane-zaznamy']}${createQueryParams({ queryParams: { cisloFA: invoiceNumber, idFA: invoiceId }, urlEncoded: true })}`;
        }}
      >
        <Button type="link-secondary">{buttons.dispZaz}</Button>
        <Icon color={color('black')} name="chevron-right" />
      </CardAction>
    </CardBubble>
  );
};

export default DispensingRecordsCard;
