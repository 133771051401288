import { Notification, Radio, RadioGroup } from '@dovera/design-system';
import React, { ReactNode } from 'react';
import SafeHtml from '../../../../../components/SafeHtml/SafeHtml';
import { cx } from '../../../../../utils/exports';
import Label from './Label';
import { ProposalRadioType } from '../../../../../types/proposals.types';
import { getElementAttrs } from './utils';
import useStyles from './RadioElement.styles';

interface RadioElementProps {
  field: any;
  help?: ReactNode;
  id: string;
  label: ReactNode | string;
  notification?: { message: string; type: 'error' | 'info' };
  onChange: (value: string) => void;
  tooltip?: string;
  tooltipClass?: string;
  type: ProposalRadioType;
  value?: string;
  withNotificationClass?: string;
  wrapperClass?: string;
}

const RadioElement = ({
  field,
  help,
  id,
  label,
  notification,
  onChange,
  tooltip,
  tooltipClass,
  type,
  value,
  withNotificationClass,
  wrapperClass,
}: RadioElementProps) => {
  const classes = useStyles();
  return (
    <div className={cx(withNotificationClass, 'mb-large')} id={id}>
      <RadioGroup
        {...field.input}
        error={field.meta.touched && field.meta.error}
        // @ts-ignore
        help={help}
        isRequired
        // @ts-ignore
        label={
          <Label
            id={id}
            label={label}
            tooltip={tooltip}
            tooltipClass={tooltipClass}
          />
        }
        onChange={(e) => {
          const { value } = e.target as HTMLInputElement;
          field.input.onChange(value);
          onChange(value);
        }}
      >
        <div className={cx(classes.radios, wrapperClass)}>
          {[true, false].map((v, index) => {
            const attrs = getElementAttrs({
              choosedValue: value,
              idProp: id,
              index,
              type,
            });
            return (
              <Radio
                id={attrs.id}
                isChecked={attrs.isChecked}
                name={id}
                value={attrs.value}
              >
                {attrs.label}
              </Radio>
            );
          })}
        </div>
      </RadioGroup>
      {notification && (
        <Notification
          message={<SafeHtml html={notification.message} />}
          variant={notification.type}
        />
      )}
    </div>
  );
};

export default RadioElement;
