import { Skeleton } from '@dovera/design-system';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';

const SkeletonHeader = () => (
  <>
    <Skeleton className="mb-large" width="60%" />
    <h2>
      <Skeleton width="40%" />
    </h2>
  </>
);

const NewProposalSkeleton = () => (
  <StaticMenuLayout contentHeader={<SkeletonHeader />}>
    <p>
      <Skeleton width="40%" />
    </p>
    <p className="mb-xsmall">
      <Skeleton className="text-space-half-right" height={30} width={30} />
      <Skeleton height={30} width="30%" />
    </p>
    <p>
      <Skeleton className="text-space-half-right" height={30} width={30} />
      <Skeleton height={30} width="30%" />
    </p>
    <Skeleton className="text-space-half-right" height={50} width="10%" />
    <Skeleton height={50} width="20%" />
  </StaticMenuLayout>
);

export default NewProposalSkeleton;
