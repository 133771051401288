import { Icon, color } from '@dovera/design-system';
import { ReactNode } from 'react';
import { STAV_DAVKY_CAKACKY } from '../types/cakacky.types';
import { STAV_DAVKY } from '../types/zuctovanie.types';
import strings from './strings';
import ColoredLabel from '../components/ColoredLabel/ColoredLabel';

const { stateLables } = strings.zuctovanie;

export const MAX_RANGE_YEARS = 1;

export const FILTER_MIN_DATE = '2000-01-01';

export const BATCH_STATES: {
  icon: ReactNode;
  label: string | ReactNode;
  stringLabel: string;
  value: STAV_DAVKY;
}[] = [
  {
    icon: <Icon color={color('success', 600)} name="check" size="medium" />,
    label: (
      <ColoredLabel className="text-semibold" color={color('success', 600)}>
        {stateLables.correctBatch}
      </ColoredLabel>
    ),
    stringLabel: stateLables.correctBatch,
    value: STAV_DAVKY.Spravna,
  },
  {
    icon: <Icon color={color('error', 600)} name="16-help" size="medium" />,
    label: (
      <ColoredLabel className="text-semibold" color={color('error', 600)}>
        {stateLables.errorBatch}
      </ColoredLabel>
    ),
    stringLabel: stateLables.errorBatch,
    value: STAV_DAVKY.Chybna,
  },
  {
    icon: <Icon name="hourglass" size="medium" />,
    label: (
      <ColoredLabel className="text-semibold" color={color('black')}>
        {stateLables.processingBatch}
      </ColoredLabel>
    ),
    stringLabel: stateLables.processingBatch,
    value: STAV_DAVKY.PrebiehaKontrola,
  },
  {
    icon: <Icon color={color('success', 600)} name="check" size="medium" />,
    label: (
      <ColoredLabel className="text-semibold" color={color('success', 600)}>
        {stateLables.uploadedBatch}
      </ColoredLabel>
    ),
    stringLabel: stateLables.uploadedBatch,
    value: STAV_DAVKY.Nahrana,
  },
];

export const BATCH_STATES_CAKACKY: {
  icon: ReactNode;
  label: string | ReactNode;
  value: STAV_DAVKY | STAV_DAVKY_CAKACKY;
}[] = [
  ...BATCH_STATES,
  ...[
    {
      icon: <Icon color={color('primary', 700)} name="check" size="medium" />,
      label: 'Vybavená',
      value: STAV_DAVKY_CAKACKY.Vybavena,
    },
    {
      icon: <Icon color={color('black')} name="hourglass" size="medium" />,
      label: 'Spracováva sa',
      value: STAV_DAVKY_CAKACKY.Spracovanie,
    },
    {
      icon: <Icon color={color('error', 600)} name="help" size="medium" />,
      label: <span className="text-color-error">Na stiahnutie</span>,
      value: STAV_DAVKY_CAKACKY.NaStiahnutie,
    },
  ],
];

export const INVOICE_TYPES = ['SK', 'EU'];

export const INVOICE_STATES: {
  color: string;
  icon: ReactNode;
  label: string;
  value: string;
}[] = [
  {
    color: color('black'),
    icon: <Icon name="edit" size="medium" />,
    label: 'Rozpracovaná',
    value: 'R',
  },
  {
    color: color('black'),
    icon: <Icon color={color('black')} name="warning" size="medium" />,
    label: 'Čaká na potvrdenie',
    value: 'C',
  },
  {
    color: color('success', 600),
    icon: <Icon color={color('success', 600)} name="check" size="medium" />,
    label: 'Zaúčtovaná',
    value: 'Z',
  },
  {
    color: color('black'),
    icon: <Icon color={color('black')} name="hourglass" size="medium" />,
    label: 'Prebieha zaúčtovanie',
    value: 'P',
  },
];

export const DEFAULT_FILTER_STATES: { label: string; value: string }[] = [
  {
    label: 'Áno',
    value: 'Y',
  },
  {
    label: 'Nie',
    value: 'N',
  },
];
