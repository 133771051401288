import { createUseStyles } from 'react-jss';
import { color, fontWeight, getRem } from '@dovera/design-system';

export default createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    zIndex: 9,
    position: 'fixed',
    top: getRem(120),
    right: 0,
    maxHeight: '80vh',
    overflowY: 'hidden',
    overflowX: 'hidden',
    transitionTimingFunction: 'ease-in-out',
    transition: '0.1s',
  },
  expandedRoot: {
    display: 'flex',
    width: `calc(100% - ${getRem(280)} - ${getRem(60)})`,
    flexDirection: 'row',
    alignItems: 'flex-start',
    zIndex: 999,
    position: 'fixed',
    top: getRem(56),
    paddingTop: getRem(24),
    right: getRem(30),
    maxHeight: 'auto',
    overflowY: 'hidden',
    overflowX: 'hidden',
    transitionTimingFunction: 'ease-in-out',
    transition: '0.1s',
    background: '#f7f7f7',
  },
  floatingButton: {
    backgroundColor: color('primary', 500),
    borderRadius: `${getRem(5)} 0 0 ${getRem(5)}`,
    cursor: 'pointer',
    color: color('black'),
    fontSize: getRem(16),
    fontWeight: fontWeight.semiBold,
    top: getRem(200),
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: getRem(40),
    height: getRem(120),
    '& p': {
      transform: 'rotate(270deg)',
      margin: `0 0 ${getRem(-56)}`,
      position: 'relative',
      whiteSpace: 'nowrap',
      fontSize: `${getRem(16)} !important`,
    },
    '& svg': {
      transform: 'rotate(450deg)',
      '&.icon': {
        marginTop: 0,
        marginLeft: getRem(4),
        position: 'absolute',
        left: getRem(72),
      },
    },
    '& span': {
      transform: 'rotate(270deg)',
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        transform: 'rotate(90deg)',
        marginLeft: getRem(10),
        width: getRem(10),
      },
    },
  },
  floatingButtonTitle: {
    '& svg': {
      marginLeft: getRem(16),
    },
  },
  content: {
    backgroundColor: 'white',
    width: getRem(520),
    borderRadius: `0 0 0 ${getRem(5)}`,
    boxShadow: `0 0 ${getRem(24)} rgba(0, 0, 0, 0.1)`,
    marginBottom: getRem(35),
  },
  expandedContent: {
    backgroundColor: 'transparent',
    width: '100%',
    maxWidth: 1100,
    margin: '0 auto',
    height: '100vh',
    borderRadius: `0 0 0 ${getRem(5)}`,
  },
  contentBody: {
    height: 'auto',
  },
  extendedContentBody: {
    height: `calc(100vh - ${getRem(110)})`,
    background: '#FFFFFF',
    borderRadius: 5,
  },
});
