import { color, colors, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  inactiveTab: {
    color: colors.error[500],
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& button': {
      fontSize: getRem(20),
      textDecoration: 'none',
      margin: 0,
      color: color('black'),
      padding: 0,
      minHeight: 0,
      lineHeight: getRem(24),
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  tabsWrapper: {
    paddingInline: getRem(32),
  },
  filterWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: getRem(24),
    flexBasis: '100%',
    '& > div, & > form': {
      width: getRem(211),
    },
  },
  cardBubble: {
    overflow: 'visible',
  },
  documents: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: getRem(48),
    rowGap: getRem(16),
  },
});
