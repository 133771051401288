import { Icon, Notification, Skeleton, Tooltip } from '@dovera/design-system';
import { LoadingState } from '../../../../types';
import { DajProfilResponse } from '../../../../types/api/profil';
import strings from '../../../../constants/strings';
import { hotjarMasking } from '../../../../utils/strings.utils';
import { formatDate } from '../../../../utils/date.utils';
import { Address, CardRow, Expertises } from './common';
import CardBubble from '../../../../components/CardBubble/CardBubble';

const { labels, tooltips } = strings.profile;

interface Props {
  data: DajProfilResponse | null;
  dataState: LoadingState;
  error: any;
}

const ProfileCard = ({ data, dataState, error }: Props) => {
  const as = data?.adresy?.some((a) => a.typAdresy === 'AS')
    ? data?.adresy?.filter((a) => a.typAdresy === 'AS')[0]
    : null;
  const ka = data?.adresy?.some((a) => a.typAdresy === 'KA')
    ? data?.adresy?.filter((a) => a.typAdresy === 'KA')[0]
    : null;
  const renderLoading = dataState === LoadingState.fetching && (
    <>
      <Skeleton className="d-block mb-small" width="100%" />
      <Skeleton className="d-block mb-small" width="70%" />
      <Skeleton className="d-block mb-small" width="70%" />
      <Skeleton className="d-block mb-small" width="50%" />
    </>
  );
  const renderContent = data && dataState === LoadingState.filled && (
    <>
      <CardRow label={labels.name} value={hotjarMasking(data.nazovPzs, 'b')} />
      <CardRow label={labels.ico} value={hotjarMasking(data.ico, 'b')} />
      <CardRow
        label={labels.doctorCode}
        value={hotjarMasking(data.kodPzshz, 'b')}
      />
      <CardRow
        label={
          <span className="content-center-vert">
            <span className="text-space-half-right">{labels.as}</span>
            <Tooltip dialog={tooltips.as} id="adresa-sidla-tooltip">
              <Icon name="info" />
            </Tooltip>
          </span>
        }
        value={
          <Address
            city={as?.nazovObce}
            country={as?.nazovStat}
            street={as?.nazovUlica}
            streetNumber={as?.orientacneCislo}
            zip={as?.psc}
          />
        }
      />
      <CardRow
        label={
          <span className="content-center-vert">
            <span className="text-space-half-right">{labels.ka}</span>
            <Tooltip dialog={tooltips.ka} id="korespondencna-adresa-tooltip">
              <Icon name="info" />
            </Tooltip>
          </span>
        }
        value={
          <Address
            city={ka?.nazovObce}
            country={ka?.nazovStat}
            street={ka?.nazovUlica}
            streetNumber={ka?.orientacneCislo}
            zip={ka?.psc}
          />
        }
      />
      <CardRow
        label={labels.asoc}
        value={hotjarMasking(data.zdruzenie || strings.undefined, 'b')}
      />
      <CardRow
        label={labels.doveraPomaha}
        value={<b>{data.doveraPomaha ? 'áno' : 'nie'}</b>}
      />
      <CardRow
        label={labels.vztah}
        value={
          <b>
            {data.zmluvnyOd
              ? `od ${formatDate(data.zmluvnyOd)}`
              : strings.undefined}
          </b>
        }
      />
      <CardRow label={labels.odbornosti} value={<Expertises data={data} />} />
    </>
  );
  const renderError = dataState === LoadingState.error && (
    <Notification message={error || strings.technickaChyba} variant="error" />
  );
  return (
    <CardBubble>
      {renderError}
      {renderLoading}
      {renderContent}
    </CardBubble>
  );
};

export default ProfileCard;
