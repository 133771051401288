import { color } from '@dovera/design-system';

interface Props {
  isInner?: boolean;
}

const RightRoundBracket = ({ isInner }: Props) => {
  if (isInner)
    return (
      <svg
        fill="none"
        height="92"
        style={{ marginLeft: -12 }}
        viewBox="0 0 21 92"
        width="21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.5 1H4.5C12.7843 1 19.5 7.71573 19.5 16V76C19.5 84.2843 12.7843 91 4.5 91H1.5"
          stroke="#DBEBDC"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </svg>
    );
  return (
    <svg
      fill="none"
      height="104"
      style={{ marginLeft: -12 }}
      viewBox="0 0 19 104"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 1H2.5C10.7843 1 17.5 7.71573 17.5 16V88C17.5 96.2843 10.7843 103 2.5 103H1.5"
        stroke={color('primary', 600)}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};

export default RightRoundBracket;
