import { color as ddsColor } from '@dovera/design-system';

interface Props {
  backgroundColor?: string;
  color?: string;
  id: string;
}

const IconBlueMinus = ({ backgroundColor, color, id }: Props) => (
  <svg
    fill="none"
    height="24"
    id={id}
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill={backgroundColor || ddsColor('primary', 600)}
    />
    <path
      d="M7.58984 11.3516H16.4098V12.6476H7.58984V11.3516Z"
      fill={color || 'white'}
    />
  </svg>
);

export default IconBlueMinus;
