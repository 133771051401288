import { PropsWithChildren } from 'react';
import { cx } from '../../../utils/exports';
import useStyles from '../Dashboard.styles';
import { Nullable } from '../../../types';
import CampaignListWidget from './skeletons/CampaignListWidget';
import StateIcon from './StateIcon';
import { CampaignListWidgetState } from '../../../types/zmluvy.types';
import { Illustration } from '@dovera/design-system';

interface Props {
  className?: Nullable<string>;
  description?: Nullable<string>;
  isLoading: boolean;
  state: CampaignListWidgetState;
  title: string;
}

const CardContent = ({
  children,
  className,
  description,
  isLoading,
  state,
  title,
}: PropsWithChildren<Props>) => {
  const classes = useStyles();

  return (
    <div className={classes.contractsCard}>
      <div>
        {isLoading ? (
          <CampaignListWidget />
        ) : (
          <div className={classes.leftInfo}>
            <div className={cx(classes.contactsInfo, className)}>
              <StateIcon state={state} />
              <h6>{title}</h6>
            </div>
            {description && <p className="mb">{description}</p>}
            {children}
          </div>
        )}
      </div>
      <Illustration
        height={160}
        name="large_reklamacia_zdrav_zaznamu"
        width={160}
      />
    </div>
  );
};

export default CardContent;
