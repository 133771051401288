import { breakpoints, color, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

interface StylesProps {
  countMessages: number;
}

export default createUseStyles({
  messages: ({ countMessages }: StylesProps) => ({
    position: 'relative',
    '& button': {
      display: 'flex',
      alignItems: 'center',
      color: '#6D7175',
      fontWeight: 500,
      textDecoration: 'none',
      '&:hover, &:focus': {
        color: '#6D7175',
        textDecoration: 'inherit',
      },
    },
    '& svg': {
      marginRight: getRem(countMessages ? 14 : 7),
    },
    '&': {
      [`@media screen and (max-width: ${breakpoints.l}px)`]: {
        marginLeft: getRem(24),
        marginRight: getRem(32),
        position: 'absolute',
        top: getRem(8),
        right: getRem(24),
      },
    },
  }),
  messagesCount: ({ countMessages }: StylesProps) => ({
    display: countMessages ? 'block' : 'none',
    padding: '0 6px',
    borderRadius: 100,
    backgroundColor: color('error', 400),
    color: 'white',
    fontSize: 12,
    textAlign: 'center',
    top: 5,
    left: 15,
    zIndex: 9,
    minWidth: 18,
    height: 25,
    position: 'absolute',
  }),
});
