import { useEffect, useState } from 'react';
import useStyles from '../Feedback.styles';
import { Checkbox, CheckboxGroup, Textarea } from '@dovera/design-system';
import { RatingOption } from '../../../types/feedback.types';
import { ratingFullSymbolColors } from '../rating';
import strings from '../../../constants/strings';
import { systemConstants } from '../../../constants/systemConstants';
import { useDebounce } from '../../../hooks/useDebounce';
import { cx } from '../../../utils/exports';

const texts = strings.feedbacker;

interface Props {
  checkboxesError?: string;
  onChangeOption: (index: number) => void;
  onChangeText: (text: string) => void;
  rating: number;
  ratingOptions: RatingOption[];
  textError?: string;
  textKey: 'standard' | 'trigger';
}

const RatingOptions = ({
  checkboxesError,
  onChangeOption,
  onChangeText,
  rating,
  ratingOptions,
  textError,
  textKey,
}: Props) => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const [remainingLetters, setRemainingLetters] = useState(
    systemConstants.MAX_TEXTAREA_LETTERS,
  );
  const debouncedText = useDebounce(text, 300);
  useEffect(() => {
    onChangeText(debouncedText);
    // eslint-disable-next-line
  }, [debouncedText]);
  useEffect(() => {
    setText('');
    setRemainingLetters(systemConstants.MAX_TEXTAREA_LETTERS);
  }, [rating]);
  const renderOptions = rating > 0 && (
    <div className={classes.options}>
      <CheckboxGroup
        error={checkboxesError}
        id="feedback-options"
        // @ts-ignore
        legend={
          <h6 className="mb-small">
            {rating < ratingFullSymbolColors.length
              ? texts[textKey].secondStep.badRating.label
              : texts[textKey].secondStep.goodRating.label}
          </h6>
        }
      >
        {ratingOptions.map((r) => (
          <Checkbox
            key={r.key}
            className="mb-small"
            data-checked={r.checked}
            // eslint-disable-next-line
            checked={r.checked}
            id={r.key}
            onChange={() => onChangeOption(r.index)}
            role="checkbox"
          >
            {r.value}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </div>
  );
  const renderTextareaLabel = (
    <div className={classes.textareaLabel}>
      <span className="text-left">
        {textKey === 'trigger'
          ? texts.trigger.secondStep.textarea
          : texts.standard.secondStep.textareaDefault}
      </span>
      <span
        className={`no-mrg-bottom ${
          remainingLetters === 0 && 'text-color-error'
        }`}
      >
        {remainingLetters} znakov
      </span>
    </div>
  );
  const renderTextarea = ratingOptions &&
    ratingOptions.length > 0 &&
    (ratingOptions.some((r) => r.value.toLowerCase() === 'iné' && r.checked) ||
      textKey === 'trigger') && (
      <div className={cx(classes.textarea, 'mb')}>
        <Textarea
          error={textError}
          id="feedback-text"
          label={renderTextareaLabel}
          maxLength={systemConstants.MAX_TEXTAREA_LETTERS}
          onChange={(e) => {
            setText(e.target.value);
            setRemainingLetters(
              systemConstants.MAX_TEXTAREA_LETTERS - e.target.value.length,
            );
          }}
          value={text}
        />
      </div>
    );
  return (
    <div data-testid="feedback-ratingOptions">
      {renderOptions}
      {renderTextarea}
    </div>
  );
};

export default RatingOptions;
