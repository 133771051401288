import { createUseStyles } from 'react-jss';
import { color, getRem } from '@dovera/design-system';

export default createUseStyles({
  navigation: {
    height: `calc(100vh - ${getRem(32)})`,
    overflowY: 'auto',
    position: 'fixed',
    left: 0,
    width: getRem(340),
    zIndex: 9,
    '& ul': {
      marginBottom: 0,
    },
  },
  navigationPreloader: {
    background: 'white',
    padding: '3rem 1rem 3rem 1.5rem',
    '& > span': {
      marginBottom: getRem(12),
    },
  },
  navigationMobileWrapper: {
    background: `rgba(0, 0, 0, .5)`,
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    overflowY: 'hidden',
    zIndex: 998,
    display: 'none',
    '&.is-active': {
      display: 'block',
    },
  },
  navigationMobile: {
    width: '100%',
    background: color('white'),
    position: 'fixed',
    top: 0,
    left: 'unset',
    right: 0,
    zIndex: 999,
    height: '100vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    transition: 'transform .5s ease-in-out',
    maxWidth: getRem(340),
    padding: `${getRem(64)} ${getRem(16)} ${getRem(16)}`,
    '& .dropdown.is-active': {
      zIndex: 1000,
    },
  },
  btnClose: {
    position: 'absolute',
    right: getRem(16),
    top: getRem(16),
  },
  logout: {
    marginTop: getRem(20),
    borderTop: `1px solid ${color('black')}`,
    padding: `${getRem(28)} ${getRem(23)} ${getRem(8)} ${getRem(14)}`,
    width: '100%',
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: getRem(18),
    display: 'flex',
    '& svg': {
      marginRight: getRem(8),
    },
  },
});
