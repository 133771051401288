import { useCallback, useContext, useEffect, useState } from 'react';
import { useField, useForm } from 'react-final-form-hooks';
import strings from '../../../../constants/strings';
import { FilterInvoiceValues } from '../../../../types/zuctovanie.types';
import { safeString } from '../../../../utils/strings.utils';
import { getInvoiceState } from '../../../../utils/zuctovanie.utils';
import {
  DEFAULT_FILTER_STATES,
  INVOICE_STATES,
  INVOICE_TYPES,
} from '../../../../constants/zuctovanie';
import { getDefaultSelectOption } from '../../../../utils/dds.utils';
import { ButtonLink } from '@dovera/design-system';
import { cx } from '../../../../utils/exports';
import useStylesApp from '../../../../App.styles';
import { sameOjects } from '../../../../utils/object.utils';
import moment from 'moment';
import { DATE_FORMAT_ORACLE } from '../../../../utils/date.utils';
import { dajZoznamKodovPracovisk } from '../../../../api/zuctovanie';
import _ from 'lodash';
import { InputPreloader } from '../../../../components/Preloader';
import { hasSection } from '../../../../utils/poskytovatel.utils';
import { EPSections } from '../../../../constants/systemConstants';
import { useAppDispatch } from '../../../../hooks/useStore';
import {
  resetInvoicesFilter,
  setInvoicesFilter,
} from '../../../../slices/zuctovanie.slice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import { FilterContext } from '../../../../providers/FilterProvider';
import {
  MultiPeriodsFilter,
  SearchBarFilter,
  SelectFilter,
} from '../../../../components/Filter';
import useStyles from '../../styles';

const texts = strings.zuctovanie.invoices.filter;

const initialState: FilterInvoiceValues = {
  periods: [Number(moment().subtract(1, 'M').format(DATE_FORMAT_ORACLE))],
  search: '',
  state: '',
  invoiceType: '',
  paperInvoice: '',
  zuc20: '',
  kodPracoviska: '',
};

interface Props {
  onChange: (values: FilterInvoiceValues) => void;
}

const Filter = ({ onChange }: Props) => {
  const classesApp = useStylesApp();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [isResetVisible, setIsResetVisible] = useState(false);
  const [lastFilterState, setLastFilterState] = useState('');
  const [resetSearchBar, setResetSearchBar] = useState(false);
  const [kodyPracovisk, setKodyPracovisk] = useState<string[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const visibleFullFilter = hasSection(EPSections.DispZaz, 'Zuctovanie');
  const { filter } = useSelector(
    (state: RootState) => state.zuctovanie.invoices,
  );
  const { setSearchParams } = useContext(FilterContext);

  const { form } = useForm({
    initialValues: {
      periods: filter.periods,
      search: filter.search,
      state: filter.state,
      invoiceType: filter.invoiceType,
      paperInvoice: filter.paperInvoice,
      zuc20: filter.zuc20,
      kodPracoviska: filter.kodPracoviska,
    },
    onSubmit: () => {},
    validate: (values) => {
      const filterState: FilterInvoiceValues = {
        periods: values.periods,
        search: values.search,
        state: values.state,
        invoiceType: values.invoiceType,
        paperInvoice: values.paperInvoice,
        zuc20: values.zuc20,
        kodPracoviska: values.kodPracoviska,
      };
      setIsResetVisible(!sameOjects(filterState, initialState));
      const fs = JSON.stringify(filterState);
      if (lastFilterState !== fs) {
        onChange(filterState);
        dispatch(
          setInvoicesFilter({
            invoiceState: filterState.state,
            invoiceType: filterState.invoiceType,
            kodPracoviska: filterState.kodPracoviska,
            paperInvoice: filterState.paperInvoice,
            periods: filterState.periods,
            search: filterState.search,
            zuc20: filterState.zuc20,
          }),
        );
        setLastFilterState(fs);
      }

      return {};
    },
  });
  const periods = useField('periods', form);
  const state = useField('state', form);
  const invoiceType = useField('invoiceType', form);
  const paperInvoice = useField('paperInvoice', form);
  const zuc20 = useField('zuc20', form);
  const kodPracoviska = useField('kodPracoviska', form);
  const resetFilter = useCallback(() => {
    form.reset();
    form.resetFieldState('state');
    form.resetFieldState('invoiceType');
    form.resetFieldState('paperInvoice');
    form.resetFieldState('zuc20');
    form.resetFieldState('kodPracoviska');
    setResetSearchBar(true);
    dispatch(resetInvoicesFilter());
    setSearchParams({});
    setTimeout(() => {
      setResetSearchBar(false);
    }, 500);
  }, [dispatch, form, setSearchParams]);
  const choosePeriods = useCallback(
    (values, isInitialState) => {
      if (!isInitialState) resetFilter();
      form.change('periods', values);
      dispatch(setInvoicesFilter({ periods: values }));
    },
    [dispatch, form, resetFilter],
  );
  useEffect(() => {
    if (visibleFullFilter) {
      dajZoznamKodovPracovisk().then((resp) => {
        setKodyPracovisk(resp.zoznamKodov);
        setIsLoaded(true);
      });
    } else {
      setTimeout(() => {
        setIsLoaded(true);
      }, 500);
    }
  }, [visibleFullFilter]);
  const renderPeriod = (
    <MultiPeriodsFilter
      id="faktury-obdobie"
      label={texts.labels.period}
      maxChoose={12}
      minPeriod={201001}
      onChoose={choosePeriods}
      values={periods.input.value}
    />
  );
  const renderVS = (
    <SearchBarFilter
      debounce
      id="faktury-vyhladavanie"
      initialValue={filter.search || undefined}
      label={texts.labels.vs}
      onSearch={(val) => form.change('search', val)}
      refresh={resetSearchBar}
      withoutTooltip
    />
  );
  const renderState = (
    <SelectFilter
      {...state.input}
      id="faktury-stav"
      label={texts.labels.state}
      options={[
        getDefaultSelectOption('Všetky'),
        ...INVOICE_STATES.map((b) => ({
          label: safeString(getInvoiceState(b.value)),
          value: b.value,
        })),
      ]}
    />
  );
  const renderZuc20 = visibleFullFilter && (
    <SelectFilter
      {...zuc20.input}
      id="faktury-zuctovanie2"
      label={texts.labels.zuc20}
      options={[
        getDefaultSelectOption('Všetky'),
        ...DEFAULT_FILTER_STATES.map((d) => ({
          label: d.label,
          value: d.value,
        })),
      ]}
    />
  );
  const renderPaperInvoice = visibleFullFilter && (
    <SelectFilter
      {...paperInvoice.input}
      id="faktury-papierovaFaktura"
      label={texts.labels.paperInvoice}
      options={[
        getDefaultSelectOption('Všetky'),
        ...DEFAULT_FILTER_STATES.map((d) => ({
          label: d.label,
          value: d.value,
        })),
      ]}
    />
  );
  const renderInvoiceType = visibleFullFilter && (
    <SelectFilter
      {...invoiceType.input}
      id="faktury-typFaktury"
      label={texts.labels.invoiceType}
      options={[
        getDefaultSelectOption('Všetky'),
        ...INVOICE_TYPES.map((d) => ({
          label: d,
          value: d,
        })),
      ]}
    />
  );
  const renderKodPracoviska = visibleFullFilter &&
    !_.isEmpty(kodyPracovisk) && (
      <SelectFilter
        {...kodPracoviska.input}
        id="faktury-kodPracoviska"
        label={texts.labels.code}
        options={[
          getDefaultSelectOption('Všetky'),
          ...kodyPracovisk.map((d) => ({
            label: d,
            value: d,
          })),
        ]}
      />
    );
  const renderResetFilter = isResetVisible && (
    <ButtonLink
      className="text-color-black no-pad no-mrg"
      onClick={resetFilter}
    >
      {texts.buttons.reset}
    </ButtonLink>
  );
  if (resetSearchBar || !isLoaded)
    return (
      <div className={cx(classesApp.appForm, classes.filterWrapper)}>
        {renderPeriod}
        <InputPreloader />
        <InputPreloader />
      </div>
    );
  return (
    <div className={cx(classes.filterWrapper)}>
      {renderPeriod}
      {renderVS}
      {renderState}
      {renderZuc20}
      {renderPaperInvoice}
      {renderInvoiceType}
      {renderKodPracoviska}
      {renderResetFilter}
    </div>
  );
};

export default Filter;
