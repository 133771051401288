import { createUseStyles } from 'react-jss';
import { breakpoints, getRem } from '@dovera/design-system';

export default createUseStyles({
  filter: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      width: '100%',
      '&:not(:last-child)': {
        marginRight: getRem(24),
      },
      [`@media screen and (min-width: ${breakpoints.m + 1}px)`]: {
        '&:not(&:first-child, &:nth-child(2))': {
          maxWidth: getRem(150),
        },
      },
    },
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      display: 'block',
      '& > div': {
        marginBottom: getRem(16),
      },
    },
  },
});
