// @ts-nocheck
import useStyles from '../../Profil.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import { getSalutation, hotjarMasking } from '../../../../utils/strings.utils';
import Man20 from '../../../../assets/illustrations/patients/man20+.png';
import Woman20 from '../../../../assets/illustrations/patients/woman20+.png';
import {
  GridColTable,
  GridRowTable,
  GridTable,
} from '../../../../components/GridTable';
import strings from '../../../../constants/strings';
// @ts-check

const texts = strings.profile.labels;

const ProfileCardDoctor = () => {
  const classes = useStyles();
  const profil = useSelector((state: RootState) => state.profil.profil);
  const { autentifikovaneOsoby, email, ico } = useSelector(
    (state: RootState) => state.poskytovatel,
  );
  // profil.data?.nazovPzs
  const udajeLekar = autentifikovaneOsoby?.udajeLekarOsoba;
  const renderCard = udajeLekar && (
    <GridTable>
      <GridRowTable>
        <GridColTable>
          <b>{texts.position}</b>
        </GridColTable>
        <GridColTable spaceLeft>Lekár</GridColTable>
      </GridRowTable>
      <GridRowTable>
        <GridColTable>
          <b>{texts.doctorCode}</b>
        </GridColTable>
        <GridColTable spaceLeft>{udajeLekar.lekarKod}</GridColTable>
      </GridRowTable>
      <GridRowTable>
        <GridColTable>
          <b>{texts.odbornosti}</b>
        </GridColTable>
        <GridColTable spaceLeft>
          {profil?.data?.odbornosti.map((o) => (
            <div key={`expertise--${o.kodOdbNz}--${o.kodTypZS}`}>
              {`${o.kodOdbNz} - ${o.nazovOdbNz}`}
            </div>
          ))}
        </GridColTable>
      </GridRowTable>
      <GridRowTable>
        <GridColTable>
          <b>{texts.email}</b>
        </GridColTable>
        <GridColTable spaceLeft>{hotjarMasking(email || '-')}</GridColTable>
      </GridRowTable>
      <GridRowTable>
        <GridColTable>
          <hr />
        </GridColTable>
        <GridColTable>
          <hr />
        </GridColTable>
      </GridRowTable>
      <GridRowTable>
        <GridColTable>{hotjarMasking(profil.data?.nazovPzs)}</GridColTable>
        <GridColTable spaceLeft>
          {`${texts.ico} `}
          {hotjarMasking(ico)}
        </GridColTable>
      </GridRowTable>
    </GridTable>
  );
  if (!udajeLekar) return <span />;
  return (
    <div className={classes.profileCard}>
      <h5 className="mb with-illustration">
        <img
          alt="doctor-ico"
          src={
            udajeLekar.priezvisko.toLowerCase().endsWith('ová')
              ? Woman20
              : Man20
          }
          srcSet={
            udajeLekar.priezvisko.toLowerCase().endsWith('ová')
              ? Woman20
              : Man20
          }
        />{' '}
        {hotjarMasking(
          getSalutation(
            udajeLekar.titulPred,
            udajeLekar.meno,
            udajeLekar.priezvisko,
            udajeLekar.titulZa,
          ),
        )}
      </h5>
      {renderCard}
    </div>
  );
};

export default ProfileCardDoctor;
