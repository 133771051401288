import {
  GetMenuItemsResponse,
  GetReportDataResponse,
  GetReportStructureResponse,
  GetReportWidgetsResponse,
} from '../../types/reports.types';
import {
  GetMenuItemsResponseSK,
  GetReportDataResponseSK,
  GetReportStructureResponseSK,
  GetReportWidgetsResponseSK,
} from '../apiTypes/reports.types';

export const convertGetMenuItems = (
  data: GetMenuItemsResponseSK,
): GetMenuItemsResponse => ({
  expertise: data.kodOdbNz,
  themes: data.temy.map((theme) => ({
    sections: theme.sekcie.map((section) => ({
      id: section.id,
      order: section.poradie,
      sectionCode: section.kodSekcie,
      sectionName: section.nazovSekcie,
    })),
    theme: theme.tema,
  })),
});

export const convertGetReportStructure = (
  data: GetReportStructureResponseSK,
): GetReportStructureResponse => ({
  report: {
    contentHeader: data.report.obsahHlavicka,
    tabs: data.report.taby.map((tab) => ({
      filters:
        tab.filtre?.map((filter) => ({
          filterId: filter.idFiltra,
          name: filter.nazov,
          type: filter.typ,
          values: filter.hodnoty?.map((value) => ({
            value: value.hodnota,
            valueId: value.idHodnoty,
          })),
        })) || null,
      id: tab.id,
      name: tab.nazov,
    })),
  },
});

export const convertGetReportData = (
  data: GetReportDataResponseSK,
): GetReportDataResponse => ({
  filterContent: data.obsahFilter,
  filterValue: data.hodnotaFilter,
  isDefault: data.priznakDefault,
  isDefaultTab: data.priznakDefaultTab,
  reportContent: data.obsahReport,
  reportId: data.idReport,
  tabId: data.idTab,
});

export const convertGetReportWidgets = (
  data: GetReportWidgetsResponseSK,
): GetReportWidgetsResponse => ({
  widgets: data.widgety.map((widget) => ({
    column: widget.stlpec,
    content: widget.obsah,
    date: widget.datum,
    id: widget.id,
    widgetCode: widget.kodWidgetu,
  })),
});
