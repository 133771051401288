import { useEffect } from 'react';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import { Container, Grid, GridCol } from '@dovera/design-system';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { fetchProfile } from '../../../api/profil';
import { DajProfilPayload } from '../../../types/api/profil';
import { LoadingState } from '../../../types';
import strings from '../../../constants/strings';
import ProfileCardDoctor from './ProfileCard/ProfileCardDoctor';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import { useAppDispatch } from '../../../hooks/useStore';
import { getPZS } from '../../../slices/auth.slice';
import ProfileCard from './ProfileCard/ProfileCard';
import ProfileLinks from './ProfileLinks/ProfileLinks';
import ContentHeader from '../../../components/ContentHeader/ContentHeader';

const texts = strings.profile;

const Profil = () => {
  const dispatch = useAppDispatch();
  const pzs = useSelector((state: RootState) => getPZS(state.auth));
  const { autentifikovaneOsoby, preLekara } = useSelector(
    (state: RootState) => state.poskytovatel,
  );
  const { data, dataState, error } = useSelector(
    (state: RootState) => state.profil.profil,
  );
  useEffect(() => {
    if (
      pzs.userId &&
      pzs.vzId &&
      [LoadingState.none, LoadingState.reset].includes(dataState)
    ) {
      const payload: DajProfilPayload = {
        idPouzivatel: pzs.userId,
        idVz: pzs.vzId,
        idLekarOsoba: preLekara
          ? autentifikovaneOsoby?.lekarOsoba?.lekarOsobaId || null
          : null,
      };
      dispatch(fetchProfile(payload));
    }
  }, [dispatch, autentifikovaneOsoby, pzs, dataState, preLekara]);
  return (
    <StaticMenuLayout
      contentHeader={
        <ContentHeader flex>
          <Container>
            <Breadcrumbs
              items={[
                {
                  name: 'Profil',
                },
              ]}
            />
            <h2 className="mb">{texts.title}</h2>
          </Container>
        </ContentHeader>
      }
      contentWrapperId="dlek-profile"
      hideEPNotifications
    >
      <Grid>
        <GridCol size={{ l: 6, m: 12 }}>
          {autentifikovaneOsoby?.lekarOsoba?.lekarOsobaId && preLekara ? (
            <ProfileCardDoctor />
          ) : (
            <ProfileCard data={data} dataState={dataState} error={error} />
          )}
        </GridCol>
        <GridCol size={{ l: 6, m: 12 }}>
          <ProfileLinks />
        </GridCol>
      </Grid>
    </StaticMenuLayout>
  );
};

export default Profil;
