import { combineReducers } from '@reduxjs/toolkit';

import appReducer from './slices/app.slice';
import cakackyReducer from './slices/cakacky.slice';
import parametersReducer from './slices/parameters.slice';
import authReducer from './slices/auth.slice';
import diagnozyReducer from './slices/diagnozy.slice';
import poskytovatelReducer from './slices/poskytovatel.slice';
import userActivityReducer from './slices/userActivity.slice';
import menuReducer from './slices/menu.slice';
import pacientiReducer from './slices/pacienti.slice';
import versionReducer from './slices/versions.slice';
import zmluvyReducer from './slices/zmluvy.slice';
import dokumentyReducer from './slices/dokumenty.slice';
import cmsReducer from './slices/cms.slice';
import pomocnikReducer from './slices/pomocnik.slice';
import profilReducer from './slices/profil.slice';
import pzsReducer from './slices/overeniaPrehlady.slice';
import feedbackReducer from './slices/feedback.slices';
import spaProposalsReducer from './slices/spaProposals.slice';
import kapitacieReducer from './slices/kapitacie.slice';
import pzsAuthReducer from './slices/pzsAuth.slice';
import widgetNaRiesenieReducer from './slices/widgetNaRiesenie.slice';
import bannerReducer from './slices/banners.slice';
import tableReducer from './slices/table.slice';
import spravyReducer from './slices/spravy.slice';
import zuctovanieReducer from './slices/zuctovanie.slice';
import drugProposalsReducer from './slices/drugProposals.slice';
import reportsReducer from './slices/reports.slice';

const globalReducer = combineReducers({
  app: appReducer,
  cakacky: cakackyReducer,
  banner: bannerReducer,
  cms: cmsReducer,
  parameters: parametersReducer,
  auth: authReducer,
  diagnozy: diagnozyReducer,
  dokumenty: dokumentyReducer,
  feedback: feedbackReducer,
  poskytovatel: poskytovatelReducer,
  userActivity: userActivityReducer,
  menu: menuReducer,
  spaProposals: spaProposalsReducer,
  drugProposals: drugProposalsReducer,
  pacienti: pacientiReducer,
  kapitacie: kapitacieReducer,
  pomocnik: pomocnikReducer,
  profil: profilReducer,
  pzs: pzsReducer,
  pzsAuth: pzsAuthReducer,
  spravy: spravyReducer,
  version: versionReducer,
  widgetNaRiesenie: widgetNaRiesenieReducer,
  zmluvy: zmluvyReducer,
  table: tableReducer,
  reports: reportsReducer,
  zuctovanie: zuctovanieReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'resetStore') {
    state = undefined;
  }
  return globalReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
