import { useCallback, useEffect, useState } from 'react';
import { Pagination, TableApp } from '@dovera/design-system';
import strings from '../../../../constants/strings';
import {
  formatNameStr,
  formatNumberToString,
  hotjarMasking,
  nbsp,
} from '../../../../utils/strings.utils';
import {
  DATE_INTERNATIONAL,
  formatDate,
  getMoment,
} from '../../../../utils/date.utils';
import { UvazokLekaraPZS } from '../../../../types/poskytovatel.types';
import { useAppDispatch } from '../../../../hooks/useStore';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import FilterDoctors from '../Filter/FilterDoctors';
import { TABLE_PAGINATION } from '../../../../constants/misc';
import _ from 'lodash';
import {
  DajZoznamUvazkovLekarovPZSPayload,
  UvazokTyp,
} from '../../../../types/api/poskytovatel';
import { fetchDoctors } from '../../../../api/poskytovatel';
import { LoadingState } from '../../../../types';
import { NoDataIllustration } from '../../../../components/EmptyState';
import Export from '../Export/Export';
import { mergeMultidimensionalArray } from '../../../../utils/array.utils';
import TablePreloaderSkeleton from '../../../../components/TablePreloaderSkeleton/TablePreloaderSkeleton';
import { getPZS } from '../../../../slices/auth.slice';

const texts = strings.profile.overeniaPrehlady.filter.doctors.columns;

const TableDoctors = () => {
  const [actualPage, setActualPage] = useState(0);
  const [activeFilter, setActiveFilter] = useState(false);
  const [filteredData, setFilteredData] = useState<UvazokLekaraPZS[][]>([]);
  const dispatch = useAppDispatch();
  const pzs = useSelector((state: RootState) => getPZS(state.auth));
  const doctors = useSelector(
    (state: RootState) => state.pzs.overeniaPrehlady.doctors,
  );
  const { autentifikovaneOsoby, preLekara } = useSelector(
    (state: RootState) => state.poskytovatel,
  );
  const onFiltered = useCallback(
    ({
      doctorName,
      expertise,
      workingTime,
    }: {
      doctorName: string;
      expertise: string;
      workingTime: string;
    }) => {
      const filteredData = doctors.data?.filter(
        (d) =>
          (!doctorName ||
            d.lekarMeno.toLowerCase().includes(doctorName.toLowerCase()) ||
            d.lekarKod.toLowerCase().includes(doctorName.toLowerCase()) ||
            `${d.lekarKod} - ${d.lekarMeno}`.toLowerCase() ===
              doctorName.toLowerCase().trim()) &&
          (!expertise ||
            expertise === 'all' ||
            d.odbLekKod.includes(expertise) ||
            d.odbLekNazov.toLowerCase().includes(expertise)) &&
          (!workingTime ||
            workingTime === 'all' ||
            d.uvazokTyp.toLowerCase().includes(workingTime.toLowerCase())),
      );
      const filtered = _.chunk(filteredData, TABLE_PAGINATION) || null;
      setFilteredData(filtered);
      setTimeout(() => {
        if (doctorName || expertise || workingTime) setActiveFilter(true);
      }, 500);
    },
    [doctors.data],
  );
  useEffect(() => {
    const payload: DajZoznamUvazkovLekarovPZSPayload = {
      datumDo: getMoment().format(DATE_INTERNATIONAL),
      idLekarOsoba: preLekara
        ? autentifikovaneOsoby?.lekarOsoba?.lekarOsobaId || null
        : null,
      idVZ: pzs.vzId || 0,
    };
    dispatch(fetchDoctors(payload));
  }, [
    autentifikovaneOsoby?.lekarOsoba?.lekarOsobaId,
    dispatch,
    preLekara,
    pzs.vzId,
  ]);
  useEffect(() => {
    onFiltered({ doctorName: '', expertise: '', workingTime: '' });
    // eslint-disable-next-line
  }, [doctors]);
  const renderNoData =
    (doctors.dataState === LoadingState.filled &&
      !doctors.data?.length &&
      !activeFilter) ||
    (!filteredData.length && doctors.data?.length && activeFilter) ? (
      <NoDataIllustration title="Neevidujeme žiadných lekárov" />
    ) : (
      doctors.dataState === LoadingState.error && (
        <NoDataIllustration
          illustration="technical"
          title={strings.defaultError}
        />
      )
    );
  const renderFilter = (
    <FilterDoctors
      doctors={doctors.filterOptions.doctors}
      expertises={doctors.filterOptions.expertises}
      onChange={(doctorName, expertise, workingTime) => {
        onFiltered({ doctorName, expertise, workingTime });
        setActualPage(0);
      }}
    />
  );
  const renderTable = (
    <TableApp
      caption=""
      className="table-caption--hide"
      columns={[
        { accessor: 'col1', Header: texts.name },
        { accessor: 'col2', Header: texts.ambulance },
        { accessor: 'col3', Header: texts.expertise },
        { accessor: 'col4', Header: texts.workingTime },
        { accessor: 'col5', Header: texts.valid },
      ]}
      data={
        filteredData?.[actualPage]?.map((d) => ({
          col1: (
            <>
              {hotjarMasking(d.lekarKod, 'b', 'd-block')}
              {hotjarMasking(formatNameStr(d.lekarMeno), 'small')}
            </>
          ),
          col2: (
            <>
              {hotjarMasking(d.ambulanciaKod, 'b', 'd-block')}
              {hotjarMasking(d.ambulanciaNazov, 'small')}
            </>
          ),
          col3: `${d.odbLekKod} - ${d.odbLekNazov}`,
          col4: nbsp(
            d.uvazokHodnota
              ? `${UvazokTyp[d.uvazokTyp]}: ${formatNumberToString(
                  d.uvazokHodnota,
                )}`
              : '0',
          ),
          col5: (
            <>
              {d.uvazokDatumOd && (
                <span className="d-block">
                  {nbsp(`Od: `)}
                  {nbsp(formatDate(d.uvazokDatumOd))}
                </span>
              )}
              {d.uvazokDatumDo && (
                <span className="d-block">
                  {nbsp(`Do: `)}
                  {nbsp(formatDate(d.uvazokDatumDo))}
                </span>
              )}
            </>
          ),
        })) || []
      }
      // @ts-ignore
      pagination={
        <Pagination
          currentPage={actualPage + 1}
          onChange={(pageNumber) => setActualPage(pageNumber - 1)}
          totalPages={filteredData.length || 0}
        />
      }
    />
  );
  const renderExport = (
    <Export
      ambulances={null}
      doctors={filteredData ? mergeMultidimensionalArray(filteredData) : null}
    />
  );
  if (doctors.dataState === LoadingState.fetching)
    return <TablePreloaderSkeleton columns={5} filterCols={3} hasFilter />;
  return (
    <>
      {renderExport}
      {renderFilter}
      {renderNoData || renderTable}
    </>
  );
};

export default TableDoctors;
