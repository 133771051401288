import { useEffect, useState } from 'react';
import useStyles from '../../Proposals.styles';
import { ButtonLink, Icon } from '@dovera/design-system';
import strings from '../../../../constants/strings';
import IconSearch from '../../../../components/CustomIcons/IconSearch';
import {
  SPA_INSUREE_VZ,
  SPA_TREATEMENT_STATES,
} from '../../../../constants/proposals';
import { ApprovedProposalsFilter } from '../../../../types/spaProposals.types';
import { useDebounce } from '../../../../hooks/useDebounce';
import { DATE_INTERNATIONAL, getMoment } from '../../../../utils/date.utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import {
  DatepickerFilter,
  InputFilter,
  MultiSelectFilter,
} from '../../../../components/Filter';

const texts = strings.proposals.spa.list.approvedProposals.filter;

interface Props {
  onFiltered: (values: ApprovedProposalsFilter, showAll: boolean) => void;
}

const Filter = ({ onFiltered }: Props) => {
  const classes = useStyles({ proposalType: 'KN' });
  const { storedFilter } = useSelector(
    (state: RootState) => state.spaProposals.list,
  );
  const [insuree, setInsuree] = useState(storedFilter?.insuree || '');
  const [reservation, setReservation] = useState(
    storedFilter?.reservation || '',
  );
  const [validityFrom, setValidityFrom] = useState<Date | null>(
    storedFilter?.validityFrom ? new Date(storedFilter?.validityFrom) : null,
  );
  const [validityTo, setValidityTo] = useState<Date | null>(
    storedFilter?.validityTo ? new Date(storedFilter?.validityTo) : null,
  );
  const [proposalNumber, setProposalNumber] = useState(
    storedFilter?.proposalNumber || '',
  );
  const [spaTreatment, setSpaTreatment] = useState(
    storedFilter?.spaTreatment || '',
  );
  const [insureeVZ, setInsureeVZ] = useState(storedFilter?.insureeVZ || '');
  const [isFilterCollapsed, setIsFilterCollapsed] = useState(false);
  const debouncedProposalNumber = useDebounce(proposalNumber, 500);
  const debouncedInsuree = useDebounce(insuree, 500);
  const debouncedReservationCode = useDebounce(reservation, 500);
  useEffect(() => {
    onFiltered(
      {
        insuree: debouncedInsuree,
        insureeVZ,
        proposalNumber: debouncedProposalNumber,
        reservationCode: debouncedReservationCode,
        spaTreatment,
        validityFrom: validityFrom
          ? getMoment(validityFrom).format(DATE_INTERNATIONAL)
          : '',
        validityTo: validityTo
          ? getMoment(validityTo).format(DATE_INTERNATIONAL)
          : '',
      },
      isFilterCollapsed,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedInsuree,
    debouncedProposalNumber,
    debouncedReservationCode,
    insureeVZ,
    onFiltered,
    spaTreatment,
    validityFrom,
    validityTo,
  ]);
  const renderInsuree = (
    <InputFilter
      addonsInside
      crossOrigin
      id="kn-vyhladavaniePacient"
      label={texts.insuree}
      onChange={(e) => setInsuree(e.target.value)}
      rightAddons={<IconSearch id={`icon-filter-insuree`} />}
      value={insuree}
    />
  );
  const renderReservation = (
    <InputFilter
      addonsInside
      crossOrigin
      id="kn-vyhladavanieRezervacnyKod"
      label={texts.reservation}
      onChange={(e) => setReservation(e.target.value)}
      rightAddons={<IconSearch id={`icon-filter-reservation`} />}
      value={reservation}
    />
  );
  const renderValidityFrom = (
    <DatepickerFilter
      className={classes.filterDatepicker}
      id="kn-datumOd"
      label={texts.validityFrom}
      maxDate={validityTo ? new Date(validityTo) : undefined}
      onChange={(value) => setValidityFrom(value)}
      value={validityFrom}
    />
  );
  const renderValidityTo = (
    <DatepickerFilter
      className={classes.filterDatepicker}
      id="kn-datumDo"
      label={texts.validityTo}
      minDate={validityFrom ? new Date(validityFrom) : undefined}
      onChange={(value) => setValidityTo(value)}
      value={validityTo}
    />
  );
  const renderProposalNumber = (
    <InputFilter
      addonsInside
      crossOrigin
      id="kn-vyhladavanieCislo"
      isPrivate={false}
      label={texts.proposalNumber}
      onChange={(e) => setProposalNumber(e.target.value)}
      rightAddons={<IconSearch id={`icon-filter-proposalNumber`} />}
      value={proposalNumber}
    />
  );
  const renderspaTreatment = (
    <MultiSelectFilter
      defaultOption="Všetky"
      id="kn-kupelnaLiecba"
      label={texts.spaTreatment}
      onSelected={(value) => setSpaTreatment(value)}
      options={SPA_TREATEMENT_STATES}
    />
  );
  const renderInsureeVZ = (
    <MultiSelectFilter
      defaultOption="Nezáleží"
      id="kn-poistnyVztah"
      label={texts.insureeVZ}
      onSelected={(value) => setInsureeVZ(value)}
      options={SPA_INSUREE_VZ}
    />
  );
  const renderShowMoreLess = (
    <ButtonLink
      className="no-pad no-mrg"
      onClick={() => setIsFilterCollapsed(!isFilterCollapsed)}
    >
      <Icon
        className="text-space-right"
        name={isFilterCollapsed ? '16-minus' : '16-plus'}
        size="medium"
      />
      {isFilterCollapsed ? texts.less : texts.more}
    </ButtonLink>
  );
  return (
    <form className={classes.listFilter}>
      {renderInsuree}
      {renderReservation}
      {renderValidityFrom}
      {renderValidityTo}
      {isFilterCollapsed && (
        <>
          {renderProposalNumber}
          {renderspaTreatment}
          {renderInsureeVZ}
        </>
      )}
      {renderShowMoreLess}
    </form>
  );
};

export default Filter;
