import { breakpoints, color, colors, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  horizontalStructure: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    '& > div': {
      '&:first-child:not(:last-child)': {
        paddingRight: getRem(12),
      },
      '&:last-child:not(:first-child)': {
        paddingLeft: getRem(12),
      },
      '& .card': {
        height: `calc(100% - ${getRem(32)})`,
        '& .card__section': {
          height: '100%',
        },
      },
    },
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      flexDirection: 'column',
      '& > div': {
        width: '100% !important',
        '&:first-child': {
          paddingRight: '0 !important',
        },
        '&:last-child': {
          paddingLeft: '0 !important',
        },
      },
    },
  },
  structure: {
    display: 'block',
    '& h6': {
      marginBottom: getRem(4),
    },
  },
  banner: {
    maxWidth: getRem(624),
    '& .notification__contentArea': {
      gap: 0,
    },
    '& .notification__iconArea': {
      display: 'block',
    },
    '& button': {
      marginTop: `0 !important`,
    },
    '&.notification--blue': {
      '& .btn--link': {
        color: color('black'),
      },
    },
    '& .btn--link.block': {
      marginTop: `${getRem(8)} !important`,
    },
  },
  card: {
    borderRadius: getRem(16),
    '& .card__section': {
      borderRadius: getRem(16),
    },
    '& .grid': {
      marginBottom: getRem(12),
      alignItems: 'flex-end',
      '& h2, & h3, & h4, & h5': {
        marginBottom: getRem(16),
      },
    },
  },
  grid: {
    marginBottom: getRem(32),
    '& h2, & h3, & h4, & h5': {
      marginTop: getRem(8),
      marginBottom: getRem(12),
    },
    '& > .grid__col--l-6:nth-child(n+3)': {
      marginTop: getRem(24),
    },
    '& > .grid__col--l-4:nth-child(n+4)': {
      marginTop: getRem(24),
    },
    '& > .grid__col--l-3:nth-child(n+5)': {
      marginTop: getRem(24),
    },
    '& > .grid__col--l-2:nth-child(n+7)': {
      marginTop: getRem(24),
    },
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      '& > .grid__col--s-12:nth-child(n+2)': {
        marginTop: getRem(24),
      },
      '& > .grid__col--s-6:nth-child(n+3)': {
        marginTop: getRem(24),
      },
      '& > .grid__col--s-4:nth-child(n+4)': {
        marginTop: getRem(24),
      },
      '& > .grid__col--s-4:nth-child(n+5)': {
        marginTop: getRem(24),
      },
    },
    [`@media screen and (max-width: ${breakpoints.s}px)`]: {
      '& > .grid__col:nth-child(n+2)': {
        marginTop: getRem(24),
      },
    },
  },
  link: {
    padding: 0,
    margin: 0,
    minHeight: 0,
    '&.inline-block': {
      display: 'inline',
    },
    '&.block': {
      display: 'block',
      marginTop: getRem(16),
      marginLeft: getRem(-2),
    },
  },
  carouselSlide: {
    minWidth: '100%',
    padding: `${getRem(32)} ${getRem(32)} ${getRem(52)}`,
    '& span': {
      fontSize: getRem(14),
      color: colors.grey[500],
      paddingLeft: getRem(24),
    },
    '& h6': {
      paddingLeft: getRem(21),
      '& + div': {
        paddingLeft: getRem(24),
      },
    },
    '& button': {
      marginTop: `${getRem(8)} !important`,
    },
  },
  carouselDotsWrapper: {
    position: 'relative',
    width: '90%',
    margin: `${getRem(-16)} auto 0`,
    textAlign: 'center',
  },
  carouselDots: {
    position: 'absolute',
    width: '100%',
    marginTop: getRem(-32),
  },
  carouselDot: {
    backgroundColor: color('grey', 300),
    borderRadius: '100%',
    cursor: 'pointer',
    height: getRem(8),
    width: getRem(8),
    margin: `0 ${getRem(4)}`,
    '&.active': {
      backgroundColor: color('primary', 500),
    },
  },
  cmsModalContent: {
    '& h4': {
      fontSize: getRem(20),
    },
  },
  modalBottomBtns: {
    '& button': {
      marginTop: getRem(16),
    },
  },
  structureIcon: {
    paddingRight: `0 !important`,
  },
  modal: {
    maxHeight: '90%',
  },
});
