import { ReactNode } from 'react';
import { RadioGroupFilter, SelectFilter } from '../../../components/Filter';
import useStyles from '../styles';
import { Radio } from '@dovera/design-system';
import { useAppDispatch } from '../../../hooks/useStore';
import { setFilter } from '../../../slices/reports.slice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { TabFilter } from '../../../types/reports.types';

interface Props {
  filters: TabFilter[];
}

const Filter = ({ filters }: Props) => {
  const dispatch = useAppDispatch();
  const filterState = useSelector(
    (state: RootState) => state.reports.virtualBudget.filter,
  );
  const classes = useStyles();
  const getField = ({
    filterId,
    name,
    options,
    type,
  }: {
    filterId: number;
    name: string;
    options: { label: string; value: string }[];
    type?: 'dropdown' | 'radio' | string;
  }): ReactNode => {
    switch (type) {
      case 'dropdown':
        return (
          <SelectFilter
            id={`${type}--${filterId}`}
            label={name}
            onChange={(value) => dispatch(setFilter({ [filterId]: value }))}
            options={options}
          />
        );
      case 'radio':
        return (
          <RadioGroupFilter
            id={`${type}--${filterId}`}
            label={name}
            onChange={(value) => dispatch(setFilter({ [filterId]: value }))}
          >
            {options.map((o, key) => (
              <Radio
                key={`${type}--${filterId}--${o.value}`}
                id={`${type}--${filterId}--${o.value}`}
                isChecked={
                  filterState?.[filterId]
                    ? filterState?.[filterId] === o.value
                    : key === 0
                }
                name={`${type}--${filterId}`}
                value={o.value}
              >
                {o.label}
              </Radio>
            ))}
          </RadioGroupFilter>
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.filter}>
      {filters.map((f) =>
        getField({
          filterId: f.filterId,
          name: f.name,
          options: f.values.map((v) => ({
            label: v.value,
            value: v.valueId.toString(),
          })),
          type: f.type,
        }),
      )}
    </div>
  );
};

export default Filter;
