import useStyles from '../../../Profil.styles';
import { Icon, Link, Tooltip } from '@dovera/design-system';
import { cx } from '../../../../../utils/exports';
import strings from '../../../../../constants/strings';
import { OrdinacneHodiny } from '../../../../../types/poskytovatel.types';
import { Adresa } from '../../../../../types/models/Adresa';
import {
  addressLowerCases,
  hotjarMasking,
} from '../../../../../utils/strings.utils';
import {
  GridColTable,
  GridRowTable,
  GridTable,
} from '../../../../../components/GridTable';
import { formatHours } from '../../../../../utils/date.utils';
import SafeHtml from '../../../../../components/SafeHtml/SafeHtml';
import { arraySort } from '../../../../../utils/array.utils';
import { ReactNode } from 'react';
import CardBubble from '../../../../../components/CardBubble/CardBubble';

const texts = strings.profile.overeniaPrehlady.ambulanceDetail.card;

interface Props {
  addresses: Adresa[];
  workingHours: OrdinacneHodiny[];
}

const calculateWrokingHours = (
  day: string,
  dayInWeek: number,
  workingHours: OrdinacneHodiny[],
): ReactNode | string => {
  if (
    !dayInWeek ||
    !workingHours ||
    !workingHours.length ||
    !workingHours?.some((w) => w.denVTyzdni === dayInWeek)
  )
    return <SafeHtml html={`<span>${day}</span> -`} />;
  const days = arraySort(workingHours, 'typOrdHodin')?.filter(
    (w) => w.denVTyzdni === dayInWeek,
  );
  let resp = '';
  days.forEach((d, key) => {
    resp += key === 0 ? `<span>${day}</span> ` : `<br /><span></span>`;
    resp += ` ${formatHours(d?.casOd)} - ${formatHours(d?.casDo)}`;
    if (d.typOrdHodin) resp += ` (${d.typOrdHodin})`;
  });
  return <SafeHtml html={resp} />;
};

const AmbulanceCard = ({ addresses, workingHours }: Props) => {
  const classes = useStyles();
  const renderAddress = (
    <>
      <h6 className={cx(classes.subtitle, 'mb-small')}>
        <Icon name="map" size="medium" />
        {texts.titleAddress}
      </h6>
      {addresses && addresses.length ? (
        <>
          {addresses.map((a, key) => (
            <div key={`ambulance-address--${key}`} className="d-flex">
              {hotjarMasking(
                addressLowerCases(
                  `${a.nazovUlica || ''} ${a.orientacneCislo || ''}, ${
                    a.psc || ''
                  } ${a.nazovObce || ''}`,
                  true,
                ),
              )}
            </div>
          ))}
        </>
      ) : (
        '-'
      )}
    </>
  );
  const renderOfficeHours = (
    <>
      <div className="mb-large" />
      <div className={classes.subtitleWithButtons}>
        <h6 className={cx(classes.subtitle, 'mb clickable')}>
          <Tooltip
            dialog={<SafeHtml html={texts.tootlip} wrapper="span" />}
            id="oh-tooltip"
          >
            <span className="clickable">
              <Icon name="clock" size="medium" />
              {texts.titleOfficeHours}
            </span>
          </Tooltip>
        </h6>
        <Tooltip
          dialog={<SafeHtml html={texts.tooltipAbsence} wrapper="span" />}
          id="oh-tooltip-absence"
        >
          <div className={cx(classes.officeHoursButtons, 'clickable')}>
            <Link
              className="btn btn--link text-space-half-right"
              download
              href="/documents/oznamenie-o-nepritomnosti-v-ambulancii_vpis.pdf"
              placeholder
              target="_blank"
            >
              <Icon name="download" size="medium" />
              {texts.btnSetAbsence}
            </Link>
          </div>
        </Tooltip>
      </div>

      <div className={classes.gridTableWrapper}>
        <GridTable className={classes.gridTableOH}>
          <GridRowTable>
            <GridColTable>
              {calculateWrokingHours('Pondelok:', 1, workingHours)}
            </GridColTable>
            <GridColTable>
              {calculateWrokingHours('Utorok:', 2, workingHours)}
            </GridColTable>
            <GridColTable>
              {calculateWrokingHours('Streda:', 3, workingHours)}
            </GridColTable>
            <GridColTable>
              {calculateWrokingHours('Štvrtok:', 4, workingHours)}
            </GridColTable>
            <GridColTable>
              {calculateWrokingHours('Piatok:', 5, workingHours)}
            </GridColTable>
          </GridRowTable>
          <GridRowTable>
            <GridColTable>
              {calculateWrokingHours('Sobota:', 6, workingHours)}
            </GridColTable>
            <GridColTable>
              {calculateWrokingHours('Nedeľa:', 7, workingHours)}
            </GridColTable>
          </GridRowTable>
        </GridTable>
      </div>
    </>
  );
  return (
    <CardBubble className={classes.infoCard} data-testid="ambulance-card">
      {renderAddress}
      {renderOfficeHours}
    </CardBubble>
  );
};

export default AmbulanceCard;
