import { useState } from 'react';
import useStyles from '../../Profil.styles';
import { useField, useForm } from 'react-final-form-hooks';
import strings from '../../../../constants/strings';
import {
  ButtonLink,
  ButtonSecondary,
  Icon,
  Input,
  Notification,
} from '@dovera/design-system';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';
import regex from '../../../../constants/regex';
import { CustomTooltip } from '../../../../components/CustomTooltip';
import { IconInfo } from '../../../../components';
import {
  getPasswordStrengthNumber,
  validationMsg,
} from '../../../../utils/form.utils';
import PasswordStrengthBar from '../../../../components/PasswordStrengthBar/PasswordStrengthBar';
import DetectCapsLock from '../../../../components/DetectCapsLock/DetectCapsLock';
import { cx } from '../../../../utils/exports';
import IconEyeCrossed from '../../../../components/CustomIcons/IconEyeCrossed';
import { VALIDATION } from '../../../../types/validation.types';

const texts = strings.profile.loginDetails;

const validateForm = (values: { [x: string]: any }) => {
  const errors: any = {};
  if (!values.currentPassword)
    errors.currentPassword = validationMsg(VALIDATION.PasswordEmpty);
  if (!values.newPassword)
    errors.newPassword = validationMsg(VALIDATION.PasswordNewEmpty);
  if (!values.repeatNewPassword)
    errors.repeatNewPassword = validationMsg(VALIDATION.PasswordRepeatEmpty);
  if (values.newPassword && !regex.PASSWORD.test(values.newPassword))
    errors.newPassword = validationMsg(VALIDATION.PasswordRegex);
  if (values.newPassword !== values.repeatNewPassword)
    errors.repeatNewPassword = validationMsg(VALIDATION.PasswordRepeatNotSame);

  return errors;
};

interface Props {
  currentPasswordError?: string | null;
  error: string | null;
  isLoading: boolean;
  newPasswordError?: string | null;
  onSubmit: (data?: any) => void;
  success: boolean | null;
}

const FormPassword = ({
  currentPasswordError,
  error,
  isLoading,
  newPasswordError,
  onSubmit,
  success,
}: Props) => {
  const classes = useStyles();
  const [passwordStrengthNumber, setPasswordStrengthNumber] =
    useState<number>(0);
  const [hidePasswords, setHidePasswords] = useState({
    currentPassword: true,
    newPassword: true,
    repeatNewPassword: true,
  });
  const [isCapsLock, setIsCapsLock] = useState(false);
  const { form, handleSubmit, values } = useForm({
    onSubmit: (values: any) => onSubmit(values),
    initialValues: {
      currentPassword: '',
      newPassword: '',
      repeatNewPassword: '',
    },
    validate: (valuesToValid) => validateForm(valuesToValid),
  });

  const currentPasswordField = useField('currentPassword', form);
  const newPasswordField = useField('newPassword', form, (value) => {
    if (value) setPasswordStrengthNumber(getPasswordStrengthNumber(value));
  });
  const repeatNewPasswordField = useField('repeatNewPassword', form);

  const renderEyePassword = (
    type: 'currentPassword' | 'newPassword' | 'repeatNewPassword',
  ) => (
    <ButtonLink
      className={cx('no-pad d-flex', classes.revealPasswordBtn)}
      onClick={() =>
        setHidePasswords({
          ...hidePasswords,
          [type]: !hidePasswords[type],
        })
      }
    >
      {!hidePasswords[type] ? (
        <IconEyeCrossed
          id={`profile--password-form--${type}-eye-crossed`}
          size="small"
        />
      ) : (
        <Icon
          color="black"
          id={`profile--password-form--${type}-eye`}
          name="view"
          size="small"
        />
      )}
    </ButtonLink>
  );

  const renderForm = (
    <form
      data-testid="profile--password-form"
      id="profile--password-form"
      onSubmit={handleSubmit}
    >
      <Input
        {...currentPasswordField.input}
        addonsInside
        crossOrigin
        data-lpignore
        error={
          (currentPasswordError && (
            <SafeHtml
              html={validationMsg(currentPasswordError)}
              wrapper="span"
            />
          )) ||
          (currentPasswordField.meta.touched &&
            currentPasswordField.meta.error && (
              <SafeHtml html={currentPasswordField.meta.error} wrapper="span" />
            ))
        }
        id="profile--currentPassword"
        inputType={hidePasswords.currentPassword ? 'password' : 'text'}
        isDisabled={error === VALIDATION.MaxAttemptsPassword}
        isRequired
        label={texts.labels.currentPassword}
        rightAddons={renderEyePassword('currentPassword')}
        role="textbox"
      />
      <Input
        {...newPasswordField.input}
        addonsInside
        crossOrigin
        data-lpignore
        error={
          (newPasswordError && (
            <SafeHtml html={validationMsg(newPasswordError)} wrapper="span" />
          )) ||
          (newPasswordField.meta.touched && newPasswordField.meta.error && (
            <SafeHtml html={newPasswordField.meta.error} wrapper="span" />
          ))
        }
        id="profile--newPassword"
        inputType={hidePasswords.newPassword ? 'password' : 'text'}
        isDisabled={error === VALIDATION.MaxAttemptsPassword}
        label={
          <div className="d-inline-flex">
            <span>{texts.labels.newPassword}</span>
            <span className="text-color-error text-space-half-right">
              &nbsp;*
            </span>
            <CustomTooltip
              dialog={
                <SafeHtml html={texts.tooltips.newPassword} wrapper="span" />
              }
              id="tooltip--profile--password-form"
              width={253}
            >
              <IconInfo id="profile--password-form--tooltip-icon" />
            </CustomTooltip>
          </div>
        }
        onKeyUp={(e) => setIsCapsLock(e.getModifierState('CapsLock'))}
        rightAddons={renderEyePassword('newPassword')}
        role="textbox"
      />
      {values.newPassword && (
        <PasswordStrengthBar
          score={passwordStrengthNumber}
          style={{ maxWidth: 323 }}
        />
      )}
      <DetectCapsLock capsLockOn={isCapsLock} />
      <Input
        {...repeatNewPasswordField.input}
        addonsInside
        crossOrigin
        data-lpignore
        error={
          repeatNewPasswordField.meta.touched &&
          repeatNewPasswordField.meta.error && (
            <SafeHtml html={repeatNewPasswordField.meta.error} wrapper="span" />
          )
        }
        id="profile--repeatPassword"
        inputType={hidePasswords.repeatNewPassword ? 'password' : 'text'}
        isDisabled={error === VALIDATION.MaxAttemptsPassword}
        isRequired
        label={texts.labels.repeatNewPassword}
        rightAddons={renderEyePassword('repeatNewPassword')}
        role="textbox"
      />
      {error && (
        <div className={classes.notification}>
          <Notification
            message={<SafeHtml html={validationMsg(error)} wrapper="span" />}
            variant={error === VALIDATION.InProgress ? 'blue' : 'error'}
          />
        </div>
      )}
      {success && (
        <div className={classes.notification}>
          <Notification
            message={strings.form.successFormDefault}
            variant="success"
          />
        </div>
      )}
      {error !== VALIDATION.MaxAttemptsPassword && (
        <ButtonSecondary isLoading={isLoading} role="button" submit>
          {texts.labels.button}
        </ButtonSecondary>
      )}
    </form>
  );
  return (
    <div className={classes.form}>
      <h5 className="mb">{texts.subtitlePassword}</h5>
      {renderForm}
    </div>
  );
};

export default FormPassword;
