import { Grid, GridCol } from '@dovera/design-system';
import strings from '../../../../../constants/strings';
import { ProposalPOIData } from '../../../../../types/proposals.types';
import { hotjarMasking } from '../../../../../utils/strings.utils';
import useStyles from '../../../Proposals.styles';
import {
  PROPOSAL_SUMMARY_LABEL_BREAKPOINTS,
  PROPOSAL_SUMMARY_VALUE_BREAKPOINTS,
} from '../../../../../constants/proposals';

const texts = strings.proposals.spa.new;

interface Props {
  insureeId: string;
  poiData?: ProposalPOIData | null;
}

const Summary = ({ insureeId, poiData }: Props) => {
  const classes = useStyles({ proposalType: 'LN' });
  return (
    <div className={classes.summary}>
      <Grid className="mb-small">
        <GridCol size={PROPOSAL_SUMMARY_LABEL_BREAKPOINTS}>
          {texts.labels.insureeId}
        </GridCol>
        <GridCol size={PROPOSAL_SUMMARY_VALUE_BREAKPOINTS}>
          {hotjarMasking(insureeId, 'b')}
        </GridCol>
      </Grid>
      <Grid>
        <GridCol
          size={PROPOSAL_SUMMARY_LABEL_BREAKPOINTS}
        >{`${texts.labels.patient}: `}</GridCol>
        <GridCol size={PROPOSAL_SUMMARY_VALUE_BREAKPOINTS}>
          {poiData &&
            hotjarMasking(`${poiData?.firstName} ${poiData?.lastName}`, 'b')}
        </GridCol>
      </Grid>
    </div>
  );
};

export default Summary;
