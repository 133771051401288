import { DetailAttributesGraph } from '../../../../types/parameters.types';
import { Bar, BarItem, Grid, GridCol, color } from '@dovera/design-system';
import { cx } from '../../../../utils/exports';
import useStyles from '../../styles/detail.styles';
import LineChart from '../../../../components/LineChart/LineChart';
import StatusIcon from './StatusIcon';
import AdditionalText from './AdditionalText';
import CompareChartNew from '../../../../components/CompareChart/CompareChart';

interface Props {
  attributes: DetailAttributesGraph;
}

const Graph = ({ attributes }: Props) => {
  const classes = useStyles();
  const { additionalText, data, graphType, status, title, value } = attributes;

  const renderGraphTitle = (
    <h4 className={cx('mb-large content-center-vert', classes.graphTitle)}>
      {title}
      <span
        className="text-space-half-left text-space-half-right"
        style={{
          color: status
            ? color(status === 'success' ? 'primary' : status, 600)
            : '#C5C6C8',
        }}
      >
        {value}
      </span>
      <StatusIcon status={status} />
    </h4>
  );

  const renderLineChart = graphType === 'thermometer' && (
    <Bar
      align="top"
      className={cx(classes.plnenieBar, 'mb-large')}
      direction={{ xs: 'vertical' }}
    >
      <BarItem
        className="text-left"
        style={{
          maxWidth: 300,
          marginTop: 0,
        }}
      >
        {additionalText?.map((item) => (
          <AdditionalText
            title={item.title}
            tooltip={item.tooltip}
            value={item.value}
          />
        ))}
      </BarItem>
      <BarItem
        isFilling
        style={{ marginRight: 40, maxWidth: 400, width: '100%' }}
      >
        <LineChart data={data} status={status} />
      </BarItem>
    </Bar>
  );
  const renderStickChart = graphType === 'stick' && (
    <CompareChartNew data={data} />
  );
  return (
    <div>
      <Grid>
        <GridCol size={{ m: 6, s: 12 }}>{renderGraphTitle}</GridCol>
        <GridCol
          className={classes.graphInfoGridWrapper}
          size={{ m: 6, s: 12 }}
        >
          {graphType === 'stick' &&
            additionalText?.map((item) => (
              <AdditionalText
                title={item.title}
                tooltip={item.tooltip}
                value={item.value}
              />
            ))}
        </GridCol>
      </Grid>

      {renderStickChart || renderLineChart}
    </div>
  );
};

export default Graph;
