import { createUseStyles } from 'react-jss';
import { breakpoints, color, fontWeight, getRem } from '@dovera/design-system';

export default createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    zIndex: 9,
    position: 'fixed',
    bottom: 150,
    right: 0,
    maxHeight: '80vh',
    overflowY: 'hidden',
    overflowX: 'hidden',
    [`@media screen and (max-height: 550px)`]: {
      bottom: 100,
    },
  },
  openedFeedback: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    zIndex: 999,
    position: 'fixed',
    bottom: 73,
    right: getRem(-410),
    maxHeight: 'auto',
    overflowY: 'hidden',
    overflowX: 'hidden',
    background: '#f7f7f7',
    boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.1)',
    transform: 'translateX(calc(-100% + 40px))',
    transition: 'transform 0.3s ease-in-out',
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      transform: 'none',
      right: 0,
    },
  },
  feedbackButton: {
    backgroundColor: color('primary', 500),
    borderRadius: '5px 0 0 5px',
    cursor: 'pointer',
    color: color('black'),
    fontSize: 16,
    fontWeight: fontWeight.semiBold,
    bottom: 150,
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 110,
    whiteSpace: 'nowrap',
    '& span': {
      transform: 'rotate(270deg)',
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        transform: 'rotate(90deg)',
        marginLeft: getRem(10),
        width: getRem(10),
      },
    },
  },
  header: {
    backgroundColor: color('secondary', 500),
    color: 'white',
  },
  content: {
    backgroundColor: '#FEFEFE',
    width: 406,
    borderRadius: 0,
    boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.1)',
    padding: 24,
  },
});
