import { useContext, useEffect, useState } from 'react';
import { FilterContext } from '../../../providers/FilterProvider';
import SearchBar from '../../SearchBar/SearchBar';
import { useQuery } from '../../../hooks/useQuery';
import { FILTER_PREFIX } from '../constants';
import { SearchBarType } from '../types';

const SearchBarFilter = ({ ...props }: SearchBarType) => {
  const { id, isPrivate = true, onSearch, ...restProps } = props;

  const { searchParams, setSearchParams } = useContext(FilterContext);
  const query = useQuery();
  const [initValue, setInitValue] = useState('');

  useEffect(() => {
    if (!isPrivate) {
      const queryValue = query.get(`${FILTER_PREFIX}_${id}`);
      if (queryValue) {
        setInitValue(queryValue);
        handleSearch(queryValue);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleSearch = (value) => {
    if (value) {
      searchParams.set(`${FILTER_PREFIX}_${id}`, isPrivate ? true : value);
    } else if (searchParams.get(`${FILTER_PREFIX}_${id}`)) {
      searchParams.delete(`${FILTER_PREFIX}_${id}`);
    }
    setSearchParams(searchParams);
    onSearch(value);
  };

  return (
    <SearchBar
      id={id || 'search-bar'}
      initialValue={initValue}
      onSearch={(str) => handleSearch(str)}
      {...restProps}
    />
  );
};

export default SearchBarFilter;
