import {
  Card,
  CardSection,
  Grid,
  GridCol,
  Icon,
  Link,
  Notification,
  Skeleton,
} from '@dovera/design-system';
import { useLocation } from 'react-router';
import useStyles from './styles';
// @ts-ignore
import Illustration from '../../assets/illustrations/rebrand-illustration.png';
import moment from 'moment';
import routes from '../../routes';
import { getCMSText } from '../../utils/cms.utils';
import { cmsPath } from '../../constants/cmsPath';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import BannerSkeleton from './BannerSkeleton';

const showUntil = '2025-03-25';

const InfoBanner = () => {
  const classes = useStyles();
  const location = useLocation();
  const { isLoading } = useSelector((state: RootState) => state.cms);

  if (moment() > moment(showUntil)) return null;

  if (location.pathname !== routes.dashboard)
    return (
      <Notification
        action={
          !isLoading ? (
            <Link
              className="text-color-black text-semibold"
              href={getCMSText(cmsPath.infoBanner.url, '', true, false)}
              placeholder=""
              target="_blank"
            >
              {getCMSText(cmsPath.infoBanner.btn, '', true, false)}
            </Link>
          ) : undefined
        }
        className={classes.notificationBanner}
        iconArea={<Icon name="heart" />}
        message={
          isLoading ? (
            <Skeleton width={300} />
          ) : (
            getCMSText(cmsPath.infoBanner.ntf, '', false, false)
          )
        }
        variant="blue"
      />
    );

  return (
    <Card className={classes.dashboardBanner}>
      <CardSection isCondensed>
        <Grid>
          <GridCol size={{ m: 8, l: 7, xl: 8, s: 12 }}>
            <div className={classes.dashboardBannerContent}>
              {isLoading ? (
                <BannerSkeleton />
              ) : (
                <>
                  {getCMSText(cmsPath.infoBanner.banner, '', false, false)}
                  <Link
                    className="btn btn--small mb-small text-color-black"
                    href={getCMSText(cmsPath.infoBanner.url, '', true, false)}
                    placeholder=""
                    target="_blank"
                  >
                    {getCMSText(cmsPath.infoBanner.btn, '', true, false)}
                  </Link>
                </>
              )}
            </div>
          </GridCol>
          <GridCol size={{ m: 4, l: 5, xl: 4, s: 12 }}>
            <img alt="" src={Illustration} srcSet={Illustration} />
          </GridCol>
        </Grid>
      </CardSection>
    </Card>
  );
};

export default InfoBanner;
