import { breakpoints, color, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  parametersIndexBanner: {
    display: 'flex',
    background: 'transparent',
    padding: getRem(30),
    alignItems: 'center',
    justifyContent: 'space-between',
    '& img': {
      width: getRem(248),
      marginRight: getRem(32),
    },
    '& > div p': {
      maxWidth: '100%',
    },
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      padding: `${getRem(16)} !important`,
      '& img': {
        display: 'none',
      },
    },
  },
  large: {
    padding: getRem(32),
    '& > div p': {
      maxWidth: getRem(492),
    },
  },
  description: {
    display: 'block',
    color: color('grey', 500),
    maxWidth: getRem(545),
  },
  bannerNtf: {
    '& .notification__contentArea': {
      gap: 0,
    },
    '& .notification__iconArea': {
      display: 'block',
    },
  },
});
