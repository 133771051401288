import useStyles from '../Spravy.styles';
import {
  Button,
  ButtonLayout,
  ButtonLink,
  Icon,
  Illustration,
  color,
} from '@dovera/design-system';
import strings from '../../../constants/strings';
import SafeHtml from '../../../components/SafeHtml/SafeHtml';
import { useNavigate } from 'react-router';
import routes from '../../../routes';
import { cx } from '../../../utils/exports';

interface Props {
  onClickLoadAgain: () => void;
  single?: boolean;
}

const texts = strings.schrankaSprav.emptyStates.loadError;

const LoadError = ({ onClickLoadAgain, single }: Props) => {
  const classes = useStyles({});
  const navigate = useNavigate();
  return (
    <div className={classes.emptyState}>
      <Illustration
        className="mb-xsmall"
        height={128}
        name="large_warning_state"
        width={128}
      />
      <h4 className="text-color-black mb-xsmall">{texts.title(!!single)}</h4>
      <SafeHtml
        className={cx(classes.description, 'mb-large')}
        html={texts.description(!!single)}
        wrapper="div"
      />
      <ButtonLayout direction="horizontal">
        {!single && (
          <ButtonLink
            className={classes.button}
            onClick={() => navigate(routes.dashboard)}
          >
            <Icon
              className="icon--left"
              color={color('primary', 700)}
              name="home"
              size="medium"
            />
            {texts.buttons.home}
          </ButtonLink>
        )}
        <Button className={classes.button} onClick={onClickLoadAgain}>
          {texts.buttons.loadAgain}
        </Button>
      </ButtonLayout>
    </div>
  );
};

export default LoadError;
