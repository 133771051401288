import {
  GetProposalsListResponse,
  GetProposalsListResponseSK,
} from '../../types/spaProposals.types';

export const convertGetProoposalsList = (
  data: GetProposalsListResponseSK,
): GetProposalsListResponse => ({
  count: data.pocet,
  proposals: data.navrhy.map((proposal) => ({
    doctor: {
      ambulanceCode: proposal.lekar.kodAmbulancia,
      ambulanceName: proposal.lekar.nazovAmbulancia,
      code: proposal.lekar.kod,
      personName: proposal.lekar.meno,
      personSurname: proposal.lekar.priezvisko,
      titleAfter: proposal.lekar.titulZa,
      titleBefore: proposal.lekar.titulPred,
    },
    id: proposal.id,
    insuree: {
      insureeId: proposal.poistenec.rodneCislo,
      personName: proposal.poistenec.meno,
      personSurname: proposal.poistenec.priezvisko,
    },
    proposalId: proposal.idNavrhNaZS,
    proposalNumber: proposal.cisloNavrhu,
    proposalValidityFrom: proposal.platnostNavrhuOd,
    proposalValidityTo: proposal.platnostNavrhuDo,
    state: proposal.stav,
  })),
});
