export default {
  header: {
    description: {
      VB_SAS_LEK: 'Ak budes setrit budget dostanes x% z usporenej sumy naspat.',
      VB_SAS_SVL:
        'Ak budes setrit budget dostanes x% z usporenej sumy naspat. Ako funguje metodika sa docitas v <a href="https://www.dovera.sk" target="_blank">clanku na nasom webe</a>',
    },
  },
  reportDetail: {
    empty: 'Pre vybrané obdobie nemáte žiadne vyhodnotenie.',
    error:
      'Mrzí nás to, ale Vaše vyhodnotenie za aktuálne obdobie sa nepodarilo zobraziť.<br />Skúste to znovu, prosím, alebo nás kontaktujte 0800 150 155.',
  },
};
