import { useCallback, useState } from 'react';
import { Tag, Textarea } from '@dovera/design-system';
import strings from '../../../../../../constants/strings';
import useStyles from '../../../../Proposals.styles';
import {
  GetDiagnosesListPayload,
  GetFormDataResponse,
} from '../../../../../../types/proposals.types';
import MultiSelectAutocomplete from '../../../../../../components/MultiSelectAutocomplete/MultiSelectAutocomplete';
import { getAnotherDiagnosesList } from '../../../../../../api/spaProposals';
import {
  firstCharToUpper,
  getListFromString,
} from '../../../../../../utils/strings.utils';
import {
  MAX_LENGTH_TEXTAREA,
  MIN_LENGTH_ANAMNESE,
} from '../../../../../../constants/proposals';
import RemainingChars from '../../../../../../components/RemainingChars/RemainingChars';
import SafeHtml from '../../../../../../components/SafeHtml/SafeHtml';

interface Props {
  additionalDiagnosesField: any;
  anamneseField: any;
  formData: GetFormDataResponse | null;
  onSelectedDiagnose: (diagnoses: { kod: string; nazov: string }[]) => void;
  reset?: boolean;
}

const texts = strings.proposals.spa.new;

const Form = ({
  additionalDiagnosesField,
  anamneseField,
  formData,
  onSelectedDiagnose,
  reset,
}: Props) => {
  const classes = useStyles({ proposalType: 'KN' });
  const [isLoadingDiagnoses, setIsLoadingDiagnoses] = useState(false);
  const [diagnosesOptions, setDiagnosesOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const diagnosesChangeCallback = useCallback((value: string) => {
    if (value.length > 1) {
      setIsLoadingDiagnoses(true);
      const payload: GetDiagnosesListPayload = {
        hladanaDiagnoza: value,
      };
      getAnotherDiagnosesList(payload).then((data) => {
        setDiagnosesOptions(
          data?.vedlajsieDiagnozy
            .filter((d, key) => key < 50)
            .map((d) => ({
              label: `${d.kod} - ${firstCharToUpper(d.nazov)}`,
              value: d.kod,
            })) || [],
        );
        setIsLoadingDiagnoses(false);
      });
    }
  }, []);
  const renderDescription = (
    <div className={classes.longTextarea}>
      <Textarea
        {...anamneseField.input}
        error={
          anamneseField.meta.touched &&
          anamneseField.meta.error && (
            <SafeHtml html={anamneseField.meta.error} />
          )
        }
        help={
          <RemainingChars
            formHelper={
              <div className={classes.tags}>
                {getListFromString(
                  formData?.udajDetIndSkup?.objektivnyNalez || '',
                )?.map((o, key) => (
                  <Tag key={`description-tag-step4--${key}`} variant="outline">
                    {o}
                  </Tag>
                ))}
              </div>
            }
            maxLength={MAX_LENGTH_TEXTAREA}
            valueLength={anamneseField.input.value?.length}
          />
        }
        id="proposal--step4--anamnese"
        isRequired
        label={texts.labels.medicalHistory}
        maxLength={MAX_LENGTH_TEXTAREA}
        minLength={MIN_LENGTH_ANAMNESE}
      />
    </div>
  );
  const renderDiagnoses = (
    <MultiSelectAutocomplete
      {...additionalDiagnosesField.input}
      help={texts.helpers.secondaryDiagnoses}
      id="proposal--step4--additionalDiagnoses"
      isLoading={isLoadingDiagnoses}
      label={texts.labels.secondaryDiagnoses}
      maxChoose={5}
      noResults="Nenašli sme žiadne diagnózy."
      onChangeDebounced={(value) => diagnosesChangeCallback(value)}
      onSelected={(values) =>
        onSelectedDiagnose(
          values.map((v) => ({
            kod: v.value,
            nazov: v.label?.split(`${v.value} - `)?.[1],
          })),
        )
      }
      options={diagnosesOptions}
      reset={reset}
    />
  );
  return (
    <>
      {renderDescription}
      {renderDiagnoses}
    </>
  );
};

export default Form;
