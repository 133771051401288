import { Button, ButtonLayout, Modal as DDSModal } from '@dovera/design-system';
import { useModalContext } from '../../../hooks/useModalContext';
import { ModalObjectType } from '../../../types/jsonObjects.types';
import JsonObject from '../JsonObject';
import useStyles from '../styles';
import { useEffect } from 'react';
import { scrollToModalTop } from '../../../utils/app.utils';

interface Props {
  modalID: string;
  object: ModalObjectType;
}

const Modal = ({ modalID, object, ...other }: Props) => {
  const classes = useStyles();
  const modalContext = useModalContext();
  const {
    attributes: { closeButton, title },
    content,
  } = object;

  useEffect(() => {
    if (modalContext.isOpenModal(modalID)) scrollToModalTop();
  }, [modalContext, modalID]);

  return (
    <DDSModal
      className={classes.modal}
      closeOnOverlayClick={false}
      data-keyboard="false"
      data-modal-initial-focus
      footer={<div />}
      header={title}
      id={modalID}
      isVisible={modalContext.isOpenModal(modalID)}
      onHide={() => {
        modalContext.closeModal(modalID);
      }}
      {...other}
    >
      <JsonObject data={content} isModalContent />
      <ButtonLayout className={classes.modalBottomBtns} direction="horizontal">
        <Button
          onClick={() => modalContext.closeModal(modalID)}
          type={closeButton.type}
        >
          {closeButton.text}
        </Button>
      </ButtonLayout>
    </DDSModal>
  );
};

export default Modal;
