import { ReactNode, useState } from 'react';
import useStyles from './SolveRow.styles';
import IconWarningCircle from '../CustomIcons/IconWarningCircle';
import IconErrorCircle from '../CustomIcons/IconErrorCircle';
import { Bar, ButtonLink, Icon, ListItem } from '@dovera/design-system';
import SmoothCollapse from 'react-smooth-collapse';
import { useNavigate } from 'react-router';
import IconCheckCircle from '../CustomIcons/IconCheckCircle';

interface Props {
  children?: any;
  countToSolve: number;
  description: string;
  showAll?: {
    route: string;
    title: string;
  } | null;
  state: 'success' | 'warning' | 'error' | string;
  testId?: string;
  title: string;
}

const SolveRow = ({
  children,
  countToSolve,
  description,
  showAll,
  state,
  testId,
  title,
}: Props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles({ countToSolve, isOpen });
  const renderIconSuccess = <IconCheckCircle />;
  const renderIconWarning = (
    <IconWarningCircle id={`solve-row-warning-${title}`} />
  );
  const renderIconError = (
    <IconErrorCircle height={32} id={`solve-row-error-${title}`} width={32} />
  );
  const renderInfo = (): ReactNode => {
    if (state === 'warning') return renderIconWarning;
    return state === 'success' ? renderIconSuccess : renderIconError;
  };
  return (
    <ListItem className="no-pad" data-testid={testId} role="listitem">
      <Bar
        className={classes.row}
        onClick={() => countToSolve > 0 && setIsOpen(!isOpen)}
      >
        <div className={classes.icon}>{renderInfo()}</div>

        <div>
          <h6 className="no-mrg">{title}</h6>
          <span className={classes.description}>{description}</span>
        </div>
        {countToSolve ? (
          <span className={classes.angle}>
            <Icon color="#6D7175" name="chevron-down" size="medium" />
          </span>
        ) : (
          <span />
        )}
      </Bar>
      <SmoothCollapse expanded={isOpen}>
        <div className={classes.children}>
          {children}
          {showAll && (
            <div className={classes.showAll}>
              <ButtonLink
                className="no-mrg no-pad"
                onClick={() => navigate(showAll.route)}
              >
                {showAll.title}
              </ButtonLink>
            </div>
          )}
        </div>
      </SmoothCollapse>
    </ListItem>
  );
};

export default SolveRow;
