import { createUseStyles } from 'react-jss';
import { color } from '../../constants';
import { getRem } from '@dovera/design-system';

interface StylesProps {
  optionsTop: boolean;
}

export default createUseStyles({
  selectedContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: getRem(12),
    marginTop: getRem(24),
    '& .Tag': {
      marginBottom: 0,
      marginLeft: 0,
      '&__closeButton': {
        color: color('black'),
        opacity: 1,
      },
    },
  },
  multiAutocomplete: {
    position: 'relative',
    '& > .form-control': {
      margin: 0,
      '& > .form-label': {
        color: color('black'),
      },
      '& > .form-message': {
        color: color('black'),
        fontSize: getRem(14),
      },
      '& > .input-addons': {
        '& > div': {
          maxWidth: 'initial',
        },
        maxWidth: `${getRem(516)} !important`,
      },
    },
  },
  optionsWrapper: ({ optionsTop }: StylesProps) => ({
    position: 'absolute',
    border: `1px solid ${color('grey', 100)}`,
    borderRadius: getRem(8),
    background: color('white'),
    zIndex: 9,
    padding: getRem(12),
    width: '100%',
    maxWidth: getRem(516),
    marginTop: getRem(4),
    top: optionsTop ? getRem(-260) : 'auto',
    '& .form-control': {
      marginBottom: `${getRem(16)} !important`,
    },
  }),
  options: {
    position: 'relative',
    overflow: 'auto',
    maxHeight: getRem(225),
  },
  buttons: {
    position: 'relative',
    width: '100%',
    padding: `${getRem(15)} 0 0`,
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      margin: 0,
      width: `calc(50% - ${getRem(6)})`,
    },
  },
});
