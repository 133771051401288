import { createUseStyles } from 'react-jss';
import { breakpoints, color, getRem } from '@dovera/design-system';

interface StylesProps {
  env: any;
  isStatic: boolean;
  isSubjectAccess: boolean;
}

const getBadgeColor = (env: string): string => {
  if (env === 'TEST-VNS') return color('secondary');
  return env === 'TEST' ? color('primary', 600) : color('error', 400);
};

export default createUseStyles({
  dlekHeader: {
    '& .header-desktop__menu': {
      textAlign: 'right',
    },
    '& .bar': {
      justifyContent: 'space-between',
    },
  },
  hamburger: {
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: '27%',
    '& svg': {
      verticalAlign: 'middle',
      marginRight: getRem(16),
    },
    [`@media screen and (min-width: ${breakpoints.l + 1}px)`]: {
      display: 'none',
    },
  },
  primaryButton: ({ env, isSubjectAccess }: StylesProps) => ({
    marginLeft: getRem(env === 'PROD' || isSubjectAccess ? 32 : 130),
    marginBottom: 0,
    alignSelf: 'center',
    [`@media screen and (max-width: ${breakpoints.l}px)`]: {
      display: 'none',
    },
  }),
  badgeVersion: ({ env }: StylesProps) => ({
    backgroundColor: getBadgeColor(env),
    color: 'white',
    borderRadius: '0 0 5px 5px',
    padding: '2px 8px',
    marginRight: 40,
    position: 'absolute',
    top: 0,
    left: 192,
    minWidth: 90,
    textAlign: 'center',
    [`@media screen and (max-width: ${breakpoints.m}px)`]: {
      width: 50,
      left: 60,
      '& span': {
        display: 'none',
      },
    },
    '@media (min-width: 768px) and (max-width: 991px)': {
      left: 250,
    },
  }),
});
