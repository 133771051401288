import { ReactNode, useEffect, useState } from 'react';
import useStyles from './Toast.styles';
import IconCheckCircle from '../CustomIcons/IconCheckCircle';
import IconWarningCircle from '../CustomIcons/IconWarningCircle';
import IconErrorCircle from '../CustomIcons/IconErrorCircle';
import { cx } from '../../utils/exports';
import { Icon } from '@dovera/design-system';

interface Props {
  /** hide after in ms */
  hideAfter?: number;
  higherPosition?: boolean;
  iconType?: 'success' | 'warning' | 'error';
  onHide?: () => void;
  show?: boolean;
  text: string | ReactNode;
  topPosition?: boolean;
}

const Toast = ({
  hideAfter,
  higherPosition,
  iconType,
  onHide,
  show,
  text,
  topPosition,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    if (hideAfter)
      setTimeout(() => {
        setVisible(false);
        if (onHide) onHide();
      }, hideAfter);
  }, [hideAfter, onHide]);
  useEffect(() => {
    if (typeof show === 'boolean') setVisible(show);
  }, [show]);
  const renderIcon = (): ReactNode => {
    if (!iconType) return <span />;
    if (iconType === 'success')
      return (
        <IconCheckCircle height={24} id={`icon-check--toast`} width={24} />
      );
    if (iconType === 'warning')
      return (
        <IconWarningCircle height={24} id={`icon-warning--toast`} width={24} />
      );
    return <IconErrorCircle height={24} id={`icon-error--toast`} width={24} />;
  };
  return (
    <div className={classes.toastWrapper}>
      <div
        className={cx(
          classes.toast,
          !visible && 'hide',
          higherPosition && 'toast--higher',
          topPosition && 'toast--top',
        )}
      >
        {renderIcon()}
        <div>{text}</div>
        {onHide && (
          <div className={classes.closeBtnWrapper}>
            <button onClick={onHide} type="button">
              <Icon color="white" name="close" size="small" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Toast;
