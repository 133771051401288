import ProposalHeader from './ProposalHeader';
import SkeletonLoaderHeader from '../SkeletonLoader/SkeletonLoaderHeader';
import strings from '../../../../constants/strings';
import useStyles from '../../Proposals.styles';
import { cx } from '../../../../utils/exports';
import { useFetchCnpDetail } from '../../../../api/drugProposals';
import HeaderLNActions from './HeaderLNActions';
import { PROPOSAL_TYPES } from '../../../../types/proposals.types';

const texts = strings.proposals.spa.detail;

interface Props {
  proposalId: number;
  proposalNumber: string;
}

const HeaderCNP = ({ proposalId, proposalNumber }: Props) => {
  const classes = useStyles({ proposalType: 'CNP' });

  const { data, error, isFetching } = useFetchCnpDetail({
    detailType: 'CNP',
    proposalId,
    proposalNumber,
  });

  const renderTitle = (
    <h2 className={cx('mb', classes.title)}>
      <span>{texts.title(proposalNumber)}</span>
    </h2>
  );
  if (isFetching)
    return (
      <>
        {renderTitle}
        <SkeletonLoaderHeader />
      </>
    );
  if (!data || error) return renderTitle;
  return (
    <>
      {renderTitle}
      <ProposalHeader
        accepted={data.detail.accepted}
        exposedDate={data.detail.exposed}
        exposedType={data.detail.exposeType}
        processDate={data.detail.decided}
        state={data.detail.state}
        type="CNP"
        validityFrom={data.detail.proposalValidityFrom}
        validityTo={data.detail.proposalValidityTo}
      />
      <HeaderLNActions
        insureeIdTxs={data.patient.insureeIdTxs}
        proposalCompletionText={data.detail.completionTxs}
        proposalId={proposalId}
        proposalNumber={proposalNumber}
        state={data.detail.state}
        type={PROPOSAL_TYPES.CNP}
      />
    </>
  );
};

export default HeaderCNP;
