import React, { ReactElement, ReactNode } from 'react';
import {
  Bar,
  BarItem,
  ButtonSecondary,
  Icon,
  color,
} from '@dovera/design-system';

import useStyles from '../formula.styles';
import { cx } from '../../../utils/exports';
import strings from '../../../constants/strings';

type Props = {
  accordionItem?: {
    child: string | ReactElement | ReactElement[];
    id: string;
    isActive: boolean;
  };
  clickable?: boolean;
  hodnota?: string | number | ReactElement;
  isFilled?: boolean;
  medzisucet?: boolean;
  nazov?: string | ReactNode;
  onFormulaClick?: (id?: string) => void;
  vnorenyVzorec?: boolean;
  withoutFill?: boolean;
} & React.JSX.IntrinsicElements['div'];

const Formula = ({
  accordionItem,
  children,
  clickable,
  hodnota,
  isFilled,
  medzisucet,
  nazov,
  onFormulaClick,
  vnorenyVzorec,
  withoutFill,
  ...other
}: Props) => {
  const classes = useStyles({
    isFilled,
    hasChildren: !!children,
    medzisucet,
    hasAccordionItem: !!accordionItem,
    vnorenyVzorec,
    withoutFill,
  });

  return (
    <div
      className={cx(
        classes.formula,
        clickable && 'clickable',
        withoutFill && 'withoutFill',
        !!children && classes.formulaWithChildren,
      )}
      {...other}
    >
      {(nazov || hodnota) && (
        <Bar
          className={cx(
            'no-mrg-bottom',
            accordionItem && classes.accordionFormula,
            accordionItem && accordionItem.isActive && 'active',
          )}
          onClick={() =>
            (clickable || accordionItem) &&
            onFormulaClick &&
            onFormulaClick(accordionItem && accordionItem.id)
          }
        >
          <BarItem isFilling>{nazov}</BarItem>
          <BarItem className={classes.hodnota}>
            {(!accordionItem || !accordionItem.isActive) &&
              (hodnota || strings.undefined)}
          </BarItem>
          {clickable && (
            <ButtonSecondary
              className={cx(
                classes.formulaAccordionIcon,
                'btn--equal btn--round no-mrg-bottom',
                accordionItem && accordionItem.isActive && 'active',
              )}
              size="xs"
            >
              <Icon
                className="clickable"
                color={color('primary', 600)}
                name="chevron-down"
                size="small"
              />
            </ButtonSecondary>
          )}
        </Bar>
      )}
      {accordionItem && accordionItem.child && (
        <div
          className={cx(
            classes.formulaAccordionContent,
            accordionItem.isActive && 'active',
          )}
        >
          {accordionItem.child}
        </div>
      )}
      {children}
    </div>
  );
};

export default Formula;
