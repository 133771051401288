import { color } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

interface StylesProps {
  isError: boolean;
}

export default createUseStyles({
  header: ({ isError }: StylesProps) => ({
    '& > .stepper__header': {
      '& > .stepper__header__heading--error': {
        color: isError ? `${color('error', 500)} !important` : 'inherit',
      },
    },
  }),
});
