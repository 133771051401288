import { useContext, useEffect, useState } from 'react';
import { FilterContext } from '../../../providers/FilterProvider';
import { Tabs } from '@dovera/design-system';
import { FILTER_PREFIX } from '../constants';
import { useQuery } from '../../../hooks/useQuery';

const TabsFilter = (props) => {
  const { activeTabIndex, children, id, onChange, ...restProps } = props;

  const [activeIndex, setActiveIndex] = useState(activeTabIndex);
  const { searchParams, setSearchParams } = useContext(FilterContext);
  const query = useQuery();

  useEffect(() => {
    const queryTabIndex = query.get(`${FILTER_PREFIX}_${id}`);
    if (queryTabIndex) {
      setTimeout(() => {
        const [panelId, index] = queryTabIndex.split('-');
        setActiveIndex(Number(index));
        handleChange({ index: Number(index), panel: { id: panelId } });
      }, 500);
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    searchParams.set(`${FILTER_PREFIX}_${id}`, `${e.panel.id}-${e.index}`);
    setSearchParams(searchParams);
    onChange(e);
  };
  return (
    <Tabs
      activeTabIndex={activeIndex}
      id={id || 'tabs--filter'}
      onChange={(e) => handleChange(e)}
      {...restProps}
    >
      {children}
    </Tabs>
  );
};

export default TabsFilter;
