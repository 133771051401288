import { ParameterDetail } from '../../../../types/parameters.types';
import CMSText from '../../../../components/CMSText/CMSText';
import Graph from './Graph';
import { Formula } from '../../../../components/FormulaVisualization';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';
import { Parametrization } from '../../../../components/JsonParametrization';
import useStyles from '../../styles/detail.styles';

interface Props {
  data: ParameterDetail[];
}

const Content = ({ data }: Props) => {
  const classes = useStyles();
  return (
    <>
      {data?.map((item) => {
        switch (item.type) {
          case 'text':
            return item.attributes.text;
          case 'cms':
            return (
              <CMSText
                classes={classes.htmlContent}
                params={item.attributes.params}
                path={item.attributes.id}
              />
            );
          case 'graph':
            return <Graph attributes={item.attributes} />;
          case 'formula':
            return <Formula attributes={item.attributes} />;
          case 'html':
            return (
              <SafeHtml
                className={classes.htmlContent}
                html={item.attributes.text}
                wrapper="div"
              />
            );
          case 'value':
            return item.attributes.value;
          case 'structure':
            return <Parametrization structure={item} />;
          default:
            return null;
        }
      })}
      <div className="mb-large" />
    </>
  );
};

export default Content;
