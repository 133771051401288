import { fontWeight, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    '& p': {
      fontSize: getRem(16),
    },
  },
  headerWrapper: {
    width: '100%',
    maxWidth: getRem(615),
  },
  textContent: {
    maxWidth: getRem(615),
    display: 'block',
    '& a': {
      fontWeight: fontWeight.semiBold,
    },
  },
  filter: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      width: getRem(382),
      gap: getRem(20),
    },
  },
  skeletonLoader: {},
  skeletonCircle: {
    borderRadius: '100px !important',
  },
  contentLayout: {
    '& h5': {
      marginTop: `${getRem(24)} !important`,
    },
  },
});
