import { CarouselAttributes } from '../../../types/jsonObjects.types';
import { Carousel as NukaCarousel, useCarousel } from 'nuka-carousel';
import useStyles from '../styles';
import JsonObject from '../JsonObject';
import { cx } from '../../../utils/exports';

const Dots = () => {
  const { currentPage, goToPage, totalPages } = useCarousel();
  const classes = useStyles();

  return (
    <div className={classes.carouselDotsWrapper}>
      <div className={classes.carouselDots}>
        {[...Array(totalPages).keys()].map((i, index) => (
          <button
            key={i}
            className={cx(
              classes.carouselDot,
              currentPage === index && 'active',
            )}
            onClick={() => goToPage(index)}
            type="button"
          />
        ))}
      </div>
    </div>
  );
};

const Carousel = ({ attributes, content }: CarouselAttributes) => {
  const classes = useStyles();

  if (!content || !content.length) return null;

  return (
    <NukaCarousel
      autoplay={attributes.autoplay}
      autoplayInterval={attributes.autoplayPause}
      dots={<Dots />}
      showArrows={false}
      showDots
      wrapMode="wrap"
    >
      {content.map((item, index) => (
        <div key={`carousel-item-${index}`} className={classes.carouselSlide}>
          <JsonObject data={[item]} />
        </div>
      ))}
    </NukaCarousel>
  );
};

export default Carousel;
