import { color } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

export default createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: color('white'),
    borderRadius: 0,
    padding: 0,
    boxShadow: '0 -4px 6px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  menuItem: {
    cursor: 'pointer',
    color: color('black'),
    padding: '16px 32px',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${color('grey', 200)}`,
    },
    '&:hover': {
      backgroundColor: color('grey', 100),
    },
  },
});
