import { ReactNode } from 'react';
import useAppStyles from '../../App.styles';
import { Container } from '@dovera/design-system';
import { cx } from '../../utils/exports';

interface Props {
  children: ReactNode;
  className?: any;
  id: string;
}

const ContentWrapper = ({ children, className, id }: Props) => {
  const classesApp = useAppStyles();
  return (
    <div
      className={cx(
        classesApp.pageLayout,
        className || classesApp.padLayoutTop,
      )}
    >
      <Container className="mb-large" id={id}>
        {children}
      </Container>
    </div>
  );
};

export default ContentWrapper;
