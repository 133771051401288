import { createUseStyles } from 'react-jss';
import { breakpoints, getRem } from '@dovera/design-system';

export default createUseStyles({
  closeBtnWrapper: {
    position: 'relative',
    width: `${getRem(24)} !important`,
    marginLeft: getRem(8),
    '& button': {
      cursor: 'pointer',
      position: 'absolute',
      right: 0,
      left: getRem(8),
      top: getRem(3),
    },
    [`@media screen and (max-width: ${breakpoints.s}px)`]: {
      display: 'none',
    },
  },
  toastWrapper: {
    position: 'fixed',
    bottom: getRem(52),
    width: '100%',
    zIndex: 999999999,
  },
  toast: {
    position: 'relative',
    background: 'rgba(43, 43, 43, .9)',
    borderRadius: 8,
    width: 'fit-content',
    maxWidth: '80%',
    margin: '0 auto',
    textAlign: 'left',
    color: 'white',
    transform: 'translate(-50%, -50%)',
    padding: getRem(16),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    fontWeight: 400,
    '& svg': {
      marginRight: getRem(12),
    },
    '&.toast--higher': {
      bottom: getRem(150),
    },
    '&.toast--top': {
      bottom: 'auto',
      top: getRem(110),
    },
    [`@media screen and (max-width: ${breakpoints.s}px)`]: {
      width: `100%`,
      '& > div': {
        width: '90%',
      },
    },
  },
});
