import SafeHtml from '../../SafeHtml/SafeHtml';
import { getPercentage } from '../utils';
import { color as ddsColor } from '@dovera/design-system';
import useStyles from '../styles';
import { containsHtmlTags } from '../../../utils/strings.utils';

interface Props {
  color?: string;
  defaultValue: string | null;
  hoveredChartIndex?: number | null;
  hoveredIndex?: number | null;
  indexChart: number;
  isMain?: boolean;
  value?: number | string;
}

const LabelEl = ({
  color,
  defaultValue,
  hoveredChartIndex,
  hoveredIndex,
  indexChart,
  isMain,
  value,
}: Props) => {
  const classes = useStyles({});
  let data = {
    dy: isMain ? '0' : '1.4em',
    fill: ddsColor('black'),
    value:
      defaultValue && typeof defaultValue === 'number'
        ? `${getPercentage(defaultValue, true)}%`
        : defaultValue?.toString() || '',
  };
  const styles = isMain
    ? {
        fontSize: 38 / (indexChart + 1),
        fontWeight: 'bold',
      }
    : { fontSize: 14 / (indexChart ? indexChart + 0.5 : 1) };
  if (hoveredIndex !== null && hoveredChartIndex === indexChart)
    data = {
      ...data,
      fill: color || '',
      value:
        value && typeof value === 'number'
          ? `${getPercentage(Number(value), true)}%`
          : value?.toString() || '',
    };
  if (indexChart)
    data = {
      ...data,
      dy: isMain ? '.3em' : '2em',
    };

  if (containsHtmlTags(data.value))
    return (
      <foreignObject
        dy={data.dy}
        height="100%"
        style={{ ...styles, ...{ color: data.fill, display: 'flex' } }}
        textAnchor="middle"
        width="100%"
        x="0"
        y="0"
      >
        <div
          className={classes.labelForeign}
          /* @ts-ignore */
          xmlns="http://www.w3.org/1999/xhtml"
        >
          <SafeHtml html={data.value} wrapper="div" />
        </div>
      </foreignObject>
    );

  return (
    <text
      dy={data.dy}
      fill={data.fill}
      style={styles}
      textAnchor="middle"
      x="50%"
      y="50%"
    >
      {data.value}
    </text>
  );
};

export default LabelEl;
