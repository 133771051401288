import React from 'react';
import useStyles from './PasswordIndicator.styles';
import strings from '../../constants/strings';
import { Bar, BarItem, Icon, color as ddsColor } from '@dovera/design-system';
import { cx } from '../../utils/exports';

const { strongLabel, weekLabel } = strings.passwordIndicator;

type Props = {
  color?: string;
  score: number;
} & React.JSX.IntrinsicElements['div'];

const PasswordIndicator = ({ color, score, ...other }: Props) => {
  const classes = useStyles({ color: color || '', score });
  return (
    // eslint-disable-next-line
    <div className={classes.root} role="presentation" {...other}>
      <div className={classes.bar} />
    </div>
  );
};

const PasswordStrengthBar = ({ score, ...other }: Props) => {
  let icon;
  let label;
  let color;

  icon = (
    <Icon id="password-strength-bar--success" name="check" size="medium" />
  );
  label = strongLabel;
  color = ddsColor('primary', 600);

  if (score < 80) {
    icon = (
      <Icon id="password-strength-bar--warning" name="warning" size="medium" />
    );
    label = weekLabel;
    color = ddsColor('warning');
  }

  if (score <= 20) {
    icon = (
      <Icon id="password-strength-bar--error" name="error" size="medium" />
    );
    label = weekLabel;
    color = ddsColor('error', 600);
  }
  const classes = useStyles({ color, score });
  return (
    // eslint-disable-next-line
    <div {...other}>
      <Bar className={cx(classes.strengthBar, 'mb-small')} space="xxsmall">
        <BarItem>{icon}</BarItem>
        <BarItem>{label}</BarItem>
        <BarItem isFilling>
          <PasswordIndicator color={color} score={score} />
        </BarItem>
      </Bar>
    </div>
  );
};

export default PasswordStrengthBar;
