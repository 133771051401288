import SafeHtml from '../SafeHtml/SafeHtml';
import useStyles from './EmptyState.styles';
import { cx } from '../../utils/exports';
import { Illustration } from '@dovera/design-system';

const EmptyStateExpertises = () => {
  const classes = useStyles();
  return (
    <div className={cx(classes.root, classes.centeredContent)}>
      <Illustration
        height={173}
        name="large_empty_state"
        size="large"
        width={208}
      />
      <SafeHtml
        className="mb-small"
        html="Dáta pre zvolenú odbornosť neexistujú"
        wrapper="h4"
      />
      <SafeHtml
        className="mb-small"
        html="Prosím, vyberte si dostupnú odbornosť pre danú sekciu a pokračujte."
        wrapper="div"
      />
    </div>
  );
};

export default EmptyStateExpertises;
