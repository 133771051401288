import { createUseStyles } from 'react-jss';
import { color, colors, getRem } from '@dovera/design-system';

interface StylesProps {
  countToSolve: number;
  isOpen: boolean;
}

export default createUseStyles({
  root: {
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.grey?.['100']}`,
    },
  },
  children: {
    '& .child-row': {
      padding: '20px 32px 24px 80px',
      '&:not(:last-child)': {
        borderBottom: `1px solid ${colors.grey?.['100']}`,
      },
      '&:first-child': {
        paddingTop: 8,
      },
      '&--auth': {
        borderBottom: `1px solid ${colors.grey?.['100']}`,
      },
      '&:last-child': {
        borderBottom: 'none !important',
      },
    },
  },
  row: ({ countToSolve }: StylesProps) => ({
    padding: `0 ${getRem(32)}`,
    position: 'relative',
    display: 'flex',
    cursor: countToSolve ? 'pointer' : 'normal',
    transition: 'all 0.2s ease 0s',
    alignItems: 'flex-start',
    marginBottom: 0,
    '& div span': {
      color: color('grey', 500),
      fontSize: getRem(14),
    },
    '&:hover': {
      backgroundColor: countToSolve && 'rgb(242, 245, 250)',
    },
    '&.bar--horizontal': {
      marginTop: 0,
    },
  }),
  angle: ({ isOpen }: StylesProps) => ({
    position: 'absolute',
    right: getRem(37),
    transition: 'transform .2s ease-in-out',
    transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  }),
  icon: {
    width: getRem(32),
    height: getRem(32),
    marginRight: getRem(16),
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  showAll: {
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: getRem(16),
    '& button': {
      minHeight: 'auto',
    },
  },
  description: {
    fontSize: `${getRem(16)} !important`,
  },
});
