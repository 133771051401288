import { Card, Grid, GridCol, Skeleton } from '@dovera/design-system';
import useStyles from './DoveraNews.styles';

const DoveraNewsSkeleton = () => {
  const classes = useStyles();
  return (
    <Card className={classes.root} isBordered>
      <h3 className={classes.title}>
        <Skeleton width="30%" />
        <Skeleton width="20%" />
      </h3>
      <Grid>
        <GridCol size={{ m: 6, s: 12 }}>
          <Skeleton width="20%" />
          <Skeleton width="100%" />
          <Skeleton width="90%" />
        </GridCol>
        <GridCol size={{ m: 6, s: 12 }}>
          <Skeleton width="20%" />
          <Skeleton width="100%" />
          <Skeleton width="90%" />
        </GridCol>
      </Grid>
    </Card>
  );
};

export default DoveraNewsSkeleton;
