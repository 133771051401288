import { createUseStyles } from 'react-jss';
import { color, getRem } from '@dovera/design-system';
import { FormVariant } from '../../../../../../types/proposals.types';

export default createUseStyles({
  insureeBar: (variant: FormVariant) => ({
    fontSize: getRem(16),
    backgroundColor: variant === 'error' ? color(variant, 100) : color('white'),
    border: variant === 'success' ? `1px solid ${color('grey', 200)}` : 'none',
    borderRadius: getRem(16),
    padding: getRem(32),
    width: getRem(560),
    maxWidth: '100%',
    marginBottom: getRem(40),
    '&.is-warning': {
      backgroundColor: color('error', 100),
    },
  }),
  statusIcon: {
    position: 'absolute',
    right: getRem(22),
  },
  notification: {
    width: 'fit-content',
  },
});
