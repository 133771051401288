import { JsonObjectType, ModalObjectType } from './jsonObjects.types';

export enum ReportThemes {
  Expenses = 'NAKL',
  VB = 'VB',
}

export enum ReportSections {
  Drugs = 'VB_SAS_LEK',
  SVLZ = 'VB_SAS_SVL',
}

export type MenuSection = {
  id: number;
  order: number;
  sectionCode: ReportSections;
  sectionName: string;
};

export type MenuTheme = {
  sections: MenuSection[];
  theme: string;
};

export type TabFilter = {
  filterId: number;
  name: string;
  type: string;
  values: {
    value: string;
    valueId: number;
  }[];
};

export type Tab = {
  filters: TabFilter[] | null;
  id: number;
  name: string;
};

export type ReportWidget = {
  column: string;
  content: JsonObjectType | null;
  date: string;
  id: number;
  widgetCode: string;
};

export interface GetMenuItemsQueries {
  expCode: string;
  healthCareCode: string;
  themes: ReportThemes[];
}

export interface GetMenuItemsResponse {
  expertise: string;
  themes: MenuTheme[];
}

export interface ReportsSliceState {
  virtualBudget: {
    activeTabId: number;
    filter?: {
      [key: string]: string;
    };
  };
}

export interface GetReportStructureQueries {
  reportId: number;
}

export interface GetReportStructureResponse {
  report: {
    contentHeader: JsonObjectType[];
    tabs: Tab[];
  };
}

export interface GetReportDataQueries {
  reportId: number;
  strFilter?: string;
  tabId: number;
}

export interface GetReportDataResponse {
  filterContent: string;
  filterValue: string;
  isDefault: boolean;
  isDefaultTab: boolean;
  reportContent: { modals: ModalObjectType[]; pageContent: JsonObjectType[] };
  reportId: number;
  tabId: number;
}

export interface GetReportWidgetsQueries {
  expertiseCode: string;
  healthCareCode: string;
}

export interface GetReportWidgetsResponse {
  widgets: ReportWidget[];
}
